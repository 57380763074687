import { Action } from '@ngrx/store';

import { EncodingProfile } from '../../../models/encoding-profile';
import { DRMPolicy } from '../../../models/drm';
import * as fromEncoding from '../../reducers/settings/encoding';

export const CREATE_ENCODING_PROFILE = '[Setting] Create Encoding Profile';
export const CREATE_ENCODING_PROFILE_FAILED = '[Setting] Create Encoding Profile Failed';
export const CREATE_ENCODING_PROFILE_SUCCEEDED = '[Setting] Create Encoding Profile Succeeded';
export const DELETE_ENCODING_PROFILE = '[Setting] Delete Encoding Profile';
export const DELETE_ENCODING_PROFILE_FAILED = '[Setting] Delete Encoding Profile Failed';
export const DELETE_ENCODING_PROFILE_SUCCEEDED = '[Setting] Delete Encoding Profile Succeeded';
export const FILTER_ENCODING_PROFILES = '[Setting] Filter Encoding Profiles';
export const FILTER_ENCODING_PROFILES_FAILED = '[Setting] Filter Encoding Profiles Failed';
export const FILTER_ENCODING_PROFILES_SUCCEEDED = '[Setting] Filter Encoding Profiles Succeeded';
export const LOAD_ACTIVE_ENCODING_PROFILE = '[Setting] Get Active Encoding Profile';
export const LOAD_ACTIVE_ENCODING_PROFILE_FAILED = '[Setting] Get Active Encoding Profile Failed';
export const LOAD_ACTIVE_ENCODING_PROFILE_SUCCEEDED = '[Setting] Get Active Encoding Profile Succeeded';
export const LOAD_DRM_POLICIES = '[Setting] Load DRM Policies';
export const LOAD_DRM_POLICIES_FAILED = '[Setting] Load DRM Policies Failed';
export const LOAD_DRM_POLICIES_SUCCEEDED = '[Setting] Load DRM Policies Succeeded';
export const LOAD_ENCODING_PROFILES = '[Setting] Load Encoding Profiles';
export const LOAD_ENCODING_PROFILES_SUCCEEDED = '[Setting] Load Encoding Profiles Succeeded';
export const LOAD_ENCODING_PROFILES_FAILED = '[Setting] Load Encoding Profiles Failed';
export const SET_ACTIVE_ENCODNG_PROFILE = '[Setting] Set Active Encoding Profile';
export const SET_ACTIVE_ENCODNG_PROFILE_FAILED = '[Setting] Set Active Encoding Profile Failed';
export const SET_ACTIVE_ENCODNG_PROFILE_SUCCEEDED = '[Setting] Set Active Encoding Profile Succeeded';
export const SET_CURR_PROFILE = '[Setting] Set Curr Profile';
export const UPDATE_ENCODING_PROFILE = '[Setting] Update Encoding Profile';
export const UPDATE_ENCODING_PROFILE_SUCCEEDED = '[Setting] Update Encoding Profile Succeeded';
export const UPDATE_ENCODING_PROFILE_FAILED = '[Setting] Update Encoding Profile Failed';

export class LoadEncodingProfiles implements Action {
  readonly type = LOAD_ENCODING_PROFILES;
}

export class LoadEncodingProfilesSucceeded implements Action {
  readonly type = LOAD_ENCODING_PROFILES_SUCCEEDED;

  constructor(public props: Partial<fromEncoding.State>) { }
}

export class LoadEncodingProfilesFailed implements Action {
  readonly type = LOAD_ENCODING_PROFILES_FAILED;
}

export class CreateEncodingProfile implements Action {
  readonly type = CREATE_ENCODING_PROFILE;

  constructor(public encodingProfile: EncodingProfile) { }
}

export class CreateEncodingProfileSucceeded implements Action {
  readonly type = CREATE_ENCODING_PROFILE_SUCCEEDED;

  constructor(public profile: EncodingProfile) { }
}

export class CreateEncodingProfileFailed implements Action {
  readonly type = CREATE_ENCODING_PROFILE_FAILED;
}

export class DeleteEncodingProfile implements Action {
  readonly type = DELETE_ENCODING_PROFILE;

  constructor(public profileID: string) { }
}

export class DeleteEncodingProfileSucceeded implements Action {
  readonly type = DELETE_ENCODING_PROFILE_SUCCEEDED;

  constructor(public profileID: string) { }
}

export class DeleteEncodingProfileFailed implements Action {
  readonly type = DELETE_ENCODING_PROFILE_FAILED;
}

export class SetCurrProfile implements Action {
  readonly type = SET_CURR_PROFILE;

  constructor(public profile: EncodingProfile) { }
}

export class UpdateEncodingProfile implements Action {
  readonly type = UPDATE_ENCODING_PROFILE;

  constructor(public profile: EncodingProfile) { }
}

export class UpdateEncodingProfileSucceeded implements Action {
  readonly type = UPDATE_ENCODING_PROFILE_SUCCEEDED;

  constructor(public profile: EncodingProfile) { }
}

export class UpdateEncodingProfileFailed implements Action {
  readonly type = UPDATE_ENCODING_PROFILE_FAILED;
}

export class LoadActiveEncodingProfile implements Action {
  readonly type = LOAD_ACTIVE_ENCODING_PROFILE;
}

export class LoadActiveEncodingProfileSucceeded implements Action {
  readonly type = LOAD_ACTIVE_ENCODING_PROFILE_SUCCEEDED;

  constructor(public activeProfileId: string) { }
}

export class LoadActiveEncodingProfileFailed implements Action {
  readonly type = LOAD_ACTIVE_ENCODING_PROFILE_FAILED;
}

export class SetActiveEncodingProfile implements Action {
  readonly type = SET_ACTIVE_ENCODNG_PROFILE;

  constructor(public activeProfileId: string) { }
}

export class SetActiveEncodingProfileSucceeded implements Action {
  readonly type = SET_ACTIVE_ENCODNG_PROFILE_SUCCEEDED;

  constructor(public activeProfileId: string) { }
}

export class SetActiveEncodingProfileFailed implements Action {
  readonly type = SET_ACTIVE_ENCODNG_PROFILE_FAILED;
}

export class FilterEncodingProfiles implements Action {
  readonly type = FILTER_ENCODING_PROFILES;

  constructor(public filter: string) { }
}

export type Actions = (
  LoadEncodingProfiles | LoadEncodingProfilesSucceeded | LoadEncodingProfilesFailed |
  CreateEncodingProfile | CreateEncodingProfileSucceeded | CreateEncodingProfileFailed |
  DeleteEncodingProfile | DeleteEncodingProfileSucceeded | DeleteEncodingProfileFailed |
  SetCurrProfile | UpdateEncodingProfile | UpdateEncodingProfileSucceeded | UpdateEncodingProfileFailed |
  LoadActiveEncodingProfile | LoadActiveEncodingProfileSucceeded | LoadActiveEncodingProfileFailed |
  SetActiveEncodingProfile | SetActiveEncodingProfileSucceeded | SetActiveEncodingProfileFailed |
  FilterEncodingProfiles
);
