import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { empty } from 'rxjs/observable/empty';
import { zip } from 'rxjs/observable/zip';
import { Scheduler } from 'rxjs/Scheduler';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import * as UserActions from '../../actions/settings/users';
import * as fromRoot from '../../reducers';
import * as fromAuth from '../../reducers/auth';
import * as fromUsers from '../../reducers/settings/users';
import { User } from '../../../models/user';
import { Errors } from '../../../models/errors';
import { AccountMgmtService } from '../../../services/account-mgmt.service';
import { parseShimErrors } from '../../../utils/parse-errors';
import { LinkUsereModalComponent } from '../../../../settings/modals/link-user-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export const LOAD_DEBOUNCE = new InjectionToken<number>('Load Debounce');
export const LOAD_SCHEDULER = new InjectionToken<Scheduler>('Load Scheduler');

@Injectable()
export class UsersEffects {
  auth$: Observable<fromAuth.State>;
  users$: Observable<fromUsers.State>;

  // Listen for the 'LOAD_USERS' action
  @Effect()
  loadUsers$: Observable<Action> = this.actions$
    .pipe(
      ofType(UserActions.LOAD_USERS),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: UserActions.LoadUsers) => {
        let orgId: string;
        this.auth$.pipe(take(1)).subscribe((authState) => orgId = authState.user.organizationId);

        // wait for both calls to be completed before passing on
        return zip(
          this.ams.getUsers().pipe(
            map((users) => _.map(users, (u) => ({ ...u, external: false }))),
          ),
          this.ams.getExternalUsers(orgId).pipe(
            map((users) => _.map(users, (u) => ({ ...u, external: true }))),
          ),
        ).pipe(
          map((usersArrays) => new UserActions.LoadUsersSucceeded(_.flatten(usersArrays))),
          catchError((error) => of(new UserActions.LoadUsersFailed())),
        );
      }),
    );

  // Listen for the 'SAVE_USER' action
  @Effect()
  saveUsers$: Observable<Action> = this.actions$
    .pipe(
      ofType(UserActions.SAVE_USER),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: UserActions.SaveUser) => {
        let obs$: Observable<User>;
        let verbPast: string;
        const itemType: string = 'amedia.settings.user';
        this.users$.pipe(take(1)).subscribe((userState) => {
          if (!!userState.currUser) {
            action.user.id = userState.currUser.id;
          }
        });

        if (!!action.user.id) {
          obs$ = this.ams.saveUser(action.user);
          verbPast = 'amedia.updated';
        } else {
          obs$ = this.ams.createUser(action.user);
          verbPast = 'amedia.created';
        }
        return obs$.pipe(
          map((user) => {
            this.toastr.success(
              this.translate.instant(
                'amedia.successMessage',
                {
                  verbPast: this.translate.instant(verbPast),
                  itemType: this.translate.instant(itemType),
                  item: `${user.firstName} ${user.lastName}`,
                },
              ),
              this.translate.instant('amedia.success'),
            );
            return new UserActions.SaveUserSucceeded(user);
          }),
          catchError((error) => {
            let canLink: boolean = false;
            const errors: Errors = parseShimErrors(error.error || {});
            const saveFailed = new UserActions.SaveUserFailed(errors);

            // If the email is already in use when creating a new user
            if (!action.user.id && _.hasIn(errors.fields, 'email')) {
              this.users$.pipe(take(1)).subscribe((usersState) => {
                // Check to see if the user exists in the current org
                canLink = !_.find(
                  usersState.users,
                  (u) => u.email.toLowerCase() === action.user.email,
                );
              });
            }

            if (canLink) {
              let orgId: string;
              this.auth$.pipe(take(1)).subscribe((authState) => orgId = authState.user.organizationId);

              // Get the full use object because we need the ID
              return this.ams.getUser(action.user.email).pipe(
                map((user) => new UserActions.LinkUser(user, orgId, action.user.role)),
                catchError((e) => of(saveFailed)),
              );
            } else {
              return of(saveFailed);
            }
          }),
        );
      }),
    );

  // Listen for the 'SAVE_USER_FAILED' action
  @Effect()
  userNotSaved$: Observable<Action> = this.actions$
    .pipe(
      ofType(UserActions.SAVE_USER_FAILED),
      // .debounceTime(this.debounce, this.scheduler || async)
      switchMap((action: UserActions.SaveUserFailed) => {
        if (_.isEmpty(action.errors.fields)) {
          this.toastr.error(
            this.translate.instant('amedia.errors.failedToSaveUser'),
            this.translate.instant('amedia.content.error'),
          );
        }
        return empty();
      }),
    );

  // Listen for the 'LINK_USER' action
  @Effect()
  linkUser$: Observable<Action> = this.actions$
    .pipe(
      ofType(UserActions.LINK_USER),
      // .debounceTime(this.debounce, this.scheduler || async)
      switchMap((action: UserActions.LinkUser) => {
        const modalRef = this.modalService.open(LinkUsereModalComponent);
        modalRef.componentInstance.user = action.user;
        modalRef.componentInstance.role = action.role;
        modalRef.result.then(
          () => {
            this.ams.linkUser(action.user.id, action.orgId, action.role).pipe(
              take(1),
              catchError((e) => {
                this.store.dispatch(new UserActions.SaveUserFailed({
                  fields: {
                    email: this.translate.instant('amedia.errors.failedToLinkUser'),
                  },
                  general: [],
                }));
                return empty();
              }),
            ).subscribe((response) => {
              this.store.dispatch(new UserActions.SaveUserSucceeded({
                ...action.user,
                role: action.role,
                external: true,
              }));
            });
          },
          () => this.store.dispatch(new UserActions.LinkUserCancelled()),
        );
        return empty();
      }),
    );

  // Listen for the 'DELETE_USER' action
  @Effect()
  deleteUser$: Observable<Action> = this.actions$
    .pipe(
      ofType(UserActions.DELETE_USER),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: UserActions.DeleteUser) => {
        return this.ams.deleteUser(action.user.id).pipe(
          map((users) => new UserActions.DeleteUserSucceeded(action.user)),
          catchError((error) => of(new UserActions.DeleteUserFailed())),
        );
      }),
    );

  // Listen for the 'UNLINK_USER' action
  @Effect()
  unlinkUser$: Observable<Action> = this.actions$
    .pipe(
      ofType(UserActions.UNLINK_USER),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: UserActions.UnlinkUser) => {
        let orgId: string;
        this.auth$.pipe(take(1)).subscribe((authState) => orgId = authState.user.organizationId);

        // Linking with a null role removes the user
        return this.ams.linkUser(action.user.id, orgId, null).pipe(
          map((users) => new UserActions.UnlinkUserSucceeded(action.user)),
          catchError((error) => of(new UserActions.UnlinkUserFailed())),
        );
      }),
    );

  // Listen for the 'DELETE_USER_FAILED' action
  @Effect()
  userNotDeleted$: Observable<Action> = this.actions$
    .pipe(
      ofType(UserActions.DELETE_USER_FAILED),
      // .debounceTime(this.debounce, this.scheduler || async)
      switchMap((action: UserActions.DeleteUserFailed) => {
        this.toastr.error(
          this.translate.instant(
            'amedia.errors.failedTo',
            {
              verb: this.translate.instant('common.delete'),
              item: this.translate.instant('amedia.settings.user'),
            },
          ),
          this.translate.instant('common.error.label'),
        );
        return empty();
      }),
    );

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private ams: AccountMgmtService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private modalService: NgbModal,
    @Optional() @Inject(LOAD_DEBOUNCE) private debounce: number = 300,
    /**
     * You inject an optional Scheduler that will be undefined
     * in normal application usage, but its injected here so that you can mock out
     * during testing using the RxJS TestScheduler for simulating passages of time.
     */
    @Optional() @Inject(LOAD_SCHEDULER) private scheduler: Scheduler,
  ) {
    this.auth$ = this.store.pipe(select(fromRoot.getAuthState));
    this.users$ = this.store.pipe(select(fromRoot.getUsers));
  }
}
