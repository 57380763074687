import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { empty } from 'rxjs/observable/empty';
import { zip } from 'rxjs/observable/zip';
import { Scheduler } from 'rxjs/Scheduler';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import * as EncodingActions from '../../actions/settings/encoding';
import { TranscodeMgmtService } from '../../../services/transcode-mgmt.service';

export const LOAD_DEBOUNCE = new InjectionToken<number>('Load Debounce');
export const LOAD_SCHEDULER = new InjectionToken<Scheduler>('Load Scheduler');

@Injectable()
export class EncodingEffects {
  // Listen for the 'LOAD_ENCODING_PROFLES' action
  @Effect()
  loadEncodingProfiles$: Observable<Action> = this.actions$
    .pipe(
      ofType(EncodingActions.LOAD_ENCODING_PROFILES),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: EncodingActions.LoadEncodingProfiles) => {
        // wait for both calls to be completed before passing on
        return zip(
          this.tms.getUniversalEncodingProfiles().pipe(
            map((props) => ({
              universalEncodingProfiles: props,
            })),
          ),
          this.tms.getCustomEncodingProfiles().pipe(
            map((props) => ({
              customEncodingProfiles: props,
            })),
          ),
        ).pipe(
          map((propsArray) => new EncodingActions.LoadEncodingProfilesSucceeded(
            _.assign({}, ...propsArray),
          )),
          catchError((error) => of(new EncodingActions.LoadEncodingProfilesFailed())),
        );
      }),
    );

  // Listen for the 'LOAD_ACTIVE_ENCODING_PROFILE' action
  @Effect()
  loadActiveProfileAcion$: Observable<Action> = this.actions$
    .pipe(
      ofType(EncodingActions.LOAD_ACTIVE_ENCODING_PROFILE),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: EncodingActions.LoadActiveEncodingProfile) => {
        return this.tms.getActiveEncodingProfile().pipe(
          map((payload) => new EncodingActions.LoadActiveEncodingProfileSucceeded(payload)),
          catchError((error) => of(new EncodingActions.LoadActiveEncodingProfileFailed())),
        );
      }),
    );

  // Listen for the 'SET_ACTIVE_ENCODING_PROFILE' action
  @Effect()
  setCurrProfileAction$: Observable<Action> = this.actions$
    .pipe(
      ofType(EncodingActions.SET_ACTIVE_ENCODNG_PROFILE),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: EncodingActions.SetActiveEncodingProfile) => {
        return this.tms.setActiveEncodingProfile(action.activeProfileId).pipe(
          map(() => new EncodingActions.SetActiveEncodingProfileSucceeded(action.activeProfileId)),
          catchError((error) => of(new EncodingActions.SetActiveEncodingProfileFailed())),
        );
      }),
    );

  // Listen for the 'CREATE_ENCODING_PROFILE' action
  @Effect()
  createEncodingProfileAction$: Observable<Action> = this.actions$
    .pipe(
      ofType(EncodingActions.CREATE_ENCODING_PROFILE),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: EncodingActions.CreateEncodingProfile) => {
        return this.tms.createEncodingProfile(action.encodingProfile).pipe(
          map((payload) => {
            const verbPast: string = 'amedia.created';
            const itemType: string = 'amedia.settings.encodingProfile';

            this.toastr.success(
              this.translate.instant(
                'amedia.successMessage',
                {
                  verbPast: this.translate.instant(verbPast),
                  itemType: _.lowerCase(this.translate.instant(itemType)),
                  item: payload.title,
                },
              ),
              this.translate.instant('amedia.success'),
            );
            return new EncodingActions.CreateEncodingProfileSucceeded(payload);
          }),
          catchError((error) => of(new EncodingActions.CreateEncodingProfileFailed())),
        );
      }),
    );

  // Listen for the 'CREATE_ENCODING_PROFILE_FAILED' action
  @Effect()
  encodingProfileNotAdded$: Observable<Action> = this.actions$
    .pipe(
      ofType(EncodingActions.CREATE_ENCODING_PROFILE_FAILED),
      // .debounceTime(this.debounce, this.scheduler || async)
      switchMap((action: EncodingActions.CreateEncodingProfileFailed) => {
        this.toastr.error(
          this.translate.instant('amedia.settings.encoding.createError'),
          this.translate.instant('amedia.content.error'),
        );
        return empty();
      }),
    );

  // Listen for the 'DELETE_ENCODING_PROFILES' action
  @Effect()
  deleteEncodingProfileAction$: Observable<Action> = this.actions$
    .pipe(
      ofType(EncodingActions.DELETE_ENCODING_PROFILE),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: EncodingActions.DeleteEncodingProfile) => {
        return this.tms.deleteEncodingProfile(action.profileID).pipe(
          map(() => new EncodingActions.DeleteEncodingProfileSucceeded(action.profileID)),
          catchError((error) => of(new EncodingActions.DeleteEncodingProfileFailed())),
        );
      }),
    );

  // Listen for the 'DELETE_ENCODING_PROFILE_FAILED' action
  @Effect()
  encodingProfileNotDeleted$: Observable<Action> = this.actions$
    .pipe(
      ofType(EncodingActions.DELETE_ENCODING_PROFILE_FAILED),
      // .debounceTime(this.debounce, this.scheduler || async)
      switchMap((action: EncodingActions.DeleteEncodingProfileFailed) => {
        this.toastr.error(
          this.translate.instant('amedia.settings.encoding.deleteError'),
          this.translate.instant('amedia.content.error'),
        );
        return empty();
      }),
    );

  // Listen for the 'UPDATE_ENCODING_PROFILES' action
  @Effect()
  updateEncodingProfileAction$: Observable<Action> = this.actions$
    .pipe(
      ofType(EncodingActions.UPDATE_ENCODING_PROFILE),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: EncodingActions.UpdateEncodingProfile) => {
        return this.tms.updateEncodingProfile(action.profile).pipe(
          map((payload) => {
            const verbPast: string = 'amedia.updated';
            const itemType: string = 'amedia.settings.encodingProfile';

            this.toastr.success(
              this.translate.instant(
                'amedia.successMessage',
                {
                  verbPast: this.translate.instant(verbPast),
                  itemType: _.lowerCase(this.translate.instant(itemType)),
                  item: payload.title,
                },
              ),
              this.translate.instant('amedia.success'),
            );
            return new EncodingActions.UpdateEncodingProfileSucceeded(action.profile);
          }),
          catchError((error) => of(new EncodingActions.UpdateEncodingProfileFailed())),
        );
      }),
    );

  // Listen for the 'UPDATE_ENCODING_PROFILE_FAILED' action
  @Effect()
  encodingProfileNotUpdated$: Observable<Action> = this.actions$
    .pipe(
      ofType(EncodingActions.UPDATE_ENCODING_PROFILE_FAILED),
      // .debounceTime(this.debounce, this.scheduler || async)
      switchMap((action: EncodingActions.UpdateEncodingProfileFailed) => {
        this.toastr.error(
          this.translate.instant('amedia.settings.encoding.updateError'),
          this.translate.instant('amedia.content.error'),
        );
        return empty();
      }),
    );

  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private tms: TranscodeMgmtService,
    private translate: TranslateService,
    @Optional() @Inject(LOAD_DEBOUNCE) private debounce: number = 300,
    /**
     * You inject an optional Scheduler that will be undefined
     * in normal application usage, but its injected here so that you can mock out
     * during testing using the RxJS TestScheduler for simulating passages of time.
     */
    @Optional() @Inject(LOAD_SCHEDULER) private scheduler: Scheduler,
  ) { }
}
