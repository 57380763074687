import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[llPreviewImageFile]' })
export class PreviewImageFileDirective {
  @Output() imageUriChanged: EventEmitter<string> = new EventEmitter();
  constructor(private el: ElementRef) {}

  @HostListener('change', ['$event'])
  onChange(e: any): void {
    const file = this.el.nativeElement.files[0];
    // only run if user selects file
    if (!!file) {
      const reader = new FileReader();
      reader.onload = (readerEvent: any) => {
        // Angular does not currently support formControls on inputs of type file
        // because of this we cannot use ngControl to update the form value
        // we will instead trigger an event emitter to the parent ot update it's form
        this.imageUriChanged.emit(readerEvent.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
}
