import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { filter, map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as Auth from '../redux/actions/auth';
import * as fromRoot from '../redux/reducers';
import * as fromAuth from '../redux/reducers/auth';

@Injectable()
export class AnonymousGuard implements CanActivate {

  private authState$: Observable<fromAuth.State>;

  constructor(private store: Store<fromRoot.State>) {
    this.authState$ = this.store.pipe(select(fromRoot.getAuthState));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    // Get the current value to find out of if we need to fetch the user
    this.authState$.pipe(take(1)).subscribe((auth) => {
      if (!auth.user && !auth.userLoading && !auth.userLoadingFailed) {
        this.store.dispatch(new Auth.GetUser());
      }
    });

    return this.authState$.pipe(
      filter((auth) => !!auth.user || auth.userLoadingFailed),
      take(1),
      map((auth) => {
        if (!!auth.user) {
          this.store.dispatch(new Auth.AnonymousRequired());
          return false;
        }
        return !auth.user;
      }),
    );
  }
}
