import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';

@Directive({ selector: '[llContenteditableChange]' })
export class ContenteditableChangeDirective implements AfterViewInit {
  // tslint:disable-next-line:no-output-rename
  @Output('llContenteditableChange') update = new EventEmitter<any>();
  lastValue: string;

  constructor(private el: ElementRef) { }

  ngAfterViewInit(): void {
    this.lastValue = this.el.nativeElement.innerText;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): void {
    // Disable pasting and newlines
    if (
      e.keyCode === 13 || // enter
      (e.keyCode === 86 && e.ctrlKey) // paste
    ) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  @HostListener('keyup')
  onKeyUp() {
    if (this.lastValue !== this.el.nativeElement.innerText) {
      this.lastValue = this.el.nativeElement.innerText;
      this.update.emit(this.lastValue);
    }
  }
}
