import { Action, Store } from '@ngrx/store';

import * as fromGroup from '../reducers/group';
import { Group } from '../../models/group';
// tslint:disable-next-line
import { GroupSection } from '../reducers/group';
import { PaginatedResponse } from '../../models/paginated-response';
import { ContentFilters } from '../../models/content-filters';
import { BulkableAction } from './bulk';
import { CustomProperty } from '../../models/custom-property';
import { Channel } from '../../models/channel';

export const CREATE_GROUP = '[Group] Create Group';
export const CREATE_GROUP_FAILED = '[Group] Create Group Failed';
export const CREATE_GROUP_SUCCEEDED = '[Group] Create Group Succeeded';
export const FILTER_CHANNEL_LIST = '[Group] Filter Channel List';
export const LOAD_CHANNELS_NOT_IN_GROUP = '[Group] Load Channels Not In Group';
export const LOAD_GROUP_CHANNELS = '[Group] Load Group Channels';
export const LOAD_GROUP_CHANNELS_FAILED = '[Group] Load Group Channels Failed';
export const LOAD_GROUP_CHANNELS_SUCCEEDED = '[Group] Load Group Channels Succeeded';
export const LOAD_GROUP = '[Group] Load Group';
export const LOAD_GROUP_SUCCEEDED = '[Group] Load Group Succeeded';
export const LOAD_GROUP_FAILED = '[Group] Load Group Failed';
export const SELECT_ALL = '[Group] Select All';
export const TOGGLE_SELECTION = '[Group] Toggle Selection';
export const UPDATE_GROUP_SECTION = '[Group] Update Group Section';
export const UPDATE_GROUP_SECTION_FAILED = '[Group] Update Group Section Failed';
export const UPDATE_GROUP_SECTION_SUCCEEDED = '[Group] Update Group Section Succeeded';
export const UPDATE_GROUP_CHANNEL_LIST = '[Group] Update Group Channel List';
export const UPDATE_GROUP_CHANNEL_LIST_FAILED = '[Group] Update Group Channel List Failed';
export const UPDATE_GROUP_CHANNEL_LIST_SUCCEEDED = '[Group] Update Group Channel List Succeeded';
export const REORDER_SINGLE_CHANNEL = '[Group] Reorder Single Channel';
export const BULK_ADD_CHANNELS = '[Group] Bulk Add Channels';
export const BULK_REMOVE_CHANNELS = '[Group] Bulk Remove Channels';
export const CLONE_GROUP = '[Group] Clone Group';
export const CLONE_GROUP_FAILED = '[Group] Clone Group Failed';

export class CreateGroup implements Action {
  readonly type = CREATE_GROUP;

  constructor(public title: string) {}
}

export class CreateGroupFailed implements Action {
  readonly type = CREATE_GROUP_FAILED;
}

export class CreateGroupSucceeded implements Action {
  readonly type = CREATE_GROUP_SUCCEEDED;
}

export class FilterChannelList implements Action {
  readonly type = FILTER_CHANNEL_LIST;

  constructor(public queryText: string) {}
}

export class LoadChannelsNotInGroup implements Action {
  readonly type = LOAD_CHANNELS_NOT_IN_GROUP;

  constructor(
    public channelIds: string[],
    public allChannels: Channel[],
  ) {}
}

export class LoadGroupChannels implements Action {
  readonly type = LOAD_GROUP_CHANNELS;

  constructor(
    public groupID: string,
    public clearContent: boolean,
    public filters: ContentFilters,
  ) { }
}

export class LoadGroupChannelsFailed implements Action {
  readonly type = LOAD_GROUP_CHANNELS_FAILED;

  constructor(public groupID: string) {}
}

export class LoadGroupChannelsSucceeded implements Action {
  readonly type = LOAD_GROUP_CHANNELS_SUCCEEDED;

  constructor(
    public channelList: PaginatedResponse,
    public groupID: string,
  ) { }
}

export class LoadGroup implements Action {
  readonly type = LOAD_GROUP;

  constructor(public groupID: string) { }
}

export class LoadGroupSucceeded implements Action {
  readonly type = LOAD_GROUP_SUCCEEDED;

  constructor(public group: Group) { }
}

export class LoadGroupFailed implements Action {
  readonly type = LOAD_GROUP_FAILED;
}

export class SelectAll implements Action {
  readonly type = SELECT_ALL;

  constructor(public channelList: string) {}
}

export class ToggleSelection implements Action {
  readonly type = TOGGLE_SELECTION;

  constructor(
    public payload: any,
    public selectionType: string,
    public channelList: string,
  ) {}
}

export class UpdateGroupSection implements Action {
  readonly type = UPDATE_GROUP_SECTION;

  constructor(
    public section: GroupSection,
    public groupID: string,
    public newValues: any,
    public file: File,
  ) { }
}

export class UpdateGroupSectionFailed implements Action {
  readonly type = UPDATE_GROUP_SECTION_FAILED;

  constructor(
    public section: GroupSection,
    public groupID: string,
  ) {}
}

export class UpdateGroupSectionSucceeded implements Action {
  readonly type = UPDATE_GROUP_SECTION_SUCCEEDED;

  constructor(
    public section: GroupSection,
    public groupID: string,
    public newValues: any,
  ) {}
}

export class UpdateGroupChannelList implements Action {
  readonly type = UPDATE_GROUP_CHANNEL_LIST;

  constructor(
    public channelList: string[],
    public groupID: string,
    public allChannels: Channel[],
    public group: Group,
    public fromClone?: boolean,
  ) {}
}

export class UpdateGroupChannelListFailed implements Action {
  readonly type = UPDATE_GROUP_CHANNEL_LIST_FAILED;

  constructor(public groupID: string) {}
}

export class UpdateGroupChannelListSucceeded implements Action {
  readonly type = UPDATE_GROUP_CHANNEL_LIST_SUCCEEDED;

  constructor(public groupID: string) {}
}

export class ReorderSingleChannel implements Action {
  readonly type = REORDER_SINGLE_CHANNEL;

  constructor(
    public channelID: string,
    public newOrder: number,
    public groupID: string,
  ) {}
}

export class BulkAddChannels implements Action {
  readonly type = BULK_ADD_CHANNELS;

  constructor(
    public channelIDs: string[],
    public groupID: string,
  ) {}
}

export class BulkRemoveChannels implements Action {
  readonly type = BULK_REMOVE_CHANNELS;

  constructor(
    public channelIDs: string[],
    public groupID: string,
  ) {}
}

export class CloneGroup implements Action {
  readonly type = CLONE_GROUP;

  constructor(
    public newGroup: Group,
    public allChannels: Channel[],
  ) {}
}

export class CloneGroupFailed implements Action {
  readonly type = CLONE_GROUP_FAILED;
}

export type Actions = (
  CreateGroup | CreateGroupFailed | CreateGroupSucceeded |
  LoadChannelsNotInGroup | FilterChannelList |
  LoadGroupChannels | LoadGroupChannelsFailed | LoadGroupChannelsSucceeded |
  LoadGroup | LoadGroupSucceeded | LoadGroupFailed |
  SelectAll | ToggleSelection |
  UpdateGroupSection | UpdateGroupSectionFailed | UpdateGroupSectionSucceeded |
  UpdateGroupChannelList | UpdateGroupChannelListFailed | UpdateGroupChannelListSucceeded |
  ReorderSingleChannel | BulkAddChannels | BulkRemoveChannels |
  CloneGroup | CloneGroupFailed
);
