import * as _ from 'lodash';

const UUID_LENGTH: number = 32;
const DUUID_LENGTH: number = 22;
const BASE_16: number = 16;
const HEX_LONG_STRING_LENGTH: number = 16;
const BASE_64_LONG_STRING_LENGTH: number = Math.ceil(64 / 6.0);
const BYTES_IN_LONG: number = 8;

function hexToByteArray(hex: string): Uint8Array {
  return new Uint8Array(hex.match(/[\da-f]{2}/gi).map((h) => {
    return parseInt(h, 16);
  }));
}

function urlSafeBase64(b: Uint8Array): string {
  const base64 = btoa(String.fromCharCode.apply(null, b));
  return base64.replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

function hexToBase64UrlSafe(hex: string): string {
  const byteArr = hexToByteArray(hex.substring(0, HEX_LONG_STRING_LENGTH));
  return urlSafeBase64(byteArr);
}

function concatAndPad(msb: string, lsb: string): string {
  return (
    _.padStart(msb, BASE_64_LONG_STRING_LENGTH, '0') +
    _.padStart(lsb, BASE_64_LONG_STRING_LENGTH, '0')
  );
}

export function uuidToDuuid(uuid: string): string {
  if (uuid.length !== UUID_LENGTH) {
    return null;
  }

  return concatAndPad(
    hexToBase64UrlSafe(uuid.substring(0, HEX_LONG_STRING_LENGTH)),
    hexToBase64UrlSafe(uuid.substring(HEX_LONG_STRING_LENGTH)),
  );
}
