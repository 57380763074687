import { DataUsageChartType } from './../../models/data-usage-chart-type';
import { GeoReportsGranularity } from './../../models/geo-reports-granularity';
import { GeoSummaryData } from './../../models/geo-summary-data';
import { MediaChartDataType } from './../reducers/reports';
import { ViewerDataSummaryData } from './../../models/viewer-data-summary-data';
import { ChannelsOrMediaSummaryData } from './../../models/channels-aggregation';
import { Action } from '@ngrx/store';
import { DatePickerRange, SelectOption } from 'control-ui-common';
import { AnalyticsFilters } from '../../analytics-filters';
import { PaginatedResponse } from '../../models/paginated-response';
import { ReportsTabs } from '../../models/reports-tabs';
import { EventHistoryRequestParams } from '../../models/event-history-request-params';

export const LOAD_ACTIVE_REPORT = '[REPORTS] Load Active Report';
export const LOAD_CHANNEL_TABLE_DATA = '[REPORTS] Load Channel Table Data';
export const LOAD_CHANNEL_TABLE_DATA_SUCCEEDED = '[REPORTS] Load Channel Table Data Succeeded';
export const LOAD_CHANNEL_TABLE_DATA_FAILED = '[REPORTS] Load Channel Table Data Failed';
export const LOAD_CHANNEL_CHART_DATA = '[Reports] Load Channel Chart Data';
export const LOAD_CHANNEL_CHART_DATA_FAILED = '[Reports] Load Channel Chart Data Failed';
export const LOAD_CHANNEL_CHART_DATA_SUCCEEDED = '[Reports] Load Channel Chart Data Succeeded';
export const LOAD_CHANNEL_SUMMARY = '[REPORTS] Load Channel Summary';
export const LOAD_CHANNEL_SUMMARY_SUCCEEDED = '[REPORTS] Load Channel Summary Succeeded';
export const LOAD_CHANNEL_SUMMARY_FAILED = '[REPORTS] Load Channel Summary Failed';
export const SELECT_CHANNEL_TABLE_ROW = '[REPORTS] Select Channel Table Row';
export const DESELECT_CHANNEL_TABLE_ROW = '[REPORTS] Deselect Channel Table Row';
export const LOAD_GEO_TABLE_DATA = '[REPORTS] Load Geo Table Data';
export const LOAD_GEO_TABLE_DATA_SUCCEEDED = '[REPORTS] Load Geo Table Data Succeeded';
export const LOAD_GEO_TABLE_DATA_FAILED = '[REPORTS] Load Geo Table Data Failed';
export const SET_GEO_REPORTS_GRANULARITY = '[REPORTS] Set Geo Reports Granularity';
export const SET_GEO_BREADCRUMBS = '[REPORTS] Set Geo Report Breadcrumbs';
export const LOAD_GEO_SUMMARY = '[REPORTS] Load Geo Summary Data';
export const LOAD_GEO_SUMMARY_SUCCEEDED = '[REPORTS] Load Geo Summary Data Succeeded';
export const LOAD_MEDIA_TABLE_DATA = '[REPORTS] Load Media Table Data';
export const LOAD_MEDIA_TABLE_DATA_SUCCEEDED = '[REPORTS] Load Media Table Data Succeeded';
export const LOAD_MEDIA_TABLE_DATA_FAILED = '[REPORTS] Load Media Table Data Failed';
export const LOAD_MEDIA_CHART_DATA = '[REPORTS] Load Media Chart Data';
export const LOAD_MEDIA_CHART_DATA_SUCCEEDED = '[REPORTS] Load Media Chart Data Succeeded';
export const LOAD_MEDIA_CHART_DATA_FAILED = '[REPORTS] Load Media Chart Data Failed';
export const LOAD_MEDIA_SUMMARY = '[REPORTS] Load Media Summary';
export const LOAD_MEDIA_SUMMARY_SUCCEEDED = '[REPORTS] Load Media Summary Succeeded';
export const SET_CHART_MEDIA_TYPE = '[REPORTS] Set Chart Media Type';
export const SELECT_MEDIA_TABLE_ROW = '[REPORTS] Select Media Table Row';
export const DESELECT_MEDIA_TABLE_ROW = '[REPORTS] Deselect Media Table Row';
export const LOAD_OVERVIEW_CHART_DATA = '[REPORTS] Load Overview Chart Data';
export const LOAD_OVERVIEW_CHART_DATA_FAILED = '[REPORTS] Load Overview Chart Data Failed';
export const LOAD_OVERVIEW_CHART_DATA_SUCCEEDED = '[REPORTS] Load Overview Chart Data Succeeded';
export const LOAD_OVERVIEW_TABLE_DATA = '[REPORTS] Load Overview Table Data';
export const LOAD_OVERVIEW_TABLE_DATA_FAILED = '[REPORTS] Load Overview Table Data Failed';
export const LOAD_OVERVIEW_TABLE_DATA_SUCCEEDED = '[REPORTS] Load Overview Table Data Succeeded';
export const SET_DATE_RANGE = '[REPORTS] Set Date Range';
export const SET_OVERVIEW_TABLE_SELECT_VAL = '[REPORTS] Set Overview Table Select Option';
export const LOAD_DATA_USAGE_CHART_DATA = '[REPORTS] Load Data Usage Chart Data';
export const LOAD_DATA_USAGE_SUMMARY_DATA = '[REPORTS] Load Data Usage Summary Data';
export const LOAD_DATA_USAGE_CHART_DATA_FAILED = '[REPORTS] Load Data Usage Chart Data Failed';
export const LOAD_DATA_USAGE_SUMMARY_DATA_FAILED = '[REPORTS] Load Data Usage Summary Data Failed';
export const LOAD_DATA_USAGE_CHART_DATA_SUCCEEDED = '[REPORTS] Load Data Usage Chart Data Succeeded';
export const LOAD_DATA_USAGE_SUMMARY_DATA_SUCCEEDED = '[REPORTS] Load Data Usage Summary Data Succeeded';
export const SET_DATA_USAGE_CHART_TYPE = '[REPORTS] Set Data Usage Chart Type';
export const SET_REPORTS_TAB = '[REPORTS] Set Reports Tab';
export const SET_VIEWER_DATA_TABLE_SELECT_VAL = '[REPORTS] Set Viewer Data Table Select Val';
export const LOAD_VIEWER_DATA_TABLE = '[REPORTS] Load Viewer Data Table';
export const LOAD_VIEWER_DATA_TABLE_FAILED = '[REPORTS] Load Viewer Data Table Failed';
export const LOAD_VIEWER_DATA_TABLE_SUCCEEDED = '[REPORTS] Load Viewer Data Table Succeeded';
export const LOAD_VIEWER_DATA_SUMMARY = '[REPORTS] Load Viewer Data Summary';
export const LOAD_VIEWER_DATA_SUMMARY_SUCCEEDED = '[REPORTS] Load Viewer Data Summary Succeeded';
export const LOAD_ENGAGEMENT_TABLE = '[REPORTS] Load Engagement Table';
export const LOAD_ENGAGEMENT_TABLE_FAILED = '[REPORTS] Load Engagement Table Failed';
export const LOAD_ENGAGEMENT_TABLE_SUCCEEDED = '[REPORTS] Load Engagement Table Succeeded';
export const LOAD_EVENT_HISTORY = '[REPORTS] Load Event History';
export const LOAD_EVENT_HISTORY_SUCCEEDED = '[REPORTS] Load Event History Succeeded';
export const LOAD_EVENT_HISTORY_FAILED = '[REPORTS] Load Event History Failed';
export const LOAD_EVENT_HISTORY_EVENT_TYPES = '[REPORTS] Load Event History Event Types';
export const LOAD_EVENT_HISTORY_EVENT_TYPES_SUCCEEDED = '[REPORTS] Load Event History Event Types Succeeded';

export class LoadActiveReport implements Action {
  readonly type = LOAD_ACTIVE_REPORT;

  // changing the date range triggers this, but it should reset the pagination.
  // Simply reloading a tab should not reset pagination.
  constructor(public resetPagination = false) {}
}

export class LoadMediaTableData implements Action {
  readonly type = LOAD_MEDIA_TABLE_DATA;

  constructor(public filters: AnalyticsFilters) {}
}

export class LoadMediaSummary implements Action {
  readonly type = LOAD_MEDIA_SUMMARY;

  constructor() {}
}

export class LoadMediaSummarySucceeded implements Action {
  readonly type = LOAD_MEDIA_SUMMARY_SUCCEEDED;

  constructor(public data: ChannelsOrMediaSummaryData) {}
}

export class SetChartMediaType implements Action {
  readonly type = SET_CHART_MEDIA_TYPE;

  constructor(public mediaType: MediaChartDataType[]) {}
}

export class LoadChannelTableData implements Action {
  readonly type = LOAD_CHANNEL_TABLE_DATA;

  constructor(public filters: AnalyticsFilters) {}
}

export class LoadChannelTableDataFailed implements Action {
  readonly type = LOAD_CHANNEL_TABLE_DATA_FAILED;

  constructor() {}
}

export class LoadChannelTableDataSucceeded implements Action {
  readonly type = LOAD_CHANNEL_TABLE_DATA_SUCCEEDED;

  constructor(public data: PaginatedResponse) {}
}

export class LoadChannelChartData implements Action {
  readonly type = LOAD_CHANNEL_CHART_DATA;

  constructor(public filters: AnalyticsFilters) {}
}

export class LoadChannelChartDataFailed implements Action {
  readonly type = LOAD_CHANNEL_CHART_DATA_FAILED;
}

export class LoadChannelChartDataSucceeded implements Action {
  readonly type = LOAD_CHANNEL_CHART_DATA_SUCCEEDED;

  constructor(public data: any) {}
}

export class LoadChannelSummary implements Action {
  readonly type = LOAD_CHANNEL_SUMMARY;

  constructor() {}
}

export class LoadChannelSummarySucceeded implements Action {
  readonly type = LOAD_CHANNEL_SUMMARY_SUCCEEDED;

  constructor(public data: ChannelsOrMediaSummaryData) {}
}

export class LoadGeoTableData implements Action {
  readonly type = LOAD_GEO_TABLE_DATA;

  constructor(public filters: AnalyticsFilters) {}
}

export class LoadGeoTableDataFailed implements Action {
  readonly type = LOAD_GEO_TABLE_DATA_FAILED;

  constructor() {}
}

export class LoadGeoSummary implements Action {
  readonly type = LOAD_GEO_SUMMARY;

  constructor() {}
}

export class LoadGeoTableDataSuceeded implements Action {
  readonly type = LOAD_GEO_TABLE_DATA_SUCCEEDED;

  constructor(public data: PaginatedResponse) {}
}

export class SetGeoReportsGranularity implements Action {
  readonly type = SET_GEO_REPORTS_GRANULARITY;

  constructor(public granularity: GeoReportsGranularity) {}
}

export class LoadGeoSummarySucceeded implements Action {
  readonly type = LOAD_GEO_SUMMARY_SUCCEEDED;

  constructor(public data: GeoSummaryData) {}
}

export class LoadMediaTableDataFailed implements Action {
  readonly type = LOAD_MEDIA_TABLE_DATA_FAILED;

  constructor() {}
}

export class SelectChannelDataRows implements Action {
  readonly type = SELECT_CHANNEL_TABLE_ROW;

  constructor(public ids: string[]) {}
}

export class DeselectChannelDataRows implements Action {
  readonly type = DESELECT_CHANNEL_TABLE_ROW;

  constructor(public ids: string[]) {}
}

export class LoadMediaTableDataSucceeded implements Action {
  readonly type = LOAD_MEDIA_TABLE_DATA_SUCCEEDED;

  constructor(public data: PaginatedResponse) {}
}

export class LoadMediaChartData implements Action {
  readonly type = LOAD_MEDIA_CHART_DATA;

  constructor(public filters: AnalyticsFilters) {}
}

export class LoadMediaChartDataFailed implements Action {
  readonly type = LOAD_MEDIA_CHART_DATA_FAILED;
}

export class LoadMediaChartDataSucceeded implements Action {
  readonly type = LOAD_MEDIA_CHART_DATA_SUCCEEDED;

  constructor(public data: any) {}
}

export class LoadOverviewTableData implements Action {
  readonly type = LOAD_OVERVIEW_TABLE_DATA;

  constructor(public filters: AnalyticsFilters) {}
}

export class LoadOverviewChartData implements Action {
  readonly type = LOAD_OVERVIEW_CHART_DATA;

  constructor(public filters: AnalyticsFilters) {}
}

export class LoadOverviewChartDataFailed implements Action {
  readonly type = LOAD_OVERVIEW_CHART_DATA_FAILED;
}

export class LoadOverviewChartDataSucceeded implements Action {
  readonly type = LOAD_OVERVIEW_CHART_DATA_SUCCEEDED;

  constructor(public data: any) {}
}

export class SelectMediaDataRows implements Action {
  readonly type = SELECT_MEDIA_TABLE_ROW;

  constructor(public ids: string[]) {}
}

export class DeselectMediaDataRows implements Action {
  readonly type = DESELECT_MEDIA_TABLE_ROW;

  constructor(public ids: string[]) {}
}

export class LoadOverviewTableDataFailed implements Action {
  readonly type = LOAD_OVERVIEW_TABLE_DATA_FAILED;
}

export class LoadOverviewTableDataSucceeded implements Action {
  readonly type = LOAD_OVERVIEW_TABLE_DATA_SUCCEEDED;

  constructor(public data: PaginatedResponse) {}
}

export class SetDateRange implements Action {
  readonly type = SET_DATE_RANGE;

  constructor(public dateRange: DatePickerRange, public triggerDataReload: boolean = true) {}
}

export class SetOverviewTableSelectVal implements Action {
  readonly type = SET_OVERVIEW_TABLE_SELECT_VAL;

  constructor(public selectVal: string) {}
}

export class LoadDataUsageChartData implements Action {
  readonly type = LOAD_DATA_USAGE_CHART_DATA;

  constructor() {}
}

export class LoadDataUsageSummaryData implements Action {
  readonly type = LOAD_DATA_USAGE_SUMMARY_DATA;

  constructor() {}
}

export class LoadDataUsageChartDataFailed implements Action {
  readonly type = LOAD_DATA_USAGE_CHART_DATA_FAILED;
}

export class LoadDataUsageSummaryDataFailed implements Action {
  readonly type = LOAD_DATA_USAGE_SUMMARY_DATA_FAILED;
}

export class LoadDataUsageChartDataSucceeded implements Action {
  readonly type = LOAD_DATA_USAGE_CHART_DATA_SUCCEEDED;

  constructor(public data: any[], public interval: string) {}
}

export class LoadDataUsageSummaryDataSucceeded implements Action {
  readonly type = LOAD_DATA_USAGE_SUMMARY_DATA_SUCCEEDED;

  constructor(public data: any[]) {}
}

export class SetDataUsageChartType implements Action {
  readonly type = SET_DATA_USAGE_CHART_TYPE;

  constructor(public chartType: DataUsageChartType) {}
}

export class SetReportsTab implements Action {
  readonly type = SET_REPORTS_TAB;

  constructor(public tab: ReportsTabs, public loadActiveReport: boolean) {}
}

export class SetViewerDataTableSelectVal implements Action {
  readonly type = SET_VIEWER_DATA_TABLE_SELECT_VAL;

  constructor(public val: string) {}
}

export class LoadViewerDataTable implements Action {
  readonly type = LOAD_VIEWER_DATA_TABLE;

  constructor(public filters: AnalyticsFilters) {}
}

export class LoadViewerDataTableFailed implements Action {
  readonly type = LOAD_VIEWER_DATA_TABLE_FAILED;

  constructor() {}
}

export class LoadViewerDataTableSucceeded implements Action {
  readonly type = LOAD_VIEWER_DATA_TABLE_SUCCEEDED;

  constructor(public data: PaginatedResponse) {}
}

export class LoadViewerDataSummary implements Action {
  readonly type = LOAD_VIEWER_DATA_SUMMARY;

  constructor() {}
}

export class LoadViewerDataSummarySucceeded implements Action {
  readonly type = LOAD_VIEWER_DATA_SUMMARY_SUCCEEDED;

  constructor(public data: ViewerDataSummaryData) {}
}

export class LoadEngagementTable implements Action {
  readonly type = LOAD_ENGAGEMENT_TABLE;

  constructor(public filters: AnalyticsFilters) {}
}

export class LoadEngagementTableFailed implements Action {
  readonly type = LOAD_ENGAGEMENT_TABLE_FAILED;

  constructor() {}
}

export class LoadEngagementTableSucceeded implements Action {
  readonly type = LOAD_ENGAGEMENT_TABLE_SUCCEEDED;

  constructor(public data: PaginatedResponse) {}
}

export class SetGeoBreadcrumbs implements Action {
  readonly type = SET_GEO_BREADCRUMBS;

  constructor(public crumbs: { country: string, province: string }) {}
}

export class LoadEventHistory implements Action {
  readonly type = LOAD_EVENT_HISTORY;

  constructor(public filters: EventHistoryRequestParams) {}
}

export class LoadEventHistorySucceeded implements Action {
  readonly type = LOAD_EVENT_HISTORY_SUCCEEDED;

  constructor(public data: PaginatedResponse) {}
}

export class LoadEventHistoryFailed implements Action {
  readonly type = LOAD_EVENT_HISTORY_FAILED;

  constructor() {}
}

export class LoadEventHistoryEventTypes implements Action {
  readonly type = LOAD_EVENT_HISTORY_EVENT_TYPES;

  constructor() {}
}

export class LoadEventHistoryEventTypesSucceeded implements Action {
  readonly type = LOAD_EVENT_HISTORY_EVENT_TYPES_SUCCEEDED;

  constructor(public eventTypes: string[]) {}
}

export type Actions = (
  LoadEngagementTable | LoadEngagementTableSucceeded | LoadEngagementTableFailed |
  SetGeoBreadcrumbs | LoadGeoTableData | LoadGeoTableDataFailed | LoadGeoTableDataSuceeded | SetGeoReportsGranularity |
  LoadGeoSummary | LoadGeoSummarySucceeded |
  LoadViewerDataSummary | LoadViewerDataSummarySucceeded |
  LoadMediaSummary | LoadMediaSummarySucceeded |
  LoadChannelSummary | LoadChannelSummarySucceeded |
  LoadActiveReport | LoadViewerDataTable | LoadViewerDataTableFailed | LoadViewerDataTableSucceeded |
  SetViewerDataTableSelectVal | SelectMediaDataRows | DeselectMediaDataRows |
  SelectChannelDataRows | DeselectChannelDataRows | LoadChannelTableData |
  LoadChannelChartData | LoadChannelChartDataFailed |
  LoadChannelChartDataSucceeded | LoadChannelTableDataFailed | LoadChannelTableDataSucceeded |
  LoadMediaTableData | LoadMediaTableDataSucceeded | LoadMediaTableDataFailed |
  LoadOverviewChartData | LoadOverviewChartDataFailed | LoadOverviewChartDataSucceeded |
  LoadOverviewTableData | LoadOverviewTableDataSucceeded | LoadOverviewTableDataFailed
  | SetOverviewTableSelectVal | SetDateRange | LoadDataUsageChartData | LoadDataUsageChartDataFailed
  | LoadDataUsageChartDataSucceeded | LoadDataUsageSummaryData | LoadDataUsageSummaryDataFailed
  | LoadDataUsageSummaryDataSucceeded | SetReportsTab |
  LoadMediaChartData | LoadMediaChartDataSucceeded | LoadMediaChartDataFailed
  | SetChartMediaType | SetDataUsageChartType
  | LoadEventHistory | LoadEventHistorySucceeded | LoadEventHistoryFailed |
  LoadEventHistoryEventTypes | LoadEventHistoryEventTypesSucceeded
);
