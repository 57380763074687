import * as _ from 'lodash';

import * as ThemeActions from '../actions/theme';
import { Skin, Theme } from '../../models/theme';
import { themes as lockedThemes } from '../../mock-data/theme';
import { SortDirection } from 'control-ui-common';

const sortThemes = (themesArray: Theme[]): Theme[] => {
  const customThemes = [];

  _.forEach(themesArray, (th) => {
    if (!th.locked) {
      customThemes.push(th);
    }
  });

  const orderedCustomThemes = _.orderBy(
    customThemes,
    [(cust) => _.toString(cust.name).toLocaleLowerCase()],
    SortDirection.ASC,
  );

  return [...lockedThemes, ...orderedCustomThemes];
};

export interface State {
  themes: Theme[];
  themesLoading: boolean;
  currTheme: Theme;
}

export const initialState: State = {
  themes: null,
  themesLoading: true,
  currTheme: null,
};

export function reducer(
  state = initialState,
  action: ThemeActions.Actions,
): State {
  let index: number;
  let newTheme;
  let newThemes: Theme[];

  switch (action.type) {
  case ThemeActions.LOAD_THEMES:
    return {
      ...state,
      themesLoading: true,
      themes: null,
      currTheme: null,
    };
  case ThemeActions.LOAD_THEMES_SUCCEEDED:
    return {
      ...state,
      themes: sortThemes(action.themes),
      currTheme: lockedThemes[0],
      themesLoading: false,
    };
  case ThemeActions.LOAD_THEMES_FAILED:
    return {
      ...state,
      themesLoading: false,
    };
  case ThemeActions.SELECT_THEME:
    if (!!action.theme) {
      newTheme = _.cloneDeep(action.theme);

      if (!!state.currTheme) {
        // Null out fields that don't exist in the new theme
        const diff = _.difference(_.keys(state.currTheme.skin), _.keys(newTheme.skin));
        diff.forEach((key) => {
          if (key !== 'videoRatio') {
            newTheme.skin[key] = null;
          }
        });
      }
    } else {
      newTheme = null;
    }

    return {
      ...state,
      currTheme: newTheme,
    };
  case ThemeActions.SAVE_THEME:
    return {
      ...state,
      themesLoading: true,
    };
  case ThemeActions.SAVE_THEME_SUCCEEDED:
    if (!!action.theme) {
      newTheme = {
        ...action.theme,
        skin: state.currTheme.skin,
      };
    } else {
      newTheme = _.cloneDeep(state.currTheme);
    }

    index = _.findIndex(state.themes, (p) => p.id === newTheme.id);
    newThemes = [ ...state.themes ];

    if (index >= 0) {
      newThemes.splice(index, 1, newTheme);
    } else {
      newThemes.push(newTheme);
    }

    return {
      ...state,
      themesLoading: false,
      themes: sortThemes(newThemes),
      currTheme: _.cloneDeep(newTheme),
    };
  case ThemeActions.SAVE_THEME_FAILED:
    return {
      ...state,
      themesLoading: false,
    };
  case ThemeActions.UPDATE_CURRENT_THEME_PROP:
    return {
      ...state,
      currTheme: {
        ...state.currTheme,
        skin: {
          ...state.currTheme.skin,
          [action.propName]: action.propValue,
        },
      },
    };
  case ThemeActions.UPDATE_CURRENT_THEME_NAME:
    return {
      ...state,
      currTheme: {
        ...state.currTheme,
        name: action.name,
      },
    };
  case ThemeActions.DELETE_THEME:
    return {
      ...state,
      themesLoading: true,
    };
  case ThemeActions.DELETE_THEME_SUCCEEDED:
    index = _.findIndex(state.themes, (p) => p.id === state.currTheme.id);
    newThemes = state.themes.filter((p) => p.id !== state.currTheme.id);

    return {
      ...state,
      currTheme: newThemes[Math.min(newThemes.length - 1, index)],
      themes: sortThemes(newThemes),
      themesLoading: false,
    };
  case ThemeActions.DELETE_THEME_FAILED:
    return {
      ...state,
      themesLoading: false,
    };
  default:
    return state;
  }
}
