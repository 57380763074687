import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CanDeactivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import { ConfirmPageChangeComponent } from '../../shared/modals/confirm-page-change.component';

export interface PromptForDeactivation {
  promptForDeactivate: () => Promise<boolean> | boolean;
}

@Injectable()
export class PromptForDeactivateGuard implements CanDeactivate<PromptForDeactivation> {

  private bypass = false;

  constructor(
    private modalService: NgbModal,
    private router: Router,
  ) {}

  bypassOnce(): void {
    this.bypass = true;
  }

  canDeactivate(
    component: PromptForDeactivation,
    currentRoute: ActivatedRouteSnapshot,
    current: RouterStateSnapshot,
    next?: RouterStateSnapshot,
  ) {
    if (
      _.get(next, 'url') !== '/auth/unauthorized' &&
      _.get(current, 'url') !== _.get(next, 'url') &&
      !!_.get(component, 'promptForDeactivate') &&
      component.promptForDeactivate()
    ) {
      if (this.bypass) {
        this.bypass = false;
        return true;
      }
      const modalRef = this.modalService.open(ConfirmPageChangeComponent);
      modalRef.componentInstance.headerMessageLocal = 'amedia.changePage';
      modalRef.componentInstance.confirmBodyMain = 'amedia.changePage.loseChanges';
      modalRef.componentInstance.additionalInformation = 'amedia.changePage.helpSuggestions';
      modalRef.componentInstance.verb = 'amedia.confirm';
      return modalRef.result.then(
        () => true, // Confirm
        () => false, // Dismiss
      );
    }
    return true;
  }
}
