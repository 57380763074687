import {
  Component,
  ContentChild,
  EventEmitter,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

/**
 * Form control component that manages a dictionary of string key:value pairs.
 *
 * Example:
 *
 * form = fb.group({
 *  config: [ { debug: false }, Validators.required ],
 * })
 *
 * ...
 *
 * <ll-key-value-pairs
 *   formControlName="config"
 * ></ll-key-value-pairs>
 */
@Component({
  selector: 'll-key-value-pairs',
  template: `
    <textarea
      rows="3"
      class="form-control"
      placeholder="examples=2&#10;finished=true"
      (keyup)="onTextChange($event)"
      [style.height]="'unset'"
    >{{ textValue }}</textarea>
  `,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line:no-forward-ref
    useExisting: forwardRef(() => KeyValuePairsComponent),
    multi: true,
  }],
})
export class KeyValuePairsComponent implements ControlValueAccessor {
  @Output() change = new EventEmitter<any>();
  textValue: string = '';
  value: any;

  trackByFn(index, item) {
    return index;
  }

  onTextChange(e: any): void {
    this.textValue = e.target.value;
    this.propagateChange();
  }

  propagateChange(): void {
    this.onTouched();

    const lines: string[] = this.textValue.split('\n');
    const obj: {[key: string]: string} = {};
    _.forEach(lines, (line) => {
      const split = _.map(line.split('='), (v) => v.trim());
      if (split.length === 2 && split[0].length > 0 && split[1].length > 0) {
        obj[split[0]] = split[1];
      }
    });
    this.change.emit(obj);
    this.onChange(obj);
  }

  writeValue(val: {[key: string]: string}): void {
    if (!!val) {
      try {
        this.textValue = _.join(
          _.map(_.pickBy(val, _.isString), (v, k) => `${k}=${v}`),
          '\n',
        );
      } catch (e) {
        // noop
      }
    }
  }

  registerOnChange(fn: (v: any) => {}): void { this.onChange = fn; }

  registerOnTouched(fn: () => {}): void { this.onTouched = fn; }

  private onChange(v: any) { }
  private onTouched() { }
}
