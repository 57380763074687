import { EnvConfigurationService, Configuration } from './environment-configuration.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import * as fromRoot from '../redux/reducers';
import { FeatureSetAuthorization } from '../models/feature-sets';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Injectable()
export class FeatureSetAuthorizationService {

  env: Configuration;

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,
    ) {
      this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);
    }

  getFeatureList(): Observable<FeatureSetAuthorization> {
    return this.http.get<FeatureSetAuthorization>(
      `${this.env.SHIM_BASE_URL}/fsas/organizations/features`,
    );
  }
}
