import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AnonymousGuard } from '../shared/auth/anonymous-guard';
import { AuthGuard } from '../shared/auth/auth-guard';
import { PwdNeedUpdateGuard } from '../shared/auth/pwd-need-update-guard';
import { MissingQuestionGuard } from '../shared/auth/missing-question-guard';
import { BaseAuthComponent } from './base-auth.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AnswerQuestionComponent } from './answer-question/answer-question.component';
import { PasswordSentComponent } from './password-sent/password-sent.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeQuestionComponent } from './change-question/change-question.component';

const routes: Routes = [

  {
    path: 'auth',
    component: BaseAuthComponent,
    children: [
      { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [ AnonymousGuard ],
      },
      {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [ AuthGuard ],
      },
      {
        path: 'unauthorized',
        component: LogoutComponent,
      },
      {
        path: 'forgotpassword',
        component: ForgotPasswordComponent,
        canActivate: [ AnonymousGuard ],
      },
      {
        path: 'answerquestion',
        component: AnswerQuestionComponent,
        canActivate: [ AnonymousGuard ],
      },
      {
        path: 'passwordsent',
        component: PasswordSentComponent,
        canActivate: [ AnonymousGuard ],
      },
      {
        path: 'changepassword',
        component: ChangePasswordComponent,
        canActivate: [ AuthGuard, PwdNeedUpdateGuard ],
      },
      {
        path: 'changequestion',
        component: ChangeQuestionComponent,
        canActivate: [ AuthGuard, MissingQuestionGuard ],
      },
    ],
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
})
export class AuthRoutingModule {}
