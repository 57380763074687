import { EnvConfigurationService, Configuration } from './../services/environment-configuration.service';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { ScrollToService } from '../../shared/services/scroll-to.service';
import * as fromRoot from '../redux/reducers';
import * as fromAuth from '../redux/reducers/auth';
import { take } from 'rxjs/operators';

@Component({
  selector: 'll-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() navExpanded: boolean = false;
  auth$: Observable<fromAuth.State>;
  year: number = new Date().getUTCFullYear();
  hasVScroll: boolean = false;
  interval: any;
  env: Configuration;

  public version: string;
  public suggestionsEmail: string = 'LVP_Support@llnw.com';

  constructor(
    private scrollToService: ScrollToService,
    private store: Store<fromRoot.State>,
    private translate: TranslateService,
    private envConfigService: EnvConfigurationService,
  ) {
    this.envConfigService.load().subscribe((res) => {
      this.env = res;
    });
    this.auth$ = this.store.pipe(select(fromRoot.getAuthState));
    this.version = this.env.APP_VERSION;
  }

  ngOnInit() {
    this.interval = setInterval(() => {
      this.hasVScroll = document.body.scrollHeight > document.body.clientHeight + 200;
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  scrollToTop() {
    this.scrollToService.scrollTo();
  }
}
