import { EnvConfigurationService, Configuration } from './../services/environment-configuration.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';

import * as moment from 'moment';
import { PromptForDeactivateGuard } from '../services/prompt-for-deactivate-guard.service';
import * as fromRoot from '../redux/reducers';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'll-idle-modal',
  templateUrl: './idle-timeout-modal.component.html',
  styleUrls: ['./idle-timeout-modal.component.scss'],
})
export class IdleTimeoutModalComponent implements OnDestroy {

  countdownEndTime;
  remaining: string;
  env: Configuration;
  private countdownTimer: any;
  private displayInterval: any;

  constructor(
    private activeModal: NgbActiveModal,
    private http: HttpClient,
    private router: Router,
    public translate: TranslateService,
    private deactivateGuard: PromptForDeactivateGuard,
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,
  ) {
    this.envConfigService.load().subscribe((res) => {
      this.env = res;
    });
    this.countdownEndTime = moment().add(this.env.IDLE_TIMEOUT_ALERT_DELTA);
    this.startCountdownTimer();
  }

  ngOnDestroy() {
    if (!!this.displayInterval) {
      clearInterval(this.displayInterval);
    }
  }

  stayLoggedIn(): void {
    // cancel the countdown
    clearTimeout(this.countdownTimer);
    // make a request, which will reset the timer
    this.http.get(`${this.env.SHIM_BASE_URL}/healthcheck`).subscribe(() => { });
    // close this modal
    this.activeModal.close();
  }

  logout(): void {
    this.deactivateGuard.bypassOnce();
    this.router.navigate(['/auth/logout']);
    this.activeModal.close();
  }

  private startCountdownTimer(): void {
    this.countdownTimer = setTimeout(() => {
      clearInterval(this.displayInterval);
      this.displayInterval = null;
      this.logout();
    }, this.env.IDLE_TIMEOUT_ALERT_DELTA);
    this.displayInterval = setInterval(() => {
      this.remaining = this.getFormattedCountdown();
    }, 1000);
  }

  private getFormattedCountdown(): string {
    const dur = moment.duration(this.countdownEndTime
      .diff(moment()), 'ms');
    return moment.utc(dur.asMilliseconds()).format('mm:ss');
  }
}
