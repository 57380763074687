import { Observable } from 'rxjs/Observable';
import { Scheduler } from 'rxjs/Scheduler';
import { async } from 'rxjs/scheduler/async';
import { of } from 'rxjs/observable/of';
import { empty } from 'rxjs/observable/empty';
import { from } from 'rxjs/observable/from';
import {
  catchError,
  concat,
  debounceTime,
  first,
  map,
  mergeMap,
  observeOn,
  take,
  filter,
  delay,
} from 'rxjs/operators';
import { Injectable, InjectionToken, Optional, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs/observable/zip';
import { SortDirection } from 'control-ui-common';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import * as GroupActions from '../actions/group';
import * as ContentActions from '../actions/content';
import * as fromRoot from '../reducers';
import * as fromContent from '../reducers/content';
import * as fromGroup from '../reducers/group';
import { MediaDataService } from '../../services/media-data.service';
// tslint:disable-next-line
import { GroupSection } from '../reducers/group';
import { PaginatedResponse } from '../../models/paginated-response';
import { ContentFilters } from '../../models/content-filters';

import { asap } from 'rxjs/scheduler/asap';
import { combineLatest } from 'rxjs/observable/combineLatest';

export const LOAD_DEBOUNCE = new InjectionToken<number>('Load Debounce');
export const LOAD_SCHEDULER = new InjectionToken<Scheduler>('Load Scheduler');

@Injectable()
export class GroupEffects {
  content$: Observable<fromContent.State>;
  groupState$: Observable<fromGroup.State>;

  // Listen for the 'CREATE_GROUP' action
  @Effect()
  createGroup$: Observable<Action> = this.actions$
    .pipe(
      ofType(GroupActions.CREATE_GROUP),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: GroupActions.CreateGroup) => {
        return this.mds.createChannelGroup(action.title).pipe(
          mergeMap((group) => {
            this.toastr.success(
              this.translate.instant('amedia.toastr.groupDetailsGroupCreated'),
              this.translate.instant('amedia.success'),
            );

            let obs$: Observable<Action> = of(new GroupActions.CreateGroupSucceeded());

            this.content$.pipe(take(1)).subscribe((contentState) => {
              // Reload current page
              if (contentState.contentType === 'channelgroups') {
                const { searchText, ...filters } = contentState.filters;

                obs$ = obs$.pipe(concat(
                  this.mds.getChannelgroupsList({ searchText: undefined, ...filters }).pipe(
                    map((payload) => {
                      return new ContentActions.ContentListLoaded(payload as PaginatedResponse, 'channelgroups', true);
                    }),
                    catchError((error) => of(new ContentActions.ContentListFailedToLoad('channelgroups'))),
                  ),
                ));
              }
            });

            return obs$;
          }),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.toastr.groupDetailsCreateGroupError'),
              this.translate.instant('common.error.label'),
            );
            return of(new GroupActions.CreateGroupFailed());
          }),
        );
      }),
  );

  // Listen for the 'LOAD_GROUP_CHANNELS' action
  @Effect()
  loadGroupChannels$: Observable<Action> = this.actions$
    .pipe(
      ofType(GroupActions.LOAD_GROUP_CHANNELS),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: any) => {
        let filters: ContentFilters = action.filters;

        if (!filters) {
          this.groupState$.pipe(take(1)).subscribe((groupState) => {
            const pageSize = !!groupState.paginatedChannelList ?
              groupState.paginatedChannelList.size : 10;
            const page = !!groupState.paginatedChannelList ?
              groupState.paginatedChannelList.page : 1;
            filters = {
              pageSize,
              page,
            };
          });
        }
        return this.mds.getGroupChannels(action.groupID, filters).pipe(
          map((channelList) => new GroupActions.LoadGroupChannelsSucceeded(
            channelList,
            action.groupID,
          )),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.toastr.groupDetailsChannelLoadingError'),
              this.translate.instant('common.error.label'),
            );
            return of(new GroupActions.LoadGroupChannelsFailed(action.groupID));
          }),
        );
      }),
  );

  // Listen for the 'LOAD_GROUP' action
  @Effect()
  loadGroup$: Observable<Action> = this.actions$
    .pipe(
      ofType(GroupActions.LOAD_GROUP),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: GroupActions.LoadGroup) => {
        return this.mds.getGroup(action.groupID).pipe(
          map((group) => new GroupActions.LoadGroupSucceeded(group)),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant(
                error['status'] === 404
                  ? 'amedia.toastr.groupNotFound'
                  : 'amedia.toastr.failedToLoadGroup',
                ),
              this.translate.instant('common.error.label'),
            );
            this.router.navigate(['/app/content/groups']);
            return of(new GroupActions.LoadGroupFailed());
          }),
        );
      }),
  );

  // Listen for the 'UPDATE_GROUP_SECTION' action
  @Effect()
  updateGroup$: Observable<Action> = this.actions$
    .pipe(
      ofType(GroupActions.UPDATE_GROUP_SECTION),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: GroupActions.UpdateGroupSection) => {
        let obs$;
        switch (action.section) {
          case GroupSection.CustomProps:
            obs$ = this.mds.assignCustomProperty(
              'channelgroups',
              action.groupID,
              action.newValues['custom_properties'],
            );
            break;
          case GroupSection.Properties:
            obs$ = this.mds.updateGroup(action.groupID, action.newValues);
            break;
          case GroupSection.Preview:
            obs$ = this.mds.savePreview(
              'channelgroups',
              action.groupID,
              action.file,
            );
            break;
        }
        return obs$.pipe(
          map((group) => {
            if (action.section === GroupSection.Preview) {
              this.toastr.success(
                this.translate.instant('amedia.toastr.previewProccessed'),
                this.translate.instant('amedia.toastr.previewSaved'),
              );
            }
            return new GroupActions.UpdateGroupSectionSucceeded(
              action.section,
              action.groupID,
              action.newValues,
            );
          }),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.toastr.groupDetailsUpdatingError'),
              this.translate.instant('common.error.label'),
            );
            return of(new GroupActions.UpdateGroupSectionFailed(action.section, action.groupID));
          }),
        );
      }),
  );

  // Listen for the 'REORDER_SINGLE_CHANNEL' action
  @Effect()
  reorderSingleChannel$: Observable<Action> = this.actions$
    .pipe(
      ofType(GroupActions.REORDER_SINGLE_CHANNEL),
      observeOn(asap), // force concat to be truly synchronous
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: GroupActions.ReorderSingleChannel) => {
        // First, get fresh group/channels lists
        return from([
          new ContentActions.GetChangeset('channelgroups'),
          new ContentActions.GetChangeset('channels'),
        ]).pipe(
          concat(
            this.content$.pipe(
              first((content) => !content.channelsChangesetLoading && !content.channelgroupsChangesetLoading),
              map((content) => {
                const group = _.find(content.allGroups, (g) => g.channelgroup_id === action.groupID);
                let channelList = group['channel_ids'];
                const idx = channelList.indexOf(action.channelID);
                if (idx === -1) {
                  this.toastr.error(
                    this.translate.instant('amedia.toastr.groupDetailsChannelRemoved'),
                    this.translate.instant('common.error.label'),
                  );

                  return new GroupActions.LoadGroupChannels(action.groupID, false, null);
                } else {
                  if (action.newOrder < 1) {
                    // check to see if user entered a negative number
                    action.newOrder = 0;
                  }

                  channelList.splice(action.newOrder, 0, channelList.splice(idx, 1)[0]);
                  return new GroupActions.UpdateGroupChannelList(
                    _.uniq(channelList),
                    action.groupID,
                    content.allChannels,
                    group,
                  );
                }
              }),
            ),
          ),
        );
      }),
    );

  // Listen for the 'BULK_ADD_MEDIA' action
  @Effect()
  bulkAddChannels$: Observable<Action> = this.actions$
    .pipe(
      ofType(GroupActions.BULK_ADD_CHANNELS),
      observeOn(asap), // force concat to be truly synchronous
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: GroupActions.BulkAddChannels) => {
        // First, get fresh group/channels lists
        return from([
          new ContentActions.GetChangeset('channelgroups'),
          new ContentActions.GetChangeset('channels'),
        ]).pipe(
          concat(
            this.content$.pipe(
              observeOn(asap),
              first((content) => !content.channelsChangesetLoading && !content.channelgroupsChangesetLoading),
              map((content) => {
                const group = _.find(content.allGroups, (g) => g.channelgroup_id === action.groupID);

                return new GroupActions.UpdateGroupChannelList(
                  _.uniq([
                    ...group['channel_ids'],
                    ...action.channelIDs,
                  ]),
                  action.groupID,
                  content.allChannels,
                  group,
                );
              }),
            ),
          ),
        );
      }),
    );

  // Listen for the 'BULK_REMOVE_CHANNELS' action
  @Effect()
  bulkRemoveChannels$: Observable<Action> = this.actions$
    .pipe(
      ofType(GroupActions.BULK_REMOVE_CHANNELS),
      observeOn(asap), // force concat to be truly synchronous
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: GroupActions.BulkRemoveChannels) => {
        // First, get fresh group/channels lists
        return from([
          new ContentActions.GetChangeset('channelgroups'),
          new ContentActions.GetChangeset('channels'),
        ]).pipe(
          concat(
            this.content$.pipe(
              first((content) => !content.channelsChangesetLoading && !content.channelgroupsChangesetLoading),
              map((content) => {
                const group = _.find(content.allGroups, (g) => g.channelgroup_id === action.groupID);
                const channelList = _.filter(
                  group['channel_ids'],
                  (id) => !_.includes(action.channelIDs, id),
                );

                return new GroupActions.UpdateGroupChannelList(
                  _.uniq(channelList),
                  action.groupID,
                  content.allChannels,
                  group,
                );
              }),
            ),
          ),
        );
      }),
    );

  // Listen for the 'UPDATE_GROUP_CHANNEL_LIST' action
  @Effect()
  updateGroupChannelList$: Observable<Action> = this.actions$
    .pipe(
      ofType(GroupActions.UPDATE_GROUP_CHANNEL_LIST),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: GroupActions.UpdateGroupChannelList) => {
        let newValues: any = {
          channelIds: action.channelList,
        };

        if (!!action.group) {
          newValues.title = action.group.title;
          newValues.thumbnailImageUrl = action.group.thumbnail_url;
        } else {
          this.groupState$.pipe(take(1)).subscribe((groupState) => {
            if (!!groupState.currGroup) {
              newValues.title = groupState.currGroup.title;
              newValues.thumbnailImageUrl = groupState.currGroup.thumbnail_url;
            }
          });
        }

        // Update list
        return this.mds.updateGroupChannelsList(action.groupID, newValues).pipe(
          delay(500), // Mitigate timing issues
          mergeMap(() => {
            return from([
              new ContentActions.GetChangeset('channelgroups'),
              new ContentActions.GetChangeset('channels'),
              new GroupActions.LoadGroupChannels(action.groupID, true, null),
            ]).pipe(
              concat(
                combineLatest(
                  this.content$.pipe(
                    first((content) => !content.channelsChangesetLoading && !content.channelgroupsChangesetLoading),
                  ),
                  this.groupState$.pipe(
                    first((groupState) => {
                      const sections = groupState.busySections[action.groupID] || [];
                      return sections.indexOf(GroupSection.ChannelList) < 0;
                    }),
                  ),
                ).pipe(
                  mergeMap(() => {
                    if (!action.fromClone) {
                      this.toastr.success(
                        this.translate.instant('amedia.toastr.groupDetails.ChannelUpdatedSuccess'),
                        this.translate.instant('amedia.success'),
                      );
                    }
                    return from([
                      new GroupActions.UpdateGroupChannelListSucceeded(action.groupID),
                      new GroupActions.LoadChannelsNotInGroup(action.channelList, action.allChannels),
                    ]);
                  }),
                ),
              ),
            );
          }),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.toastr.groupDetailsUpdatingError'),
              this.translate.instant('common.error.label'),
            );
            return of(new GroupActions.UpdateGroupChannelListFailed(action.groupID));
          }),
        );
      }),
  );

  // Listen for the 'CLONE_GROUP' action
  @Effect()
  cloneGroup$: Observable<Action> = this.actions$
    .pipe(
      ofType(GroupActions.CLONE_GROUP),
      observeOn(asap),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: GroupActions.CloneGroup) => {
        this.toastr.info(
          this.translate.instant('amedia.toastr.cloningGroupStarted'),
          this.translate.instant('amedia.notification'),
        );
        return this.mds.createChannelGroup(action.newGroup.title).pipe(
          mergeMap((res) => {
            return of(new GroupActions.UpdateGroupChannelList(
              action.newGroup['channel_ids'],
              res.channelgroup_id,
              action.allChannels,
              action.newGroup,
              true,
            )).pipe(
              concat(
                this.groupState$.pipe(
                  first((groupState) => {
                    const sections = groupState.busySections[res.channelgroup_id] || [];
                    return sections.indexOf(GroupSection.ChannelList) < 0;
                  }),
                  mergeMap((groupState) => {
                    let redoFilters;
                    let contentType;
                    this.content$.pipe(take(1)).subscribe((contentState) => {
                      contentType = contentState.contentType;
                      if (contentType === 'channelgroups') {
                        const { searchText, ...filters } = contentState.filters;
                        redoFilters = filters;
                      }
                    });

                    if (contentType === 'channelgroups') {
                      // Reload current page
                      return this.mds.getChannelgroupsList({ searchText: undefined, ...redoFilters }).pipe(
                        map((payload) => {
                          this.toastr.success(
                            this.translate.instant('amedia.toastr.groupCloned'),
                            this.translate.instant('amedia.success'),
                          );
                          return new ContentActions.ContentListLoaded(payload as PaginatedResponse,
                                                                      'channelgroups', true);
                        }),
                        catchError((error) => of(new ContentActions.ContentListFailedToLoad('channelgroups'))),
                      );
                    } else {
                      return empty() as Observable<Action>;
                    }
                  }),
                ),
              ),
            );
          }),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.toastr.groupCloneFailed'),
              this.translate.instant('common.error.label'),
            );
            return of(new GroupActions.CloneGroupFailed());
          }),
        );
      }),
  );

  constructor(
    private actions$: Actions,
    private mds: MediaDataService,
    private router: Router,
    private store: Store<fromRoot.State>,
    private translate: TranslateService,
    private toastr: ToastrService,
    @Optional() @Inject(LOAD_DEBOUNCE) private debounce: number = 300,
    /**
     * You inject an optional Scheduler that will be undefined
     * in normal application usage, but its injected here so that you can mock out
     * during testing using the RxJS TestScheduler for simulating passages of time.
     */
    @Optional() @Inject(LOAD_SCHEDULER) private scheduler: Scheduler,
  ) {
    this.content$ = this.store.pipe(select(fromRoot.getContentState));
    this.groupState$ = this.store.pipe(select(fromRoot.getGroupState));
  }
}
