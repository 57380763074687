import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

/**
 * FormControl component that manages an array of values and dynamically renders
 * the `itemComponent` for each item in the array.
 *
 * Example:
 *
 * UserListItemComponent = UserListItemComponent;
 * form = fb.group({
 *  list: [ [{ id: 1, first_name: 'Jon' }] ],
 * });
 *
 * ...
 *
 * <ll-list-input
 *   formControlName="list"
 *   [itemComponent]="UserListItemComponent"
 * ></ll-list-input>
 */
@Component({
  selector: 'll-list-input',
  templateUrl: './list-input.component.html',
  styles: [
    `:host { display: block; position: relative; }`,
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line:no-forward-ref
    useExisting: forwardRef(() => ListInputComponent),
    multi: true,
  }],
})
export class ListInputComponent implements ControlValueAccessor {
  @Input() itemComponent: any;
  @Input() noDataTileHeight: number = 100;
  @Output() change = new EventEmitter<any>();
  items: any[];
  numItemsWriting: number = 0;

  outputsFor(index: number) {
    return {
      ngModelChange: (e) => {
        this.items[index] = e;

        if (this.numItemsWriting > 0) {
          this.numItemsWriting--;
          return;
        }

        this.propagateChange();
      },
      clone: () => {
        const newItem = _.cloneDeep(this.items[index]);
        this.items.splice(index, 0, newItem);
        this.propagateChange();
      },
      delete: () => {
        this.items = this.items.filter((item, idx) => idx !== index);
        this.propagateChange();
      },
    };
  }

  trackByFn(index, item) {
    return index;
  }

  propagateChange(): void {
    this.onTouched();

    this.change.emit(this.items);
    this.onChange(this.items);
  }

  writeValue(val: any[]): void {
    if (!!val) {
      this.numItemsWriting = val.length;
      this.items = val;
    }
  }

  registerOnChange(fn: (v: any) => {}): void { this.onChange = fn; }

  registerOnTouched(fn: () => {}): void { this.onTouched = fn; }

  private onChange(v: any) { }
  private onTouched() { }
}
