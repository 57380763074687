import * as _ from 'lodash';
import { ContentFilters } from '../models/content-filters';

export function sortContent(results: any[], filters: ContentFilters): void {
  if (filters.sortBy) {
    results.sort((a, b) => {
      if (a[filters.sortBy] < b[filters.sortBy]) {
        return filters.sortDir === 'asc' ? -1 : 1;
      } else if (a[filters.sortBy] === b[filters.sortBy]) {
        return 0;
      } else {
        return filters.sortDir === 'asc' ? 1 : -1;
      }
    });
  }
}

export function contentTypeToId(contentType: string): string {
  if (contentType === 'media') {
    return 'mediaId';
  } else if (contentType === 'channels') {
    return 'channel_id';
  } else {
    return 'channelgroup_id';
  }
}

export function mssToAmedia(contentType: string, content: any): any {
  let converted: any;
  if (contentType === 'media') {
    return content;
  } else {
    // Make snake case instead of camel
    converted = _.reduce(
      content,
      (result, v, k) => {
        // Convert dates from ms to s
        if (k.indexOf('Date') >= 0) {
          v = v / 1000;
        }
        result[_.snakeCase(k)] = v;

        return result;
      },
      {},
    );

    // We must rename fields from the MSS-variant to MDS
    const rename: {[key: string]: string} = {
      content_access_rule_id: 'restrictionrule_id',
      last_modified_date: 'update_date',
      created_date: 'create_date',
      thumbnail_image_url: 'thumbnail_url',
      autoplay_next_clip_enabled: 'autoplay_next_enabled',
    };

    if (contentType === 'channels') {
      rename['id'] = 'channel_id';
    } else {
      rename['id'] = 'channelgroup_id';
    }

    // rename fields if needed
    return _.mapKeys(converted, (v, k) => rename[k] || k);
  }
}
