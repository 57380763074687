import {
  Directive,
  ElementRef,
  HostListener,
  Optional,
} from '@angular/core';
import * as _ from 'lodash';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[llTrimInput]' })
export class TrimInputDirective {
  constructor(
    @Optional() private ngControl: NgControl,
    private el: ElementRef,
  ) { }

  @HostListener('blur')
  onInputBlur(): any {
    if (!!this.ngControl && !!this.ngControl.control) {
      this.ngControl.control.setValue(
        _.trim(this.el.nativeElement.value),
      );
    }
  }

  @HostListener('keydown', ['$event'])
  onEnterDown(e: KeyboardEvent) {
    if (e.keyCode === 13) {
      if (!!this.ngControl && !!this.ngControl.control) {
        this.ngControl.control.setValue(
          _.trim(this.el.nativeElement.value),
        );
      }
    }
  }

  @HostListener('keyup', ['$event'])
  onEnterUp(e: KeyboardEvent) {
    if (e.keyCode === 13) {
      // Delay to allow default submit behavior
      setTimeout(() => (e.target as any).blur());
    }
  }
}
