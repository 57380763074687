import * as _ from 'lodash';
import { SortDirection, SortParams } from 'control-ui-common';

import * as customProp from '../../actions/settings/custom-properties';
import { CustomProperty } from '../../../models/custom-property';

export interface State {
  mediaCustomProps: CustomProperty[];
  mediaCustomPropsLoading: boolean;
  mediaCustomPropsSaving: boolean;
  mediaCustomPropsDeleting: boolean;
  channelsCustomProps: CustomProperty[];
  channelsCustomPropsLoading: boolean;
  channelsCustomPropsSaving: boolean;
  channelsCustomPropsDeleting: boolean;
  channelgroupsCustomProps: CustomProperty[];
  channelgroupsCustomPropsLoading: boolean;
  channelgroupsCustomPropsSaving: boolean;
  channelgroupsCustomPropsDeleting: boolean;
  currCustomProp: CustomProperty;
  savingPropFailed: boolean;
  sortParams: {
    sortBy: string,
    sortDir: SortDirection,
  };
}

export const initialState: State = {
  mediaCustomProps: [],
  mediaCustomPropsLoading: true,
  mediaCustomPropsSaving: false,
  mediaCustomPropsDeleting: false,
  channelsCustomProps: [],
  channelsCustomPropsLoading: true,
  channelsCustomPropsSaving: false,
  channelsCustomPropsDeleting: false,
  channelgroupsCustomProps: [],
  channelgroupsCustomPropsLoading: true,
  channelgroupsCustomPropsSaving: false,
  channelgroupsCustomPropsDeleting: false,
  currCustomProp: null,
  savingPropFailed: false,
  sortParams: {
    sortBy: 'type_name',
    sortDir: SortDirection.ASC,
  },
};

const sortProperties = (propArray: CustomProperty[], p: SortParams): CustomProperty[] => {
  return _.orderBy(
    _.cloneDeep(propArray),
    [(cp) => _.toString(cp[p.sortBy]).toLowerCase()],
    [p.sortDir],
  );
};

export function reducer(
  state = initialState,
  action: customProp.Actions,
): State {
  let newProperties;
  switch (action.type) {
    case customProp.LOAD_CUSTOM_PROPERTIES:
      return {
        ...state,
        [`${action.contentType}CustomProps`]: [],
        [`${action.contentType}CustomPropsLoading`]: true,
        currCustomProp: null,
      };
    case customProp.LOAD_CUSTOM_PROPERTIES_SUCCEEDED:
      return {
        ...state,
        [`${action.contentType}CustomPropsLoading`]: false,
        [`${action.contentType}CustomProps`]: sortProperties(action.props, state.sortParams),
      };
    case customProp.LOAD_CUSTOM_PROPERTIES_FAILED:
      return {
        ...state,
        [`${action.contentType}CustomPropsLoading`]: false,
        [`${action.contentType}CustomProps`]: [],
      };
    case customProp.SELECT_CURR_CUSTOM_PROP:
      return {
        ...state,
        currCustomProp: _.cloneDeep(action.property),
      };
    case customProp.CHANGE_CUSTOM_PROP_SORT:
      return {
        ...state,
        sortParams: {
          sortBy: action.sortParams.sortBy,
          sortDir: action.sortParams.sortDir,
        },
        [`${action.contentType}CustomProps`]:
          sortProperties(state[`${action.contentType}CustomProps`], action.sortParams),
      };
    case customProp.CREATE_CUSTOM_PROP:
      return {
        ...state,
        [`${action.contentType}CustomPropsSaving`]: true,
        savingPropFailed: false,
      };
    case customProp.CREATE_CUSTOM_PROP_SUCCEEDED:
      if (state[`${action.contentType}CustomProps`]) {
        newProperties = [
          ...state[`${action.contentType}CustomProps`],
          {
            ...action.property,
          },
        ];
        return {
          ...state,
          [`${action.contentType}CustomProps`]: sortProperties(newProperties, state.sortParams),
          [`${action.contentType}CustomPropsSaving`]: false,
          savingPropFailed: false,
        };
      }
      return state;
    case customProp.SAVE_CUSTOM_PROP_FAILED:
      return {
        ...state,
        [`${action.contentType}CustomPropsSaving`]: false,
        savingPropFailed: true,
      };
    case customProp.UPDATE_CUSTOM_PROP:
      return {
        ...state,
        [`${action.contentType}CustomPropsSaving`]: true,
        savingPropFailed: false,
      };
    case customProp.UPDATE_CUSTOM_PROP_SUCCEEDED:
      if (
        state[`${action.contentType}CustomProps`] &&
        state.currCustomProp
      ) {
        newProperties = _.cloneDeep(state[`${action.contentType}CustomProps`]);

        const propsIndex = state[`${action.contentType}CustomProps`].findIndex(
          (p) => p.id === action.property.id,
        );

        newProperties.splice(propsIndex, 1, _.cloneDeep({
          ...action.property,
        }));
        return {
          ...state,
          [`${action.contentType}CustomProps`]: sortProperties(newProperties, state.sortParams),
          [`${action.contentType}CustomPropsSaving`]: false,
        };
      }
      return state;
    case customProp.DELETE_CUSTOM_PROP:
      return {
        ...state,
        [`${action.contentType}CustomPropsDeleting`]: true,
      };
    case customProp.DELETE_CUSTOM_PROP_SUCCEEDED:
      return {
        ...state,
        [`${action.contentType}CustomProps`]:
          state[`${action.contentType}CustomProps`].filter((p) => p.id !== action.id),
        currCustomProp: null,
        [`${action.contentType}CustomPropsDeleting`]: false,
      };
    case customProp.DELETE_CUSTOM_PROP_FAILED:
      return {
        ...state,
        [`${action.contentType}CustomPropsDeleting`]: false,
      };
    default:
      return state;
  }
}

export const getCustomProperties = (state: State) => state;
