import { Action } from '@ngrx/store';
import { SortParams } from 'control-ui-common';

import { User } from '../../../models/user';
import { Errors } from '../../../models/errors';

export const LOAD_USERS = '[Users] Load Users';
export const LOAD_USERS_SUCCEEDED = '[Users] Load Users Succeeded';
export const LOAD_USERS_FAILED = '[Users] Load Users Failed';
export const SELECT_CURR_USER = '[Users] Select Current User';
export const FILTER_USERS = '[Users] Filter Users';
export const SORT_USERS = '[Users] Sort Users';
export const SAVE_USER = '[Users] Save User';
export const SAVE_USER_SUCCEEDED = '[Users] Save User Succeeded';
export const SAVE_USER_FAILED = '[Users] Save User Failed';
export const LINK_USER = '[Users] Link User';
export const LINK_USER_CANCELLED = '[Users] Link User Cancelled';
export const DELETE_USER = '[Users] Delete User';
export const DELETE_USER_SUCCEEDED = '[Users] Delete User Succeeded';
export const DELETE_USER_FAILED = '[Users] Delete User Failed';
export const UNLINK_USER = '[Users] Unlink User';
export const UNLINK_USER_SUCCEEDED = '[Users] Unlink User Succeeded';
export const UNLINK_USER_FAILED = '[Users] Unlink User Failed';

export class LoadUsers implements Action {
  readonly type = LOAD_USERS;
}

export class LoadUsersSucceeded implements Action {
  readonly type = LOAD_USERS_SUCCEEDED;

  constructor(public users: User[]) { }
}

export class LoadUsersFailed implements Action {
  readonly type = LOAD_USERS_FAILED;
}

export class FilterUsers implements Action {
  readonly type = FILTER_USERS;

  constructor(public queryText: string) { }
}

export class SortUsers implements Action {
  readonly type = SORT_USERS;

  constructor(public sortParams: SortParams) { }
}

export class SelectCurrentUser implements Action {
  readonly type = SELECT_CURR_USER;

  constructor(public user: User) { }
}

export class SaveUser implements Action {
  readonly type = SAVE_USER;

  constructor(public user: User) { }
}

export class SaveUserSucceeded implements Action {
  readonly type = SAVE_USER_SUCCEEDED;

  constructor(public user: User) { }
}

export class SaveUserFailed implements Action {
  readonly type = SAVE_USER_FAILED;

  constructor(public errors: Errors) { }
}

export class LinkUser implements Action {
  readonly type = LINK_USER;

  constructor(
    public user: User,
    public orgId: string,
    public role: string,
  ) { }
}

export class LinkUserCancelled implements Action {
  readonly type = LINK_USER_CANCELLED;
}

export class DeleteUser implements Action {
  readonly type = DELETE_USER;

  constructor(public user: User) { }
}

export class DeleteUserSucceeded implements Action {
  readonly type = DELETE_USER_SUCCEEDED;

  constructor(public user: User) { }
}

export class DeleteUserFailed implements Action {
  readonly type = DELETE_USER_FAILED;
}

export class UnlinkUser implements Action {
  readonly type = UNLINK_USER;

  constructor(public user: User) { }
}

export class UnlinkUserSucceeded implements Action {
  readonly type = UNLINK_USER_SUCCEEDED;

  constructor(public user: User) { }
}

export class UnlinkUserFailed implements Action {
  readonly type = UNLINK_USER_FAILED;
}

export type Actions = (
  LoadUsers | LoadUsersSucceeded | LoadUsersFailed |
  FilterUsers | SortUsers | SelectCurrentUser |
  SaveUser | SaveUserSucceeded | SaveUserFailed |
  LinkUser | LinkUserCancelled |
  DeleteUser | DeleteUserSucceeded | DeleteUserFailed |
  UnlinkUser | UnlinkUserSucceeded | UnlinkUserFailed
);
