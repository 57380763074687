import { Component, Input, Output, EventEmitter, OnChanges,
  SimpleChanges, ViewChild, TemplateRef } from '@angular/core';
import * as _ from 'lodash';
import { duration, utc } from 'moment';
import { ColumnDef } from './table-column-def';
import { SortParams, LazyPaginationOptions, SortableTableDirective } from 'control-ui-common';
export interface PageChange {
  page: number;
  pageSize: number;
}

export interface RowSelection {
  selected: boolean;
  rowData: any;
}

@Component({
  selector: 'll-sortable-table',
  templateUrl: './sortable-table.component.html',
  styleUrls: ['./sortable-table.component.scss'],
})
export class SortableTableComponent implements OnChanges {
  @ViewChild(SortableTableDirective) sortableTable;

  @Input() columnDefs: ColumnDef[];
  @Input() initialSelectionState: boolean = false;
  @Input() paginationOptions: LazyPaginationOptions;
  @Input() possiblePageCounts: number[] = [10, 25, 50];
  @Input() showPaginator: boolean;
  @Input() showRowSelectCheckboxes: boolean = true;
  @Input() tableData: any[];
  @Input() tableDefaultSortParams: SortParams;
  @Input() tableLoading: boolean;

  @Output() onPageChange: EventEmitter<PageChange> = new EventEmitter<PageChange>();
  @Output() onRowSelect: EventEmitter<RowSelection> = new EventEmitter<RowSelection>();
  @Output() tableSortChanged: EventEmitter<SortParams> = new EventEmitter<SortParams>();
  @Output() selectAll: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectionModels: boolean[] = []; // ng model variables for selection checkboxes
  selectAllModel: boolean = false; // ng model for select all checkbox

  get noData(): boolean {
    return !this.tableLoading && !!this.tableData && this.tableData.length === 0;
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (this.hasOwnProperty(prop)) {
        this[prop] = changes[prop].currentValue;
        if (prop === 'tableData' &&
          !_.isEqual(changes[prop].currentValue, changes[prop].previousValue)) {
          this.handleTableDataChanged();
        }
      }
    }
  }

  formatDurationData(header: ColumnDef, datum: any): string | number {
    if (_.isUndefined(datum[header.dataKey])) {
      return '';
    }
    let dur = duration(datum[header.dataKey], 'ms');
    return String(Math.floor(dur.asHours())) +
      utc(dur.asMilliseconds()).format(':mm:ss.SSS');
  }

  handlePageChange(e: any): void {
    this.onPageChange.emit({ page: e.page, pageSize: e.pageSize });
  }

  handleRowSelected(index: number, selected: boolean, rowData: any): void {
    this.onRowSelect.emit({ selected, rowData });
    this.selectionModels[index] = selected;
    this.selectAllModel = this.allSelected();
  }

  handleSelectAll(selected: boolean): void {
    if (this.tableData && this.tableData.length > 0) {
      this.tableData.forEach((rowData, index) => {
        this.selectionModels[index] = selected;
      });
    }
    this.selectAll.emit(selected);
  }

  handleTableDataChanged(): void {
    this.initSelectionModels();
  }

  handleTableSortChanged(sort: SortParams): void {
    this.tableSortChanged.emit(sort);
  }

  setTableUiSort(sortParams: SortParams): void {
    this.sortableTable.setSort(sortParams);
  }

  private initSelectionModels(): void {
    if (this.tableData && this.tableData.length > 0) {
      this.selectionModels = [];
      this.tableData.forEach(() => {
        this.selectionModels.push(this.initialSelectionState);
      });
      // during initialization, we may need to emit the select all if we default to selected
      this.selectAllModel = this.allSelected();
      if (this.selectAllModel) {
        // can result in changedafterchecked error here
        setTimeout(() => {
          this.selectAll.emit(this.selectAllModel);
        });
      }
    }
  }

  private allSelected(): boolean {
    let notSelected = this.selectionModels.find((m) => m === false);
    if (notSelected !== undefined) {
      return false;
    }
    return true;
  }

}
