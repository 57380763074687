import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { filter, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { SelectOption } from 'control-ui-common';

import * as fromRoot from '../../shared/redux/reducers';
import * as fromAuth from '../../shared/redux/reducers/auth';
import * as AuthActions from '../../shared/redux/actions/auth';
import { Organization, OrgNode } from '../../shared/models/organization';

@Component({
  selector: 'll-org-selector',
  styleUrls: ['./org-selector.component.scss'],
  templateUrl: './org-selector.component.html',
})
export class OrgSelectorComponent {
  @Input() subject: string;
  auth$: Observable<fromAuth.State>;
  orgs: any[] = [];
  constructor(
    private store: Store<fromRoot.State>,
    private translate: TranslateService,
  ) {
    this.auth$ = store.pipe(select(fromRoot.getAuthState));
    this.auth$.pipe(
      filter(this.orgsLoaded),
      take(1),
    ).subscribe((authState) => {
      _.forEach(
        _.sortBy(
          authState.orgs,
          (org: OrgNode) => _.lowerCase(org.organizationDetails.name || 'Unnamed'),
        ),
        (org) => this.addOrgNode(org),
      );
    });
  }

  orgsLoaded(authState: fromAuth.State): boolean {
    return !authState.organizationLoading && !authState.orgsLoading;
  }

  addOrgNode(org: OrgNode, level: number = 0): void {
    this.orgs.push({
      value: org.organizationDetails.id,
      text: org.organizationDetails.name || '<Unnamed>',
      level,
    });
    _.forEach(
      _.sortBy(
        org.children,
        (childList: OrgNode) => _.lowerCase(childList.organizationDetails.name || 'Unnamed'),
      ),
      (childOrg: OrgNode) => this.addOrgNode(childOrg, level + 1),
    );
  }

  changeOrg(orgId: string): void {
    this.store.dispatch(new AuthActions.SwitchOrg(orgId));
  }
}
