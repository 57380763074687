export enum CsvExportStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
  FAILED = 'FAILED',
}

export interface CsvExportResponse {
  token: string;
  signed_url: string;
  status: CsvExportStatus;
}
