import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import {
  StoreRouterConnectingModule,
  RouterStateSerializer,
} from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule, TranslateService, TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { LLCommonModule, locales } from 'control-ui-common';
import { Locker, LockerModule, DRIVERS } from 'angular-safeguard';
import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from 'ngx-clipboard';
import * as _ from 'lodash';

import { reducers, metaReducers } from './shared/redux/reducers';
import { AnonymousGuard } from './shared/auth/anonymous-guard';
import { AuthModule } from './auth/auth.module';
import { CustomRouterStateSerializer } from './shared/utils/router';
import { RootRoutingModule } from './root-routing.module';
import { ScriptsService } from './shared/services/scripts.service';
import { PromptForDeactivateGuard } from './shared/services/prompt-for-deactivate-guard.service';
import { AdminGuard } from './shared/auth/admin-guard';
import { AuthGuard } from './shared/auth/auth-guard';
import { PwdNeedUpdateGuard } from './shared/auth/pwd-need-update-guard';
import { PwdSetGuard } from './shared/auth/pwd-set-guard';
import { MissingQuestionGuard } from './shared/auth/missing-question-guard';
import { AccountMgmtService } from './shared/services/account-mgmt.service';
import { RootComponent } from './root.component';
import { AppComponent } from './app/app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SharedModule } from './shared/shared.module';
import { locales as amediaLocales } from '../_i18n';
import { SupportComponent } from './support/support.component';

// EFFECTS
import { AuthEffects } from './shared/redux/effects/auth';
import { BulkEffects } from './shared/redux/effects/bulk';
import { ChannelEffects } from './shared/redux/effects/channel';
import { ContentEffects } from './shared/redux/effects/content';
import { ContentRestrictionEffects } from './shared/redux/effects/settings/content-restrictions';
import { CustomPropertyEffects } from './shared/redux/effects/settings/custom-properties';
import { DeveloperToolsEffects } from './shared/redux/effects/settings/developer-tools';
import { EncodingEffects } from './shared/redux/effects/settings/encoding';
import { GroupEffects } from './shared/redux/effects/group';
import { MediaEffects } from './shared/redux/effects/media';
import { UsersEffects } from './shared/redux/effects/settings/users';
import { ThemeEffects } from './shared/redux/effects/theme';
import { ReportsEffects } from './shared/redux/effects/reports';
import { IdleTimeoutModalComponent } from './shared/modals/idle-timeout-modal.component';
import { httpInterceptorProviders } from './shared/utils/http-interceptors-provider';
import { IdleTimeoutService } from './shared/services/idle-timeout.service';
import { EnvConfigurationService } from './shared/services/environment-configuration.service';

export class CustomAmediaLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    const allLocales = _.assign(
      {},
      locales[lang],
      amediaLocales[lang],
    );
    return Observable.create((observer: any) => {
      observer.next(allLocales);
      observer.complete();
    });
  }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule,
    EffectsModule.forRoot([
      AuthEffects,
      BulkEffects,
      ChannelEffects,
      ContentEffects,
      ContentRestrictionEffects,
      CustomPropertyEffects,
      DeveloperToolsEffects,
      EncodingEffects,
      GroupEffects,
      MediaEffects,
      ReportsEffects,
      UsersEffects,
      ThemeEffects,
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomAmediaLoader,
      },
    }),
    LockerModule.withConfig({
      driverNamespace: 'll',
      driverFallback: [ DRIVERS.LOCAL, DRIVERS.SESSION, DRIVERS.COOKIE ],
    }),
    LLCommonModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      disableTimeOut: false,
      positionClass: 'toast-top-center',
      preventDuplicates: false,
    }),
    ClipboardModule,
    AuthModule,
    SharedModule,
    RootRoutingModule,
  ],
  declarations: [
    RootComponent,
    AppComponent,
    IdleTimeoutModalComponent,
    PageNotFoundComponent,
    SupportComponent,
  ],
  providers: [
    AdminGuard,
    AnonymousGuard,
    AuthGuard,
    PromptForDeactivateGuard,
    PwdNeedUpdateGuard,
    PwdSetGuard,
    MissingQuestionGuard,
    ScriptsService,
    AccountMgmtService,
    IdleTimeoutService,
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    {
      provide: APP_INITIALIZER,
      useFactory: (envConfigService: EnvConfigurationService) => () => envConfigService.load().toPromise(),
      deps: [EnvConfigurationService],
      multi: true,
    },
    ...httpInterceptorProviders,
  ],
  entryComponents: [
    IdleTimeoutModalComponent,
  ],
  bootstrap: [RootComponent],
})
export class RootModule {
  constructor(
    locker: Locker,
    translate: TranslateService,
  ) {
    let language = 'en_US';
    const existingLang = locker.get(DRIVERS.LOCAL, 'lang');
    if (!!existingLang) {
      language = existingLang;
    } else {
      locker.set(DRIVERS.LOCAL, 'lang', 'en_US');
    }
    translate.setDefaultLang('en_US');
    translate.use(language);
  }
}
