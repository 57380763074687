import { ReportsTabs } from './../../models/reports-tabs';
import {
  ActionReducerMap,
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';
import { RouterStateUrl } from '../../utils/router';
import * as fromRouter from '@ngrx/router-store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import * as fromAuth from './auth';
import * as fromBulk from './bulk';
import * as fromChannel from './channel';
import * as fromContent from './content';
import * as fromContentRestriction from './settings/content-restrictions';
import * as fromCustomProperty from './settings/custom-properties';
import * as fromDeveloperTools from './settings/developer-tools';
import * as fromEncoding from './settings/encoding';
import * as fromGroup from './group';
import * as fromMedia from './media';
import * as fromReports from './reports';
import * as fromTheme from './theme';
import * as fromUsers from './settings/users';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  auth: fromAuth.State;
  bulk: fromBulk.State;
  channel: fromChannel.State;
  content: fromContent.State;
  contentRestriction: fromContentRestriction.State;
  customProperty: fromCustomProperty.State;
  developerTools: fromDeveloperTools.State;
  encoding: fromEncoding.State;
  group: fromGroup.State;
  media: fromMedia.State;
  reports: fromReports.State;
  theme: fromTheme.State;
  users: fromUsers.State;
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
  bulk: fromBulk.reducer,
  channel: fromChannel.reducer,
  content: fromContent.reducer,
  contentRestriction: fromContentRestriction.reducer,
  customProperty: fromCustomProperty.reducer,
  developerTools: fromDeveloperTools.reducer,
  encoding: fromEncoding.reducer,
  group: fromGroup.reducer,
  media: fromMedia.reducer,
  reports: fromReports.reducer,
  theme: fromTheme.reducer,
  users: fromUsers.reducer,
  routerReducer: fromRouter.routerReducer,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): State => {
    let s = reducer(state, action);
    // console.log('action', action, 'state', s);
    return s;
  };
}

export const metaReducers: Array<MetaReducer<State>> = [];

/**
 * Layout Reducers
 */
export const getAuthState = createFeatureSelector<fromAuth.State>('auth');
export const getBulkState = createFeatureSelector<fromBulk.State>('bulk');
export const getChannelState = createFeatureSelector<fromChannel.State>('channel');
export const getContentState = createFeatureSelector<fromContent.State>('content');
export const getContentRestrictionState = createFeatureSelector<fromContentRestriction.State>('contentRestriction');
export const getCustomPropertiesState = createFeatureSelector<fromCustomProperty.State>('customProperty');
export const getDeveloperToolsState = createFeatureSelector<fromDeveloperTools.State>('developerTools');
export const getEncodingState = createFeatureSelector<fromEncoding.State>('encoding');
export const getGroupState = createFeatureSelector<fromGroup.State>('group');
export const getMediaState = createFeatureSelector<fromMedia.State>('media');
export const getReportsState = createFeatureSelector<fromReports.State>('reports');
export const getThemeState = createFeatureSelector<fromTheme.State>('theme');
export const getUsersState = createFeatureSelector<fromUsers.State>('users');

export const getUser = createSelector(
  getAuthState,
  fromAuth.getUser,
);

export const getPlayerNonce = createSelector(
  getAuthState,
  fromAuth.getPlayerNonce,
);

export const getCurrOrgId = createSelector(
  getAuthState,
  fromAuth.getCurrOrgId,
);

export const getBulkActions = createSelector(
  getBulkState,
  fromBulk.getBulkActions,
);

export const getChannel = createSelector(
  getChannelState,
  fromChannel.getChannel,
);

export const getContent = createSelector(
  getContentState,
  fromContent.getContent,
);

export const getMediaContent = createSelector(
  getContentState,
  fromContent.getMediaContent,
);

export const getChannelContent = createSelector(
  getContentState,
  fromContent.getChannelContent,
);

export const getGroupContent = createSelector(
  getContentState,
  fromContent.getGroupContent,
);

export const getMediaChangesetPollingActive = createSelector(
  getContentState,
  fromContent.getMediaChangesetPollingActive,
);

export const getChannelsChangesetPollingActive = createSelector(
  getContentState,
  fromContent.getChannelsChangesetPollingActive,
);

export const getGroupsChangesetPollingActive = createSelector(
  getContentState,
  fromContent.getGroupsChangesetPollingActive,
);

export const getFacetPollingActive = createSelector(
  getContentState,
  fromContent.getFacetPollingActive,
);

export const getNewlyCreatedMedia = createSelector(
  getContentState,
  fromContent.getNewlyCreatedMedia,
);

export const getContentRestriction = createSelector(
  getContentRestrictionState,
  fromContentRestriction.getContentRestrictions,
);

export const getCustomProperty = createSelector(
  getCustomPropertiesState,
  fromCustomProperty.getCustomProperties,
);

export const getDeveloperTools = createSelector(
  getDeveloperToolsState,
  fromDeveloperTools.getDeveloperTools,
);

export const getEncoding = createSelector(
  getEncodingState,
  fromEncoding.getEncoding,
);

export const getGroup = createSelector(
  getGroupState,
  fromGroup.getGroup,
);

export const getUsers = createSelector(
  getUsersState,
  fromUsers.getUsers,
);

export const getCcVersions = createSelector(
  getMediaState,
  fromMedia.getCcVersions,
);

export const getOverviewTableSelectVal = createSelector(
  getReportsState,
  fromReports.getOverviewTableSelectVal,
);

export const getDataUsageSummaryData = createSelector(
  getReportsState,
  fromReports.getDataUsageSummaryData,
);

export const getDataUsageChartData = createSelector(
  getReportsState,
  fromReports.getDataUsageChartData,
);

export const getReportsSelectedTab = createSelector(
  getReportsState,
  fromReports.getReportsSelectedTab,
);

export const getMediaReportChartData = createSelector(
  getReportsState,
  fromReports.getMediaReportChartData,
);

export const getChannelReportChartData = createSelector(
  getReportsState,
  fromReports.getChannelReportChartData,
);

export const getReportsViewerDataTableData = createSelector(
  getReportsState,
  fromReports.getViewerDataTableData,
);

export const getReportsMediaTableFilters = createSelector(
  getReportsState,
  fromReports.getMediaTableFilters,
);

export const getReportsChannelTableFilters = createSelector(
  getReportsState,
  fromReports.getChannelTableFilters,
);

export const getReportsViewerDataTableFilters = createSelector(
  getReportsState,
  fromReports.getViewerDataTableFilters,
);

export const getReportsChannelSummaryData = createSelector(
  getReportsState,
  fromReports.getChannelSummaryData,
);

export const getReportsMediaSummaryData = createSelector(
  getReportsState,
  fromReports.getMediaSummaryData,
);

export const getReportsViewerDataSummaryData = createSelector(
  getReportsState,
  fromReports.getViewerDataSummaryData,
);

export const getReportsGeoTableFilters = createSelector(
  getReportsState,
  fromReports.getGeoTableFilters,
);

export const getReportsGeoGranularity = createSelector(
  getReportsState,
  fromReports.getGeoReportsGranularity,
);

export const getReportsGeoSummaryData = createSelector(
  getReportsState,
  fromReports.getGeoSummaryData,
);

export const getOverviewChartData = createSelector(
  getReportsState,
  fromReports.getOverviewChartData,
);

export const getReportsEngagementTableFilters = createSelector(
  getReportsState,
  fromReports.getEngagementTableFilters,
);

export const getReportsEngagementTableData = createSelector(
  getReportsState,
  fromReports.getEngagementTableData,
);

export const getReportsGeoTableData = createSelector(
  getReportsState,
  fromReports.getGeoTableData,
);

export const getReportsGeoBreadcrumbs = createSelector(
  getReportsState,
  fromReports.getGeoBreadcrumbs,
);

export const getReportsViewerDataTableSelectVal = createSelector(
  getReportsState,
  fromReports.getViewerDataTableSelectVal,
);

export const getReportsViewerDataChartData = createSelector(
  getReportsState,
  fromReports.getViewerDataChartData,
);

export const getReportsMediaChartDataType = createSelector(
  getReportsState,
  fromReports.getMediaChartDataType,
);

export const getReportsDataUsageChartType = createSelector(
  getReportsState,
  fromReports.getDataUsageChartType,
);

export const getReportsEventHistoryTableData = createSelector(
  getReportsState,
  fromReports.getEventHistoryTableData,
);

export const getReportsEventHistoryFilters = createSelector(
  getReportsState,
  fromReports.getEventHistoryFilters,
);

export const getReportsEventHistoryEventTypes = createSelector(
  getReportsState,
  fromReports.getEventHistoryEventTypes,
);

export const getReportsOverviewTableFilters = createSelector(
  getReportsState,
  fromReports.getOverviewTableFilters,
);

export const getReportsOverviewTableData = createSelector(
  getReportsState,
  fromReports.getOverviewTableData,
);
