import { Injectable } from '@angular/core';

@Injectable()
export class Base64UriToFileConverter {
  // converts a base 64 uri to a file
  base64UriToFile(uri: string): File {
    const arr = uri.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'thumbnail.png', { type: mime });
  }
}
