import { EnvConfigurationService, Configuration } from './environment-configuration.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, take } from 'rxjs/operators';
import * as _ from 'lodash';

import * as fromRoot from '../redux/reducers';
import { Cuepoint } from '../models/cuepoints';
import { Store, select } from '@ngrx/store';

@Injectable()
export class CuepointManagementService {

  env: Configuration;

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,
  ) {
    this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);
  }

  getCuepoints(mediaID: string): Observable<Cuepoint[]> {
    return this.http.get<Cuepoint[]>(
      `${this.env.SHIM_BASE_URL}/cpms/media/${mediaID}/cuepoints`,
    ).pipe(
      map((res) => _.map(
        res['cuepoints'],
        (cuepoint) => {
          // Only every deal with details as an object
          cuepoint.details = JSON.parse(cuepoint.details);

          // These are required fields when saving.  Give them a value
          cuepoint.details.isOverlay = cuepoint.type === 1;
          cuepoint.details.trackingPixelUrls = cuepoint.details.trackingPixelUrls || [];
          if (cuepoint.type === 'Ad') {
            cuepoint.type = 0;
          } else if (cuepoint.type === 'ContentOverlay') {
            cuepoint.type = 1;
            cuepoint.details.source = cuepoint.details.source || null;
            cuepoint.details.label = cuepoint.details.label || null;
          } else {
            cuepoint.type = 2;
            cuepoint.details.data = cuepoint.details.data || null;
          }

          return cuepoint;
        },
      )),
    );
  }

  replaceCuepoints(mediaID: string, cuepoints: Cuepoint[]): Observable<any> {
    return this.http.post<any>(
      `${this.env.SHIM_BASE_URL}/cpms/media/${mediaID}/cuepoints`,
      cuepoints,
    );
  }
}
