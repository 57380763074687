import { Component, forwardRef } from '@angular/core';

import { ListItemComponent } from '../../shared/lists/list-item.component';

@Component({
  selector: 'll-text-list-item',
  styleUrls: ['./text-list-item.component.scss'],
  templateUrl: './text-list-item.component.html',
  providers: [{
    provide: ListItemComponent,
    // tslint:disable-next-line:no-forward-ref
    useExisting: forwardRef(() => TextListItemComponent),
  }],
})
export class TextListItemComponent extends ListItemComponent {
}
