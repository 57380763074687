import { Component, Input } from '@angular/core';

@Component({
  selector: 'll-loadable-block',
  styleUrls: ['./loadable-block.component.scss'],
  templateUrl: './loadable-block.component.html',
})
export class LoadableBlockComponent {
  @Input() loading: boolean = false;
  @Input() tileHeight: number = 150;
  @Input() noData: boolean = false;
  @Input() msg: string = 'common.noData';
}
