import { ContentChild, Component, Input, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store, select } from '@ngrx/store';

import * as fromRoot from '../redux/reducers';
import * as fromAuth from '../redux/reducers/auth';
import * as Auth from '../redux/actions/auth';
import { User } from '../models/user';
import { WindowRef } from '../utils/window-ref';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'll-user-menu-dropdown',
  styleUrls: ['./user-menu-dropdown.component.scss'],
  templateUrl: './user-menu-dropdown.component.html',
})
export class UserMenuDropdownComponent {
  auth$: Observable<fromAuth.State>;

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private winRef: WindowRef,
    private translate: TranslateService,
  ) {
    this.auth$ = this.store.pipe(select(fromRoot.getAuthState));
  }

  getUserName(authState: fromAuth.State): string {
    return `${authState.user.firstName} ${authState.user.lastName}`;
  }

  goToAccount(): void {
    this.router.navigate(['/app/settings/profile']);
  }

  logout(): void {
    this.router.navigate(['/auth/logout']);
  }
}
