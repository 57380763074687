import * as _ from 'lodash';

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: `
    <ll-header-modal headerMessage="{{headerMessage}}"></ll-header-modal>
    <div class="modal-body">
      <p><b>{{ confirmBodyMain | translate }}</b></p>
      <p>{{ additionalInformation | translate }}</p>
    </div>
    <ll-footer-modal [confirmMessage]="verb | translate"></ll-footer-modal>
  `,
  styles: ['.modal-body {max-width: 80vw; overflow-wrap: break-word; }' ],
})
export class ConfirmPageChangeComponent {
  @Input() headerMessage: string;
  @Input() confirmBodyMain: string;
  @Input() additionalInformation: string;
  @Input() verb: string;

  constructor(private translate: TranslateService) { }
}
