import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

export interface Configuration {
  production: boolean;
  CLOSED_CAPTIONS_BASE_URL: string;
  CONTROL_BASE_URL: string;
  THEMES_PREVIEW_GROUP_ID: string;
  THEMES_PREVIEW_CHANNEL_ID: string;
  THEMES_PREVIEW_MEDIA_ID: string;
  THEMES_PREVIEW_AUDIO_ID: string;
  PLAYER_JS_URL: string;
  PLAYER_HTML_LINK_URL: string;
  SHIM_BASE_URL: string;
  VPWS_URL: string;
  STAGE: string;
  GOOGLE_ANALYTICS_ID: string;
  APP_VERSION: string;
  SUGGESTIONS_EMAIL: string;
  FREE_TRIAL_URL: string;
  GOOGLE_MAPS_API_KEY: string;
  IDLE_TIMEOUT_VALUE: number;
  IDLE_TIMEOUT_ALERT_DELTA: number;
  PLAYER_NONCE_EXPIRY_SECS: number;
}

@Injectable({ providedIn: 'root' })
export class EnvConfigurationService {
  // tslint:disable-next-line:max-line-length
  private readonly apiUrl = window.location.protocol + '//' + window.location.host + '/lvp/vpws/environment';
  private configuration$: Observable<Configuration>;
  private http: HttpClient;

  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  public load(): Observable<Configuration> {
    if (!this.configuration$) {
      this.configuration$ = this.http
        .get<Configuration>(`${this.apiUrl}`).pipe(
          shareReplay(1),
          map((res) => {
            return {
              production: res['production'],
              CLOSED_CAPTIONS_BASE_URL: res['ccBaseUrl'],
              THEMES_PREVIEW_GROUP_ID: res['previewGroupId'],
              THEMES_PREVIEW_CHANNEL_ID: res['previewChannelId'],
              THEMES_PREVIEW_MEDIA_ID: res['previewMediaId'],
              THEMES_PREVIEW_AUDIO_ID: res['previewAudioId'],
              PLAYER_JS_URL: res['jsPlayerUrl'],
              PLAYER_HTML_LINK_URL: res['htmlPlayerUrl'],
              VPWS_URL: res['vpwsUrl'],
              STAGE: res['stage'],
              APP_VERSION: res['appVersion'],
              CONTROL_BASE_URL: window.location.protocol + '//' + window.location.host,
              SHIM_BASE_URL: window.location.protocol + '//' + window.location.host + '/lvp/vpws',
              GOOGLE_ANALYTICS_ID: 'UA-120652287-1',
              SUGGESTIONS_EMAIL: 'LVP_Support@llnw.com',
              FREE_TRIAL_URL: 'https://www.limelight.com/free-trial/',
              GOOGLE_MAPS_API_KEY: 'AIzaSyCmzTDdwA7wMOxBm2N3KWe7qgBWyXWxSWo',
              IDLE_TIMEOUT_VALUE: 15 * 60 * 1000,
              IDLE_TIMEOUT_ALERT_DELTA: 3 * 60 * 1000,
              PLAYER_NONCE_EXPIRY_SECS: 24 * 60 * 60,
            };
          }),
        );
    }
    return this.configuration$;
  }
}
