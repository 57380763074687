import { Action } from '@ngrx/store';
import { PaginatedResponse } from '../../models/paginated-response';
import { LoadContentPayload } from '../../models/load-content-payload';
import { ContentActionPayload } from '../../models/content-action-payload';

import { BulkableAction } from './bulk';
import { Media } from '../../models/media';
import { Channel } from '../../models/channel';
import { Group } from '../../models/group';
import { ContentFilters } from '../../models/content-filters';
import { MediaTotals } from '../../models/facets';
import { Moment } from 'moment';

export const SET_CONTENT_TYPE = '[Content] Set Content Type';
export const LOAD_CONTENT_LIST = '[Content] Load Content List';
export const CONTENT_LIST_LOADED = '[Content] Content List Loaded';
export const CONTENT_LIST_FAILED_TO_LOAD = '[Content] Content List Failed to Load';
export const START_CHANGESET_POLLING = '[Content] Start Changeset Polling';
export const STOP_CHANGESET_POLLING = '[Content] Stop Changeset Polling';
export const CLEAR_CHANGESET_DATA = '[Content] Clear Changeset Data';
export const CHUNKED_CONTENT_DELETE = '[Content] Chunked Deletion';
export const CHUNKED_CONTENT_DELETE_SUCCEEDED = '[Content] Chunked Deletion Succeeded';
export const CHUNKED_CONTENT_DELETE_FAILED = '[Content] Chunked Deletion Failed';
export const GET_CHANGESET = '[Content] Get Changeset';
export const GET_CHANGESET_SUCCEEDED = '[Content] Get Changeset Succeeded';
export const GET_CHANGESET_FAILED = '[Content] Get Changeset Failed';
export const START_FACET_POLLING = '[Content] Start Facet Polling';
export const STOP_FACET_POLLING = '[Content] Stop Facet Polling';
export const UPDATE_FACETS = '[Content] Update Facets';
export const UPDATE_FACETS_SUCCEEDED = '[Content] Update Facets Succeeded';
export const UPDATE_FACETS_FAILED = '[Content] Update Facets Failed';
export const CREATE_MEDIA = '[Content] Create Media';
export const CREATE_MEDIA_FAILED = '[Content] Create Media Failed';
export const CREATE_MEDIA_SUCCEEDED = '[Content] Create Media Succeeded';
export const REPLACE_SOURCE = '[Content] Replace Source';
export const REPLACE_SOURCE_FAILED = '[Content] Replace Source Failed';
export const REPLACE_SOURCE_SUCCEEDED = '[Content] Replace Source Succeeded';
export const MARK_MEDIA_CREATED = '[Content] Mark Media Created';
export const PERFORM_ACTION = '[Content] Perform Content Action';
export const PERFORM_ACTION_SUCCEEDED = '[Content] Perform Action Succeeded';
export const PERFORM_ACTION_FAILED = '[Content] Perform Action Failed';
export const TOGGLE_SELECTION = '[Content] Toggle Selection';
export const SELECT_ALL = '[Content] Select All';

export class SetContentType implements Action {
  readonly type = SET_CONTENT_TYPE;

  constructor(public contentType: string) {}
}

export class LoadContentList extends BulkableAction {
  readonly type = LOAD_CONTENT_LIST;

  constructor(public payload: LoadContentPayload) { super(); }
}

export class ContentListLoaded implements Action {
  readonly type = CONTENT_LIST_LOADED;

  constructor(public payload: PaginatedResponse, public contentType: string,
              public preserveSelections: boolean = false) {}
}

export class ContentListFailedToLoad implements Action {
  readonly type = CONTENT_LIST_FAILED_TO_LOAD;

  constructor(public contentType: string) {}
}

export class StartChangesetPolling implements Action {
  readonly type = START_CHANGESET_POLLING;

  constructor(public contentType: string) {}
}

export class StopChangesetPolling implements Action {
  readonly type = STOP_CHANGESET_POLLING;

  constructor(public contentType: string) {}
}

export class ClearChangesetData implements Action {
  readonly type = CLEAR_CHANGESET_DATA;
}

export class GetChangeset implements Action {
  readonly type = GET_CHANGESET;

  constructor(public contentType: string) {}
}

export class GetChangesetSucceeded implements Action {
  readonly type = GET_CHANGESET_SUCCEEDED;

  constructor(
    public contentType: string,
    public changeset: Media[] | Channel[] | Group[],
    public after: Moment,
    public lastUpdated: Moment,
  ) {}
}

export class GetChangesetFailed implements Action {
  readonly type = GET_CHANGESET_FAILED;

  constructor(public contentType: string) {}
}

export class StartFacetPolling implements Action {
  readonly type = START_FACET_POLLING;
}

export class StopFacetPolling implements Action {
  readonly type = STOP_FACET_POLLING;
}

export class UpdateFacets implements Action {
  readonly type = UPDATE_FACETS;
}

export class UpdateFacetsSucceeded implements Action {
  readonly type = UPDATE_FACETS_SUCCEEDED;

  constructor(public totals: MediaTotals) {}
}

export class UpdateFacetsFailed implements Action {
  readonly type = UPDATE_FACETS_FAILED;
}

export class CreateMedia extends BulkableAction {
  readonly type = CREATE_MEDIA;

  constructor(public file: File) { super(); }
}

export class CreateMediaFailed implements Action {
  readonly type = CREATE_MEDIA_FAILED;

  constructor(public file: File) { }
}

export class CreateMediaSucceeded implements Action {
  readonly type = CREATE_MEDIA_SUCCEEDED;

  constructor(public media: any) { }
}

export class ReplaceSource extends BulkableAction {
  readonly type = REPLACE_SOURCE;

  constructor(
    public media: Media,
    public file: File,
  ) { super(); }
}

export class ReplaceSourceFailed implements Action {
  readonly type = REPLACE_SOURCE_FAILED;

  constructor(public file: File) { }
}

export class ReplaceSourceSucceeded implements Action {
  readonly type = REPLACE_SOURCE_SUCCEEDED;

  constructor(public media: any) { }
}

export class MarkMediaCreated implements Action {
  readonly type = MARK_MEDIA_CREATED;

  constructor(public ids: string[]) { }
}

export class PerformAction extends BulkableAction {
  readonly type = PERFORM_ACTION;

  constructor(public payload: ContentActionPayload) { super(); }
}

export class ChunkedContentDelete extends BulkableAction {
  readonly type = CHUNKED_CONTENT_DELETE;

  constructor(public payload: ContentActionPayload) {
    super();
  }
}

export class ChunkedContentDeleteSucceeded implements Action {
  readonly type = CHUNKED_CONTENT_DELETE_SUCCEEDED;

  constructor() {}
}

export class ChunkedContentDeleteFailed implements Action {
  readonly type = CHUNKED_CONTENT_DELETE_FAILED;

  constructor(
    public payload: ContentActionPayload,
    public error: any,
  ) {}
}

export class PerformActionSucceeded implements Action {
  readonly type = PERFORM_ACTION_SUCCEEDED;

  constructor(public content: any, public contentType: string) {}
}

export class PerformActionFailed implements Action {
  readonly type = PERFORM_ACTION_FAILED;

  constructor(
    public payload: ContentActionPayload,
    public error: any,
  ) {}
}

export class ToggleSelection implements Action {
  readonly type = TOGGLE_SELECTION;

  constructor(public payload: any) {}
}

export class SelectAll implements Action {
  readonly type = SELECT_ALL;
}

export type Actions = (
  SetContentType | LoadContentList | ContentListLoaded | ContentListFailedToLoad |
  StartChangesetPolling | StopChangesetPolling | ClearChangesetData |
  GetChangeset | GetChangesetSucceeded | GetChangesetFailed |
  StartFacetPolling | StopFacetPolling |
  UpdateFacets | UpdateFacetsSucceeded | UpdateFacetsFailed |
  CreateMedia | CreateMediaSucceeded | CreateMediaFailed | MarkMediaCreated |
  ReplaceSource | ReplaceSourceSucceeded | ReplaceSourceFailed |
  PerformAction | PerformActionSucceeded | PerformActionFailed |
  ToggleSelection | SelectAll | ChunkedContentDelete |
  ChunkedContentDeleteSucceeded | ChunkedContentDeleteFailed
);
