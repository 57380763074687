import { Pipe, PipeTransform } from '@angular/core';

/*
 * Capitalize
 * Takes an argument and capitalizes the first letter
 * Example:
 *  {{ 'jon' | llCapitalize }}
 *  formats to: Jon
*/

@Pipe({
  name: 'llCapitalize',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: any) {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }
}
