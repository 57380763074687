import * as _ from 'lodash';

import { Errors, ShimError } from '../models/errors';

export function parseShimErrors(shimError: ShimError): Errors {
  const fieldErrors: {[field: string]: string} = {};
  let generalErrors: string[] = [];

  if (!!shimError.errorDeveloperMessage) {
    if (shimError.errorDeveloperMessage.startsWith('{(')) {
      // SOAP errors
      const split: string[] = shimError.errorDeveloperMessage.substr(
        2,
        shimError.errorDeveloperMessage.length - 4,
      ).split(': ');

      fieldErrors[split[0].split('.')[1]] = _.capitalize(split[1]);
    } else {
      // VPWS errors
      try {
        generalErrors = JSON.parse(
          shimError.errorDeveloperMessage || '{"errors":[]}',
        ).errors;
      } catch (e) {
        generalErrors = [shimError.errorMessage];
      }
    }
  }

  return {
    fields: fieldErrors,
    general: generalErrors,
  };
}
