import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { AppComponent } from './app/app.component';

// Content
import { BaseContentComponent } from './content/base/base-content.component';
import { MediaComponent } from './content/media/media.component';
import { ChannelsComponent } from './content/channels/channels.component';
import { ChannelsDetailsComponent } from './content/channels/channels-details.component';
import { GroupsComponent } from './content/groups/groups.component';
import { GroupsDetailsComponent } from './content/groups/group-details.component';
import { MediaDetailsComponent } from './content/media/media-details.component';
// Themes
import { BaseThemesComponent } from './themes/base-themes.component';
import { ThemesComponent } from './themes/themes.component';
import { ThemeDetailsComponent } from './themes/theme-details.component';

// Reports
import { BaseReportsComponent } from './reports/base-reports.component';
import { OverviewReportsComponent } from './reports/overview/overview-reports.component';
import { GeographyReportsComponent } from './reports/geography/geography-reports.component';
import { ViewerDataReportComponent } from './reports/viewer-data-report/viewer-data-report.component';
import { ChannelReportComponent } from './reports/channel-report/channel-report.component';
import { MediaReportComponent } from './reports/media-report/media-report.component';
import { UsageReportComponent } from './reports/usage-report/usage-report.component';
import { EventHistoryReportComponent } from './reports/event-history/event-history-report.component';
import { EngagementReportComponent } from './reports/engagement-report/engagement-reports.component';

// Support
import { SupportComponent } from './support/support.component';

// Guards
import { AuthGuard } from './shared/auth/auth-guard';
import { PromptForDeactivateGuard } from './shared/services/prompt-for-deactivate-guard.service';
import { PwdSetGuard } from './shared/auth/pwd-set-guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: '/app/content/media', pathMatch: 'full' },
  {
    path: 'app',
    component: AppComponent,
    canActivate: [ AuthGuard, PwdSetGuard ],
    children: [
      { path: '', redirectTo: '/app/content/media', pathMatch: 'full' },
      {
        path: 'content',
        loadChildren: './content/content.module#ContentModule',
      },
      {
        path: 'themes',
        loadChildren: './themes/themes.module#ThemesModule',
      },
      {
        path: 'reports',
        loadChildren: './reports/reports.module#ReportsModule',
      },
      {
        path: 'settings',
        loadChildren: './settings/settings.module#SettingsModule',
      },
      {
        path: 'support',
        component: SupportComponent,
        runGuardsAndResolvers: 'always',
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [ AuthGuard, PwdSetGuard ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        onSameUrlNavigation: 'reload',
        preloadingStrategy: PreloadAllModules,
        useHash: true,
      },
    ),
  ],
  exports: [ RouterModule ],
})
export class RootRoutingModule {}
