import { EnvConfigurationService, Configuration } from './environment-configuration.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { map, take } from 'rxjs/operators';
import * as _ from 'lodash';

import { CallbackListener } from '../models/callback-listeners';
import * as fromRoot from '../redux/reducers';
import { Store, select } from '@ngrx/store';

@Injectable()
export class NotificationService {

  env: Configuration;

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,
  ) {
    this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);
  }

  /* Callback Listeners */
  addCallbackListener(listener: CallbackListener): Observable<any> {
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/notifications/listeners`,
      listener,
    );
  }

  deleteCallbackListener(id: string): Observable<any> {
    return this.http.delete(
      `${this.env.SHIM_BASE_URL}/notifications/listeners/${id}`,
    );
  }

  getCallbackListeners(): Observable<CallbackListener[]> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/notifications/listeners`,
    ).pipe(
      map((res) => res['listeners']),
    );
  }

  updateCallbackListener(listener: CallbackListener): Observable<any> {
    return this.http.put(
      `${this.env.SHIM_BASE_URL}/notifications/listeners/${listener.id}`,
      listener,
    );
  }
}
