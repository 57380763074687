import { trigger, state, style, transition, animate } from '@angular/animations';

// tslint:disable-next-line:variable-name
export const SlideInOutAnimation = trigger('slideInOut', [
  state('in', style({
    'margin-left': '45px',
  })),
  state('out', style({
    'margin-left': '240px',
  })),
  transition('in => out', animate('300ms ease-in-out')),
  transition('out => in', animate('300ms ease-in-out')),
]);
