import { Component, Input } from '@angular/core';

@Component({
  selector: 'll-inline-button',
  styleUrls: ['./inline-button.component.scss'],
  template: `
    <button
      [disabled]="disabled"
      [title]="title"
      class="ll-inline-button"
    >
      <i class="{{ faClass }}"></i>
    </button>
  `,
})

export class InlineButtonComponent {
  @Input() disabled: boolean;
  @Input() title: string;
  @Input() faClass: string;
}
