import * as _ from 'lodash';

import { CustomProperty } from '../models/custom-property';

export function convertIntegerMin(customProp: CustomProperty): number {
  const restriction: any = _.find(
    customProp.restrictions || [],
    (r) => r.restriction_type === 'GreaterThanOrEqual',
  );

  return _.get(restriction, 'restriction_values.0');
}

export function convertIntegerMax(customProp: CustomProperty): number {
  const restriction: any = _.find(
    customProp.restrictions || [],
    (r) => r.restriction_type === 'LessThanOrEqual',
  );

  return _.get(restriction, 'restriction_values.0');
}

export function addRestrictionsField(min: string | number, max: string | number) {
  return [
    {
      restriction_type: 'GreaterThanOrEqual',
      restriction_values: min ? [_.toString(min)] : [null],
    },
    {
      restriction_type: 'LessThanOrEqual',
      restriction_values: max ? [_.toString(max)] : [null],
    },
  ];
}
