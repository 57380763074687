import { PaginatedResponse } from './../../../models/paginated-response';
import { Subject } from 'rxjs/Subject';
import * as Highcharts from 'highcharts';
import { Observable } from 'rxjs';
import { OnInit, Input, SimpleChanges, Component, OnChanges } from '@angular/core';
import * as fromReports from '../../../redux/reducers/reports';
import * as fromRoot from '../../../redux/reducers';
import { Store, MemoizedSelector } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export interface PieSeriesData {
  name: string;
  y?: number;
}

@Component({
  selector: 'll-reports-pie-chart',
  templateUrl: './reports-pie-chart.component.html',
})
export class ReportsPieChartComponent implements OnChanges {
  reportState$: Observable<fromReports.State>;

  @Input() pieSeriesLabel: string;
  @Input() series: PieSeriesData[];
  @Input() loading: boolean;

  Highcharts = Highcharts;
  chartOptions = {
    chart: {
      type: 'pie',
    },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: this.translate.instant('amedia.reports.charts.pointFormat'),
    },
    plotOptions: {
      pie: {
        showInLegend: true,
      },
    },
    series: [{
      name: this.pieSeriesLabel,
      data: this.series,
    }],
    credits: {
      enabled: false,
    },
  };

  updateFlag: boolean = false;

  constructor(
     public translate: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    for (let prop in changes) {
      if (this.hasOwnProperty(prop)) {
        this[prop] = changes[prop].currentValue;
      }
    }
    this.refreshChart();
  }

  private refreshChart() {
    this.chartOptions.series = [{
        name: this.pieSeriesLabel,
        data: this.series,
      }];
    setTimeout(() => {
      this.updateFlag = true;
    });
  }
}
