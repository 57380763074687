import { EnvConfigurationService, Configuration } from './environment-configuration.service';
import { EventHistoryRequestParams } from './../models/event-history-request-params';

import { CsvExportResponse, CsvExportStatus } from './../models/csv-export-response';
import { ReportsTabs } from './../models/reports-tabs';
import { Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import { PaginatedResponse } from '../models/paginated-response';
import { ChannelsOrMediaOverviewResponse } from '../models/channels-aggregation';

import * as fromAuth from '../../shared/redux/reducers/auth';
import * as fromRoot from '../../shared/redux/reducers';
import { map, mergeMap, switchMap, takeWhile, first, take } from 'rxjs/operators';
import { AnalyticsFilters } from '../analytics-filters';
import * as _ from 'lodash';
import { DateRange } from 'moment-range';
import { Moment } from 'moment';
import { GeoReportsGranularity } from '../models/geo-reports-granularity';

@Injectable()
export class AnalyticsService {
  auth$: Observable<fromAuth.State>;
  env: Configuration;

  private headers = new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'});

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,

  ) {
    this.auth$ = store.pipe(select(fromRoot.getAuthState));
    this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);

  }

  public getMediaPerformance(f: AnalyticsFilters): Observable<PaginatedResponse> {
    let params = this.buildPerformanceRequestParams(f);
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/media`,
      { params },
    ).pipe(
      map((res) => {
        return {
          hasNext: res['has_next'],
          page: res['page_id'],
          results: res['media'],
          size: res['media'].length,
          sort: f.sortBy,
          sort_dir: f.sortDir,
        };
      }),
    );
  }

  public getMediaIntervals(f: AnalyticsFilters, ids: string[], mediaType: string) {
    let params = this.buildPerformanceRequestParams(f);
    params = params.append('media_ids', ids.join(','));
    params = params.append('media_type', mediaType);
    return this.http.get<any>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/media/interval`,
      { params },
    );
  }

  public getChannelIntervals(f: AnalyticsFilters, ids: string[]) {
    let params = this.buildPerformanceRequestParams(f);
    params = params.append('channel_ids', ids.join(','));
    return this.http.get<any>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/channels/interval`,
      { params },
    );
  }

  public getDomainIntervals(f: AnalyticsFilters, domains: string[]) {
    let params = this.buildPerformanceRequestParams(f);
    params = params.append('referrer_urls', domains.join(','));
    return this.http.get<any>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/domain/interval`,
      { params },
    );
  }

  public getMediaOverview(dateRange: DateRange): Observable<ChannelsOrMediaOverviewResponse> {
    return this.getMediaOrChannelAggregate('media', dateRange);
  }

  public getChannelOverview(dateRange: DateRange): Observable<ChannelsOrMediaOverviewResponse> {
    return this.getMediaOrChannelAggregate('channels', dateRange);
  }

  public getChannelPerformance(f: AnalyticsFilters): Observable<PaginatedResponse> {
    let params = this.buildPerformanceRequestParams(f);
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/channels`,
      { params },
    ).pipe(
      map((res) => {
        return {
          hasNext: res['has_next'],
          page: res['page_id'],
          results: res['channels'],
          size: res['channels'].length,
          sort: f.sortBy,
          sort_dir: f.sortDir,
        };
      }),
    );
  }

  public getDomainPerformance(f: AnalyticsFilters): Observable<PaginatedResponse> {
    let params = this.buildPerformanceRequestParams(f);
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/domain`,
      { params },
    ).pipe(
      map((res) => {
        return {
          hasNext: res['has_next'],
          page: res['page_id'],
          results: res['domain_list'],
          size: res['domain_list'].length,
          sort: f.sortBy,
          sort_dir: f.sortDir,
        };
      }),
    );
  }

  public getMediaEngagement(f: AnalyticsFilters): Observable<PaginatedResponse> {
    let params = this.buildPerformanceRequestParams(f);
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/analytics/engagement/media`,
      { params },
    ).pipe(
      map((res) => {
        return {
          hasNext: res['has_next'],
          page: res['page_id'],
          results: res['records'],
          size: res['records'].length,
          sort: f.sortBy,
          sort_dir: f.sortDir,
        };
      }),
    );
  }

  public getGeoPerformance(granularity: GeoReportsGranularity, f: AnalyticsFilters): Observable<PaginatedResponse> {
    let params = this.buildPerformanceRequestParams(f);
    let pathEnding = granularity.toLowerCase();
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/${pathEnding}`,
      { params },
    ).pipe(
      map((res) => {
        switch (granularity) {
          case GeoReportsGranularity.COUNTRY:
            return {
              hasNext: res['has_next'],
              page: res['page_id'],
              results: res['countries'],
              size: res['countries'].length,
              sort: f.sortBy,
              sort_dir: f.sortDir,
            };
          case GeoReportsGranularity.REGION:
            return {
              hasNext: res['has_next'],
              page: res['page_id'],
              results: res['regions'],
              size: res['regions'].length,
              sort: f.sortBy,
              sort_dir: f.sortDir,
            };
          case GeoReportsGranularity.CITY:
            return {
              hasNext: res['has_next'],
              page: res['page_id'],
              results: res['cities'],
              size: res['cities'].length,
              sort: f.sortBy,
              sort_dir: f.sortDir,
            };
        }
      }),
    );
  }

  public getDataUsage(dateRange: DateRange, timeInterval: string): Observable<any[]> {
    let beginDate = dateRange.start.date();
    let beginMonth = dateRange.start.month();
    let beginYear = dateRange.start.year();
    // bump the end date by 1 because the user expects inclusive data
    // the service treats dates as midnight of that day
    let end = dateRange.end.clone().add(1, 'day');
    let endDate = end.date();
    let endMonth = end.month();
    let endYear = end.year();
    let params = new HttpParams();

    params = params.append('begin_date', String(beginDate));
    params = params.append('begin_month', String(beginMonth));
    params = params.append('begin_year', String(beginYear));
    params = params.append('end_date', String(endDate));
    params = params.append('end_month', String(endMonth));
    params = params.append('end_year', String(endYear));
    params = params.append('interval', timeInterval);
    return this.http.get<any[]>(
      `${this.env.SHIM_BASE_URL}/analytics/datausage`,
      { params },
    );
  }

  // this method returns a PaginatedResponse for convenience, but there should always be only one page
  // these endpoints should return up to 500 results if no pagination params are supplied
  // if we have more than 500 platforms, we're all billionaires
  public getBrowserPerformance(dateRange: DateRange): Observable<PaginatedResponse> {
    let params = new HttpParams();
    let formattedDates = this.formatStartAndEndDateStrings(dateRange.start, dateRange.end);
    params = params.append('start', formattedDates['start']);
    params = params.append('end', formattedDates['end']);
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/browser`,
      { params },
    ).pipe(
      map((res) => {
        return {
          hasNext: res['has_next'],
          page: res['page_id'],
          results: res['browsers'],
          size: res['browsers'].length,
        };
      }),
    );
  }

  public getBrowserMediaPerformance(f: AnalyticsFilters): Observable<PaginatedResponse> {
    let params = this.buildPerformanceRequestParams(f);
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/browser/media`,
      { params },
    ).pipe(
      map((res) => {
        return {
          hasNext: res['has_next'],
          page: res['page_id'],
          results: res['media'],
          size: res['media'].length,
          sort: f.sortBy,
          sort_dir: f.sortDir,
        };
      }),
    );
  }

  public getPlatformMediaPerformance(f: AnalyticsFilters): Observable<PaginatedResponse> {
    let params = this.buildPerformanceRequestParams(f);
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/platform/media`,
      { params },
    ).pipe(
      map((res) => {
        return {
          hasNext: res['has_next'],
          page: res['page_id'],
          results: res['media'],
          size: res['media'].length,
          sort: f.sortBy,
          sort_dir: f.sortDir,
        };
      }),
    );
  }

  // this method returns a PaginatedResponse for convenience, but there should always be only one page
  // these endpoints should return up to 500 results if no pagination params are supplied
  // if we have more than 500 platforms, we're all billionaires
  public getPlatformPerformance(dateRange: DateRange): Observable<PaginatedResponse> {
    let params = new HttpParams();
    let formattedDates = this.formatStartAndEndDateStrings(dateRange.start, dateRange.end);
    params = params.append('start', formattedDates['start']);
    params = params.append('end', formattedDates['end']);
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/platform`,
      { params },
    ).pipe(
      map((res) => {
        return {
          hasNext: res['has_next'],
          page: res['page_id'],
          results: res['platforms'],
          size: res['platforms'].length,
        };
      }),
    );
  }

  public getOperatingSystemMediaPerformance(f: AnalyticsFilters): Observable<PaginatedResponse> {
    let params = this.buildPerformanceRequestParams(f);
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/os/media`,
      { params },
    ).pipe(
      map((res) => {
        return {
          hasNext: res['has_next'],
          page: res['page_id'],
          results: res['media'],
          size: res['media'].length,
          sort: f.sortBy,
          sort_dir: f.sortDir,
        };
      }),
    );
  }

  // this method returns a PaginatedResponse for convenience, but there should always be only one page
  public getOperatingSystemPerformance(dateRange: DateRange): Observable<PaginatedResponse> {
    let params = new HttpParams();
    let formattedDates = this.formatStartAndEndDateStrings(dateRange.start, dateRange.end);
    params = params.append('start', formattedDates['start']);
    params = params.append('end', formattedDates['end']);
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/analytics/performance/os`,
      { params },
    ).pipe(
      map((res) => {
        return {
          hasNext: res['has_next'],
          page: res['page_id'],
          results: res['operating_systems'],
          size: res['operating_systems'].length,
        };
      }),
    );
  }

  public exportEventHistoryCsv(filters: EventHistoryRequestParams): Observable<string> {
    return this.exportCsvAndPoll(
      `${this.env.SHIM_BASE_URL}/notifications/events/export/csv`,
      filters).pipe(
        map((data: CsvExportResponse) => data.signed_url),
    );
  }

  public exportChannelPerformanceCsv(filters: AnalyticsFilters): Observable<string> {
    return this.exportCsvAndPoll(
      `${this.env.SHIM_BASE_URL}/analytics/performance/channels/export/csv`,
      filters).pipe(
      map((data: CsvExportResponse) => data.signed_url),
    );
  }

  public exportDomainPerformanceCsv(filters: AnalyticsFilters): Observable<string> {
    return this.exportCsvAndPoll(
      `${this.env.SHIM_BASE_URL}/analytics/performance/domain/export/csv`,
      filters).pipe(
      map((data: CsvExportResponse) => data.signed_url),
    );
  }

  public exportBrowserMediaPerformanceCsv(filters: AnalyticsFilters): Observable<string> {
    return this.exportCsvAndPoll(
      `${this.env.SHIM_BASE_URL}/analytics/performance/browser/media/export/csv`,
      filters).pipe(
      map((data: CsvExportResponse) => data.signed_url),
    );
  }

  public exportOperatingSystemMediaPerformanceCsv(filters: AnalyticsFilters): Observable<string> {
    return this.exportCsvAndPoll(
      `${this.env.SHIM_BASE_URL}/analytics/performance/os/media/export/csv`,
      filters).pipe(
      map((data: CsvExportResponse) => data.signed_url),
    );
  }

  public exportPlatformMediaPerformanceCsv(filters: AnalyticsFilters): Observable<string> {
    return this.exportCsvAndPoll(
      `${this.env.SHIM_BASE_URL}/analytics/performance/platform/media/export/csv`,
      filters).pipe(
      map((data: CsvExportResponse) => data.signed_url),
    );
  }

  public exportMediaPerformanceCsv(filters: AnalyticsFilters): Observable<string> {
    return this.exportCsvAndPoll(
      `${this.env.SHIM_BASE_URL}/analytics/performance/media/export/csv`,
      filters).pipe(
      map((data: CsvExportResponse) => data.signed_url),
    );
  }

  public getEventHistory(f: EventHistoryRequestParams): Observable<PaginatedResponse> {
    let params = this.buildEventHistoryRequestParams(f);
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/notifications/events`,
      { params },
    ).pipe(
      map((res) => {
        return {
          hasNext: res['has_next'],
          page: res['page_id'],
          results: res['eventList'],
          size: res['eventList'].length,
        };
      }),
    );
  }

  public getEventHistoryEventTypes(): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.env.SHIM_BASE_URL}/notifications/eventTypes`,
    );
  }

  public getPublisherTimezone(): Observable<string> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/analytics/publisher/timezone`,
      {responseType: 'text'},
    );
  }

  private exportCsvAndPoll(url: string,
                           filters: (AnalyticsFilters | EventHistoryRequestParams)): Observable<CsvExportResponse> {
    let params;
    if ((filters as EventHistoryRequestParams).eventTypes !== undefined) {
      params = this.buildEventHistoryRequestParams(filters as EventHistoryRequestParams);
    } else {
      params = this.buildPerformanceRequestParams(filters);
    }
    params = params.delete('page_size');
    params = params.delete('page_id');
    return this.http.get<CsvExportResponse>(
      url,
      { params },
    ).pipe(
      mergeMap((initialResponse: CsvExportResponse) => {
        return interval(1000).pipe(
          switchMap(() => this.getExportStatus(initialResponse.token)),
          first((data: CsvExportResponse) => data.status !== CsvExportStatus.INCOMPLETE),
        );
      }),
    );
  }

  // the services require formatted date strings for proper timezone lookup
  // epoch date format skips the timezone lookup in the service
  // the service treats 11/28/2018 as midnight of the 28th, so must add a day to get inclusive data
  private formatStartAndEndDateStrings(start: Moment, end: Moment): object {
    let startf;
    let endf;
    startf = start.format('YYYY-MM-DD');
    // always make the ending date tomorrow. The service treats the date as midnight.
    endf = end.clone().add(1, 'day').format('YYYY-MM-DD');
    return { start: startf, end: endf };
  }

  private getExportStatus(token: string): Observable<CsvExportResponse> {
    return this.http.get<CsvExportResponse>(
      `${this.env.SHIM_BASE_URL}/export/${token}/status`,
    );
  }

  private buildEventHistoryRequestParams(p: EventHistoryRequestParams): HttpParams {
    let params = new HttpParams();
    if (!_.isNil(p.begin)) {
      params = params.append('begin', String(p.begin.unix()));
    }
    if (!_.isNil(p.until)) {
      params = params.append('until', String(p.until.unix()));
    }
    if (!_.isNil(p.eventTypes)) {
      params = params.append('event_types', p.eventTypes.join(','));
    }
    if (!_.isNil(p.sortBy)) {
      params = params.append('sort_by', p.sortBy);
    }
    if (!_.isNil(p.sortDir)) {
      params = params.append('sort_order', p.sortDir);
    }
    if (!_.isNil(p.pageId)) {
      params = params.append('page_id', String(p.pageId));
    }
    if (!_.isNil(p.pageSize)) {
      params = params.append('page_size', String(p.pageSize));
    }
    if (!_.isNil(p.id) && !_.isNil(p.type)) {
      params = params.append('id', p.id);
      params = params.append('type', p.type);
    }
    return params;
  }

  private buildPerformanceRequestParams(f: AnalyticsFilters): HttpParams {
    let params = new HttpParams();
    if (!_.isUndefined(f.sortBy)) {
      params = params.append('sort_by', f.sortBy);
    }
    if (!_.isUndefined(f.sortDir)) {
      params = params.append('sort_order', f.sortDir.toLowerCase());
    }
    if (!_.isUndefined(f.pageSize)) {
      params = params.append('page_size', String(f.pageSize));
    }
    if (!_.isUndefined(f.page)) {
      params = params.append('page_id', String(f.page));
    }
    if (!_.isUndefined(f.startDate) && !_.isUndefined(f.endDate)) {
      let formattedDates = this.formatStartAndEndDateStrings(f.startDate, f.endDate);
      params = params.append('start', formattedDates['start']);
      params = params.append('end', formattedDates['end']);
    }
    if (!_.isUndefined(f.queryParams)) {
      f.queryParams.keys().forEach((key) => {
        params = params.append(key, f.queryParams.get(key));
      });
    }
    if (!_.isUndefined(f.interval)) {
      params = params.append('interval', f.interval);
    }
    if (!_.isUndefined(f.geoCountryId)) {
      params = params.append('country_id', f.geoCountryId);
    }
    if (!_.isUndefined(f.geoRegionId)) {
      params = params.append('region_id', f.geoRegionId);
    }
    if (!_.isUndefined(f.search) && f.search !== '') {
      // note that the API's will treat a lowercase "or" as a fuzzy search
      let s = f.search.toLowerCase();
      params = params.append('or', `title:${s};tag:${s}`);
    }

    return params;
  }

  private getMediaOrChannelAggregate(subPath: string, dateRange: DateRange)
  : Observable<ChannelsOrMediaOverviewResponse> {
    let params = new HttpParams();
    let formattedDates = this.formatStartAndEndDateStrings(dateRange.start, dateRange.end);
    params = params.append('start', formattedDates['start']);
    params = params.append('end', formattedDates['end']);
    return this.http.get<ChannelsOrMediaOverviewResponse>(
      `${this.env.SHIM_BASE_URL}/analytics/${subPath}/aggregations`,
      { params },
    ).pipe(
      map((res) => {
        let obj = res['views'][0];
        let emptyResponse = (obj === undefined);
        return {
          plays: emptyResponse ? 0 : obj['plays'],
          replays: emptyResponse ? 0 : obj['replays'],
          totalTimePlayed: emptyResponse ? 0 : obj['total_time_viewed_in_milliseconds'],
        };
      }),
    );
  }
}
