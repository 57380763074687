import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { first } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as SHA1 from 'crypto-js/sha1';
import * as Base64 from 'crypto-js/enc-base64';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

import * as fromRoot from '../../shared/redux/reducers';
import * as fromAuth from '../../shared/redux/reducers/auth';
import * as Auth from '../../shared/redux/actions/auth';
import { BaseAuthComponent } from '../base-auth.component';

export type ShowFieldErrorFnType = (control: FormControl) => boolean;

@Component({
  styleUrls: ['./answer-question.component.scss'],
  templateUrl: './answer-question.component.html',
})
export class AnswerQuestionComponent implements OnInit {
  form: FormGroup;
  auth$: Observable<fromAuth.State>;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  constructor(
    public base: BaseAuthComponent,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.auth$ = store.pipe(select(fromRoot.getAuthState));
    this.base.authTitle = 'amedia.forgotPwd.title';
    this.form = fb.group({
      answer: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.toastr.overlayContainer = this.toastContainer;
  }

  showErrors(): ShowFieldErrorFnType {
    return (email) => email.dirty && email.invalid;
  }

  onSubmit() {
    this.form.get('answer').markAsDirty();

    if (this.form.valid) {
      this.store.dispatch(new Auth.AnswerSecurityQuestion(
        Base64.stringify(SHA1(this.form.value.answer)),
      ));
    }

    this.auth$.pipe(first((p) => !p.pwdResetLoading)).subscribe((authState: fromAuth.State) => {
      if (
        !!authState.pwdResetQuestion
        && !!authState.wrongEmailOrAnswer
      ) {
        this.base.onShake();
      }
    });
  }
}
