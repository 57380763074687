import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';

import { WindowRef } from '../utils/window-ref';
import { easeInOut$ } from '../../shared/utils/ease';

const USER_SCROLL_EVENTS = ['wheel', 'mousewheel', 'touchmove', 'DOMMouseScroll'];

@Injectable()
export class ScrollToService {

  constructor( private windowRef: WindowRef ) { }

  scrollTo(location: number = 0): void {
    // Home-grown interpolation
    const sub: Subscription = easeInOut$(
      this.windowRef.nativeWindow.pageYOffset, // from
      location, // to
    ).subscribe((pos) => this.windowRef.nativeWindow.scrollTo(0, pos));

    // Hold reference to callback so we can remove it when we're done
    const cancelSub = (e) => sub.unsubscribe();

    // If user scrolls, cancel animation
    _.forEach(USER_SCROLL_EVENTS, (event) => {
      this.windowRef.nativeWindow.addEventListener(event, cancelSub);
    });

    // Add tear down callback to remove scroll listener
    sub.add(() => {
      _.forEach(USER_SCROLL_EVENTS, (event) => {
        this.windowRef.nativeWindow.removeEventListener(event, cancelSub);
      });
    });
  }
}
