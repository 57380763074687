import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

export class PropPathWrapper {
  constructor(
    public path: string,
    public listItem: ListItemComponent,
  ) { }

  get value() {
    return _.get(this.listItem.value, this.path);
  }

  set value(v: any) {
    const copy = { ...this.listItem.value };
    _.updateWith(copy, this.path, _.constant(v));
    this.listItem.value = copy;
  }
}

/**
 * Component to be sub-classed that manages the value of an entry in a ll-list-input
 *
 * Example sub class:
 *
 * @Component({
 *   selector: 'll-text-list-item',
 *   template: `
 *     <div>
 *       <input
 *         class="form-control"
 *         type="text"
 *         [(ngModel)]="value" />
 *       <a
 *         title="Clone"
 *         (click)="clone.emit()"
 *       ><i class="far fa-copy"></i></a>
 *       <a
 *         title="Delete"
 *         (click)="delete.emit()"
 *       ><i class="fas fa-trash-alt"></i></a>
 *     </div>
 *   `
 *   providers: [{
 *     provide: ListItemComponent,
 *     // tslint:disable-next-line:no-forward-ref
 *     useExisting: forwardRef(() => TextListItemComponent),
 *   }],
 * })
 * export class TextListItemComponent extends ListItemComponent { }
 */
@Component({
  template: `<div>list item</div>`,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line:no-forward-ref
    useExisting: forwardRef(() => ListItemComponent),
    multi: true,
  }],
})
export class ListItemComponent {
  _value: any;

  @Input() get ngModel() { return this.value; }
  set ngModel(val: any) {
    this.value = val;
  }

  get value(): any { return this._value; }
  set value(v: any) {
    if (!_.isEqual(this._value, v)) {
      this._value = v;

      const doChange = () => {
        this.ngModelChange.emit(this.value);
      };

      // If no one is listening, make asynchronous
      if (this.ngModelChange.observers.length === 0) {
        setTimeout(doChange);
      } else {
        doChange();
      }
    }
  }

  @Output() ngModelChange = new EventEmitter<any>();
  @Output() clone = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  wrapProp(path: string): PropPathWrapper {
    return new PropPathWrapper(path, this);
  }
}
