import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { first, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as SHA1 from 'crypto-js/sha1';
import * as Base64 from 'crypto-js/enc-base64';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

import * as fromRoot from '../../shared/redux/reducers';
import * as fromAuth from '../../shared/redux/reducers/auth';
import * as Auth from '../../shared/redux/actions/auth';
import { BaseAuthComponent } from '../base-auth.component';

@Component({
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  auth$: Observable<fromAuth.State>;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  imposterMode: boolean;
  freeTrialURL: string;

  constructor(
    public base: BaseAuthComponent,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
    this.auth$ = store.pipe(select(fromRoot.getAuthState));
    this.base.authTitle = 'amedia.login.title';
    this.form = fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      imposterUsername: ['', Validators.required],
    });
    this.freeTrialURL = `https://www.limelight.com/free-trial/`;
    this.imposterMode = false;
  }

  ngOnInit(): void {
    this.toastr.overlayContainer = this.toastContainer;
  }

  onSubmit() {
    const username = this.form.value.username;
    const password = this.form.value.password;
    const imposter = this.form.value.imposterUsername;

    this.store.dispatch(new Auth.Login(
      username,
      Base64.stringify(SHA1(password)),
      imposter,
      '/app/content/media',
    ));

    this.auth$.pipe(first((au) => !au.userLoading)).subscribe((authState: fromAuth.State) => {
      if (authState.loginAttempted && !authState.user) {
        this.base.onShake();
      }
    });
  }

  openTabForFreeTrialSignUp() {
    window.open(this.freeTrialURL, '_blank');
  }

  @HostListener('window:keyup', ['$event'])
  onKeyup(event: KeyboardEvent) {
    if (event.ctrlKey && event.altKey && event.keyCode === 76) {
      this.imposterMode = !this.imposterMode;
      if (!this.imposterMode) {
        this.form.patchValue({ imposterUsername: '' });
      }
    }
  }
}
