import { Component } from '@angular/core';
import { BaseAuthComponent } from '../base-auth.component';

@Component({
  templateUrl: './password-sent.component.html',
})
export class PasswordSentComponent {
  constructor(public base: BaseAuthComponent) {
    this.base.authTitle = 'amedia.forgotPwd.title';
  }
}
