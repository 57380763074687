import { SortDirection, SortParams } from 'control-ui-common';
import * as _ from 'lodash';

import * as users from '../../actions/settings/users';
import { User } from '../../../models/user';
import { Organization } from '../../../models/organization';
import { Errors } from '../../../models/errors';

export interface State {
  users: User[];
  usersLoading: boolean;
  filteredUsers: User[];
  sortParams: SortParams;
  queryText: string;
  currUser: User;
  currUserSaving: boolean;
  linkUserCancelled: boolean;
  savingUserErrors: Errors;
  removingUser: boolean;
}

export const initialState: State = {
  users: null,
  usersLoading: true,
  filteredUsers: null,
  sortParams: {
    sortBy: 'firstName',
    sortDir: SortDirection.ASC,
  },
  queryText: '',
  currUser: null,
  currUserSaving: false,
  linkUserCancelled: false,
  savingUserErrors: null,
  removingUser: false,
};

const filterUsers = (userArr: User[], q: string): User[] => {
  return (
    q.length > 0 ?
    _.filter(userArr, (u) => {
      const lowerCaseString = q.toLocaleLowerCase();
      return (
        u.email.toLocaleLowerCase().indexOf(lowerCaseString) >= 0 ||
        `${u.firstName} ${u.lastName}`.toLocaleLowerCase().indexOf(lowerCaseString) >= 0
      );
    }) :
    [ ...userArr ]
  );
};

const sortUsers = (userArr: User[], p: SortParams): User[] => {
  return _.orderBy(
    userArr,
    [(u) => _.lowerCase(u[p.sortBy])],
    [p.sortDir],
  );
};

export function reducer(
  state = initialState,
  action: users.Actions,
): State {
  let newUsers: User[];

  switch (action.type) {
    case users.LOAD_USERS:
      return {
        ...state,
        queryText: '',
        usersLoading: true,
        users: null,
        filteredUsers: null,
      };
    case users.LOAD_USERS_SUCCEEDED:
      return {
        ...state,
        usersLoading: false,
        users: action.users,
        filteredUsers: sortUsers(action.users, state.sortParams),
      };
    case users.LOAD_USERS_FAILED:
      return {
        ...state,
        usersLoading: false,
        users: null,
      };
    case users.FILTER_USERS:
      return {
        ...state,
        queryText: action.queryText,
        filteredUsers: sortUsers(
          filterUsers(state.users, action.queryText),
          state.sortParams,
        ),
      };
    case users.SORT_USERS:
      return {
        ...state,
        sortParams: action.sortParams,
        filteredUsers: sortUsers(state.filteredUsers, action.sortParams),
      };
    case users.SELECT_CURR_USER:
      return {
        ...state,
        currUser: action.user,
      };
    case users.SAVE_USER:
      return {
        ...state,
        currUserSaving: true,
        savingUserErrors: null,
      };
    case users.SAVE_USER_SUCCEEDED:
      newUsers = [ ...state.users ];
      const index = newUsers.findIndex((u) => u.id === action.user.id);

      if (index >= 0) {
        newUsers.splice(index, 1, _.clone(action.user));
      } else {
        newUsers.push(action.user);
      }

      return {
        ...state,
        currUserSaving: false,
        linkUserCancelled: false,
        currUser: null,
        users: newUsers,
        filteredUsers: sortUsers(
          filterUsers(newUsers, state.queryText),
          state.sortParams,
        ),
      };
    case users.SAVE_USER_FAILED:
      return {
        ...state,
        currUserSaving: false,
        savingUserErrors: action.errors,
      };
    case users.LINK_USER_CANCELLED:
      return {
        ...state,
        currUserSaving: false,
        linkUserCancelled: true,
      };
    case users.DELETE_USER:
    case users.UNLINK_USER:
      return {
        ...state,
        removingUser: true,
      };
    case users.DELETE_USER_SUCCEEDED:
    case users.UNLINK_USER_SUCCEEDED:
      return {
        ...state,
        removingUser: false,
        users: _.filter(state.users, (u) => u.id !== action.user.id),
        filteredUsers: _.filter(state.filteredUsers, (u) => u.id !== action.user.id),
      };
    case users.DELETE_USER_FAILED:
    case users.UNLINK_USER_FAILED:
      return {
        ...state,
        removingUser: false,
      };
    default:
      return state;
  }
}

export const getUsers = (state: State) => state;
