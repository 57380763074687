import {  FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { LOWERCASE_REGEX, UPPERCASE_REGEX } from '../models/constants';

export function minLengthPwdValidator(c: FormControl): ValidationErrors {
  if (!!c.value && c.value.length < 8) {
    return { minLength: { valid: false } };
  }
  return null;
}

export function restrictivePwdValidator(c: FormControl): ValidationErrors {
  // È
  if (!!c.value) {
    if (c.value.length < 8) {
      return { minLength: { valid: false } };
    } else if (!UPPERCASE_REGEX.test(c.value)) {
      return { oneUpper: { valid: false } };
    } else if (!LOWERCASE_REGEX.test(c.value)) {
      return { oneLower: { valid: false } };
    } else if (!/[0-9]+/.test(c.value)) {
      return { oneNumber: { valid: false } };
    } else if (!/[&!#@$%^*]+/.test(c.value)) {
      return { oneSymbol: { valid: false } };
    }
  }
  return null;
}
