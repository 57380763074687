import { EnvConfigurationService, Configuration } from './environment-configuration.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, take } from 'rxjs/operators';
import * as _ from 'lodash';

import * as fromRoot from '../redux/reducers';
import { User } from '../../shared/models/user';
import { Organization, OrgNode } from '../../shared/models/organization';
import { Country } from '../models/country';
import { FfmpegVersionInfo } from '../models/ffmpeg-version-info';
import { Store, select } from '@ngrx/store';

@Injectable()
export class AccountMgmtService {

  env: Configuration;

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,
  ) {
    this.envConfigService.load().subscribe((res) => {
      this.env = res;
    });
  }

  login(email: string, pwdHash: string, imposterEmail: string): Observable<User> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/ams/login` +
        `?password_hash=${encodeURIComponent(pwdHash)}` +
        `&email=${encodeURIComponent(email)}` +
        (imposterEmail ? `&imposter_email=${encodeURIComponent(imposterEmail)}` : ''),
    ).pipe(
      map((res: HttpResponse<any>) => _.reduce(
          res,
          (result, v, k) => {
            result[_.camelCase(k)] = v;
            return result;
          },
          {},
        ) as User,
      ),
    );
  }

  getSelf(): Observable<User> {
    return this.http.get<User>(
      `${this.env.SHIM_BASE_URL}/ams/users/self/details`,
    );
  }

  saveSelf(user: User): Observable<User> {
    return this.http.post<User>(
      `${this.env.SHIM_BASE_URL}/ams/users/self`,
      user,
    );
  }

  logout(): Observable<any> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/ams/logout`,
    );
  }

  getOrg(): Observable<Organization> {
    return this.http.get<Organization>(
      `${this.env.SHIM_BASE_URL}/ams/organizations/details`,
    );
  }

  saveOrg(org: Organization): Observable<Organization> {
    return this.http.post<Organization>(
      `${this.env.SHIM_BASE_URL}/ams/organizations`,
      org,
    );
  }

  getOrgs(userId: string): Observable<OrgNode[]> {
    return this.http.get<OrgNode[]>(
      `${this.env.SHIM_BASE_URL}/ams/users/${userId}/organizations`,
    );
  }

  switchOrg(orgId: string): Observable<any> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/ams/users/organizations/${orgId}`,
    );
  }

  getSecurityQuestion(email: string): Observable<string> {
    return this.http.get<string>(
      `${this.env.SHIM_BASE_URL}/ams/users/question?email=${encodeURIComponent(email)}`,
    ).pipe(
      map((res) => res['securityQuestion']),
    );
  }

  answerSecurityQuestion(email: string, answerHash: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.env.SHIM_BASE_URL}/ams/users/question/response` +
        `?email=${encodeURIComponent(email)}` +
        `&answer_hash=${encodeURIComponent(answerHash)}`,
    );
  }

  resendNewUserEmail(userId: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.env.SHIM_BASE_URL}/ams/users/${userId}/email`,
    );
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(
      `${this.env.SHIM_BASE_URL}/ams/organizations/users`,
    );
  }

  getExternalUsers(orgId: string): Observable<User[]> {
    return this.http.get<User[]>(
      `${this.env.SHIM_BASE_URL}/ams/users/external/${orgId}`,
    );
  }

  getUser(email: string): Observable<User> {
    return this.http.get<User>(
      `${this.env.SHIM_BASE_URL}/ams/users/email?email=${encodeURIComponent(email)}`,
    );
  }

  createUser(user: User): Observable<User> {
    return this.http.post<User>(
      `${this.env.SHIM_BASE_URL}/ams/users`,
      user,
    );
  }

  saveUser(user: User): Observable<User> {
    return this.http.put<User>(
      `${this.env.SHIM_BASE_URL}/ams/users`,
      user,
    );
  }

  linkUser(userId: string, orgId: string, role: string): Observable<any> {
    return this.http.post<User>(
      `${this.env.SHIM_BASE_URL}/ams/users/link`,
      { userId, orgId, role },
    );
  }

  deleteUser(id: string): Observable<any> {
    return this.http.delete(
      `${this.env.SHIM_BASE_URL}/ams/users/${id}`,
    );
  }

  getKeys(): Observable<any> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/ams/organizations/keys`,
    );
  }

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(
      `${this.env.SHIM_BASE_URL}/ams/countries`,
    );
  }

  getFfmpegVersions(): Observable<FfmpegVersionInfo[]> {
    return this.http.get<FfmpegVersionInfo[]>(
      `${this.env.SHIM_BASE_URL}/ams/organizations/ffmpegVersions`,
    );
  }

  rotateApiKeys(): Observable<any> {
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/ams/organizations/keys/rotate`,
      undefined,
    );
  }
}
