import { FfmpegVersionInfo } from './../../models/ffmpeg-version-info';
import { Action } from '@ngrx/store';
import { User } from '../../models/user';
import { Organization, OrgNode } from '../../models/organization';
import { OrgKeys } from '../../models/org-keys';
import { Errors, ShimError } from '../../models/errors';
import { FeatureSetAuthorization } from '../../models/feature-sets';

export const GRAB_KEYS = '[Auth] Grab Keys';
export const GRAB_KEYS_FAILED = '[Auth] Grab Keys Failed';
export const GRAB_KEYS_SUCCEEDED = '[Auth] Grab Keys Succeeded';
export const LOGIN = '[Auth] Login';
export const GET_USER = '[Auth] Get User';
export const USER_LOADED = '[Auth] User Loaded';
export const NOT_LOGGED_IN = '[Auth] Not Logged In';
export const LOGOUT = '[Auth] Logout';
export const FIRST_LOAD = '[Auth] First Load';
export const CLEAR_NEXT_URL = '[Auth] Clear Next URL';
export const ANON_REQUIRED = '[Auth] Anonymous Required';
export const SAVE_USER = '[Auth] Save User';
export const SAVE_USER_SUCCEEDED = '[Auth] Save User Succeeded';
export const SAVE_USER_FAILED = '[Auth] Save User Failed';
export const START_PASSWORD_RESET = '[Auth] Start Password Reset';
export const USER_NOT_FOUND = '[Auth] User Not Found';
export const SECURITY_QUESTION_REQUIRED = '[Auth] Security Question Required';
export const ANSWER_SECURITY_QUESTION = '[Auth] Answer Security Question';
export const SECURITY_QUESTION_ANSWERED_WRONGLY = '[Auth] Security Question Answered Wrongly';
export const PASSWORD_RESET_SENT = '[Auth] Password Reset Sent';
export const LOAD_ORG = '[Auth] Load Org';
export const LOAD_ORG_SUCCEEDED = '[Auth] Load Org Succeeded';
export const LOAD_ORG_FAILED = '[Auth] Load Org Failed';
export const SWITCH_ORG = '[Auth] Switch Org';
export const SWITCH_ORG_FAILED = '[Auth] Switch Org Failed';
export const LOAD_ORG_LIST = '[Auth] Load Org List';
export const LOAD_ORG_LIST_SUCCEEDED = '[Auth] Load Org List Succeeded';
export const LOAD_ORG_LIST_FAILED = '[Auth] Load Org List Failed';
export const SAVE_ORG = '[Auth] Save Org';
export const SAVE_ORG_SUCCEEDED = '[Auth] Save Org Succeeded';
export const SAVE_ORG_FAILED = '[Auth] Save Org Failed';
export const RESEND_NEW_USER_EMAIL = '[Auth] Resend New User Email';
export const RESEND_NEW_USER_EMAIL_SUCCEEDED = '[Auth] Resend New User Email Succeeded';
export const RESEND_NEW_USER_EMAIL_FAILED = '[Auth] Resend New User Email Failed';
export const LOAD_FEATURE_SETS = '[Auth] Load Feature Sets';
export const LOAD_FEATURE_SETS_SUCCEEDED = '[Auth] Load Feature Sets Succeeded';
export const LOAD_FEATURE_SETS_FAILED = '[Auth] Load Feature Sets Failed';
export const STATUS_ERROR = '[Auth] Status error';
export const LOAD_FFMPEG_VERSIONS = '[Auth] Load FFMPEG Versions';
export const LOAD_FFMPEG_VERSIONS_SUCCEEDED = '[Auth] Load FFMPEG Versions Succeeded';
export const GET_PLAYER_NONCE = '[Auth] Get Player Nonce';
export const GET_PLAYER_NONCE_SUCCEEDED = '[Auth] Get Player Nonce Succeeded';
export const GET_PUBLISHER_TIMEZONE = '[Auth] Get Publisher Timezone';
export const GET_PUBLISHER_TIMEZONE_SUCCEEDED = '[Auth] Get Publisher Timezone Succeeded';

export class GrabKeys implements Action {
  readonly type = GRAB_KEYS;
}

export class GrabKeysFailed implements Action {
  readonly type = GRAB_KEYS_FAILED;
}

export class GrabKeysSucceeded implements Action {
  readonly type = GRAB_KEYS_SUCCEEDED;

  constructor(public keys: OrgKeys) { }
}

export class Login implements Action {
  readonly type = LOGIN;

  constructor(
    public email: string,
    public pwdHash: string,
    public imposterEmail: string,
    public nextUrl: string = null,
  ) {}
}

export class GetUser implements Action {
  readonly type = GET_USER;

  constructor(public nextUrl: string = null) {}
}

export class UserLoaded implements Action {
  readonly type = USER_LOADED;

  constructor(public user: User) {}
}

export class NotLoggedIn implements Action {
  readonly type = NOT_LOGGED_IN;

  constructor(
    public nextUrl: string,
    public errors?: ShimError,
  ) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class FirstLoad implements Action {
  readonly type = FIRST_LOAD;
}

export class ClearNextURL implements Action {
  readonly type = CLEAR_NEXT_URL;
}

export class AnonymousRequired implements Action {
  readonly type = ANON_REQUIRED;
}

export class SaveUser implements Action {
  readonly type = SAVE_USER;

  constructor(public user: User) {}
}

export class SaveUserSucceeded implements Action {
  readonly type = SAVE_USER_SUCCEEDED;

  constructor(public user: User) {}
}

export class SaveUserFailed implements Action {
  readonly type = SAVE_USER_FAILED;

  constructor(public errors: Errors) {}
}

export class LoadOrg implements Action {
  readonly type = LOAD_ORG;
}

export class LoadOrgSucceeded implements Action {
  readonly type = LOAD_ORG_SUCCEEDED;

  constructor(public org: Organization) {}
}

export class LoadOrgFailed implements Action {
  readonly type = LOAD_ORG_FAILED;
}

export class SwitchOrg implements Action {
  readonly type = SWITCH_ORG;

  constructor(public orgId: string) {}
}

export class SwitchOrgFailed implements Action {
  readonly type = SWITCH_ORG_FAILED;

  constructor(public errors: Errors) { }
}

export class LoadOrgList implements Action {
  readonly type = LOAD_ORG_LIST;
}

export class LoadOrgListSucceeded implements Action {
  readonly type = LOAD_ORG_LIST_SUCCEEDED;

  constructor(public orgs: OrgNode[]) {}
}

export class LoadOrgListFailed implements Action {
  readonly type = LOAD_ORG_LIST_FAILED;
}

export class SaveOrg implements Action {
  readonly type = SAVE_ORG;

  constructor(public org: Organization) {}
}

export class SaveOrgSucceeded implements Action {
  readonly type = SAVE_ORG_SUCCEEDED;

  constructor(public org: Organization) {}
}

export class SaveOrgFailed implements Action {
  readonly type = SAVE_ORG_FAILED;
}

export class StartPasswordReset implements Action {
  readonly type = START_PASSWORD_RESET;

  constructor(public email: string) {}
}

export class UserNotFound implements Action {
  readonly type = USER_NOT_FOUND;

  constructor(public email: string) {}
}

export class SecurityQuestionRequired implements Action {
  readonly type = SECURITY_QUESTION_REQUIRED;

  constructor(public question: string) {}
}

export class AnswerSecurityQuestion implements Action {
  readonly type = ANSWER_SECURITY_QUESTION;

  constructor(public answerHash: string) {}
}

export class SecurityQuestionAnsweredWrongly implements Action {
  readonly type = SECURITY_QUESTION_ANSWERED_WRONGLY;
}

export class PasswordResetSent implements Action {
  readonly type = PASSWORD_RESET_SENT;
}

export class ResendNewUserEmail implements Action {
  readonly type = RESEND_NEW_USER_EMAIL;

  constructor(public userId: string) {}
}

export class ResendNewUserEmailSucceeded implements Action {
  readonly type = RESEND_NEW_USER_EMAIL_SUCCEEDED;

  constructor(public userId: string) {}
}

export class ResendNewUserEmailFailed implements Action {
  readonly type = RESEND_NEW_USER_EMAIL_FAILED;

  constructor(public userId: string) {}
}

export class LoadFeatureSets implements Action {
  readonly type = LOAD_FEATURE_SETS;
}

export class LoadFeatureSetsSucceeded implements Action {
  readonly type = LOAD_FEATURE_SETS_SUCCEEDED;

  constructor(public features: FeatureSetAuthorization) {}
}

export class LoadFeatureSetsFailed implements Action {
  readonly type = LOAD_FEATURE_SETS_FAILED;
}

export class StatusError implements Action {
  readonly type = STATUS_ERROR;

  constructor(public status: number) {}
}

export class LoadFfmpegVersions implements Action {
  readonly type = LOAD_FFMPEG_VERSIONS;
}

export class LoadFfmpegVersionsSucceeded implements Action {
  readonly type = LOAD_FFMPEG_VERSIONS_SUCCEEDED;

  constructor(public versions: FfmpegVersionInfo[]) {}
}

export class GetPlayerNonce implements Action {
  readonly type = GET_PLAYER_NONCE;

  constructor() {}
}

export class GetPlayerNonceSucceeded implements Action {
  readonly type = GET_PLAYER_NONCE_SUCCEEDED;

  constructor(public nonce: string) {}
}

export class GetPublisherTimezone implements Action {
  readonly type = GET_PUBLISHER_TIMEZONE;

  constructor() {}
}

export class GetPublisherTimezoneSucceeded implements Action {
  readonly type = GET_PUBLISHER_TIMEZONE_SUCCEEDED;

  constructor(public timezone: string) {}
}

export type Actions = (
  Login | GetUser | UserLoaded | AnonymousRequired |
  NotLoggedIn | Logout | FirstLoad | ClearNextURL |
  SaveUser | SaveUserSucceeded | SaveUserFailed |
  LoadOrg | LoadOrgSucceeded | LoadOrgFailed |
  SwitchOrg | SwitchOrgFailed |
  LoadOrgList | LoadOrgListSucceeded | LoadOrgListFailed |
  SaveOrg | SaveOrgSucceeded | SaveOrgFailed |
  GrabKeys | GrabKeysFailed | GrabKeysSucceeded |
  StartPasswordReset | UserNotFound | SecurityQuestionRequired |
  AnswerSecurityQuestion | SecurityQuestionAnsweredWrongly | PasswordResetSent |
  ResendNewUserEmail | ResendNewUserEmailSucceeded | ResendNewUserEmailFailed |
  LoadFeatureSets | LoadFeatureSetsSucceeded | LoadFeatureSetsFailed | StatusError |
  LoadFfmpegVersions | LoadFfmpegVersionsSucceeded |
  GetPlayerNonce | GetPlayerNonceSucceeded |
  GetPublisherTimezone | GetPublisherTimezoneSucceeded
);
