import * as _ from 'lodash';
import { SortDirection, SortParams } from 'control-ui-common';

import * as contentRestriction from '../../actions/settings/content-restrictions';
import { Restriction } from '../../../models/restriction';

export interface State {
  contentRestrictions: Restriction[];
  currContentRestriction: Restriction;
  contentRestrictionsLoading: boolean;
  contentRestrictionsSaving: boolean;
  contentRestrictionsDeleting: boolean;
  savingRestrictionFailed: boolean;
  sortParams: {
    sortBy: string,
    sortDir: SortDirection,
  };
}

export const initialState: State = {
  contentRestrictions: [],
  currContentRestriction: null,
  contentRestrictionsLoading: true,
  contentRestrictionsSaving: false,
  contentRestrictionsDeleting: false,
  savingRestrictionFailed: false,
  sortParams: {
    sortBy: 'name',
    sortDir: SortDirection.ASC,
  },
};

const sortRestrictions = (propArray: Restriction[], p: SortParams): Restriction[] => {
  return _.orderBy(
    _.cloneDeep(propArray),
    [(cp) => _.toString(cp[p.sortBy]).toLowerCase()],
    [p.sortDir],
  );
};

export function reducer(
  state = initialState,
  action: contentRestriction.Actions,
): State {
  let newRestrictions;
  switch (action.type) {
    case contentRestriction.SELECT_CURR_RESTRICTION:
      return {
        ...state,
        currContentRestriction: _.cloneDeep(action.restriction),
      };
    case contentRestriction.CHANGE_RESTRICTION_SORT:
      return {
        ...state,
        sortParams: {
          sortBy: action.sortParams.sortBy,
          sortDir: action.sortParams.sortDir,
        },
        contentRestrictions: sortRestrictions(state.contentRestrictions, action.sortParams),
      };
    case contentRestriction.LOAD_RESTRICTIONS:
      return {
        ...state,
        contentRestrictionsLoading: true,
      };
    case contentRestriction.LOAD_RESTRICTIONS_SUCCEEDED:
      return {
        ...state,
        contentRestrictionsLoading: false,
        contentRestrictions: sortRestrictions(action.restrictions, state.sortParams),
      };
    case contentRestriction.LOAD_RESTRICTIONS_FAILED:
      return {
        ...state,
        contentRestrictionsLoading: false,
        contentRestrictions: [],
      };
    case contentRestriction.CREATE_RESTRICTION:
      return {
        ...state,
        contentRestrictionsSaving: true,
        savingRestrictionFailed: false,
      };
    case contentRestriction.CREATE_RESTRICTION_SUCCEEDED:
      if (state.contentRestrictions) {
        newRestrictions = [
          ...state.contentRestrictions,
            {
              ...action.restriction,
              id: action.restriction.id,
            },
        ];
        return {
          ...state,
          contentRestrictions: sortRestrictions(newRestrictions, state.sortParams),
        contentRestrictionsSaving: false,
        };
      }
      return state;
    case contentRestriction.CREATE_RESTRICTION_FAILED:
      // TODO: Display notification

      return {
        ...state,
        contentRestrictionsSaving: false,
        savingRestrictionFailed: true,
      };
    case contentRestriction.UPDATE_RESTRICTION:
      return {
        ...state,
        contentRestrictionsSaving: true,
        savingRestrictionFailed: false,
      };
    case contentRestriction.UPDATE_RESTRICTION_SUCCEEDED:
      if (state.contentRestrictions) {
        newRestrictions = _.cloneDeep(state.contentRestrictions);

        const restrictionIndex = state.contentRestrictions.findIndex((p) => p.id === action.restriction.id);

        newRestrictions.splice(restrictionIndex, 1, _.cloneDeep(action.restriction));
        return {
          ...state,
          contentRestrictions: sortRestrictions(newRestrictions, state.sortParams),
          contentRestrictionsSaving: false,
        };
      }
      return state;
    case contentRestriction.UPDATE_RESTRICTION_FAILED:
      return {
        ...state,
        contentRestrictionsSaving: false,
        savingRestrictionFailed: true,
      };
    case contentRestriction.DELETE_RESTRICTION:
      return {
        ...state,
        contentRestrictionsDeleting: true,
      };
    case contentRestriction.DELETE_RESTRICTION_SUCCEEDED:
      return {
        ...state,
        contentRestrictions: state.contentRestrictions.filter((p) => p.id !== action.id),
        currContentRestriction: null,
        contentRestrictionsDeleting: false,
      };
    case contentRestriction.DELETE_RESTRICTION_FAILED:
      return {
        ...state,
        contentRestrictionsDeleting: false,
      };
    default:
      return state;
  }
}

export const getContentRestrictions = (state: State) => state;
