export enum ContentActionType {
  AddTo,
  Create,
  Delete,
  Clone,
  Edit,
  Embed,
  Preview,
  Publish,
  ReEncode,
  Tag,
  Unpublish,
}
