import { EnvConfigurationService, Configuration } from './../services/environment-configuration.service';
import { PlayerNonceService } from './../services/player-nonce.service';
/*
  On aMedia, video player will be used in the preview modal, media details, and
  themes page.
*/
import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { filter, skip, take } from 'rxjs/operators';
import * as _ from 'lodash';

import { WindowRef } from '../utils/window-ref';
import { Skin } from '../models/theme';
import { Subscription } from 'rxjs/Subscription';
import { ScriptsService } from '../services/scripts.service';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../shared/redux/reducers';

interface LimelightCallbackEvent {
  playerId: string;
  eventName: string;
  data: any;
}

@Component({
  selector: 'll-player',
  templateUrl: './video-player.component.html',
})
export class PlayerComponent implements AfterContentChecked, OnChanges {
  static playerCallback = new Subject<LimelightCallbackEvent>();
  // Uniquely identify each video in the DOM
  static idCtr = 1;

  id = PlayerComponent.idCtr++;
  callbackSub: Subscription;
  appliedConfig: any = {};
  sdkFailedToLoad: boolean = false;
  loaded: boolean = false;
  playbackStarted: boolean = false;
  visible: boolean = false;
  content: any;
  @Input() set mediaId(mediaId: string) {
    this.content = { mediaId };
  }
  @Input() set channelId(channelId: string) {
    this.content = { channelId };
  }
  @Input() set channelListId(channelListId: string) {
    this.content = { channelListId };
  }
  @Input() height: string | number;
  @Input() width: string | number;
  @Input() skin: Skin;
  @Input() playerForm: string;
  @Input() seekbarEnabled: boolean = true;
  @Input() hideControls: boolean = false;
  @Output() playerLoad: EventEmitter<any> = new EventEmitter();
  @Output() mediaLoad: EventEmitter<any> = new EventEmitter();
  @Output() playStateChanged: EventEmitter<any> = new EventEmitter();
  @Output() playheadUpdate: EventEmitter<any> = new EventEmitter();
  @Output() mediaComplete: EventEmitter<any> = new EventEmitter();
  @ViewChild('playerTmpl') playerTmpl: TemplateRef<any>;
  @ViewChild('container', {read: ViewContainerRef}) playerContainer: ViewContainerRef;

  sdkLoaded$: Observable<boolean>;
  env: Configuration;

  constructor(
    public winRef: WindowRef,
    public scripts: ScriptsService,
    private el: ElementRef,
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,
  ) {
    this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);
    // Load player SDK if not already
    this.sdkLoaded$ = scripts.load(
      `${this.env.PLAYER_JS_URL}`,
    );

    this.sdkLoaded$.pipe(take(1)).subscribe(() => {
      this.sdkFailedToLoad = !this.playerUtil;

      if (!this.winRef.nativeWindow['limelightPlayerCallback']) {
        // Register a global callback to emit a new value on the static subject
        this.winRef.nativeWindow['limelightPlayerCallback'] = (playerId, eventName, data) => {
          PlayerComponent.playerCallback.next({playerId, eventName, data});
        };
      }
    });

    // Subscribe to the static callback subject to detect events pertaining to this player
    this.callbackSub = PlayerComponent.playerCallback.subscribe((e: LimelightCallbackEvent) => {
      if (e.playerId === this.playerId) {
        // ex. onPlayerLoad -> playerLoad
        const emitter: string = e.eventName.charAt(2).toLowerCase() + e.eventName.substring(3);

        if (emitter === 'playerLoad') {
          this.loaded = true;
        }

        if (this[emitter] !== undefined) {
          this[emitter].emit(e.data);
        }
      }
    });
  }

  ngOnChanges(simple: SimpleChanges): void {
    // Attempt to embed if we haven't already
    if (_.isEmpty(this.appliedConfig)) {
      this.embed();
    } else if (!!simple.skin) {
      this.sdkLoaded$.pipe(take(1)).subscribe((loaded) => {
        this.execute('doSkin', simple.skin.currentValue);
      });
    }
  }

  ngAfterContentChecked(): void {
    const nowVisible = this.el.nativeElement.offsetParent;

    if (!this.visible && nowVisible) {
      this.visible = true;

      // Gotta have a parent element to embed, so add small delay
      const thiz = this;
      setTimeout(() => thiz.embed(), 100);
    } else if (this.visible && !nowVisible) {
      this.visible = false;
    }
  }

  embed(): void {
    if (
      this.visible &&
      !_.isEqual(this.appliedConfig, this.config) &&
      this.isConfigValid()
    ) {
      const p = this.el.nativeElement.querySelector(`#${this.playerId}`);
      if (!!p) {
        p.remove();
        this.id = PlayerComponent.idCtr++;
      }
      this.playerContainer.createEmbeddedView(this.playerTmpl);
      setTimeout(() => {
        this.sdkLoaded$.pipe(take(1)).subscribe((loaded) => {
          // now we need to get the nonce to play unpublished media
          this.store.pipe(
            select(fromRoot.getPlayerNonce),
            filter((nonce) => !!nonce),
            take(1),
          ).subscribe((nonce: string) => {
            const conf = _.cloneDeep(this.config); // getter
            conf._allowUnpublishedNonce = nonce;
            this.appliedConfig = this.config;
            if (!!this.playerUtil && !!this.el.nativeElement.offsetParent) {
              this.playerUtil.embed(conf);
            }
          });
        });
      }, 100);
    }
  }

  ngOnDestroy(): void {
    this.callbackSub.unsubscribe();
  }

  isConfigValid(): boolean {
    return (
      this.width > 0 &&
      this.height > 0 &&
      !!this.content &&
      (
        !_.isEmpty(this.content.mediaId) ||
        !_.isEmpty(this.content.channelId) ||
        !_.isEmpty(this.content.channelListId)
      )
    );
  }

  get config(): any {
    let lookAndFeel: any = {
      height: this.height,
      width: this.width,
    };

    if (!!this.playerForm) {
      lookAndFeel.playerForm = this.playerForm;
    }
    if (!!this.skin) {
      lookAndFeel.skin = this.skin;
    }

    const stage: any = this.env.STAGE !== 'prod' ? { stage: this.env.STAGE } : {};

    return {
      ...this.content, // mediaId or channelId
      ...lookAndFeel,
      ...stage,
      playerId: this.playerId,
      enableAnalytics: false,
    };
  }

  get playerId(): string {
    return `video-player-${this.id}`;
  }

  get player(): any {
    return this.winRef.nativeWindow['LimelightPlayer'];
  }

  get playerUtil(): any {
    return this.winRef.nativeWindow['LimelightPlayerUtil'];
  }

  seekAndPause(second: number) {
    if (!this.playbackStarted) {
      const currVolume = this.execute('doGetVolume');
      this.execute('doSetVolume', 0);
      this.execute('doPlay');

      // Setup callback to happen after pause is done that resets the volume
      this.playStateChanged.pipe(
        filter((state) => !state.isPlaying),
        take(1),
      ).subscribe((state) => this.execute('doSetVolume', currVolume));

      this.playheadUpdate.pipe(
        skip(1),
        take(1),
      ).subscribe((val) => {
        setTimeout(() => {
          this.execute('doPause');
          this.execute('doSeekToSecond', second);
          this.playbackStarted = true;
        }, 10);
      });
    } else {
      this.execute('doSeekToSecond', second);
      this.execute('doPause');
    }
  }

  // jump to seconds in video and play.
  seekAndPlay(second: number): void {
    this.execute('doSeekToSecond', second);
    this.execute('doPlay');
  }

  // Generates a base64-encoded image URI
  getSnapshot(): string {
    if (!this.loaded) {
      return null;
    }

    const video = this.el.nativeElement.querySelector('video');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    try {
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    } catch (e) {
      // no-op
    }

    return canvas.toDataURL('image/png');
  }

  execute(method: string, ...args: any[]): any {
    if (!this.loaded || !this.player) {
      return null;
    }

    return this.player[method].apply(this.player, args);
  }
}
