import { EventHistoryRequestParams } from './../../models/event-history-request-params';
import { GeoReportsGranularity } from './../../models/geo-reports-granularity';
import { GeoSummaryData } from './../../models/geo-summary-data';
import { OverviewReportTables } from './../../models/overview-report-tables';
import { DatePickerRange, SelectOption, SortDirection } from 'control-ui-common';
import * as reports from '../actions/reports';
import { PaginatedResponse } from '../../models/paginated-response';
import { AnalyticsFilters } from '../../analytics-filters';
import { ReportsTabs } from '../../models/reports-tabs';
import { ChannelsOrMediaSummaryData } from '../../models/channels-aggregation';
import { ViewerDataSummaryData } from './../../models/viewer-data-summary-data';
import * as _ from 'lodash';
import { ViewerDataReports } from '../../models/viewer-data-reports';
import { DataUsageChartType } from '../../models/data-usage-chart-type';

export enum MediaChartDataType {
  Video = 'video',
  Audio = 'audio',
}

export interface State {
  dateRange: DatePickerRange;
  channelSummaryData: ChannelsOrMediaSummaryData;
  channelSummaryLoading: boolean;
  channelTableData: PaginatedResponse;
  channelTableDataLoading: boolean;
  channelTableDataLoadFailed: boolean;
  channelTableFilters: AnalyticsFilters;
  channelTableSelectedIds: string[];
  channelChartData: any;
  channelChartLoading: boolean;
  channelChartFilters: AnalyticsFilters;
  engagementTableData: PaginatedResponse;
  engagementTableFilters: AnalyticsFilters;
  engagementTableLoading: boolean;
  engagementTableLoadingFailed: boolean;
  eventHistoryTableData: PaginatedResponse;
  eventHistoryFilters: EventHistoryRequestParams;
  eventHistoryTableLoading: boolean;
  eventHistoryEventTypes: string[];
  geoReportsGranularity: GeoReportsGranularity;
  geoTableData: PaginatedResponse;
  geoTableDataLoading: boolean;
  geoTableDataLoadFailed: boolean;
  geoTableFilters: AnalyticsFilters;
  geoBreadcrumbs: {country: string, province: string};
  geoSummaryData: GeoSummaryData;
  geoSummaryDataLoading: boolean;
  mediaSummaryData: ChannelsOrMediaSummaryData;
  mediaSummaryLoading: boolean;
  mediaTableData: PaginatedResponse;
  mediaTableDataLoading: boolean;
  mediaTableDataLoadFailed: boolean;
  mediaTableFilters: AnalyticsFilters;
  mediaChartFilters: AnalyticsFilters;
  mediaChartData: any;
  mediaChartLoading: any;
  mediaChartDataType: MediaChartDataType[];
  mediaTableSelectedIds: string[];
  overviewChartData: any;
  overviewChartLoading: boolean;
  overviewTableData: PaginatedResponse;
  overviewTableDataLoadFailed: boolean;
  overviewTableLoading: boolean;
  overviewTableSelectVal: string;
  overviewFilters: AnalyticsFilters;
  dataUsageChartLoading: boolean;
  dataUsageSummaryLoading: boolean;
  dataUsageChartLoadingFailed: boolean;
  dataUsageSummaryLoadingFailed: boolean;
  dataUsageSummaryData: any[];
  dataUsageChartData: any[];
  dataUsageChartInterval: string;
  dataUsageChartType: DataUsageChartType;
  selectedReportsTab: string;
  viewerDataTableData: PaginatedResponse;
  viewerDataTableFilters: AnalyticsFilters;
  viewerDataTableLoading: boolean;
  viewerDataTableLoadingFailed: boolean;
  viewerDataTableSelectVal: string;
  viewerDataTableSelectedIds: string[];
  viewerDataSummaryData: ViewerDataSummaryData;
  viewerDataSummaryLoading: boolean;
}

export const initialState: State = {
  dateRange: null,
  channelSummaryData: null,
  channelSummaryLoading: false,
  channelTableData: null,
  channelTableDataLoading: false,
  channelTableDataLoadFailed: false,
  channelTableFilters: {
    hasNext: false,
    page: 0,
    pageSize: 10,
    sortBy: 'plays',
    sortDir: SortDirection.DESC,
  },
  channelTableSelectedIds: [],
  channelChartData: null,
  channelChartLoading: false,
  channelChartFilters: {
    interval: 'hourly',
  },
  engagementTableData: null,
  engagementTableFilters: {
    hasNext: false,
    page: 0,
    pageSize: 10,
    sortBy: 'plays',
    sortDir: SortDirection.DESC,
  },
  engagementTableLoading: false,
  engagementTableLoadingFailed: false,
  eventHistoryTableData: null,
  eventHistoryFilters: {
    pageId: 0,
    pageSize: 10,
    eventTypes: [],
    sortBy: 'eventTimestamp',
    sortDir: SortDirection.DESC,
  },
  eventHistoryTableLoading: false,
  eventHistoryEventTypes: [],
  geoBreadcrumbs: { country: null, province: null },
  geoReportsGranularity: GeoReportsGranularity.COUNTRY,
  geoTableData: null,
  geoTableDataLoading: false,
  geoTableDataLoadFailed: false,
  geoTableFilters: {
    hasNext: false,
    page: 0,
    pageSize: 10,
    sortBy: 'plays',
    sortDir: SortDirection.DESC,
  },
  geoSummaryData: null,
  geoSummaryDataLoading: false,
  mediaSummaryData: null,
  mediaSummaryLoading: false,
  mediaTableData: null,
  mediaTableDataLoading: false,
  mediaTableDataLoadFailed: false,
  mediaTableFilters: {
    hasNext: false,
    page: 0,
    pageSize: 10,
    sortBy: 'plays',
    sortDir: SortDirection.DESC,
  },
  mediaChartFilters: {
    interval: 'hourly',
  },
  mediaChartDataType: [MediaChartDataType.Video, MediaChartDataType.Audio],
  mediaChartData: null,
  mediaChartLoading: false,
  mediaTableSelectedIds: [],
  overviewChartData: null,
  overviewChartLoading: false,
  overviewTableData: null,
  overviewTableDataLoadFailed: false,
  overviewTableLoading: false,
  overviewTableSelectVal: OverviewReportTables.MEDIA,
  overviewFilters: {
    page: 0,
    pageSize: 5,
    sortBy: 'plays',
    sortDir: SortDirection.DESC,
  },
  dataUsageChartLoading: false,
  dataUsageSummaryLoading: false,
  dataUsageChartLoadingFailed: false,
  dataUsageSummaryLoadingFailed: false,
  dataUsageSummaryData: [],
  dataUsageChartData: [],
  dataUsageChartInterval: null,
  dataUsageChartType: DataUsageChartType.BANDWIDTH,
  selectedReportsTab: ReportsTabs.OVERVIEW,
  viewerDataTableData: null,
  viewerDataTableFilters: {
    page: 0,
    pageSize: 10,
    sortBy: 'plays',
    sortDir: SortDirection.DESC,
  },
  viewerDataTableLoading: false,
  viewerDataTableLoadingFailed: false,
  viewerDataTableSelectVal: ViewerDataReports.OPERATING_SYSTEM,
  viewerDataTableSelectedIds: [],
  viewerDataSummaryData: null,
  viewerDataSummaryLoading: false,
};

export function reducer(
  state = initialState,
  action: reports.Actions,
): State {
  switch (action.type) {
    case  reports.LOAD_CHANNEL_SUMMARY:
      return {
        ...state,
        channelSummaryLoading: true,
      };
    case reports.LOAD_CHANNEL_SUMMARY_SUCCEEDED:
      return {
        ...state,
        channelSummaryData: action.data,
        channelSummaryLoading: false,
      };
    case reports.LOAD_CHANNEL_TABLE_DATA:
      return {
        ...state,
        channelTableFilters: action.filters,
        channelTableDataLoading: true,
        channelTableDataLoadFailed: false,
        channelTableSelectedIds: [],
      };
    case reports.LOAD_CHANNEL_TABLE_DATA_FAILED:
      return {
        ...state,
        channelTableDataLoading: false,
        channelTableDataLoadFailed: true,
      };
    case reports.LOAD_CHANNEL_TABLE_DATA_SUCCEEDED:
      return {
        ...state,
        channelTableData: action.data,
        channelTableDataLoading: false,
        channelTableDataLoadFailed: false,
        channelTableFilters: _.assign({}, state.channelTableFilters, { hasNext: action.data.hasNext }),
      };
    case reports.SELECT_CHANNEL_TABLE_ROW:
      return {
        ...state,
        channelTableSelectedIds: _.uniq([
          ...state.channelTableSelectedIds,
          ...action.ids,
        ]),
      };
    case reports.DESELECT_CHANNEL_TABLE_ROW:
      let newSelectList = state.channelTableSelectedIds.slice();
      _.remove(newSelectList, (x) => action.ids.includes(x));
      return {
        ...state,
        channelTableSelectedIds: newSelectList,
      };
    case reports.LOAD_CHANNEL_CHART_DATA:
      return {
        ...state,
        channelChartLoading: true,
      };
    case reports.LOAD_CHANNEL_CHART_DATA_SUCCEEDED:
      return {
        ...state,
        channelChartData: action.data,
        channelChartLoading: false,
      };
    case reports.LOAD_ENGAGEMENT_TABLE:
      return {
        ...state,
        engagementTableFilters: action.filters,
        engagementTableLoading: true,
        engagementTableLoadingFailed: false,
      };
    case reports.LOAD_ENGAGEMENT_TABLE_FAILED:
      return {
        ...state,
        engagementTableLoading: false,
        engagementTableLoadingFailed: true,
      };
    case reports.LOAD_ENGAGEMENT_TABLE_SUCCEEDED:
      return {
        ...state,
        engagementTableData: action.data,
        engagementTableLoading: false,
        engagementTableLoadingFailed: false,
        engagementTableFilters: _.assign({}, state.engagementTableFilters, { hasNext: action.data.hasNext }),
      };
    case reports.LOAD_EVENT_HISTORY:
      return {
        ...state,
        eventHistoryFilters: action.filters,
        eventHistoryTableLoading: true,
      };
    case reports.LOAD_EVENT_HISTORY_SUCCEEDED:
      return {
        ...state,
        eventHistoryTableData: action.data,
        eventHistoryTableLoading: false,
        eventHistoryFilters: _.assign({}, state.eventHistoryFilters, { hasNext: !!action.data.hasNext }),
      };
    case reports.LOAD_EVENT_HISTORY_FAILED:
      return {
        ...state,
        eventHistoryTableLoading: false,
      };
    case reports.LOAD_EVENT_HISTORY_EVENT_TYPES_SUCCEEDED:
      return {
        ...state,
        eventHistoryEventTypes: action.eventTypes,
      };
    case reports.SET_GEO_BREADCRUMBS:
      return {
        ...state,
        geoBreadcrumbs: action.crumbs,
      };
    case reports.LOAD_GEO_TABLE_DATA:
      return {
        ...state,
        geoTableDataLoadFailed: false,
        geoTableDataLoading: true,
        geoTableFilters: action.filters,
      };
    case reports.LOAD_GEO_TABLE_DATA_FAILED:
      return {
        ...state,
        geoTableDataLoadFailed: true,
        geoTableDataLoading: false,
      };
    case reports.LOAD_GEO_TABLE_DATA_SUCCEEDED:
      return {
        ...state,
        geoTableData: action.data,
        geoTableDataLoadFailed: false,
        geoTableDataLoading: false,
        geoTableFilters: _.assign({}, state.geoTableFilters, { hasNext: action.data.hasNext }),
      };
    case reports.SET_GEO_REPORTS_GRANULARITY:
      return {
        ...state,
        geoReportsGranularity: action.granularity,
      };
    case reports.LOAD_GEO_SUMMARY:
      return {
        ...state,
        geoSummaryDataLoading: true,
      };
    case reports.LOAD_GEO_SUMMARY_SUCCEEDED:
      return {
        ...state,
        geoSummaryDataLoading: false,
        geoSummaryData: action.data,
      };
    case reports.LOAD_MEDIA_SUMMARY:
      return {
        ...state,
        mediaSummaryLoading: true,
      };
    case reports.LOAD_MEDIA_SUMMARY_SUCCEEDED:
      return {
        ...state,
        mediaSummaryData: action.data,
        mediaSummaryLoading: false,
      };
    case reports.LOAD_MEDIA_TABLE_DATA:
      return {
        ...state,
        mediaTableFilters: action.filters,
        mediaTableDataLoading: true,
        mediaTableDataLoadFailed: false,
        mediaTableSelectedIds: [],
      };
    case reports.LOAD_MEDIA_TABLE_DATA_SUCCEEDED:
      return {
        ...state,
        mediaTableData: action.data,
        mediaTableDataLoading: false,
        mediaTableDataLoadFailed: false,
        mediaTableFilters: _.assign({}, state.mediaTableFilters, { hasNext: action.data.hasNext }),
      };
    case reports.LOAD_MEDIA_TABLE_DATA_FAILED:
      return {
        ...state,
        mediaTableDataLoadFailed: true,
        mediaTableDataLoading: false,
      };
    case reports.LOAD_MEDIA_CHART_DATA:
      return {
        ...state,
        mediaChartLoading: true,
      };
    case reports.LOAD_MEDIA_CHART_DATA_FAILED:
      return {
        ...state,
        mediaChartLoading: false,
      };
    case reports.LOAD_MEDIA_CHART_DATA_SUCCEEDED:
      return {
        ...state,
        mediaChartData: action.data,
        mediaChartLoading: false,
      };
    case reports.SELECT_MEDIA_TABLE_ROW:
      return {
        ...state,
        mediaTableSelectedIds: _.uniq([
          ...state.mediaTableSelectedIds,
          ...action.ids,
        ]),
      };
    case reports.DESELECT_MEDIA_TABLE_ROW:
      let mediaIdList = state.mediaTableSelectedIds.slice();
      _.remove(mediaIdList, (x) => action.ids.includes(x));
      return {
        ...state,
        mediaTableSelectedIds: mediaIdList,
      };
    case reports.SET_CHART_MEDIA_TYPE:
      return {
        ...state,
        mediaChartDataType: action.mediaType,
      };
    case reports.LOAD_OVERVIEW_CHART_DATA:
      return {
        ...state,
        overviewChartLoading: true,
      };
    case reports.LOAD_OVERVIEW_CHART_DATA_FAILED:
      return {
        ...state,
        overviewChartLoading: false,
      };
    case reports.LOAD_OVERVIEW_CHART_DATA_SUCCEEDED:
      return {
        ...state,
        overviewChartData: action.data,
        overviewChartLoading: false,
      };
    case reports.LOAD_OVERVIEW_TABLE_DATA:
      return {
        ...state,
        overviewFilters: Object.assign({}, action.filters),
        overviewTableLoading: true,
        overviewTableDataLoadFailed: false,
      };
    case reports.LOAD_OVERVIEW_TABLE_DATA_SUCCEEDED:
      return {
        ...state,
        overviewTableData: action.data,
        overviewTableLoading: false,
        overviewTableDataLoadFailed: false,
      };
    case reports.LOAD_OVERVIEW_TABLE_DATA_FAILED:
      return {
        ...state,
        overviewTableDataLoadFailed: true,
        overviewTableLoading: false,
      };
    case reports.SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.dateRange,
      };
    case reports.SET_OVERVIEW_TABLE_SELECT_VAL:
      return {
        ...state,
        overviewFilters: Object.assign({}, initialState.overviewFilters),
        overviewTableSelectVal: action.selectVal,
      };
    case reports.LOAD_DATA_USAGE_CHART_DATA:
      return {
        ...state,
        dataUsageChartLoading: true,
      };
    case reports.LOAD_DATA_USAGE_SUMMARY_DATA:
      return {
        ...state,
        dataUsageSummaryLoading: true,
      };
    case reports.LOAD_DATA_USAGE_CHART_DATA_FAILED:
      return {
        ...state,
        dataUsageChartLoading: false,
        dataUsageChartLoadingFailed: true,
      };
    case reports.LOAD_DATA_USAGE_CHART_DATA_SUCCEEDED:
      return {
        ...state,
        dataUsageChartLoading: false,
        dataUsageChartData: action.data,
        dataUsageChartInterval: action.interval,
      };
      case reports.LOAD_DATA_USAGE_SUMMARY_DATA_FAILED:
      return {
        ...state,
        dataUsageSummaryLoading: false,
        dataUsageSummaryLoadingFailed: true,
      };
    case reports.LOAD_DATA_USAGE_SUMMARY_DATA_SUCCEEDED:
      return {
        ...state,
        dataUsageSummaryLoading: false,
        dataUsageSummaryData: action.data,
      };
    case reports.SET_DATA_USAGE_CHART_TYPE:
      return {
        ...state,
        dataUsageChartType: action.chartType,
      };
    case reports.SET_REPORTS_TAB:
      return {
        ...state,
        selectedReportsTab: action.tab,
      };
    case reports.SET_VIEWER_DATA_TABLE_SELECT_VAL:
      return {
        ...state,
        viewerDataTableSelectVal: action.val,
      };
    case reports.LOAD_VIEWER_DATA_TABLE:
      return {
        ...state,
        viewerDataTableFilters: action.filters,
        viewerDataTableLoading: true,
        viewerDataTableLoadingFailed: false,
        viewerDataTableSelectedIds: [],
      };
    case reports.LOAD_VIEWER_DATA_TABLE_FAILED:
      return {
        ...state,
        viewerDataTableLoading: false,
        viewerDataTableLoadingFailed: true,
      };
    case reports.LOAD_VIEWER_DATA_TABLE_SUCCEEDED:
      return {
        ...state,
        viewerDataTableData: action.data,
        viewerDataTableLoading: false,
        viewerDataTableLoadingFailed: false,
        viewerDataTableFilters: _.assign({}, state.viewerDataTableFilters, { hasNext: action.data.hasNext }),
      };
    case reports.LOAD_VIEWER_DATA_SUMMARY:
      return {
        ...state,
        viewerDataSummaryLoading: true,
      };
    case reports.LOAD_VIEWER_DATA_SUMMARY_SUCCEEDED:
      return {
        ...state,
        viewerDataSummaryData: action.data,
        viewerDataSummaryLoading: false,
      };
    default:
      return state;
  }
}

export const getOverviewTableSelectVal = (state: State) => state.overviewTableSelectVal;
export const getDataUsageSummaryData = (state: State) => state.dataUsageSummaryData;
export const getDataUsageChartData = (state: State) => state.dataUsageChartData;
export const getReportsSelectedTab = (state: State) => state.selectedReportsTab;
export const getMediaReportChartData = (state: State) => state.mediaChartData;
export const getChannelReportChartData = (state: State) => state.channelChartData;
export const getViewerDataTableData = (state: State) => state.viewerDataTableData;
export const getChannelTableFilters = (state: State) => state.channelTableFilters;
export const getMediaTableFilters = (state: State) => state.mediaTableFilters;
export const getViewerDataTableFilters = (state: State) => state.viewerDataTableFilters;
export const getChannelSummaryData = (state: State) => state.channelSummaryData;
export const getMediaSummaryData = (state: State) => state.mediaSummaryData;
export const getViewerDataSummaryData = (state: State) => state.viewerDataSummaryData;
export const getGeoTableFilters = (state: State) => state.geoTableFilters;
export const getGeoReportsGranularity = (state: State) => state.geoReportsGranularity;
export const getGeoSummaryData = (state: State) => state.geoSummaryData;
export const getOverviewChartData = (state: State) => state.overviewChartData;
export const getEngagementTableFilters = (state: State) => state.engagementTableFilters;
export const getEngagementTableData = (state: State) => state.engagementTableData;
export const getGeoTableData = (state: State) => state.geoTableData;
export const getGeoBreadcrumbs = (state: State) => state.geoBreadcrumbs;
export const getViewerDataTableSelectVal = (state: State) => state.viewerDataTableSelectVal;
export const getViewerDataChartData = (state: State) => {
  return {
    tableData: state.viewerDataTableData,
    summaryData: state.viewerDataSummaryData,
    selectedReport: state.viewerDataTableSelectVal,
  };
};
export const getMediaChartDataType = (state: State) => state.mediaChartDataType;
export const getDataUsageChartType = (state: State) => state.dataUsageChartType;
export const getEventHistoryTableData = (state: State) => state.eventHistoryTableData;
export const getEventHistoryFilters = (state: State) => state.eventHistoryFilters;
export const getEventHistoryEventTypes = (state: State) => state.eventHistoryEventTypes;
export const getOverviewTableFilters = (state: State) => state.overviewFilters;
export const getOverviewTableData = (state: State) => state.overviewTableData;
