import { EnvConfigurationService, Configuration } from './environment-configuration.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpEventType, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { delay, filter, map, take, mergeMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { SortDirection } from 'control-ui-common';
import * as _ from 'lodash';

import * as fromRoot from '../../shared/redux/reducers';
import * as fromAuth from '../../shared/redux/reducers/auth';
import * as Auth from '../../shared/redux/actions/auth';
import { ContentFilters } from '../models/content-filters';
import { sortContent } from '../utils/content-utils';
import { encodingProfiles } from '../mock-data/encoding-profile';
import { CustomProperty } from '../models/custom-property';
import { Media } from '../models/media';
import { Group, ChannelListDetails } from '../models/group';
import { VpwsSigningService } from './vpws-signing.service';
import { ImageType } from '../models/image-type';
import { Changeset } from '../models/changeset';
import { PaginatedResponse } from '../models/paginated-response';
import { Moment } from 'moment';
import { Channel } from '../models/channel';
import { Theme } from '../models/theme';
import { AdConfig, AdConfigType } from '../models/ad-config';
import { Thumbnail } from '../models/media-encoding';

@Injectable()
export class MediaDataService {
  auth$: Observable<fromAuth.State>;
  env: Configuration;

  private headers = new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'});

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private vpwsSigning: VpwsSigningService,
    private envConfigService: EnvConfigurationService,
  ) {
    this.auth$ = store.pipe(select(fromRoot.getAuthState));
    this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);
  }

  /**************** Media ****************/
  updateMedia(id: string, values: any): Observable<any> {
    if (!!values.tags) {
      values.tags = _.join(values.tags, ',');
    }
    return this.http.put(
      `${this.env.SHIM_BASE_URL}/mds/media/${id}/update`,
      values,
    );
  }

  publishUnpublishMedia(id: string, value: any): Observable<any> {
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/mds/media/${id}/${value}`,
      '',
      { headers: this.headers },
    );
  }

  createMedia(title: string, file: File): Observable<any> {
    let orgId: string;
    this.auth$.pipe(take(1)).subscribe((auth) => orgId = auth.currOrgId);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('media_file', file);

    return this.http.request(this.vpwsSigning.signRequest(
      new HttpRequest(
        'POST',
        `${this.env.VPWS_URL}/rest/organizations/${orgId}/media`,
        formData,
        { reportProgress: true },
      ),
    ));
  }

  replaceSource(id: string, file: File): Observable<any> {
    let orgId: string;
    this.auth$.pipe(take(1)).subscribe((auth) => orgId = auth.currOrgId);

    const formData = new FormData();
    formData.append('media_file', file);

    return this.http.request(this.vpwsSigning.signRequest(
      new HttpRequest(
        'PUT',
        `${this.env.VPWS_URL}/rest/organizations/${orgId}/media/${id}`,
        formData,
        { reportProgress: true },
      ),
    ));
  }

  deleteMedia(id: string): Observable<any> {
    return this.http.delete(`${this.env.SHIM_BASE_URL}/mds/media/${id}`);
  }

  mediaBulkDelete(ids: string[]): Observable<any> {
    const params = new HttpParams().set('mediaIds', ids.join(','));
    return this.http.delete(`${this.env.SHIM_BASE_URL}/mds/media/delete`, { params });
  }

  savePreview(contentType: string, id: string, image: File): Observable<any> {
    const formData = new FormData();
    formData.append('preview_image_file', image);
    return this.http.request(new HttpRequest(
      'PUT',
      `${this.env.SHIM_BASE_URL}/mds/${contentType}/${id}/preview_image`,
      formData,
    )).pipe(
      // Using http.request(...) emits multile events, only get the response
      filter((e) => e.type === HttpEventType.Response),
    );
  }

  savePreviewUrl(contentType: string, id: string, previewUrl: string, thumbUrl: string): Observable<any> {
    return this.http.put(
      `${this.env.SHIM_BASE_URL}/mds/${contentType}/${id}/imageUrls`,
      {
        previewImageUrl: previewUrl,
        thumbnailImageUrl: thumbUrl,
      },
    );
  }

  // The other methods above will directly set the preview image with mds and are synchronous.
  // This method will trigger processing for MWF for high-quality thumbnail extraction
  // from a frame within the video.
  startMediaPreviewProcessing(id: string, frameTimeMs: number): Observable<void> {
    return this.http.post<void>(
      `${this.env.SHIM_BASE_URL}/mds/media/${id}/extract_thumbnail`,
      {
        frameTimeInMillis: frameTimeMs,
      },
    );
  }

  uploadImage(
    image: File, imageType: ImageType, width: number, height: number, orgId: string,
  ): Observable<string> {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('ImageType', imageType);
    formData.append('FileName', image.name);
    formData.append('Width', `${width}`);
    formData.append('Height', `${height}`);
    formData.append('OwnerId', orgId);

    return this.http.request(new HttpRequest(
      'POST',
      `${this.env.SHIM_BASE_URL}/mds/uploadImage`,
      formData,
    )).pipe(
      // Using http.request(...) emits multile events, only get the response
      filter((e) => e.type === HttpEventType.Response),
      map((res) => _.get(res, 'body.PostResponse.Location')),
    );
  }

  createClip(
    id: string, title: string, startTime: number, endTime: number, endscreenImageUri: string,
  ): Observable<any> {
    const mergePropertiesList: any[] = [
      {
        mediaType: 'Video',
        mediaIdentifier: id,
        startTimeInMilliseconds: startTime.toString(),
        endTimeInMilliseconds: endTime.toString(),
      },
    ];

    if (!!endscreenImageUri) {
      mergePropertiesList.push({
        mediaType: 'Image',
        mediaIdentifier: endscreenImageUri,
        startTimeInMilliseconds: '0',
        endTimeInMilliseconds: '5000',
      });
    }

    return this.http.post(
      `${this.env.SHIM_BASE_URL}/mds/media/mergeMedia`,
      {
        title,
        mergePropertiesList,
      },
    );
  }

  getAllThumbnails(mediaId: string): Observable<Thumbnail[]> {
    let thumbs: Thumbnail[] = [];
    const helper = (res) => {
      thumbs = thumbs.concat(res['thumbnail_list']);
      if (res['has_next']) {
        let params = new HttpParams();
        params = params.append('page_id', res['page_id'] + 1);
        return this.http.get(
          `${this.env.SHIM_BASE_URL}/mds/media/${mediaId}/thumbnails`,
          { params },
          ).pipe(mergeMap(helper));
      } else {
        return of(thumbs);
      }
    };
    return this.http.get(`${this.env.SHIM_BASE_URL}/mds/media/${mediaId}/thumbnails`).pipe(mergeMap(helper));
  }

  getChangeset(contentType: string, after: Moment): Observable<Changeset> {
    return this.http.get<Changeset>(
      `${this.env.SHIM_BASE_URL}/mds/${contentType}/changeSet/${after.toISOString()}`,
    ).pipe(
      map((res) => {
        const content = (
          res['changedMedia'] ||
          res['changedChannels'] ||
          res['changedChannelLists'] ||
          []
        );

        return {
          timestamp: res['changeSetTimestamp'],
          changeset: content,
        };
      }),
    );
  }

  listChannelsForMedia(mediaID: string): Observable<any> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/mds/media/${mediaID}/channels`,
    );
  }

  addMediaTagBulk(values: any): Observable<any> {
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/mds/tags/bulk`,
      values,
    );
  }

  /**************** Channels ****************/
  getAllChannelProperties(channelID: string): Observable<any> {
    let params = new HttpParams().set('skip_cache', 'true');
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/mds/channels/${channelID}/properties`,
      {
        params,
      },
    );
  }

  updateChannel(channelID: string, values: any): Observable<any> {
    return this.http.put(
      `${this.env.SHIM_BASE_URL}/mds/channels/${channelID}/properties`,
      values,
    );
  }

  getChannelsList(f: ContentFilters): Observable<PaginatedResponse> {
    const params = [];
    if (!!f.queryParams) {
      // State
      if (
        !!f.queryParams['state'] &&
        f.queryParams['state'].length < 5
      ) {
        params.push(`&or=${_.join(
          _.map(f.queryParams['state'], (entry: string) => `state:${entry.toLowerCase()}`),
          ';',
        )}`);
      }
      const and = [];
      // Type
      if (
        !!f.queryParams['media_type'] &&
        f.queryParams['media_type'].length === 1) {
        and.push(`media_type:${f.queryParams['media_type'][0]}`);
      }
      // Search
      if (!!f.searchText) {
        and.push(`title:${f.searchText}`);
      }

      if (and.length > 0) {
        params.push(`&and=${and.join(';')}`);
      }
    }
    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/mds/channels/all` +
      `?sort_by=${f.sortBy}` +
      `&sort_order=${f.sortDir.toUpperCase()}` +
      (f.pageSize !== undefined ? `&page_size=${f.pageSize}` : '') +
      (f.page !== undefined ? `&page_id=${f.page - 1}` : '') +
      `${params.join('')}`,
    ).pipe(
      map((res) => {
        return {
          page: res['page_id'] + 1,
          size: f.pageSize,
          hasNext: res['has_next'],
          results: res['channel_list'],
        };
      }),
    );
  }

  getChannelMedia(id: string, f: ContentFilters): Observable<any> {
    const params = [];
    if (!!f.queryParams) {
      // State
      if (
        !!f.queryParams['state'] &&
        f.queryParams['state'].length < 5
      ) {
        params.push(`&or=${_.join(
          _.map(f.queryParams['state'], (entry: string) => `state:${entry.toLowerCase()}`),
          ';',
        )}`);
      }
      const and = [];
      // Type
      if (
        !!f.queryParams['media_type'] &&
        f.queryParams['media_type'].length === 1) {
        and.push(`media_type:${f.queryParams['media_type'][0]}`);
      }
      // Search
      if (!!f.searchText) {
        and.push(`title:${f.searchText}`);
      }

      if (and.length > 0) {
        params.push(`&and=${and.join(';')}`);
      }
    }

    return this.http.get(
      `${this.env.SHIM_BASE_URL}/mds/channels/${id}/media` +
      `?sort_by=play_order` +
      `&sort_order=${SortDirection.ASC.toUpperCase()}` +
      (f.pageSize !== undefined ? `&page_size=${f.pageSize}` : '') +
      (f.page !== undefined ? `&page_id=${f.page - 1}` : '') +
      `${params.join('')}`,
    ).pipe(
      map((res) => {
        return {
          page: res['page_id'] + 1,
          size: f.pageSize,
          hasNext: res['has_next'],
          results: res['media_list'],
        };
      }),
    );
  }

  publishUnpublishChannel(id: string, value: any): Observable<any> {
    const state: string = value === 'publish' ? 'Published' : 'NotPublished';

    return this.http.put(
      `${this.env.SHIM_BASE_URL}/mds/channels/${id}/properties`,
      { state },
    );
  }

  deleteChannel(id: string): Observable<any> {
    return this.http.delete(`${this.env.SHIM_BASE_URL}/mds/channels/${id}`);
  }

  createChannel(newValues: any): Observable<any> {
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/mds/channels`,
      newValues,
    );
  }

  convertAdConfigArray(configs: any): AdConfig {
    if (configs.length === 0) {
      return null;
    }

    return {
      ...configs[0],
      details: _.reduce(configs[0].details, (result, v, k) => {
        if (!_.has(result, 'weights')) {
          if (configs[0].adConfigurationType === AdConfigType.ChannelMerge) {
            result['weights'] = {};
          } else {
            result['weights'] = [];
          }
        }
        if (k.startsWith('mediaId')) {
          result['weights'][k.split(':')[1]] = v;
        } else {
          result[k] = v;
        }
        return result;
      }, {}),
    } as AdConfig;
  }

  getChannelAdConfigurationDetails(channelID: string): Observable<AdConfig> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/mds/channels/${channelID}/adConfigurationDetails`,
    ).pipe(
      map((configs: any) => this.convertAdConfigArray(configs)),
    );
  }

  updateChannelAdConfigurationDetails(channelID: string, newValues: any): Observable<any> {
    if (newValues.adConfigurationType === AdConfigType.ChannelMerge) {
      let mediaWeights = {};
      if (newValues.randomizeAdOrder === 'true') {
        // When we are cloning, weights come in as an object instead of an array of weights
        if (_.isArray(newValues.weights)) {
          newValues.weights.forEach((m) => {
            mediaWeights[`mediaId:${m.id}`] = m.weight;
          });
        } else {
          for (let k in newValues.weights) {
            if (newValues.weights.hasOwnProperty(k)) {
              mediaWeights[`mediaId:${k}`] = newValues.weights[k];
            }
          }
        }
      }
      newValues = [{
        adConfigurationType: newValues.adConfigurationType,
        details: {
          adPosition: newValues.adPosition,
          adChannelId: newValues.adChannelId,
          includeEnclosingAd: 'false',
          randomizeAdOrder: newValues.randomizeAdOrder,
          ...mediaWeights,
        },
      }];
    } else if (newValues.adConfigurationType !== null) {
      newValues = [{
        adConfigurationType: newValues.adConfigurationType,
        details: {
          preRollUrl: newValues.preRollUrl,
          postRollUrl: newValues.postRollUrl,
        },
      }];
    } else {
      newValues = [];
    }

    return this.http.post(
      `${this.env.SHIM_BASE_URL}/mds/channels/${channelID}/adConfigurationDetails`,
      newValues,
    ).pipe(
      map(() => this.convertAdConfigArray(newValues)),
    );
  }

  removeMediaFromChannel(channelID: string, mediaID: string): Observable<any> {
    return this.http.delete(
      `${this.env.SHIM_BASE_URL}/mds/channels/${channelID}/media/${mediaID}`,
    );
  }

  setChannelMediaList(channelID: string, newValues: any): Observable<any> {
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/mds/channels/${channelID}/media`,
      newValues,
    );
  }

  addMediaToChannel(channelID: string, mediaID: string): Observable<any> {
    return this.http.put(
      `${this.env.SHIM_BASE_URL}/mds/channels/${channelID}/media/${mediaID}`,
      mediaID,
    );
  }

  /**************** Groups ****************/
  getChannelgroupsList(f: ContentFilters): Observable<any> {
    const params = [];
    if (!!f.queryParams) {
      // State
      if (
        !!f.queryParams['state'] &&
        f.queryParams['state'].length < 5
      ) {
        params.push(`&or=${_.join(
          _.map(f.queryParams['state'], (entry: string) => `state:${entry.toLowerCase()}`),
          ';',
        )}`);
      }
      const and = [];
      // Type
      if (
        !!f.queryParams['media_type'] &&
        f.queryParams['media_type'].length === 1) {
        and.push(`media_type:${f.queryParams['media_type'][0]}`);
      }
      // Search
      if (!!f.searchText) {
        and.push(`title:${f.searchText}`);
      }

      if (and.length > 0) {
        params.push(`&and=${and.join(';')}`);
      }
    }
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/mds/channelgroups/all` +
      `?page_size=${f.pageSize}` +
      `&page_id=${f.page - 1}` +
      `&sort_by=${f.sortBy}` +
      `&sort_order=${f.sortDir.toUpperCase()}` +
      `${params.join('')}`,
    ).pipe(
      map((res) => {
        return {
          page: res['page_id'] + 1,
          size: f.pageSize,
          hasNext: res['has_next'],
          results: res['channelgroup_list'],
        };
      }),
    );
  }

  getGroup(id: string): Observable<any> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/mds/channelgroups/${id}/properties`,
    );
  }

  getGroupChannels(id: string, f: ContentFilters): Observable<any> {
    f.sortBy = 'play_order';
    f.sortDir = SortDirection.ASC;

    return this.http.get<PaginatedResponse>(
      `${this.env.SHIM_BASE_URL}/mds/channelgroups/${id}/channels` +
      `?filter_unpublished=false` +
      `&sort_by=${f.sortBy}` +
      `&sort_order=${f.sortDir.toUpperCase()}` +
      (f.pageSize !== undefined ? `&page_size=${f.pageSize}` : '') +
      (f.page !== undefined ? `&page_id=${f.page - 1}` : ''),
    ).pipe(
      map((res) => {
        return {
          page: res['page_id'] + 1,
          size: f.pageSize,
          hasNext: res['has_next'],
          results: res['channel_list'],
        };
      }),
    );
  }

  addGroupChannel(groupID: string, channelID: string): Observable<any> {
    return this.http.put(
      `${this.env.SHIM_BASE_URL}/mds/channelgroups/${groupID}/channels/${channelID}`,
      {channelgroupId: groupID, channel_id: channelID},
    );
  }
  removeGroupChannel(groupID: string, channelID: string): Observable<any> {
    return this.http.delete(
      `${this.env.SHIM_BASE_URL}/mds/channelgroups/${groupID}/channels/${channelID}`,
    );
  }

  // properties endpoint only updates title
  updateGroup(id: string, values: any): Observable<any> {
    return this.http.put(
      `${this.env.SHIM_BASE_URL}/mds/channelgroups/${id}/properties`,
      values,
    );
  }

  deleteChannelgroup(id: string): Observable<any> {
    return this.http.delete(`${this.env.SHIM_BASE_URL}/mds/channelgroups/${id}`);
  }

  createChannelGroup(title: string): Observable<any> {
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/mds/channelgroups`,
      { title },
    );
  }

  // used for bulk add, delete, and reorder of channel IDs
  updateGroupChannelsList(groupID: string, newValues: ChannelListDetails): Observable<any> {
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/mds/channelgroups/${groupID}`,
      newValues,
    );
  }

  /***************** Custom Properties ***************/
  getCustomProperties(contentType: string): Observable<CustomProperty[]> {
    return this.http.get<CustomProperty[]>(
      `${this.env.SHIM_BASE_URL}/mds/${contentType}/properties/custom_details`,
    ).pipe(
      map((res) => res['custom_property_type_details']),
    );
  }
  createCustomProperty(contentType: string, property: CustomProperty): Observable<CustomProperty> {
    // if we see new_property_name, substitute for custom_property.
    // vpws requires this field, which is actually the name
    let prop = Object.assign({}, property);
    prop['custom_property'] = property.new_property_name;
    prop.default_values = _.join(property.default_values, ',');
    return this.http.put<CustomProperty>(
      `${this.env.SHIM_BASE_URL}/mds/${contentType}/properties/custom`,
      prop,
    );
  }
  updateCustomProperty(contentType: string, id: string, property: CustomProperty): Observable<CustomProperty> {
    if (!!property.default_values) {
      property.default_values = _.join(property.default_values, ',');
    }
    return this.http.post<CustomProperty>(
      `${this.env.SHIM_BASE_URL}/mds/${contentType}/properties/custom/${id}`,
      property,
    );
  }
  deleteCustomProperty(contentType: string, id: string): Observable<any> {
    return this.http.delete(
      `${this.env.SHIM_BASE_URL}/mds/${contentType}/properties/custom/${id}`,
    );
  }

  assignCustomProperty(contentType: string, id: string, values: any): Observable<any> {
    return this.http.put(
      `${this.env.SHIM_BASE_URL}/mds/${contentType}/${id}/properties/custom`,
      values,
    );
  }

  /***************** Encoding for Media ***************/
  getMediaEncodings(id: string): Observable<any> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/mds/media/${id}/encoding` +
      `?only_published=false`,
    ).pipe(
      map((res) => res['encodings']),
    );
  }

  /*************** Suggested Tags *****************/
  getSuggestedTags(id: string): Observable<any> {
    // Will eventually be hooked up to an API that returns an array of suggested tags
    // based on the media id we provide.
    // Currently sending back mock data.
    return Observable.of(['video', 'awesome', 'coolio', 'bananas', 'wowza']).pipe(delay(500));
  }

  /********************* Publish State *********************/
  getPubishState(): Observable<any> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/mds/globalConfigurationDetails`,
    ).pipe(
      map((res) => res['defaultMediaState']),
    );
  }

  updateDefaultPublishState(state: boolean): Observable<any> {
    const newState = state ? 'Published' : 'Publishable';
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/mds/media/state/default`,
      { state: newState },
    );
  }

  /**************** Closed Captions ****************/
  saveClosedCaptions(mediaId: string, caption: File): Observable<any> {
    const formData = new FormData();
    formData.append('caption_file', caption);
    return this.http.request(new HttpRequest(
      'PUT',
      `${this.env.SHIM_BASE_URL}/mds/media/${mediaId}/captions`,
      formData,
    )).pipe(
      // Using http.request(...) emits multile events, only get the response
      filter((e) => e.type === HttpEventType.Response),
    );
  }

  deleteClosedCaption(mediaId: string): Observable<any> {
    return this.http.delete(
      `${this.env.SHIM_BASE_URL}/mds/media/${mediaId}/captions`,
    );
  }

  /**************** Player Themes ****************/
  _parseTheme(t: any): Theme {
    if (!t) {
      return null;
    }

    const { value, ...theme } = t;
    let skin: any;

    try {
      skin = JSON.parse(value);
    } catch (e) {
      // no-op
    }

    return {
      ...theme,
      skin,
    };
  }

  getThemesList(): Observable<Theme[]> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/mds/playerSkins`,
    ).pipe(
      map((res) => _.map(
        res,
        (t) => this._parseTheme(t),
      )),
    );
  }

  createTheme(theme: Partial<Theme>): Observable<any> {
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/mds/playerSkins`,
      theme,
    ).pipe(
      map((t) => this._parseTheme(t)),
    );
  }

  saveTheme(theme: Partial<Theme>): Observable<any> {
    return this.http.put(
      `${this.env.SHIM_BASE_URL}/mds/playerSkins`,
      theme,
    ).pipe(
      map((t) => this._parseTheme(t)),
    );
  }

  deleteTheme(id: string): Observable<any> {
    return this.http.delete(
      `${this.env.SHIM_BASE_URL}/mds/playerSkins/${id}`,
    );
  }
}
