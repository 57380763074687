import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { filter, map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromRoot from '../redux/reducers';
import * as fromAuth from '../redux/reducers/auth';

@Injectable()
export class PwdSetGuard implements CanActivate {

  private authState$: Observable<fromAuth.State>;

  constructor(private store: Store<fromRoot.State>) {
    this.authState$ = this.store.pipe(select(fromRoot.getAuthState));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authState$.pipe(
      filter((auth) => !auth.userLoading && !!auth.user || auth.userLoadingFailed),
      take(1),
      map((auth) => !!auth.user && !auth.user.passwordUpdateNeeded),
    );
  }
}
