import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({ selector: '[llFileDrop]' })
export class FileDropDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('llFileDropDisabled') disabled: boolean = false;
  @Output() fileHover: EventEmitter<boolean> = new EventEmitter();
  @Output() fileDrop: EventEmitter<FileList> = new EventEmitter();
  dragEnterCount = 0;

  constructor(private element: ElementRef) { }

  @HostListener('dragenter', ['$event'])
  onDragEnter(e: any): void {
    if (this.disabled) {
      return;
    }
    if (e.currentTarget !== this.element.nativeElement) {
      return;
    }
    const transfer = this.getDataTransfer(e);
    if (!this.haveFiles(transfer.types)) {
      return;
    }
    if (this.dragEnterCount === 0) {
      this.fileHover.emit(true);
    }
    this.dragEnterCount++;
    this.preventAndStop(e);
  }

  @HostListener('dragover', ['$event'])
  onDragOver(e: any): void {
    if (this.disabled) {
      return;
    }
    const transfer = this.getDataTransfer(e);
    if (!this.haveFiles(transfer.types)) {
      return;
    }
    this.preventAndStop(e);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(e: any): void {
    if (this.disabled) {
      return;
    }
    if (e.currentTarget !== this.element.nativeElement) {
      return;
    }
    this.dragEnterCount--;
    if (this.dragEnterCount === 0) {
      this.fileHover.emit(false);
    }
    this.preventAndStop(e);
  }

  @HostListener('drop', ['$event'])
  onDrop(e: any): void {
    if (this.disabled) {
      return;
    }
    const transfer = this.getDataTransfer(e);
    this.dragEnterCount = 0;
    this.fileHover.emit(false);

    if (!transfer) {
      return;
    }

    this.preventAndStop(e);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      this.fileDrop.emit(files);
    }
  }

  private getDataTransfer(event: any): DataTransfer {
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
  }

  private preventAndStop(e: any): void {
    e.preventDefault();
    e.stopPropagation();
  }

  private haveFiles(types: any): boolean {
    if (!types) {
      return false;
    }

    if (types.indexOf) {
      return types.indexOf('Files') !== -1;
    }

    if (types.contains) {
      return types.contains('Files');
    }

    return false;
  }
}
