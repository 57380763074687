import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DynamicModule } from 'ng-dynamic-component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LLCommonModule } from 'control-ui-common';
import { HighchartsChartModule } from 'highcharts-angular';

import { LoadableBlockComponent } from './progress/loadable-block.component';
import { FooterComponent } from '../shared/footer/footer.component';
import { ConfirmPageChangeComponent } from '../shared/modals/confirm-page-change.component';
import { FooterModalComponent } from './modals/footer-modal.component';
import { HeaderModalComponent } from './modals/header-modal.component';
import { ConfirmDialogComponent } from './modals/confirm-dialog.component';
import { PlayerComponent } from './video/video-player.component';
import { InlineButtonComponent } from './buttons/inline-button.component';
import { FileDropDirective } from './utils/file-drop.directive';
import { DurationDirective } from './utils/duration.directive';
import { ContenteditableChangeDirective } from './utils/contenteditable-change.directive';
import { TrimInputDirective } from './utils/trim-input.directive';
import { PreviewImageFileDirective } from './utils/preview-image-file.directive';
import { RemoveChannelMediaModalComponent } from './modals/remove-channel-media-modal.component';

// BUTTONS
import { ButtonGroupComponent } from './buttons/button-group.component';
// CHARTS
import { ReportsChartComponent } from './charts/reports-chart/reports-chart.component';
import { ReportsPieChartComponent } from './charts/reports-chart/reports-pie-chart/reports-pie-chart.component';
// DROPDOWNS
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { OrgSelectorComponent } from './dropdowns/org-selector.component';
import { UserMenuDropdownComponent } from './dropdowns/user-menu-dropdown.component';
// KEY VALUE PAIRS
import { KeyValuePairsComponent } from './key-value-pairs/key-value-pairs.component';
// LISTS
import { ListInputComponent } from './lists/list-input.component';
import { ListItemComponent } from './lists/list-item.component';
// NAVIGATION
import { LeftNavComponent } from './navigation/left-nav.component';
import { TopNavComponent } from './navigation/top-nav.component';
// TABLES
import { SortableTableComponent } from './table/sortable-table.component';
// UTILS
import { CapitalizePipe } from './utils/capitalize.pipe';
import { FileSizePipe } from './utils/file-size.pipe';
import { Base64UriToFileConverter } from './utils/base-64-uri-to-file';
import { StateNamePipe } from './utils/state.pipe';
import { WindowRef } from './utils/window-ref';
// SERVICES
import { AnalyticsService } from './services/analytics.service';
import { ScrollToService } from './services/scroll-to.service';
import { ContentActionManagementService } from './services/content-action-management.service';
import { FeatureSetAuthorizationService } from './services/feature-set-authorization.service';
import { MediaSearchService } from './services/media-search.service';
import { VpwsSigningService } from './services/vpws-signing.service';
import { MediaDataService } from './services/media-data.service';
import { NotificationService } from './services/notification.service';
import { TranscodeMgmtService } from './services/transcode-mgmt.service';
import { CuepointManagementService } from './services/cuepoint-mgmt.service';
import { TextListItemComponent } from '../content/media/text-list-item.component';
import { PlayerNonceService } from './services/player-nonce.service';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    DynamicModule.withComponents([ListItemComponent]),
    NgbModule,
    LLCommonModule,
    HighchartsChartModule,
  ],
  declarations: [
    // BUTTONS
    ButtonGroupComponent,
    // CHARTS
    ReportsChartComponent,
    ReportsPieChartComponent,
    // DROPDOWNS
    LanguageSelectorComponent,
    OrgSelectorComponent,
    UserMenuDropdownComponent,
    // KEY VALUE PAIRS
    KeyValuePairsComponent,
    // LISTS
    InlineButtonComponent,
    ListInputComponent,
    ListItemComponent,
    // MODALS
    ConfirmPageChangeComponent,
    FooterModalComponent,
    HeaderModalComponent,
    ConfirmDialogComponent,
    PlayerComponent,
    LoadableBlockComponent,
    OrgSelectorComponent,
    // NAVIGATION
    FooterComponent,
    LeftNavComponent,
    TopNavComponent,
    // TABLES
    SortableTableComponent,
    // UTILS
    CapitalizePipe,
    DurationDirective,
    FileDropDirective,
    ContenteditableChangeDirective,
    FileSizePipe,
    StateNamePipe,
    TrimInputDirective,
    PreviewImageFileDirective,
    RemoveChannelMediaModalComponent,
    TextListItemComponent,
  ],
  providers: [
    CapitalizePipe,
    FileSizePipe,
    Base64UriToFileConverter,
    StateNamePipe,
    WindowRef,
    AnalyticsService,
    ScrollToService,
    ContentActionManagementService,
    FeatureSetAuthorizationService,
    MediaSearchService,
    MediaDataService,
    VpwsSigningService,
    NotificationService,
    TranscodeMgmtService,
    CuepointManagementService,
    PlayerNonceService,
  ],
  exports: [
    TranslateModule,
    // BUTTONS
    ButtonGroupComponent,
    // CHARTS
    ReportsChartComponent,
    ReportsPieChartComponent,
    // DROPDOWNS
    LanguageSelectorComponent,
    OrgSelectorComponent,
    // KEY VALUE PAIRS
    InlineButtonComponent,
    KeyValuePairsComponent,
    // LISTS
    ListInputComponent,
    // MODALS
    ConfirmPageChangeComponent,
    FooterModalComponent,
    HeaderModalComponent,
    ConfirmDialogComponent,
    PlayerComponent,
    LoadableBlockComponent,
    // NAVIGATION
    FooterComponent,
    LeftNavComponent,
    TopNavComponent,
    // TABLES
    SortableTableComponent,
    // UTILS
    CapitalizePipe,
    DurationDirective,
    FileDropDirective,
    FileSizePipe,
    StateNamePipe,
    ContenteditableChangeDirective,
    TrimInputDirective,
    PreviewImageFileDirective,
    RemoveChannelMediaModalComponent,
    TextListItemComponent,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ConfirmPageChangeComponent,
    RemoveChannelMediaModalComponent,
    TextListItemComponent,
  ],
})
export class SharedModule {
}
