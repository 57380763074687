import { Action } from '@ngrx/store';

import { Theme } from '../../models/theme';

export const LOAD_THEMES = '[Theme] Load Themes';
export const LOAD_THEMES_SUCCEEDED = '[Theme] Load Themes Succeeded';
export const LOAD_THEMES_FAILED = '[Theme] Load Themes Failed';
export const SELECT_THEME = '[Theme] Select Theme';
export const SAVE_THEME = '[Theme] Save Theme';
export const SAVE_THEME_SUCCEEDED = '[Theme] Save Theme Succeeded';
export const SAVE_THEME_FAILED = '[Theme] Save Theme Failed';
export const UPDATE_CURRENT_THEME_PROP = '[Theme] Update Current Theme Prop';
export const UPDATE_CURRENT_THEME_NAME = '[Theme] Update Current Theme Name';
export const DELETE_THEME = '[Theme] Delete Theme';
export const DELETE_THEME_SUCCEEDED = '[Theme] Delete Theme Succeeded';
export const DELETE_THEME_FAILED = '[Theme] Delete Theme Failed';

export class LoadThemes implements Action {
  readonly type = LOAD_THEMES;
}

export class LoadThemesSucceeded implements Action {
  readonly type = LOAD_THEMES_SUCCEEDED;

  constructor(public themes: Theme[]) {}
}

export class LoadThemesFailed implements Action {
  readonly type = LOAD_THEMES_FAILED;
}

export class SelectTheme implements Action {
  readonly type = SELECT_THEME;

  constructor(public theme: Theme) {}
}

export class UpdateCurrentThemeName implements Action {
  readonly type = UPDATE_CURRENT_THEME_NAME;

  constructor(public name: string) {}
}

export class UpdateCurrentThemeProp implements Action {
  readonly type = UPDATE_CURRENT_THEME_PROP;

  constructor(
    public propName: string,
    public propValue: any,
  ) {}
}

export class SaveTheme implements Action {
  readonly type = SAVE_THEME;

  constructor(public theme: Partial<Theme>) {}
}

export class SaveThemeSucceeded implements Action {
  readonly type = SAVE_THEME_SUCCEEDED;

  constructor(public theme: Theme) {}
}

export class SaveThemeFailed implements Action {
  readonly type = SAVE_THEME_FAILED;
}

export class DeleteTheme implements Action {
  readonly type = DELETE_THEME;

  constructor(public id: string) {}
}

export class DeleteThemeSucceeded implements Action {
  readonly type = DELETE_THEME_SUCCEEDED;
}

export class DeleteThemeFailed implements Action {
  readonly type = DELETE_THEME_FAILED;
}

export type Actions = (
  LoadThemes | LoadThemesSucceeded | LoadThemesFailed |
  SelectTheme | UpdateCurrentThemeProp | UpdateCurrentThemeName |
  SaveTheme | SaveThemeSucceeded | SaveThemeFailed |
  DeleteTheme | DeleteThemeSucceeded | DeleteThemeFailed
);
