import * as _ from 'lodash';
import { SortDirection, SortParams } from 'control-ui-common';

import * as encoding from '../../actions/settings/encoding';
import { EncodingProfile } from '../../../models/encoding-profile';

export interface State {
  activeEncodingProfileId: string;
  currProfile: EncodingProfile | null;
  customEncodingProfiles: EncodingProfile[];
  filteredCustomEncodingProfiles: EncodingProfile[];
  filteredUniversalEncodingProfiles: EncodingProfile[];
  encodingProfilesLoading: boolean;
  isDeleting: boolean;
  isSaving: boolean;
  queryText: string;
  saveFailed: boolean;
  universalEncodingProfiles: EncodingProfile[];
  sortParams: {
    sortBy: string,
    sortDir: SortDirection,
  };
}

export const initialState: State = {
  activeEncodingProfileId: null,
  currProfile: null,
  customEncodingProfiles: [],
  filteredCustomEncodingProfiles: [],
  filteredUniversalEncodingProfiles: [],
  encodingProfilesLoading: false,
  isDeleting: false,
  isSaving: false,
  queryText: '',
  saveFailed: false,
  universalEncodingProfiles: [],
  sortParams: {
    sortBy: 'title',
    sortDir: SortDirection.ASC,
  },
};

const filterProfiles = (encodingArr: EncodingProfile[], q: string): EncodingProfile[] => {
  return (
    q.length > 0 ?
    _.filter(encodingArr, (ep) => {
      return ep.title.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) >= 0;
    }) :
    [ ...encodingArr ]
  );
};

const sortEncodingProfiles = (propArray: EncodingProfile[], p: SortParams): EncodingProfile[] => {
  return _.orderBy(
    _.cloneDeep(propArray),
    [(cp) => _.toString(cp[p.sortBy]).toLowerCase()],
    [p.sortDir],
  );
};

export function reducer(
  state = initialState,
  action: encoding.Actions,
): State {
  switch (action.type) {
    case encoding.LOAD_ENCODING_PROFILES:
      return {
        ...state,
        currProfile: null,
        customEncodingProfiles: [],
        encodingProfilesLoading: true,
        queryText: '',
        universalEncodingProfiles: [],
      };
    case encoding.LOAD_ENCODING_PROFILES_SUCCEEDED:
      let newState: State = {
        ...state,
        encodingProfilesLoading: false,
        ...action.props,
      };

      newState.filteredUniversalEncodingProfiles = sortEncodingProfiles(
        newState.universalEncodingProfiles,
        state.sortParams,
      );

      newState.filteredCustomEncodingProfiles = sortEncodingProfiles(
        newState.customEncodingProfiles,
        state.sortParams,
      );

      return newState;
    case encoding.LOAD_ENCODING_PROFILES_FAILED:
      return {
        ...state,
        encodingProfilesLoading: false,
      };
    case encoding.FILTER_ENCODING_PROFILES:
      return {
        ...state,
        filteredUniversalEncodingProfiles: sortEncodingProfiles(
          filterProfiles(
            state.universalEncodingProfiles,
            action.filter,
          ),
          state.sortParams,
        ),
        filteredCustomEncodingProfiles: sortEncodingProfiles(
          filterProfiles(
            state.customEncodingProfiles,
            action.filter,
          ),
          state.sortParams,
        ),
        queryText: action.filter,
      };
    case encoding.CREATE_ENCODING_PROFILE_SUCCEEDED:
      return {
        ...state,
        currProfile: null,
        customEncodingProfiles: sortEncodingProfiles(
          [...state.customEncodingProfiles, action.profile],
          state.sortParams,
        ),
        filteredCustomEncodingProfiles: sortEncodingProfiles(
          filterProfiles(
            [...state.customEncodingProfiles, action.profile],
            state.queryText,
          ),
          state.sortParams,
        ),
        isSaving: false,
      };
    case encoding.DELETE_ENCODING_PROFILE:
      return {
        ...state,
        isDeleting: true,
      };
    case encoding.DELETE_ENCODING_PROFILE_FAILED:
      return {
        ...state,
        isDeleting: false,
      };
    case encoding.DELETE_ENCODING_PROFILE_SUCCEEDED:
      const profile = !!state.currProfile && action.profileID === state.currProfile.id ? null : state.currProfile;
      return {
        ...state,
        currProfile: profile,
        customEncodingProfiles: _.filter(state.customEncodingProfiles, (p) => p.id !== action.profileID),
        filteredCustomEncodingProfiles: _.filter(state.customEncodingProfiles, (p) => p.id !== action.profileID),
        isDeleting: false,
      };
    case encoding.SET_CURR_PROFILE:
      return {
        ...state,
        currProfile: _.cloneDeep(action.profile),
      };
    case encoding.CREATE_ENCODING_PROFILE:
    case encoding.UPDATE_ENCODING_PROFILE:
      return {
        ...state,
        isSaving: true,
        saveFailed: false,
      };
    case encoding.UPDATE_ENCODING_PROFILE_SUCCEEDED:
      const i = _.findIndex(state.customEncodingProfiles, { id: action.profile.id });
      state.customEncodingProfiles[i] = _.cloneDeep(action.profile);
      return {
        ...state,
        currProfile: null,
        customEncodingProfiles: sortEncodingProfiles(
          state.customEncodingProfiles,
          state.sortParams,
        ),
        filteredCustomEncodingProfiles: sortEncodingProfiles(
          filterProfiles(
            state.customEncodingProfiles,
            state.queryText,
          ),
          state.sortParams,
        ),
        isSaving: false,
        saveFailed: false,
      };
    case encoding.CREATE_ENCODING_PROFILE_FAILED:
    case encoding.UPDATE_ENCODING_PROFILE_FAILED:
      return {
        ...state,
        isSaving: false,
        saveFailed: true,
      };
    case encoding.LOAD_ACTIVE_ENCODING_PROFILE_SUCCEEDED:
    case encoding.SET_ACTIVE_ENCODNG_PROFILE_SUCCEEDED:
      return {
        ...state,
        activeEncodingProfileId: action.activeProfileId,
      };
    default:
      return state;
  }
}

export const getEncoding = (state: State) => state;
