import { Component, Renderer, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate} from '@angular/animations';
import { Observable } from 'rxjs/Observable';
import { Store, select } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

import * as fromRoot from '../shared/redux/reducers';
import * as fromAuth from '../shared/redux/reducers/auth';
import * as fromContent from '../shared/redux/reducers/content';
import * as Auth from '../shared/redux/actions/auth';
import * as Bulk from '../shared/redux/actions/bulk';
import * as Content from '../shared/redux/actions/content';
import { User } from '../shared/models/user';
import { NavItem } from '../shared/navigation/nav-item';
import { LeftNavComponent } from '../shared/navigation/left-nav.component';
import { TopNavComponent } from '../shared/navigation/top-nav.component';
import { FooterComponent } from '../shared/footer/footer.component';
import { SlideInOutAnimation } from '../shared/animations/slide-in-out.animation';

@Component({
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  animations: [
    SlideInOutAnimation,
  ],
})
export class AppComponent {
  fileHover: boolean = false;
  menuState = 'in';
  navItems: NavItem[] = [
    { title: 'amedia.content', url: '/app/content', faClass: 'fas fa-play' },
    { title: 'amedia.themes', url: '/app/themes', faClass: 'fas fa-cubes' },
    { title: 'amedia.reports', url: '/app/reports', faClass: 'far fa-chart-bar' },
    { title: 'amedia.settings', url: '/app/settings', faClass: 'fas fa-cog' },
    { title: 'amedia.support', url: '/app/support', faClass: 'far fa-life-ring' },
  ];
  auth$: Observable<fromAuth.State>;

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
    this.auth$ = this.store.pipe(select(fromRoot.getAuthState));

    this.toastr.overlayContainer = undefined;
  }

  onNavToggle(): void {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  showLogin(authState: fromAuth.State): boolean {
    return (
      authState.loginAttempted ||
      (authState.userLoadingFailed && !authState.userLoading)
    );
  }

  routeSupportsMediaUpload(auth: fromAuth.State): boolean {
    return this.router.url === '/app/content/media' && !_.get(auth.featureSet, 'uploadDisabled', true);
  }

  onMediaUpload(fileList: FileList): void {
    const actions = [];
    let file: File;

    for (let index = 0; index < fileList.length; index++) {
      file = fileList.item(index);
      if (
        file.type.startsWith('audio/') ||
        file.type.startsWith('video/') ||
        file.type.startsWith('application/mxf')
      ) {
        actions.push(new Content.CreateMedia(fileList.item(index)));
      }
    }

    if (actions.length) {
      this.store.dispatch(new Bulk.StartBulkAction(actions));
    }
  }
}
