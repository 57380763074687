import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { concat, delay } from 'rxjs/operators';
import { Action } from '@ngrx/store';

import * as fromBulk from '../reducers/bulk';
import * as fromRoot from '../reducers';

export const START_BULK_ACTION = '[Bulk] Start Bulk Action';
export const BULK_SUB_ACTION_UPDATE = '[Bulk] Bulk Sub Action Update';
export const BULK_SUB_ACTION_COMPLETE = '[Bulk] Bulk Sub Action Complete';
export const BULK_CANCEL_SUB_ACTION = '[Bulk] Bulk Cancel Sub Action';
export const BULK_SUB_ACTION_FAILED = '[Bulk] Bulk Sub Action Failed';
export const BULK_ACTION_COMPLETE = '[Bulk] Bulk Action Complete';

export class StartBulkAction implements Action {
  static ACTION_ID_CTR = 1;
  static getId(): number { return StartBulkAction.ACTION_ID_CTR++; }

  readonly id = StartBulkAction.getId();
  readonly type = START_BULK_ACTION;

  constructor(
    public actions: BulkableAction[],
    public doneAction: Action = null,
  ) { }
}

export class BulkSubActionUpate implements Action {
  readonly type = BULK_SUB_ACTION_UPDATE;

  constructor(
    public subAction: BulkableAction,
    public progress: number,
  ) { }
}

export class BulkSubActionComplete implements Action {
  readonly type = BULK_SUB_ACTION_COMPLETE;

  constructor(
    public bulkActionId: number,
    public subAction: BulkableAction,
  ) { }
}

export class BulkCancelSubAction implements Action {
  readonly type = BULK_CANCEL_SUB_ACTION;

  constructor(public subAction: BulkableAction) { }
}

export class BulkSubActionFailed implements Action {
  readonly type = BULK_SUB_ACTION_FAILED;

  constructor(
    public bulkActionId: number,
    public subAction: BulkableAction,
  ) { }
}

export class BulkActionComplete implements Action {
  readonly type = BULK_ACTION_COMPLETE;

  constructor(public bulkActionId: number) { }
}

export class BulkableAction implements Action {
  bulkAction: StartBulkAction = null;
  type = '';
  progress = 0;
  done = false;
  cancelled = false;
  failed = false;
  cancelCallbacks: Array<() => void> = [];

  registerCancelCallback(fn: () => void): void {
    this.cancelCallbacks.push(fn);
  }

  progressUpdate(progress: number): Observable<Action> {
    return of(new BulkSubActionUpate(this, progress));
  }

  cancel(): void {
    this.cancelled = true;
    this.cancelCallbacks.forEach((fn) => fn());
  }

  finish(responseAction: Observable<Action>): Observable<Action> {
    if (this.bulkAction !== null) {
      return responseAction.pipe(
        concat(of(new BulkSubActionComplete(this.bulkAction.id, this)).pipe(delay(1000))),
      );
    }
    return responseAction;
  }

  finishWithError(responseAction: Observable<Action>, error: any): Observable<Action> {
    if (this.bulkAction !== null) {
      return this.finish(responseAction.pipe(
        concat(of(new BulkSubActionFailed(this.bulkAction.id, this))),
      ));
    }
    return responseAction;
  }
}

export type Actions = (
  StartBulkAction | BulkSubActionComplete | BulkSubActionFailed |
  BulkCancelSubAction | BulkSubActionUpate | BulkActionComplete
);
