import { AccountMgmtService } from './../../../services/account-mgmt.service';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { Scheduler } from 'rxjs/Scheduler';
import { ToastrService } from 'ngx-toastr';
import { empty } from 'rxjs/observable/empty';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import * as AuthActions from '../../actions/auth';
import * as DeveloperToolsActions from '../../actions/settings/developer-tools';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';
export const LOAD_DEBOUNCE = new InjectionToken<number>('Load Debounce');
export const LOAD_SCHEDULER = new InjectionToken<Scheduler>('Load Scheduler');

@Injectable()
export class DeveloperToolsEffects {
  // Listen for the 'ADD_CALLBACK_LISTENER' action
  @Effect()
  addCallbackListenerAction$: Observable<Action> = this.actions$
    .pipe(
      ofType(DeveloperToolsActions.ADD_CALLBACK_LISTENER),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: DeveloperToolsActions.AddCallbackListener) => {
        return this.notification.addCallbackListener(action.listener).pipe(
          map((listener) => {
            const verbPast: string = 'amedia.created';
            const itemType: string = 'amedia.settings.callback';
            this.toastr.success(
              this.translate.instant(
                'amedia.successMessage',
                {
                  verbPast: this.translate.instant(verbPast),
                  itemType: _.lowerCase(this.translate.instant(itemType)),
                  item: listener.name,
                },
              ),
              this.translate.instant('amedia.success'),
            );
            return new DeveloperToolsActions.AddCallbackListenerSucceeded(listener);
          }),
          catchError((error) => of(new DeveloperToolsActions.AddCallbackListenerFailed())),
        );
      }),
    );

  // Listen for the 'ADD_CALLBACK_LISTENER_FAILED' action
  @Effect()
  callbackNotAdded$: Observable<Action> = this.actions$
    .pipe(
      ofType(DeveloperToolsActions.ADD_CALLBACK_LISTENER_FAILED),
      // .debounceTime(this.debounce, this.scheduler || async)
      switchMap((action: DeveloperToolsActions.AddCallbackListenerFailed) => {
        // TODO: Update this when better exception handling is added to SHIMs
        this.toastr.error(
          this.translate.instant(
            'amedia.errors.failedTo',
            {
              verb: _.lowerCase(this.translate.instant('common.button.save')),
              item: _.lowerCase(this.translate.instant('amedia.settings.callback')),
            },
          ),
          this.translate.instant('common.error.label'),
        );
        return empty();
      }),
    );

  // Listen for the 'DELETE_CALLBACK_LISTENER' action
  @Effect()
  deleteCallbackListenerAction$: Observable<Action> = this.actions$
    .pipe(
      ofType(DeveloperToolsActions.DELETE_CALLBACK_LISTENER),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: DeveloperToolsActions.DeleteCallbackListener) => {
        return this.notification.deleteCallbackListener(action.id).pipe(
          map(() => new DeveloperToolsActions.DeleteCallbackListenerSucceeded(action.id)),
          catchError((error) => of(new DeveloperToolsActions.DeleteCallbackListenerFailed())),
        );
      }),
    );

  // Listen for the 'DELETE_CALLBACK_LISTENER_FAILED' action
  @Effect()
  callbackNotDeleted$: Observable<Action> = this.actions$
    .pipe(
      ofType(DeveloperToolsActions.DELETE_CALLBACK_LISTENER_FAILED),
      // .debounceTime(this.debounce, this.scheduler || async)
      switchMap((action: DeveloperToolsActions.DeleteCallbackListenerFailed) => {
        this.toastr.error(
          this.translate.instant(
            'amedia.errors.failedTo',
            {
              verb: this.translate.instant('common.delete'),
              item: _.lowerCase(this.translate.instant('amedia.settings.callback')),
            },
          ),
          this.translate.instant('common.error.label'),
        );
        return empty();
      }),
    );

    // Listen for the 'LOAD_CALLBACK_LISTENERS' action
    @Effect()
    loadCallbackListenersAction$: Observable<Action> = this.actions$
    .pipe(
      ofType(DeveloperToolsActions.LOAD_CALLBACK_LISTENERS),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: DeveloperToolsActions.LoadCallbackListeners) => {
        return this.notification.getCallbackListeners().pipe(
          map((listeners) => new DeveloperToolsActions.LoadCallbackListenersSucceeded(listeners)),
          catchError((error) => of(new DeveloperToolsActions.LoadCallbackListenersFailed())),
        );
      }),
    );

    // Listen for the 'UPDATE_CALLBACK_LISTENER' action
    @Effect()
    updateCallbackListenerAction$: Observable<Action> = this.actions$
      .pipe(
        ofType(DeveloperToolsActions.UPDATE_CALLBACK_LISTENER),
        // .debounceTime(this.debounce, this.scheduler || async)
        mergeMap((action: DeveloperToolsActions.UpdateCallbackListener) => {
          return this.notification.updateCallbackListener(action.listener).pipe(
            map((listener) => {
              const verbPast: string = 'amedia.updated';
              const itemType: string = 'amedia.settings.callback';
              this.toastr.success(
                this.translate.instant(
                  'amedia.successMessage',
                  {
                    verbPast: this.translate.instant(verbPast),
                    itemType: _.lowerCase(this.translate.instant(itemType)),
                    item: listener.name,
                  },
                ),
                this.translate.instant('amedia.success'),
              );
              return new DeveloperToolsActions.UpdateCallbackListenerSucceeded(listener);
            }),
            catchError((error) => of(new DeveloperToolsActions.UpdateCallbackListenerFailed())),
          );
        }),
      );

  @Effect()
  rotateApiKeys$: Observable<Action> = this.actions$
    .pipe(
      ofType(DeveloperToolsActions.ROTATE_API_KEYS),
      mergeMap((action: DeveloperToolsActions.RotateApiKeys) => {
        this.ams.rotateApiKeys().subscribe(
          () => {
            this.router.navigate(['/auth/logout']);
          });
        return empty();
      }),
    );

  constructor(
    private ams: AccountMgmtService,
    private actions$: Actions,
    private notification: NotificationService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    @Optional() @Inject(LOAD_DEBOUNCE) private debounce: number = 300,
    /**
     * You inject an optional Scheduler that will be undefined
     * in normal application usage, but its injected here so that you can mock out
     * during testing using the RxJS TestScheduler for simulating passages of time.
     */
    @Optional() @Inject(LOAD_SCHEDULER) private scheduler: Scheduler,
  ) { }
}
