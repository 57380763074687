import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { filter, map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as Auth from '../redux/actions/auth';
import * as fromRoot from '../redux/reducers';
import * as fromAuth from '../redux/reducers/auth';
import { User } from '../models/user';
import { WindowRef } from '../utils/window-ref';

@Injectable()
export class AuthGuard implements CanActivate {

  private authState$: Observable<fromAuth.State>;

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
  ) {
    this.authState$ = this.store.pipe(select(fromRoot.getAuthState));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot,
  ): Observable<boolean> {
    // Get the current value to find out of if we need to fetch the user
    this.authState$.pipe(take(1)).subscribe((auth) => {
      if (!auth.user && !auth.userLoadingFailed) {
        this.store.dispatch(new Auth.GetUser(routerState.url || '/app/content/media'));
      }
    });

    return this.authState$.pipe(
      filter((auth) => !auth.userLoading && !!auth.user || auth.userLoadingFailed),
      take(1),
      map((auth) => !!auth.user),
    );
  }
}
