import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { filter, first, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as SHA1 from 'crypto-js/sha1';
import * as Base64 from 'crypto-js/enc-base64';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

import * as fromRoot from '../../shared/redux/reducers';
import * as fromAuth from '../../shared/redux/reducers/auth';
import * as AuthActions from '../../shared/redux/actions/auth';
import { PasswordPolicy } from '../../shared/models/password-policy';
import { BaseAuthComponent } from '../base-auth.component';
import { minLengthPwdValidator, restrictivePwdValidator } from '../../shared/utils/pwd-validation';

export type ShowFieldErrorFnType = (control: FormControl) => boolean;

@Component({
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  form: FormGroup;
  auth$: Observable<fromAuth.State>;
  pwdPolicies: {[policy: string]: string[]} = {
    [PasswordPolicy.MinLengthOnly]: [
      'amedia.settings.profile.pwdRule.min8',
    ],
    [PasswordPolicy.MostRestrictive]: [
      'amedia.settings.profile.pwdRule.min8',
      'amedia.settings.profile.pwdRule.oneUpper',
      'amedia.settings.profile.pwdRule.oneLower',
      'amedia.settings.profile.pwdRule.oneNumber',
      'amedia.settings.profile.pwdRule.oneSymbol',
    ],
  };
  passwordErrorLabels: any = {
    pwdMatch: this.translate.instant('common.directives.errorMessages.passwordsDoNotMatch'),
    minLength: this.translate.instant('amedia.settings.profile.pwdRule.min8'),
    oneUpper: this.translate.instant('amedia.settings.profile.pwdRule.oneUpper'),
    oneLower: this.translate.instant('amedia.settings.profile.pwdRule.oneLower'),
    oneNumber: this.translate.instant('amedia.settings.profile.pwdRule.oneNumber'),
    oneSymbol: this.translate.instant('amedia.settings.profile.pwdRule.oneSymbol'),
  };

  constructor(
    public base: BaseAuthComponent,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.auth$ = store.pipe(select(fromRoot.getAuthState));
    this.base.authTitle = 'amedia.changePwd.title';
    this.form = fb.group({
      newSha1DigestedPassword: '',
      confirmPassword: ['', this.pwdMatchValidator],
    });

    this.store.dispatch(new AuthActions.LoadOrg());
    this.auth$.pipe(
      filter((authState) => !authState.organizationLoading),
      take(1),
    ).subscribe((authState) => {
      const pwdValidators: ValidatorFn[] = [ Validators.required ];

      switch (authState.organization.passwordPolicy) {
      case PasswordPolicy.MinLengthOnly:
        pwdValidators.push(minLengthPwdValidator);
        break;
      case PasswordPolicy.MostRestrictive:
        pwdValidators.push(restrictivePwdValidator);
        break;
      }

      this.form.get('newSha1DigestedPassword').setValidators(pwdValidators);
      this.form.get('newSha1DigestedPassword').updateValueAndValidity();
    });
  }

  ngOnInit(): void {
    this.toastr.overlayContainer = this.toastContainer;
  }

  pwdMatchValidator: ValidatorFn = (c: FormControl): ValidationErrors => {
    if (
      !!this.form &&
      c.value !== this.form.value.newSha1DigestedPassword
    ) {
      return { pwdMatch: { valid: false } };
    }
    return null;
  }

  get confirmPwdErrorLabels(): any {
    return {
      pwdMatch: this.translate.instant('common.directives.errorMessages.passwordsDoNotMatch'),
    };
  }

  get confirmPasswordDisabled(): boolean {
    const newPwd = this.form.value.newSha1DigestedPassword;

    return !newPwd || newPwd.length === 0;
  }

  showErrors(): ShowFieldErrorFnType {
    return (email) => email.dirty && email.invalid;
  }

  onSubmit() {
    this.form.get('newSha1DigestedPassword').markAsDirty();
    this.form.get('confirmPassword').markAsDirty();

    if (this.form.valid) {
      this.auth$.pipe(take(1)).subscribe((authState) => {
        this.store.dispatch(new AuthActions.SaveUser({
          ...authState.user,
          passwordUpdateNeeded: false,
          newSha1DigestedPassword: Base64.stringify(SHA1(this.form.value.confirmPassword)),
        }));
      });

      this.auth$.pipe(
        first((p) => !p.userLoading),
      ).subscribe((authState: fromAuth.State) => {
        if (!!authState.userSavingErrors && authState.userSavingErrors.fields) {
          // Add a 'custom' error label and manually set the control errors
          _.forEach(authState.userSavingErrors.fields, (errorText, field) => {
            this.passwordErrorLabels['custom'] = errorText;
            this.form.get(field).setErrors({ custom: true });
          });
          this.base.onShake();
        }
      });
    }
  }
}
