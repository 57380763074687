import { Action, Store } from '@ngrx/store';
import { SortParams } from 'control-ui-common';

import { CustomProperty } from '../../../models/custom-property';

export const LOAD_CUSTOM_PROPERTIES = '[Custom Property] Load Custom Properties';
export const LOAD_CUSTOM_PROPERTIES_SUCCEEDED = '[Custom Property] Load Custom Properties Succeeded';
export const LOAD_CUSTOM_PROPERTIES_FAILED = '[Custom Property] Load Custom Properties Failed';
export const SELECT_CURR_CUSTOM_PROP = '[Custom Property] Select Current Custom Property';
export const CHANGE_CUSTOM_PROP_SORT = '[Custom Property] Change Sorting Parameters';
export const CREATE_CUSTOM_PROP = '[Custom Property] Create New Custom Property';
export const CREATE_CUSTOM_PROP_SUCCEEDED = '[Custom Property] Create New Custom Property Succeeded';
export const SAVE_CUSTOM_PROP_FAILED = '[Custom Property] Save Custom Property Failed';
export const UPDATE_CUSTOM_PROP = '[Custom Property] Update Custom Property';
export const UPDATE_CUSTOM_PROP_SUCCEEDED = '[Custom Property] Update Custom Property Succeeded';
export const DELETE_CUSTOM_PROP = '[Custom Property] Delete Custom Property';
export const DELETE_CUSTOM_PROP_SUCCEEDED = '[Custom Property] Delete Custom Property Succeeded';
export const DELETE_CUSTOM_PROP_FAILED = '[Custom Property] Delete Custom Property Failed';

export class LoadCustomProps implements Action {
  readonly type = LOAD_CUSTOM_PROPERTIES;

  constructor(public contentType: string) {}
}

export class LoadCustomPropsSucceeded implements Action {
  readonly type = LOAD_CUSTOM_PROPERTIES_SUCCEEDED;

  constructor(
    public contentType: string,
    public props: CustomProperty[],
  ) {}
}

export class LoadCustomPropsFailed implements Action {
  readonly type = LOAD_CUSTOM_PROPERTIES_FAILED;

  constructor(public contentType: string) {}
}

export class SelectCurrCustomProp implements Action {
  readonly type = SELECT_CURR_CUSTOM_PROP;

  constructor(public property: CustomProperty) {}
}

export class ChangePropertySort implements Action {
  readonly type = CHANGE_CUSTOM_PROP_SORT;

  constructor(
    public contentType: string,
    public sortParams: SortParams,
  ) {}
}

export class CreateCustomProp implements Action {
  readonly type = CREATE_CUSTOM_PROP;

  constructor(
    public contentType: string,
    public property: CustomProperty,
  ) {}
}

export class CreateCustomPropSucceeded implements Action {
  readonly type = CREATE_CUSTOM_PROP_SUCCEEDED;

  constructor(
    public contentType: string,
    public property: CustomProperty,
  ) {}
}

export class SaveCustomPropFailed implements Action {
  readonly type = SAVE_CUSTOM_PROP_FAILED;

  constructor(
    public contentType: string,
    public property: CustomProperty,
    public error: any,
  ) {}
}

export class UpdateCustomProp implements Action {
  readonly type = UPDATE_CUSTOM_PROP;

  constructor(
    public id: string,
    public contentType: string,
    public property: CustomProperty,
  ) {}
}

export class UpdateCustomPropSucceeded implements Action {
  readonly type = UPDATE_CUSTOM_PROP_SUCCEEDED;

  constructor(
    public contentType: string,
    public property: CustomProperty,
  ) {}
}

export class DeleteCustomProp implements Action {
  readonly type = DELETE_CUSTOM_PROP;

  constructor(
    public contentType: string,
    public id: string,
  ) {}
}

export class DeleteCustomPropSucceeded implements Action {
  readonly type = DELETE_CUSTOM_PROP_SUCCEEDED;

  constructor(
    public contentType: string,
    public id: string,
  ) {}
}

export class DeleteCustomPropFailed implements Action {
  readonly type = DELETE_CUSTOM_PROP_FAILED;

  constructor(
    public contentType: string,
    public error: any,
  ) {}
}

export type Actions = (
  LoadCustomProps | LoadCustomPropsSucceeded | LoadCustomPropsFailed |
  SelectCurrCustomProp | ChangePropertySort |
  CreateCustomProp | CreateCustomPropSucceeded | SaveCustomPropFailed |
  UpdateCustomProp | UpdateCustomPropSucceeded |
  DeleteCustomProp | DeleteCustomPropSucceeded | DeleteCustomPropFailed
);
