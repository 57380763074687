import { EnvConfigurationService, Configuration } from './../services/environment-configuration.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { tap, take } from 'rxjs/operators';

import * as fromRoot from '../redux/reducers';
import * as Auth from '../redux/actions/auth';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  env: Configuration;

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private envConfigService: EnvConfigurationService,
  ) {
    this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = request.headers;

    headers = headers.set('Cache-Control', 'no-cache');
    headers = headers.set('Pragma', 'no-cache');

    if (
      !!request.body &&
      !(request.body instanceof FormData) &&
      !headers.get('Content-Type')
    ) {
      headers = headers.set('Content-Type', 'application/json;charset=utf-8');
    }

    const vpwsUrl: boolean = request.url.startsWith(`${this.env.VPWS_URL}`);

    return next.handle(
      request.clone({
        headers,
        withCredentials: !vpwsUrl,
      }),
    ).pipe(
      tap(
        (event) => { },
        (err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/auth/unauthorized']);
            } else if (err.status >= 500) {
              this.store.dispatch(new Auth.StatusError(err.status));
            }
          }
        },
      ),
    );
  }
}
