import { ContentActionPayload } from './../../models/content-action-payload';
import * as _ from 'lodash';

import * as content from '../actions/content';
import { PaginatedResponse } from '../../models/paginated-response';
import { Media } from '../../models/media';
import { ContentFilters } from '../../models/content-filters';
import { sortContent, contentTypeToId, mssToAmedia } from '../../utils/content-utils';
import { MediaTotals, ChannelTotals, GroupTotals } from '../../models/facets';
import * as moment from 'moment';
import { Channel } from '../../models/channel';
import { Group } from '../../models/group';
import { ContentActionType } from '../../models/content-action-type';

export interface State {
  filteringText: boolean;
  mediaChangesetPollingActive: boolean;
  lastMediaChangeset: moment.Moment;
  channelsChangesetPollingActive: boolean;
  lastChannelsChangeset: moment.Moment;
  channelgroupsChangesetPollingActive: boolean;
  lastChannelgroupsChangeset: moment.Moment;
  facetPollingActive: boolean;
  mediaChangesetLoading: boolean;
  channelsChangesetLoading: boolean;
  channelgroupsChangesetLoading: boolean;
  newlyCreatedMedia: any[];
  allChannels: Channel[];
  publishedChannels: Channel[];
  allGroups: Group[];
  mediaTotals: MediaTotals;
  channelTotals: ChannelTotals;
  groupTotals: GroupTotals;
  contentType: string | null;
  filters: ContentFilters;
  mediaContent: PaginatedResponse | null;
  channelContent: PaginatedResponse | null;
  groupContent: PaginatedResponse | null;
  mediaContentLoading: boolean;
  mediaContentLoadingFailed: boolean;
  channelsContentLoading: boolean;
  channelsContentLoadingFailed: boolean;
  groupsContentLoading: boolean;
  groupsContentLoadingFailed: boolean;
  lastSelectedItem: any | null;
  allSelected: boolean;
  currMedia: Media | null;
  currMediaLoading: boolean;
  currChannelMedia: PaginatedResponse | null;
  mediaDeleting: {[mediaID: string]: boolean};
  channelsDeleting: {[channelID: string]: boolean};
  channelgroupsDeleting: {[groupID: string]: boolean};
}

export const initialState: State = {
  filteringText: false,
  mediaChangesetPollingActive: false,
  lastMediaChangeset: moment.utc(),
  channelsChangesetPollingActive: false,
  lastChannelsChangeset: moment('1970-01-01'),
  channelgroupsChangesetPollingActive: false,
  lastChannelgroupsChangeset: moment('1970-01-01'),
  facetPollingActive: false,
  mediaChangesetLoading: false,
  channelsChangesetLoading: false,
  channelgroupsChangesetLoading: false,
  newlyCreatedMedia: [],
  allChannels: null,
  publishedChannels: null,
  allGroups: null,
  mediaTotals: null,
  channelTotals: null,
  groupTotals: null,
  contentType: null,
  filters: {
    page: 1,
    pageSize: 25,
    searchText: '',
    queryParams: {},
    sortBy: null,
    sortDir: null,
  },
  mediaContent: null,
  channelContent: null,
  groupContent: null,
  mediaContentLoading: true,
  mediaContentLoadingFailed: false,
  channelsContentLoading: true,
  channelsContentLoadingFailed: false,
  groupsContentLoading: true,
  groupsContentLoadingFailed: false,
  lastSelectedItem: null,
  allSelected: false,
  currMedia: null,
  currMediaLoading: false,
  currChannelMedia: null,
  mediaDeleting: {},
  channelsDeleting: {},
  channelgroupsDeleting: {},
};

export function reducer(
  state = initialState,
  action: content.Actions,
): State {
  let newState: State;
  let newContent: PaginatedResponse;
  let currentContent: PaginatedResponse;
  let id: string;
  switch (action.type) {
  case content.SET_CONTENT_TYPE:
    return {
      ...state,
      contentType: action.contentType,
    };
  case content.LOAD_CONTENT_LIST:
    newState = {
      ...state,
      filteringText: action.payload.filters.searchText !== undefined,
    };
    setContentLoadingByType(action.payload.type, true, newState);
    setContentLoadingFailedByType(action.payload.type, false, newState);

    if (action.payload.clearContent) {
      // null out the content for the specific content type
      setContentByType(action.payload.type, null, newState);
      newState.filters = _.cloneDeep(action.payload.filters);
    } else {
      // Merge in non-nil filters
      const nonNilFilters = _.pickBy(action.payload.filters, _.negate(_.isNil));
      Object.assign(newState.filters, _.cloneDeep(nonNilFilters));
    }
    return newState;
  case content.CONTENT_LIST_LOADED:
    let results = action.payload.results;
    if (!!results && action.preserveSelections === true) {
      action.payload.results = copyContentSelection(action.contentType, results,
        getContentByType(action.contentType, state).results);
    }
    newState = {
      ...state,
      filteringText: false,
      lastSelectedItem: null,
      allSelected: false,
    };
    setContentLoadingByType(action.contentType, false, newState);
    setContentByType(action.contentType, action.payload, newState);
    return newState;
  case content.CONTENT_LIST_FAILED_TO_LOAD:
    newState = {
      ...state,
      filteringText: false,
      lastSelectedItem: null,
      allSelected: false,
    };
    setContentLoadingFailedByType(action.contentType, true, newState);
    return newState;
  case content.START_CHANGESET_POLLING:
    return {
      ...state,
      [`${action.contentType}ChangesetPollingActive`]: true,
    };
  case content.STOP_CHANGESET_POLLING:
    return {
      ...state,
      [`${action.contentType}ChangesetPollingActive`]: false,
    };
  case content.CLEAR_CHANGESET_DATA:
    return {
      ...state,
      mediaTotals: null,
      channelTotals: null,
      groupTotals: null,
      allChannels: null,
      publishedChannels: null,
      allGroups: null,
      lastMediaChangeset: moment.utc(),
      lastChannelsChangeset: moment('1970-01-01'),
      lastChannelgroupsChangeset: moment('1970-01-01'),
    };
  case content.GET_CHANGESET:
    return {
      ...state,
      [`${action.contentType}ChangesetLoading`]: true,
    };
  case content.GET_CHANGESET_SUCCEEDED:
    // Ignore if request took too long
    if (action.after.isBefore(state[`last${_.upperFirst(action.contentType)}Changeset`])) {
      return state;
    }

    id = contentTypeToId(action.contentType);
    let mdsId: string;
    if (action.contentType === 'media') {
      mdsId = 'mediaId';
    } else {
      mdsId = 'id';
    }

    // Update / remove entries that are currently being displayed
    newContent = getContentByType(action.contentType, state);
    if (!!newContent && !!newContent.results) {
      _.forEach(action.changeset, (updatedContent: any) => {
        const index: number = _.findIndex(
          newContent.results,
          (c) => c[id] === updatedContent[mdsId],
        );

        if (index >= 0) {
          if (
            updatedContent.mediaState === 'Deleted' || // media
            updatedContent.state === 'Deleted' || // channel
            updatedContent.deleted // group
          ) {
            // Remove if its been deleted
            newContent.results.splice(index, 1);
          } else {
            // Merge in the new value to preserve those we don't get from MDS
            newContent.results[index] = {
              ...newContent.results[index],
              ...mssToAmedia(action.contentType, updatedContent),
            };
          }
        }
      });
    }

    const newValues: Partial<State> = {
      allChannels: state.allChannels,
      publishedChannels: state.publishedChannels,
      allGroups: state.allGroups,
    };

    if (action.contentType === 'channels') {
      // Sync up all channels
      if (!newValues.allChannels) {
        // First time store them all
        newValues.allChannels = _.map(
          action.changeset,
          (c) => mssToAmedia(action.contentType, c),
        );
      } else {
        _.forEach(action.changeset, (updatedContent: any) => {
          let index: number = _.findIndex(
            newValues.allChannels,
            (existing) => existing.channel_id === updatedContent[mdsId],
          );

          if (updatedContent.state === 'Deleted') {
            if (index >= 0) {
              // Remove if its been deleted
              newValues.allChannels.splice(index, 1);
            }
          } else {
            newValues.allChannels.splice(
              index, // append to end if not found
              index >= 0 ? 1 : 0, // replace if found
              mssToAmedia(action.contentType, updatedContent),
            );
          }
        });
      }

      newValues.publishedChannels = _.filter(
        newValues.allChannels,
        (c) => c.state === 'Published',
      );

      newValues.channelTotals = {
        published: newValues.publishedChannels.length,
        unpublished: newValues.allChannels.length - newValues.publishedChannels.length,
        total: newValues.allChannels.length,
        mediaCounts: _.reduce(
          newValues.allChannels,
          (result, v, k) => {
            result[v['channel_id']] = v['media_list'].length;

            return result;
          },
          {},
        ),
      };
    } else if (action.contentType === 'channelgroups') {
      // Sync up all groups
      if (!newValues.allGroups) {
        // First time store them all
        newValues.allGroups = _.map(
          action.changeset,
          (g) => mssToAmedia(action.contentType, g),
        );
      } else {
        _.forEach(action.changeset, (updatedContent: any) => {
          let index: number = _.findIndex(
            newValues.allGroups,
            (existing) => existing.channelgroup_id === updatedContent[mdsId],
          );

          if (updatedContent.deleted) {
            if (index >= 0) {
              // Remove if its been deleted
              newValues.allGroups.splice(index, 1);
            }
          } else {
            // Replace it or append it
            newValues.allGroups.splice(
              index, // append to end if not found
              index >= 0 ? 1 : 0, // replace if found
              mssToAmedia(action.contentType, updatedContent),
            );
          }
        });
      }

      // Calculate totals once per changeset
      newValues.groupTotals = {
        total: newValues.allGroups.length,
        channelCounts: _.reduce(
          newValues.allGroups,
          (result, v, k) => {
            result[v['channelgroup_id']] = v['channel_ids'].length;

            return result;
          },
          {},
        ),
      };
    }

    newState = {
      ...state,
      // Subtract 5 seconds to mitigate timing problems.  This still suffers from latency problems
      [`last${_.upperFirst(action.contentType)}Changeset`]: action.lastUpdated.subtract(5, 's'),
      [`${action.contentType}ChangesetLoading`]: false,
      ...newValues,
    };
    setContentByType(action.contentType, newContent, newState);
    return newState;
  case content.GET_CHANGESET_FAILED:
    return {
      ...state,
      [`${action.contentType}ChangesetLoading`]: false,
    };
  case content.START_FACET_POLLING:
    return {
      ...state,
      facetPollingActive: true,
    };
  case content.STOP_FACET_POLLING:
    return {
      ...state,
      facetPollingActive: false,
    };
  case content.UPDATE_FACETS_SUCCEEDED:
    return {
      ...state,
      mediaTotals: action.totals,
    };
  case content.UPDATE_FACETS_FAILED:
    return {
      ...state,
      mediaTotals: null,
    };
  case content.CREATE_MEDIA_SUCCEEDED:
    return {
      ...state,
      newlyCreatedMedia: [
        ...state.newlyCreatedMedia,
        action.media,
      ],
    };
  case content.MARK_MEDIA_CREATED:
    return {
      ...state,
      newlyCreatedMedia: _.differenceWith(
        state.newlyCreatedMedia,
        action.ids,
        (newMedia, newId) => newMedia.media_id === newId,
      ),
    };
  case content.CHUNKED_CONTENT_DELETE:
    newState = _.cloneDeep(state);
    handleContentActionStateUpdates(action.payload, newState);
    return newState;

  case content.CHUNKED_CONTENT_DELETE_FAILED:
    newState = _.cloneDeep(state);
    setContentDeletingFlag(action.payload, newState, false);
    return newState;

  case content.PERFORM_ACTION:
    newState = { ...state };
    handleContentActionStateUpdates(action.payload, newState);
    return newState;

  case content.PERFORM_ACTION_SUCCEEDED:
    newContent = _.cloneDeep(getContentByType(action.contentType, state));
    newState = {
      ...state,
    };
    // don't overwrite the content if the content type has changed
    // this can indicate that the user has loaded a different tab while
    // a long-running action was still processing
    if (!!action.content && action.contentType === state.contentType) {
      id = contentTypeToId(state.contentType);
      const index = newContent.results.findIndex((p) => p[id] === action.content[id]);

      if (index >= 0) {
        newContent.results[index] = {
          ...newContent.results[index],
          ...action.content,
        };
      }
      setContentByType(action.contentType, newContent, newState);
    }
    return newState;
  case content.PERFORM_ACTION_FAILED:
    newState = { ...state };

    if (action.payload.actionType === ContentActionType.Delete) {
      setContentDeletingFlag(action.payload, newState, false);
    }

    return newState;
  case content.TOGGLE_SELECTION:
    id = contentTypeToId(state.contentType);

    currentContent = getContentByType(state.contentType, state);
    const clickedItem = currentContent.results.find((item) => item[id] === action.payload.item[id]);

    if (state.lastSelectedItem && action.payload.shiftPressed) {
      const desiredValue = !clickedItem.selected;
      let startIndex = currentContent.results.indexOf(state.lastSelectedItem);
      let endIndex = currentContent.results.indexOf(clickedItem);
      if (startIndex > endIndex) {
        const tmpIndex = startIndex;
        startIndex = endIndex;
        endIndex = tmpIndex;
      }
      for (let index = startIndex; index <= endIndex; index++) {
        currentContent.results[index].selected = desiredValue;
      }
    } else {
      clickedItem.selected = !clickedItem.selected;
    }
    state.allSelected = currentContent.results.every((item) => item.selected);
    state.lastSelectedItem = clickedItem;
    return state;
  case content.SELECT_ALL:
    currentContent = getContentByType(state.contentType, state);
    state.allSelected = !state.allSelected;
    currentContent.results.forEach((m) => {
      m.selected = state.allSelected;
    });
    return state;
  default:
    return state;
  }
}

export const getContent = (state: State) => getContentByType(state.contentType, state);
export const getContentLoadingByType = (contentType: string, state: State): boolean => {
  switch (contentType) {
    case 'media':
      return state.mediaContentLoading;
    case 'channels':
      return state.channelsContentLoading;
    case 'channelgroups':
      return state.groupsContentLoading;
  }
  return false;
};
export const isContentLoading = (state: State) => getContentLoadingByType(state.contentType, state);
export const getMediaContent = (state: State) => getContentByType('media', state);
export const getChannelContent = (state: State) => getContentByType('channels', state);
export const getGroupContent = (state: State) => getContentByType('channelgroups', state);
export const getMediaChangesetPollingActive = (state: State) => state.mediaChangesetPollingActive;
export const getChannelsChangesetPollingActive = (state: State) => state.channelsChangesetPollingActive;
export const getGroupsChangesetPollingActive = (state: State) => state.channelgroupsChangesetPollingActive;
export const getFacetPollingActive = (state: State) => state.facetPollingActive;
export const getNewlyCreatedMedia = (state: State) => state.newlyCreatedMedia;

const handleContentActionStateUpdates = (payload: ContentActionPayload, state: State): void => {
  let idKey: string;
  let contentList = [];
  if (payload.content && !payload.contentList) {
    contentList = [ payload.content ];
  } else {
    contentList = payload.contentList;
  }
  for (let ct of contentList) {
    if (!!_.get(getContent(state), 'results')) {
      idKey = contentTypeToId(state.contentType);
      const currentContent = getContentByType(state.contentType, state);
      const index =  currentContent.results.findIndex((p) => p[idKey] === ct[idKey]);

      if (index >= 0) {
        currentContent.results[index] = {
          ...currentContent.results[index],
          selected: false,
        };
        state.allSelected = false;
      }
    }
    if (payload.actionType === ContentActionType.Delete) {
      state[`${payload.contentType}Deleting`] = {
        ...state[`${payload.contentType}Deleting`],
        [ct[idKey]]: true,
      };
    }
  }
};

const setContentDeletingFlag = (payload: ContentActionPayload, state: State, value: boolean): void => {
  const idKey = contentTypeToId(payload.contentType);
  let itemsToMark = [];
  if (payload.contentList) {
    itemsToMark = itemsToMark.concat(payload.contentList);
  } else {
    itemsToMark.push(payload.content);
  }
  for (let item of itemsToMark) {
    state[`${payload.contentType}Deleting`] = {
      ...state[`${payload.contentType}Deleting`],
      [item[idKey]]: value,
    };
  }
};

const copyContentSelection = (contentType: string, newContent: any[], oldContent: any[]): any[] => {
  let res = _.cloneDeep(newContent);
  const idKey = contentTypeToId(contentType);
  for (let entry of res) {
    let old = _.find(oldContent, (c) => c[idKey] === entry[idKey]);
    if (old) {
      entry.selected = old.selected || false;
    }
  }
  return res;
};

const getContentByType = (contentType: string, state: State): PaginatedResponse | null => {
  switch (contentType) {
    case 'media':
      return state.mediaContent;
    case 'channels':
      return state.channelContent;
    case 'channelgroups':
      return state.groupContent;
  }
  return null;
};

const setContentByType = (contentType: string, newContent: PaginatedResponse, state: State): void => {
  switch (contentType) {
    case 'media':
      state.mediaContent = newContent;
      break;
    case 'channels':
      state.channelContent = newContent;
      break;
    case 'channelgroups':
      state.groupContent = newContent;
      break;
  }
};

const setContentLoadingByType = (contentType: string, value: boolean, state: State): void => {
  switch (contentType) {
    case 'media':
      state.mediaContentLoading = value;
      break;
    case 'channels':
      state.channelsContentLoading = value;
      break;
    case 'channelgroups':
      state.groupsContentLoading = value;
      break;
  }
};

const setContentLoadingFailedByType = (contentType: string, value: boolean, state: State): void => {
  switch (contentType) {
    case 'media':
      state.mediaContentLoadingFailed = value;
      break;
    case 'channels':
      state.channelsContentLoadingFailed = value;
      break;
    case 'channelgroups':
      state.groupsContentLoadingFailed = value;
      break;
  }
};
