import { Component, EventEmitter, Input, OnDestroy, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Button } from '../models/button';

@Component({
  selector: 'll-button-group',
  styleUrls: ['./button-group.component.scss'],
  template: `
    <ul class="ll-btn-group">
      <li
        class="ll-btn-group__button"
        *ngFor="let btn of buttons"
        [class.ll-btn-group__button--active]="btn.enabled && selectedButton === btn"
        [class.ll-btn-group__button--disabled]="!btn.enabled"
        (click)="onSelect(btn)"
        [innerHTML]="btn.innerHtml"
      ></li>
    </ul>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => ButtonGroupComponent),
      multi: true,
    },
  ],
})
export class ButtonGroupComponent implements ControlValueAccessor {
  @Input() buttons: Button[];
  @Output() change: EventEmitter<any> = new EventEmitter();
  selectedButton: Button;

  onSelect(button: Button): void {
    this.selectedButton = button;
    this.onTouched();
    this.change.emit(button.value);
    this.onChange(button.value);
  }

  writeValue(val: any): void {
    if (val !== undefined) {
      this.selectedButton = this.buttons.find((btn) => btn.value === val);
    }
  }

  registerOnChange(fn: (_: any) => {}): void { this.onChange = fn; }

  registerOnTouched(fn: () => {}): void { this.onTouched = fn; }

  protected onChange = (_: any) => { };
  protected onTouched = () => { };
}
