import { EnvConfigurationService, Configuration } from './environment-configuration.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, take } from 'rxjs/operators';
import * as fromRoot from '../redux/reducers';
import { Store, select } from '@ngrx/store';

@Injectable()
export class PlayerNonceService {

  env: Configuration;

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,
  ) {
    this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);
  }

  getNonce(expirySeconds: number): Observable<string> {
    return this.http.post<string>(
      `${this.env.SHIM_BASE_URL}/nonce?validFor=${expirySeconds}`,
      null,
    ).pipe(map((res: any) => res.value));
  }
}
