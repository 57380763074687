import { EnvConfigurationService, Configuration } from './environment-configuration.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, take } from 'rxjs/operators';
import * as _ from 'lodash';

import * as fromRoot from '../redux/reducers';
import { ContentFilters } from '../models/content-filters';
import { Media } from '../models/media';
import { MediaTotals } from '../models/facets';
import { PaginatedResponse } from '../models/paginated-response';
import * as moment from 'moment';
import { DateRange } from 'moment-range';
import { Store, select } from '@ngrx/store';

@Injectable()
export class MediaSearchService {

  env: Configuration;

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,
  ) {
    this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);
  }

  filtersToQuery(f: ContentFilters, urlEncode: boolean = true): string {
    const criteria: string[] = [];

    if (!!f.queryParams) {
      _.forIn(f.queryParams, (val, key) => {
        if (_.isArray(val)) {
          if (val.length === 1) {
            criteria.push(`${key}:${val[0].toLowerCase()}`);
          } else if (val.length > 0) {
            criteria.push(`(${_.join(
              _.map(val, (v) => `${key}:${v.toLowerCase()}`),
              ' OR ',
            )})`);
          }
        } else if (_.has(val, 'rangeId')) {
          criteria.push(
            `(${key}:[${val.range.start.toISOString()} TO ${val.range.end.toISOString()}])`,
          );
        }
      });
    }

    if (!!f.searchText) {
      // Escape reserved chars in SOLR syntax -"[]!^()%\"
      const escapedSearchText = f.searchText.replace(/[-"\[\]\{\}\!\^\(\):\/%\\]/g, (char) => `\\${char}`);

      criteria.push(`(${_.join(
        _.map(
          ['id', 'title', 'description', 'tags', 'original_filename'],
          (k) => `${k}:*${urlEncode ? encodeURIComponent(escapedSearchText) : escapedSearchText}*`,
        ),
        ' OR ',
      )})`);
    }

    return `${_.join(criteria, ' AND ') || '*:*'}`;
  }

  getStateFacets(f: ContentFilters): Observable<Partial<MediaTotals>> {
    return this.http.post<Partial<MediaTotals>>(
      `${this.env.SHIM_BASE_URL}/media/facet`,
      {
        limit: '100',
        field: 'state',
        q: this.filtersToQuery(f, false),
        fq: (
          'state:publishable,state:published,state:error,' +
          '((state:processing OR state:processingthenpublishing) NOT media_type:livestream),' +
          '((state:uploading OR state:new) NOT media_type:livestream)'
        ),
      },
    ).pipe(
      map((res) => ({
        unpublished: _.get(res, 'values.0.count'),
        published: _.get(res, 'values.1.count'),
        error: _.get(res, 'values.2.count'),
        processing: _.get(res, 'values.3.count'),
        uploading: _.get(res, 'values.4.count'),
      })),
    );
  }

  getTypeFacets(f: ContentFilters): Observable<Partial<MediaTotals>> {
    return this.http.post<Partial<MediaTotals>>(
      `${this.env.SHIM_BASE_URL}/media/facet`,
      {
        limit: '100',
        field: 'media_type',
        q: this.filtersToQuery(f, false),
        fq: 'media_type:video,media_type:audio',
      },
    ).pipe(
      map((res) => {
        const video = _.get(res, 'values.0.count') || 0;
        const audio = _.get(res, 'values.1.count') || 0;

        return {
          video,
          audio,
          total: audio + video,
        };
      }),
    );
  }

  getMediaList(f: ContentFilters): Observable<PaginatedResponse> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/media/detailed-query?` +
      (f.pageSize !== undefined ? `limit=${f.pageSize}&` : '') +
      `fields=id` +
      `&fq=*:* NOT state:Deleted` +
      `&q=${this.filtersToQuery(f)}` +
      (f.page !== undefined ? `&offset=${(f.page - 1) * f.pageSize}` : '') +
      (f.sortBy !== undefined && f.sortDir !== undefined
        ? `&sort=${f.sortBy} ${f.sortDir.toLowerCase()}`
        : ''
      ),
    ).pipe(
      map((res) => {
        return {
          page: f.page,
          queryParams: f.queryParams,
          searchText: f.searchText,
          size: f.pageSize,
          sort: f.sortBy,
          sort_dir: f.sortDir,
          total: res['total_count'],
          results: res['media'],

        };
      }),
    );
  }

  getMedia(id: string): Observable<Media> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/media/detailed-query?limit=1&q=id:${id}&fields=id`,
    ).pipe(
      map((res) => {
        if (res['total_count'] > 0) {
          const results: Media[] = res['media'];

          return results[0];
        }
        throw new Error('Media not found');
      }),
    );
  }
}
