import { Component, EventEmitter, Input, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { ExpandInOutAnimation } from '../animations/expand-in-out.animation';
import { NavItem } from './nav-item';
import { WindowRef } from '../utils/window-ref';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'll-left-nav',
  styleUrls: ['./left-nav.component.scss'],
  templateUrl: './left-nav.component.html',
  animations: [
    ExpandInOutAnimation,
  ],
})
export class LeftNavComponent implements OnDestroy, OnInit {
  selectedItem: NavItem;
  @Input() items: NavItem[];
  @Input() menuState;
  @Output() onNavToggle: EventEmitter<any> = new EventEmitter();
  routerSubscription: any;

  constructor(
    private router: Router,
    private winRef: WindowRef,
    private translate: TranslateService,
   ) { }

  ngOnInit(): void {
    if (this.items.length) {
      this.items.unshift({
        title: '', url: '', faClass: 'fas fa-bars',
      });

      const startingItem = this.items.find((item) => {
        return item.url && this.router.url.indexOf(item.url) === 0;
      });
      this.selectedItem = startingItem ? startingItem : this.items[0];
    }

    // Keep selected item in sync with URLs that start with the same prefix
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (this.items.length) {
          const url = val.urlAfterRedirects ? val.urlAfterRedirects : val.url;
          const newItem = this.items.find((item) => item.url && url.indexOf(item.url) === 0);
          if (newItem && this.selectedItem !== newItem) {
            this.selectedItem = newItem;
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  onSelect(item: NavItem): void {
    if (this.items.indexOf(item) === 0) {
      this.onNavToggle.emit();
    } else {
      this.selectedItem = item;
      if (item.url.indexOf('http') === 0) {
        this.winRef.nativeWindow.location.href = item.url;
      } else {
        this.router.navigate([item.url]);
      }
    }
  }
}
