import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { first, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

import * as fromRoot from '../../shared/redux/reducers';
import * as fromAuth from '../../shared/redux/reducers/auth';
import * as Auth from '../../shared/redux/actions/auth';
import { BaseAuthComponent } from '../base-auth.component';

export type ShowFieldErrorFnType = (control: FormControl) => boolean;

@Component({
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  auth$: Observable<fromAuth.State>;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  constructor(
    public base: BaseAuthComponent,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.auth$ = store.pipe(select(fromRoot.getAuthState));
    this.base.authTitle = 'amedia.forgotPwd.title';
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    this.toastr.overlayContainer = this.toastContainer;
  }

  showEmailErrors(): ShowFieldErrorFnType {
    return (email) => email.dirty && email.invalid;
  }

  get emailErrorLabels(): any {
    return {
      email: this.translate.instant('common.directives.errorMessages.invalidEmail'),
    };
  }

  onSubmit() {
    this.form.get('email').markAsDirty();

    if (this.form.valid) {
      this.store.dispatch(new Auth.StartPasswordReset(this.form.value.email));
    }

    this.auth$.pipe(first((au) => !au.pwdResetLoading)).subscribe((authState: fromAuth.State) => {
      setTimeout(() => {
        if (
          !!authState.pwdResetEmail
          && !!authState.wrongEmailOrAnswer
        ) {
          this.base.onShake();
        }
      }, 200);
    });
  }
}
