import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { User } from '../../shared/models/user';

@Component({
  templateUrl: './link-user-modal.component.html',
})
export class LinkUsereModalComponent {
  @Input() user: User;
  @Input() role: string;

  constructor(public activeModal: NgbActiveModal) { }

  get bodyTranslateParams(): any {
    return {
      name: `<b>${this.user.firstName} ${this.user.lastName}</b>`,
      email: `<b>${this.user.email}</b>`,
      role: `<b>${this.role}</b>`,
    };
  }
}
