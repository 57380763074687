import { Action } from '@ngrx/store';

import { Media } from '../../models/media';
import { Encoding } from '../../models/media-encoding';
import { Cuepoint } from '../../models/cuepoints';
import { ImageType } from '../../models/image-type';
import { MediaSection } from '../reducers/media';
import { Channel } from '../../models/channel';
import { Errors } from '../../models/errors';

export const LOAD_MEDIA = '[Media] Load Media';
export const LOAD_MEDIA_SUCCEEDED = '[Media] Load Media Succeeded';
export const LOAD_MEDIA_FAILED = '[Media] Load Media Failed';
export const EDIT_MEDIA_SECTION = '[Media] Edit Media Section';
export const EDIT_MEDIA_SECTION_SUCCEEDED = '[Media] Edit Media Section Succeeded';
export const EDIT_MEDIA_SECTION_FAILED = '[Media] Edit Media Section Failed';
export const GET_PUBLISH_STATE = '[Media] Get Publish State';
export const GET_PUBLISH_STATE_FAILED = '[Media] Get Publish State Failed';
export const GET_PUBLISH_STATE_SUCCEEDED = '[Media] Get Publish State Succeeded';
export const SAVE_PREVIEW = '[Media] Save Preview';
export const SAVE_PREVIEW_SUCCEEDED = '[Media] Save Preview Succeeded';
export const SAVE_PREVIEW_FAILED = '[Media] Save Preview Failed';
export const UPLOAD_IMAGE = '[Media] Upload Image';
export const UPLOAD_IMAGE_SUCCEEDED = '[Media] Upload Image Succeeded';
export const UPLOAD_IMAGE_FAILED = '[Media] Upload Image Failed';
export const CREATE_CLIP = '[Media] Create Clip';
export const CREATE_CLIP_SUCCEEDED = '[Media] Create Clip Succeeded';
export const CREATE_CLIP_FAILED = '[Media] Create Clip Failed';
export const LOAD_SUGGESTED_TAGS = '[Content] Load Suggested Tags';
export const LOAD_SUGGESTED_TAGS_SUCCEEDED = '[Content] Load Suggested Tags Succeeded';
export const LOAD_SUGGESTED_TAGS_FAILED = '[Content] Load Suggested Tags Failed';
export const LOAD_MEDIA_ENCODINGS = '[Media] Load Media Encodings';
export const LOAD_MEDIA_ENCODINGS_SUCCEEDED = '[Media] Load Media Encodings Succeeded';
export const LOAD_MEDIA_ENCODINGS_FAILED = '[Media] Load Media Encodings Failed';
export const LOAD_CUEPOINTS = '[Media] Load Cuepoints';
export const LOAD_CUEPOINTS_SUCCEEDED = '[Media] Load Cuepoints Succeeded';
export const LOAD_CUEPOINTS_FAILED = '[Media] Load Cuepoints Failed';
export const REPLACE_CUEPOINTS = '[Media] Replace Cuepoints';
export const REPLACE_CUEPOINTS_SUCCEEDED = '[Media] Replace Cuepoints Succeeded';
export const REPLACE_CUEPOINTS_FAILED = '[Media] Replace Cuepoints Failed';
export const LIST_CHANNELS_FOR_MEDIA = '[Media] List Channels For Media';
export const LIST_CHANNELS_FOR_MEDIA_FAILED = '[Media] List Channels For Media Failed';
export const LIST_CHANNELS_FOR_MEDIA_SUCCEEDED = '[Media] List Channels For Media Succeeded';
export const LOAD_CLOSED_CAPTION = '[Media] Load Closed Caption';
export const CREATE_CLOSED_CAPTION = '[Media] Create Closed Caption';
export const CREATE_CLOSED_CAPTION_SUCCEEDED = '[Media] Create Closed Caption Succeeded';
export const CREATE_CLOSED_CAPTION_FAILED = '[Media] Create Closed Caption Failed';
export const UPDATE_CC_VERSIONS = '[Media] Update Closed Caption Versions';
export const DELETE_CLOSED_CAPTION = '[Media] Delete Closed Caption';
export const DELETE_CLOSED_CAPTION_SUCCEEDED = '[Media] Delete Closed Caption Succeeded';
export const DELETE_CLOSED_CAPTION_FAILED = '[Media] Delete Closed Caption Failed';
export const UPDATE_DEFAULT_PUBLISH_STATE = '[Media] Update Default Publish State';
export const UPDATE_DEFAULT_PUBLISH_STATE_FAILED = '[Media] Update Default Publish State Failed';
export const UPDATE_DEFAULT_PUBLISH_STATE_SUCCEEDED = '[Media] Update Default Publish State Succeeded';
export const BULK_TAG_MEDIA = '[Media] Bulk Tag Media';

export class GetPublishState implements Action {
  readonly type = GET_PUBLISH_STATE;
}

export class GetPublishStateFailed implements Action {
  readonly type = GET_PUBLISH_STATE_FAILED;
}

export class GetPublishStateSucceeded implements Action {
  readonly type = GET_PUBLISH_STATE_SUCCEEDED;

  constructor(public publishState: string) { }
}

export class LoadMedia implements Action {
  readonly type = LOAD_MEDIA;

  constructor(public mediaID: string) { }
}

export class LoadMediaSucceeded implements Action {
  readonly type = LOAD_MEDIA_SUCCEEDED;

  constructor(public media: Media) { }
}

export class LoadMediaFailed implements Action {
  readonly type = LOAD_MEDIA_FAILED;

  constructor(public mediaID: string) { }
}

export class EditMediaSection implements Action {
  readonly type = EDIT_MEDIA_SECTION;

  constructor(
    public section: MediaSection,
    public mediaID: string,
    public newValues: any,
  ) { }
}

export class EditMediaSectionSucceeded implements Action {
  readonly type = EDIT_MEDIA_SECTION_SUCCEEDED;

  constructor(
    public section: MediaSection,
    public mediaID: string,
    public newValues: any,
  ) { }
}

export class EditMediaSectionFailed implements Action {
  readonly type = EDIT_MEDIA_SECTION_FAILED;

  constructor(
    public section: MediaSection,
    public mediaID: string,
  ) { }
}

export class SavePreview implements Action {
  readonly type = SAVE_PREVIEW;

  constructor(
    public contentType: string,
    public contentId: string,
    public frameTimeInMillis: number,
    public imageURI: string,
    public image: File,
    public thumbImageURI: string,
  ) { }
}

export class SavePreviewFailed implements Action {
  readonly type = SAVE_PREVIEW_FAILED;

  constructor(
    public contentId: string,
    public image: File,
  ) { }
}

export class SavePreviewSucceeded implements Action {
  readonly type = SAVE_PREVIEW_SUCCEEDED;

  constructor(
    public contentId: string,
    public previewImageURI: string,
    public thumbImageURI: string,
  ) { }
}

export class UploadImage implements Action {
  static ACTION_ID_CTR = 1;
  static getId(): number { return UploadImage.ACTION_ID_CTR++; }

  readonly id = UploadImage.getId();
  readonly type = UPLOAD_IMAGE;

  constructor(
    public base64URI: string,
    public image: File,
    public imageType: ImageType,
    public width: number,
    public height: number,
    public orgId: string,
  ) { }
}

export class UploadImageFailed implements Action {
  readonly type = UPLOAD_IMAGE_FAILED;

  constructor(public id: number) { }
}

export class UploadImageSucceeded implements Action {
  readonly type = UPLOAD_IMAGE_SUCCEEDED;

  constructor(
    public id: number,
    public imageURL: string,
  ) { }
}

export class CreateClip implements Action {
  readonly type = CREATE_CLIP;

  constructor(
    public mediaID: string,
    public title: string,
    public startTime: number,
    public endTime: number,
    public endscreenImageUri: string,
  ) { }
}

export class CreateClipSucceeded implements Action {
  readonly type = CREATE_CLIP_SUCCEEDED;

  constructor(public mediaID: string) { }
}

export class CreateClipFailed implements Action {
  readonly type = CREATE_CLIP_FAILED;

  constructor(public mediaID: string) { }
}

export class LoadSuggestedTags implements Action {
  readonly type = LOAD_SUGGESTED_TAGS;

  constructor(public mediaID: string) {}
}

export class LoadSuggestedTagsSucceeded implements Action {
  readonly type = LOAD_SUGGESTED_TAGS_SUCCEEDED;

  constructor(public suggestedTags: any) {}
}

export class LoadSuggestedTagsFailed implements Action {
  readonly type = LOAD_SUGGESTED_TAGS_FAILED;
}

export class LoadMediaEncodings implements Action {
  readonly type = LOAD_MEDIA_ENCODINGS;

  constructor(public mediaID: string) { }
}

export class LoadMediaEncodingsSucceeded implements Action {
  readonly type = LOAD_MEDIA_ENCODINGS_SUCCEEDED;

  constructor(
    public mediaID: string,
    public mediaEncodings: Encoding[],
  ) { }
}

export class LoadMediaEncodingsFailed implements Action {
  readonly type = LOAD_MEDIA_ENCODINGS_FAILED;

  constructor(public mediaID: string) { }
}

export class LoadCuepoints implements Action {
  readonly type = LOAD_CUEPOINTS;

  constructor(public mediaID: string) { }
}

export class LoadCuepointsSucceeded implements Action {
  readonly type = LOAD_CUEPOINTS_SUCCEEDED;

  constructor(
    public mediaID: string,
    public cuepoints: Cuepoint[],
  ) {}
}

export class LoadCuepointsFailed implements Action {
  readonly type = LOAD_CUEPOINTS_FAILED;

  constructor(public mediaID: string) { }
}

export class ReplaceCuepoints implements Action {
  readonly type = REPLACE_CUEPOINTS;

  constructor(
    public mediaID: string,
    public cuepoints: Cuepoint[],
  ) {}
}

export class ReplaceCuepointsSucceeded implements Action {
  readonly type = REPLACE_CUEPOINTS_SUCCEEDED;

  constructor(
    public mediaID: string,
    public cuepoints: Cuepoint[],
  ) {}
}

export class ReplaceCuepointsFailed implements Action {
  readonly type = REPLACE_CUEPOINTS_FAILED;

  constructor(public mediaID: string) { }
}

export class UpdateDefaultPublishState implements Action {
  readonly type = UPDATE_DEFAULT_PUBLISH_STATE;

  constructor(public state: boolean) { }
}

export class UpdateDefaultPublishStateFailed implements Action {
  readonly type = UPDATE_DEFAULT_PUBLISH_STATE_FAILED;
}

export class UpdateDefaultPublishStateSucceeded implements Action {
  readonly type = UPDATE_DEFAULT_PUBLISH_STATE_SUCCEEDED;
}

export class LoadClosedCaption implements Action {
  readonly type = LOAD_CLOSED_CAPTION;

  constructor(
    public mediaID: string,
    public captionFile: File,
  ) {}
}

export class CreateClosedCaption implements Action {
  readonly type = CREATE_CLOSED_CAPTION;

  constructor(
    public mediaID: string,
    public ccVersion: number,
    public captionFile: File,
  ) {}
}

export class CreateClosedCaptionSucceeded implements Action {
  readonly type = CREATE_CLOSED_CAPTION_SUCCEEDED;

  constructor(
    public mediaID: string,
    public captionFile: File,
  ) {}
}

export class CreateClosedCaptionFailed implements Action {
  readonly type = CREATE_CLOSED_CAPTION_FAILED;

  constructor(
    public errors: Errors,
    public fileName: string,
    public mediaID: string,
  ) {}
}

export class UpdateCCVersions implements Action {
  readonly type = UPDATE_CC_VERSIONS;

  constructor(public media: Media) {}
}

export class DeleteClosedCaption implements Action {
  readonly type = DELETE_CLOSED_CAPTION;

  constructor(public mediaID: string) {}
}

export class DeleteClosedCaptionSucceeded implements Action {
  readonly type = DELETE_CLOSED_CAPTION_SUCCEEDED;

  constructor(public mediaID: string) {}
}

export class DeleteClosedCaptionFailed implements Action {
  readonly type = DELETE_CLOSED_CAPTION_FAILED;

  constructor(public mediaID: string) {}
}

export class ListChannelsForMedia implements Action {
  readonly type = LIST_CHANNELS_FOR_MEDIA;

  constructor(public mediaID: string) {}
}

export class ListChannelsForMediaFailed implements Action {
  readonly type = LIST_CHANNELS_FOR_MEDIA_FAILED;
}

export class ListChannelsForMediaSucceeded implements Action {
  readonly type = LIST_CHANNELS_FOR_MEDIA_SUCCEEDED;

  constructor(public channelList: Channel[]) {}
}

export class BulkTagMedia implements Action {
  readonly type = BULK_TAG_MEDIA;

  constructor(
    public mediaList: Media[],
    public tag: string[],
    public append: boolean,
    ) {}
}

export type Actions = (
  LoadMedia | LoadMediaSucceeded | LoadMediaFailed |
  EditMediaSection | EditMediaSectionSucceeded | EditMediaSectionFailed |
  SavePreview | SavePreviewSucceeded | SavePreviewFailed |
  UploadImage | UploadImageSucceeded | UploadImageFailed |
  CreateClip | CreateClipSucceeded | CreateClipFailed |
  ListChannelsForMedia | ListChannelsForMediaFailed | ListChannelsForMediaSucceeded |
  LoadSuggestedTags | LoadSuggestedTagsSucceeded | LoadSuggestedTagsFailed |
  LoadCuepoints | LoadCuepointsSucceeded | LoadCuepointsFailed |
  ReplaceCuepoints | ReplaceCuepointsSucceeded | ReplaceCuepointsFailed |
  LoadMediaEncodings | LoadMediaEncodingsSucceeded | LoadMediaEncodingsFailed |
  GetPublishState | GetPublishStateFailed | GetPublishStateSucceeded |
  CreateClosedCaption | CreateClosedCaptionSucceeded | CreateClosedCaptionFailed |
  UpdateCCVersions | LoadClosedCaption |
  DeleteClosedCaption | DeleteClosedCaptionSucceeded | DeleteClosedCaptionFailed |
  UpdateDefaultPublishState | UpdateDefaultPublishStateFailed |
  UpdateDefaultPublishStateSucceeded |
  BulkTagMedia
);
