import { Component } from '@angular/core';

@Component({
  templateUrl: './base-auth.component.html',
  styleUrls: ['./base-auth.component.scss'],
})
export class BaseAuthComponent {
  shake: boolean = false;
  authTitle: string = 'amedia.login.title';

  onShake() {
    this.shake = true;
    setTimeout(() => {
      this.shake = false;
    }, 500);
  }
}
