import { trigger, state, style, transition, animate } from '@angular/animations';

// tslint:disable-next-line:variable-name
export const ExpandInOutAnimation = trigger('expandInOut', [
  state('in', style({
    width: '45px',
  })),
  state('out', style({
    width: '240px',
  })),
  transition('in => out', animate('300ms ease-in-out')),
  transition('out => in', animate('300ms ease-in-out')),
]);
