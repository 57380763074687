import * as _ from 'lodash';

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  styles: ['.modal-body {max-width: 80vw; overflow-wrap: break-word; }' ],
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  @Input() headerMessageLocal: string;
  @Input() itemType: string;
  @Input() title: string;
  @Input() verb: string;
  @Input() verbPast: string;
  @Input() bodyOverride: string;

  constructor(private translate: TranslateService) {}

  get headerTranslateParams(): any {
    return {
      itemType: this.itemType,
      verb: this.verb,
    };
  }

  get bodyTranslateParams(): any {
    return {
      itemType: this.itemType,
      title: _.escape(this.title).bold(),
      verbPast: this.verbPast,
    };
  }
}
