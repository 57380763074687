import { Theme, Skin } from '../models/theme';

const baseSkin: Partial<Skin> = {
  videoRatio: 0.5,
  playlist: 'none',
  itemFont: '12px sans-serif',
  // headerFont: '17px serif',
  // headerBackground: 'transparent linear-gradient(-90deg,#1c1819 0%,#363636 100%)',
  itemBackground: 'transparent linear-gradient(-90deg,#1d191a 0%,#3b3b3d 25%,#282628 100%)',
  activeItemBackground: 'transparent linear-gradient(-90deg,#6e6b71 0%,#515255 25%,#636568 100%)',
  controlBackground: '#2B333FB3',
  controlColor: 'white',
  bigPlayColor: 'white',
  playlistColor: '#C1C1C1',
  playlistBackground: '#B3B3B3',
};

export const themes: Theme[] = [
  {
    id: 'LVPPlayerStandard',
    name: 'Player (with standard aspect ratio)',
    locked: true,
    description: '',
    skin: {
      ...baseSkin,
      height: 300,
      width: 400,
    },
  },
  {
    id: 'LVPPlayer',
    name: 'Widescreen',
    locked: true,
    description: '',
    skin: {
      ...baseSkin,
      height: 321,
      width: 540,
    },
  },
  {
    id: 'LVPPlayerHorizontalPlaylist',
    name: 'Widescreen (with horizontal playlist)',
    locked: true,
    description: '',
    skin: {
      ...baseSkin,
      height: 360,
      width: 960,
      videoRatio: 0.572,
      playlist: 'right',
    },
  },
  {
    id: 'PlayerVerticalPlaylist',
    name: 'Widescreen Player (with vertical playlist)',
    locked: true,
    description: '',
    skin: {
      ...baseSkin,
      height: 540,
      width: 480,
      playlist: 'bottom',
    },
  },
  // {
  //   id: 'PlayerInlinePlaylist',
  //   name: 'Widescreen Player (with inline playlist)',
  //   locked: true,
  //   description: '',
  //   skin: {
  //     ...baseSkin,
  //     height: 321,
  //     width: 480,
  //     playlist: 'inline',
  //   },
  // },
  {
    id: 'Player',
    name: '1024 Widescreen Player',
    locked: true,
    description: 'A widescreen player with larger dimensions',
    skin: {
      ...baseSkin,
      height: 576,
      width: 1024,
    },
  },
  {
    id: 'LVPMiniPlayer',
    name: 'Mini',
    locked: true,
    description: '',
    skin: {
      ...baseSkin,
      height: 168,
      width: 255,
    },
  },
  {
    id: 'MiniDelvePlaylistPlayer',
    name: 'Mini (with bottom playlist)',
    locked: true,
    description: '',
    skin: {
      ...baseSkin,
      height: 336,
      width: 255,
      playlist: 'bottom',
    },
  },
  {
    id: 'LVPAudioPlayer',
    name: 'Audio',
    locked: true,
    description: '',
    skin: {
      ...baseSkin,
      height: 76,
      width: 281,
    },
  },
  // {
  //   id: 'LVPHoverPlayer',
  //   name: 'Hover Player',
  //   locked: true,
  //   description: '',
  //   skin: {
  //      ...baseSkin,
  //      height: 270,
  //      width: 480,
  //   },
  // },
  // {
  //   id: 'LVPHoverPlayerHorizontalPlaylist',
  //   name: 'Hover Player (with horizontal playlist)',
  //   locked: true,
  //   description: '',
  //   skin: {
  //     ...baseSkin,
  //     height: 360,
  //     width: 960,
  //     playlist: 'right',
  //   },
  // },
  {
    id: 'WidescreenTabbedPlayer',
    name: 'Tabbed Player (with horizontal playlist)',
    locked: true,
    description: '',
    skin: {
      ...baseSkin,
      height: 360,
      width: 960,
      playlist: 'right',
    },
  },
];
