import { EnvConfigurationService, Configuration } from './../../services/environment-configuration.service';
import { AnalyticsService } from './../../services/analytics.service';
import { FfmpegVersionInfo } from './../../models/ffmpeg-version-info';
import { IdleTimeoutService } from './../../services/idle-timeout.service';
import { Observable } from 'rxjs/Observable';
import { Scheduler } from 'rxjs/Scheduler';
import { async } from 'rxjs/scheduler/async';
import { empty } from 'rxjs/observable/empty';
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { catchError, debounceTime, map, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { Injectable, InjectionToken, Optional, Inject } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { Action, Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import * as Auth from '../actions/auth';
import * as fromRoot from '../reducers';
import * as fromAuth from '../reducers/auth';
import * as Content from '../actions/content';
import * as CustomProps from '../actions/settings/custom-properties';
import * as ContentRestrictions from '../actions/settings/content-restrictions';
import * as Reports from '../actions/reports';
import { AccountMgmtService } from '../../services/account-mgmt.service';
import { FeatureSetAuthorizationService } from '../../services/feature-set-authorization.service';
import { User } from '../../models/user';
import { WindowRef } from '../../utils/window-ref';
import { parseShimErrors } from '../../utils/parse-errors';
import { Errors } from '../../models/errors';
import { PlayerNonceService } from '../../services/player-nonce.service';

export const DEBOUNCE = new InjectionToken<number>('Load Debounce');
export const SCHEDULER = new InjectionToken<Scheduler>('Load Scheduler');

const addToasterButtonType = (toaster: ToastrService): any => {
  setTimeout(() => {
    const toasterElement = toaster.overlayContainer.getContainerElement();
    const toastCloseButton = toasterElement.querySelector('.toast-close-button');
    toastCloseButton.setAttribute('type', 'button');
  });
};

const INLINE_CONFIG: Partial<IndividualConfig> = {
  disableTimeOut: true,
  positionClass: 'inline',
  easeTime: 0,
};

@Injectable()
export class AuthEffects {
  // Listen for the 'GRAB_KEYS' action
  @Effect()
  grabKeys$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.GRAB_KEYS),
      // debounceTime(this.debounce, this.scheduler || async),
      mergeMap((action: Auth.GrabKeys) => {
        return this.ams.getKeys().pipe(
          map((keys) => new Auth.GrabKeysSucceeded(keys)),
          catchError((error) => of(new Auth.GrabKeysFailed())),
        );
      }),
    );

  // Listen for the 'LOGIN' action
  @Effect()
  login$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.LOGIN),
      debounceTime(this.debounce, this.scheduler || async),
      switchMap((action: Auth.Login) => {
        if (!action.email || !action.pwdHash) {
          return of(new Auth.NotLoggedIn(this.getNextUrl()));
        }

        return this.ams.login(action.email, action.pwdHash, action.imposterEmail).pipe(
          map((user: User) => {
            this.idleTimeoutService.allow();
            return new Auth.UserLoaded(user);
          }),
          catchError((error) => {
            return of(new Auth.NotLoggedIn(
            this.getNextUrl(),
            error,
            ));
            },
          ),
        );
      }),
    );

  // Listen for the 'GET_USER' action
  @Effect()
  getUser$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.GET_USER),
      debounceTime(this.debounce, this.scheduler || async),
      switchMap((action: Auth.GetUser) => {
        return this.ams.getSelf().pipe(
          map((user: User) => new Auth.UserLoaded(user)),
          catchError((error) => of(new Auth.NotLoggedIn(
            this.getNextUrl(),
            error,
          ))),
        );
      }),
    );

  // Listen for the 'USER_LOADED' action
  @Effect()
  userLoaded$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.USER_LOADED),
      debounceTime(this.debounce, this.scheduler || async),
      mergeMap((action: Auth.UserLoaded) => {
        let obs$: any = empty();

        // Don't do the first load if the user just had their password reset
        if (action.user.passwordUpdateNeeded) {
          this.router.navigate([ '/auth/changepassword' ]);
        } else {
          this.auth$.pipe(take(1)).subscribe((auth) => {
            if (!auth.firstUserLoadComplete) {
              obs$ = of(new Auth.FirstLoad());
            }
          });
        }

        return obs$;
      }),
    );

  // Listen for the 'FIRST_LOAD' action
  @Effect()
  firstLoad$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.FIRST_LOAD),
      debounceTime(this.debounce, this.scheduler || async),
      mergeMap((action: Auth.UserLoaded) => {
        // Ignore any nextURL that disallows authState.firstLoadComplete
        const preFirstLoadURLs: string[] = [
          '/auth/changepassword',
          '/auth/changequestion',
        ];

        const nextUrl: string = this.getNextUrl();

        if (!!nextUrl) {
          this.router.navigate([
            _.includes(preFirstLoadURLs, nextUrl) ? '/app/content/media' : nextUrl,
          ]);
        }

        return from([
          new Auth.ClearNextURL(),
          new Auth.LoadOrg(),
          new Auth.LoadOrgList(),
          new Auth.LoadFeatureSets(),
          new Auth.GrabKeys(),
          new Auth.GetPlayerNonce(),
          new Content.ClearChangesetData(),
          new CustomProps.LoadCustomProps('media'),
          new CustomProps.LoadCustomProps('channels'),
          new CustomProps.LoadCustomProps('channelgroups'),
          new ContentRestrictions.LoadRestrictions(),
          new Reports.LoadActiveReport(),
        ]);
      }),
    );

  @Effect()
  notLoggedIn$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.NOT_LOGGED_IN),
      debounceTime(this.debounce, this.scheduler || async),
      switchMap((action: Auth.NotLoggedIn) => {
        let toastrMessage = this.translate.instant('amedia.login.loginFailed');

        if (!!action.errors) {
          const parsedErrors: Errors = parseShimErrors(action.errors['error'] || {});
          if (
            !_.isEmpty(parsedErrors.general) &&
            (typeof(parsedErrors.general[0]) === 'string')
          ) {
            toastrMessage = parsedErrors.general[0];
          } else if (!_.isEmpty(parsedErrors.general) && typeof(parsedErrors.general[0] === Object)) {
            const msg: string  = parsedErrors.general[0].message;
            if (msg.includes('parent organization is disabled')) {
              toastrMessage = this.translate.instant('amedia.toastr.orgDisabled');
            } else if (msg.includes('parent organization is suspended')) {
              toastrMessage = this.translate.instant('amedia.toastr.orgSuspended');
            }
          }
        }

        this.auth$.pipe(take(1)).subscribe((authState) => {
          if (authState.loginAttempted) {
            this.toastr.clear();
            this.toastr.error(
              toastrMessage,
              '',
              INLINE_CONFIG,
            );
            addToasterButtonType(this.toastr);
          }

          this.router.navigate([ '/auth/login' ]);
        });
        return empty();
      }),
    );

  @Effect()
  logout$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.LOGOUT),
      debounceTime(this.debounce, this.scheduler || async),
      switchMap((action: Auth.Logout) => {
        this.idleTimeoutService.block();
        return this.ams.logout().pipe(
          switchMap((res) => empty()),
        );
      }),
    );

  @Effect()
  anonRequired$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.ANON_REQUIRED),
      debounceTime(this.debounce, this.scheduler || async),
      switchMap((action: Auth.AnonymousRequired) => {
        this.router.navigate([ '/app/content/media' ]);
        return empty();
      }),
    );

  // Listen for the 'SAVE_USER' action
  @Effect()
  saveUser$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.SAVE_USER),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Auth.SaveUser) => {
        return this.ams.saveSelf(action.user).pipe(
          mergeMap((user) => {
            // Always update the store with the new user value
            let actions: Action[] = [new Auth.SaveUserSucceeded(user)];

            this.auth$.pipe(take(1)).subscribe((authState) => {
              // The user changed their password or question before the first load
              if (!authState.firstUserLoadComplete) {
                if (!!user.securityQuestion) {
                  // We're done if they have a security question
                  actions.push(new Auth.FirstLoad());
                } else {
                  // Ask them for a question / answer if they don't have one
                  this.router.navigate([ '/auth/changequestion' ]);
                }
              } else {
                this.toastr.success(
                  this.translate.instant(
                    'amedia.successMessage',
                    {
                      verbPast: this.translate.instant('amedia.updated'),
                      itemType: _.lowerCase(
                        this.translate.instant('amedia.settings.profile.myProfile'),
                      ),
                      item: `${user.firstName} ${user.lastName}`,
                    },
                  ),
                );
              }
            });

            return from(actions);
          }),
          catchError((error) => {
            const errors: Errors = parseShimErrors(error.error || {});

            if (_.isEmpty(errors.fields)) {
              let toastConfig: any;

              this.auth$.pipe(take(1)).subscribe((authState) => {
                if (!authState.firstUserLoadComplete) {
                  toastConfig = INLINE_CONFIG;
                  this.toastr.clear();
                }
              });

              this.toastr.error(
                _.isEmpty(errors.general)
                  ? this.translate.instant('amedia.errors.failedToSaveProfile')
                  : errors.general[0],
                '',
                toastConfig,
              );
              addToasterButtonType(this.toastr);
            }

            return of(new Auth.SaveUserFailed(errors));
          }),
        );
      }),
    );

  // Listen for the 'LOAD_ORG' action
  @Effect()
  loadOrg$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.LOAD_ORG),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Auth.LoadOrg) => {
        return this.ams.getOrg().pipe(
          map((org) => new Auth.LoadOrgSucceeded(org)),
          catchError((error) => of(new Auth.LoadOrgFailed())),
        );
      }),
    );

  // Listen for the 'SWITCH_ORG' action
  @Effect()
  switchOrg$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.SWITCH_ORG),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Auth.SwitchOrg) => {
        let currOrgId: string;
        this.auth$.pipe(take(1)).subscribe((authState) => currOrgId = authState.currOrgId);

        if (currOrgId === action.orgId) {
          return empty();
        }

        return this.ams.switchOrg(action.orgId).pipe(
          map((res) => new Auth.FirstLoad()),
          catchError((error) => {
            const errors: Errors = parseShimErrors(error.error || {});
            return of(new Auth.SwitchOrgFailed(errors));
          }),
        );
      }),
    );

  // Listen for 'SWITCH_ORG_FAILED' action
  @Effect()
  switchOrgFailed$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.SWITCH_ORG_FAILED),
      // .debounceTime(this.debounce, this.scheduler || async)
      switchMap((action: Auth.SwitchOrgFailed) => {
        this.toastr.error(
          _.isEmpty(action.errors.general) ?
            this.translate.instant('amedia.toastr.switchOrgError') :
            action.errors.general[0],

            this.translate.instant('amedia.content.error'),
        );
        return empty();
      }),
    );

  // Listen for the 'LOAD_ORG_LIST' action
  @Effect()
  loadOrgList$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.LOAD_ORG_LIST),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Auth.LoadOrgList) => {
        let userId: string;
        this.auth$.pipe(take(1)).subscribe((authState) => userId = authState.user.id);

        return this.ams.getOrgs(userId).pipe(
          map((orgs) => new Auth.LoadOrgListSucceeded(orgs)),
          catchError((error) => of(new Auth.LoadOrgListFailed())),
        );
      }),
    );

  // Listen for the 'SAVE_ORG' action
  @Effect()
  saveOrg$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.SAVE_ORG),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Auth.SaveOrg) => {
        return this.ams.saveOrg(action.org).pipe(
          map((org) => {
            this.toastr.success(
              this.translate.instant(
                'amedia.successMessage',
                {
                  verbPast: this.translate.instant('amedia.updated'),
                  itemType: _.lowerCase(
                    this.translate.instant('amedia.settings.profile.orgProfile'),
                  ),
                  item: `${org.name}`,
                },
              ),
              this.translate.instant('amedia.success'),
            );

            return new Auth.SaveOrgSucceeded(org);
          }),
          catchError((error) => {
            const errors: Errors = parseShimErrors(error.error || {});

            this.toastr.error(
              _.isEmpty(errors.general)
                ? this.translate.instant('amedia.errors.failedToSaveOrg')
                : errors.general[0],
              '',
            );

            return of(new Auth.SaveOrgFailed());
          }),
        );
      }),
    );

  // Listen for the 'START_PASSWORD_RESET' action
  @Effect()
  startPasswordReset$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.START_PASSWORD_RESET),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Auth.StartPasswordReset) => {
        return this.ams.getSecurityQuestion(action.email).pipe(
          map((question) => question
            ? new Auth.SecurityQuestionRequired(question) // ask for the security answer
            : new Auth.AnswerSecurityQuestion(''), // bypass question if not specified
          ),
          catchError((error) => of(new Auth.UserNotFound(action.email))),
        );
      }),
    );

  // Listen for the 'USER_NOT_FOUND' action
  @Effect()
  userNotFound$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.USER_NOT_FOUND),
      // .debounceTime(this.debounce, this.scheduler || async)
      switchMap((action: Auth.UserNotFound) => {
        this.toastr.clear();
        this.toastr.error(
          this.translate.instant('amedia.forgotPwd.notAbleToEmail'),
          '',
          INLINE_CONFIG,
        );
        addToasterButtonType(this.toastr);
        return empty();
      }),
    );

  // Listen for the 'SECURITY_QUESTION_REQUIRED' action
  @Effect()
  securityQuestionRequired$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.SECURITY_QUESTION_REQUIRED),
      debounceTime(this.debounce, this.scheduler || async),
      switchMap((action: Auth.SecurityQuestionRequired) => {
        this.router.navigate([ '/auth/answerquestion' ]);
        return empty();
      }),
    );

  // Listen for the 'ANSWER_SECURITY_QUESTION' action
  @Effect()
  answerSecurityQuestion$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.ANSWER_SECURITY_QUESTION),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Auth.AnswerSecurityQuestion) => {
        let email: string;
        this.auth$.pipe(take(1)).subscribe((authState) => email = authState.pwdResetEmail);

        return this.ams.answerSecurityQuestion(email, action.answerHash).pipe(
          map((success) => success['securityResponse']
            ? new Auth.PasswordResetSent()
            : new Auth.SecurityQuestionAnsweredWrongly(),
          ),
          catchError((error) => of(new Auth.SecurityQuestionAnsweredWrongly())),
        );
      }),
    );

  // Listen for the 'PASSWORD_RESET_SENT' action
  @Effect()
  passwordResetSent$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.PASSWORD_RESET_SENT),
      debounceTime(this.debounce, this.scheduler || async),
      switchMap((action: Auth.PasswordResetSent) => {
        this.router.navigate([ '/auth/passwordsent' ]);
        return empty();
      }),
    );

  // Listen for the 'SECURITY_QUESTION_ANSWERED_WRONGLY' action
  @Effect()
  securityQuestionAnsweredWrongly$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.SECURITY_QUESTION_ANSWERED_WRONGLY),
      // .debounceTime(this.debounce, this.scheduler || async)
      switchMap((action: Auth.SecurityQuestionAnsweredWrongly) => {
        this.toastr.clear();
        this.toastr.error(
          this.translate.instant('amedia.forgotPwd.wrongAnswer'),
          '',
          INLINE_CONFIG,
        );
        addToasterButtonType(this.toastr);
        return empty();
      }),
    );

  // Listen for the 'RESEND_NEW_USER_EMAIL' action
  @Effect()
  resendNewUserEmail$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.RESEND_NEW_USER_EMAIL),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Auth.ResendNewUserEmail) => {
        return this.ams.resendNewUserEmail(action.userId).pipe(
          map((p) => {
            this.toastr.success(
              this.translate.instant('amedia.settings.users.emailSent.description'),
              this.translate.instant('amedia.settings.users.emailSent'),
            );
            return new Auth.ResendNewUserEmailSucceeded(action.userId);
          }),
          catchError((error) => {
            error = error.error || { errorMessage: null };
            this.toastr.error(
              error.errorMessage || this.translate.instant('amedia.settings.users.failedToSendEmail'),
              this.translate.instant('common.error.label'),
            );
            return of(new Auth.ResendNewUserEmailFailed(action.userId));
          }),
        );
      }),
    );

   // Listen for the 'LOAD_FEATURE_SETS' action
   @Effect()
   loadFeatureSetsAction$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.LOAD_FEATURE_SETS),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Auth.LoadFeatureSets) => {
        return this.fsas.getFeatureList().pipe(
          map((featureSet) => {
            return new Auth.LoadFeatureSetsSucceeded(featureSet);
          }),
          catchError((e) => of(new Auth.LoadFeatureSetsFailed())),
        );
      }),
     );

    // Listen for the 'STATUS_ERROR' action
    @Effect()
    statusError$: Observable<Action> = this.actions$
     .pipe(
      ofType(Auth.STATUS_ERROR),
      debounceTime(500, this.scheduler || async),
      switchMap((action: Auth.StatusError) => {
        this.auth$.pipe(take(1)).subscribe((authState) => {
          this.toastr.clear();
          this.toastr.error(
            this.translate.instant('common.services.httpErrNotifier.errorOccuredWhileProcessingRequest') +
              (action.status === 503
                ? ` ${this.translate.instant('amedia.login.serviceUnavailable')}`
                : ''
              ),
            authState.user ? this.translate.instant('common.error.label') : '',
            !authState.user ? INLINE_CONFIG : undefined,
          );
          if (!authState.user) {
            addToasterButtonType(this.toastr);
          }
        });
        return empty();
      }),
     );

  // Listen for 'LOAD_FFMPEG_VERSIONS'
  @Effect()
  loadFfmpegVersions$: Observable<Action> = this.actions$
    .pipe(
      ofType(Auth.LOAD_FFMPEG_VERSIONS),
      switchMap((action: Auth.LoadFfmpegVersions) => {
        return this.ams.getFfmpegVersions().pipe(
          map((res: FfmpegVersionInfo[]) => {
            return new Auth.LoadFfmpegVersionsSucceeded(res);
          }),
        );
      }),
    );

  // Listen for 'GET_PLAYER_NONCE'
  @Effect()
  getPlayerNonce$: Observable<Action> = this.actions$
  .pipe(
    ofType<Auth.GetPlayerNonce>(Auth.GET_PLAYER_NONCE),
    switchMap(() => {
      return this.nonceService.getNonce(this.env.PLAYER_NONCE_EXPIRY_SECS).pipe(
        map((nonce: string) => new Auth.GetPlayerNonceSucceeded(nonce)),
      );
    }),
  );

  // Listen for 'GET_PUBLISHER_TIMEZONE'
  @Effect()
  getPublisherTimezone$: Observable<Action> = this.actions$
  .pipe(
      ofType(Auth.GET_PUBLISHER_TIMEZONE),
      switchMap((action: Auth.GetPublisherTimezone) => {
        return this.vars.getPublisherTimezone().pipe(
          map((res: string) => {
            return new Auth.GetPublisherTimezoneSucceeded(res);
          }),
        );
      }),
  );

  auth$: Observable<fromAuth.State>;
  env: Configuration;

  constructor(
    private actions$: Actions,
    private ams: AccountMgmtService,
    private vars: AnalyticsService,
    private fsas: FeatureSetAuthorizationService,
    private winRef: WindowRef,
    private router: Router,
    private store: Store<fromRoot.State>,
    private translate: TranslateService,
    private toastr: ToastrService,
    private idleTimeoutService: IdleTimeoutService,
    private nonceService: PlayerNonceService,
    private envConfigService: EnvConfigurationService,
    @Optional() @Inject(DEBOUNCE) private debounce: number = 0,
    /**
     * You inject an optional Scheduler that will be undefined
     * in normal application usage, but its injected here so that you can mock out
     * during testing using the RxJS TestScheduler for simulating passages of time.
     */
    @Optional() @Inject(SCHEDULER) private scheduler: Scheduler,
  ) {
    this.envConfigService.load().subscribe((res) => {
      this.env = res;
    });
    this.auth$ = this.store.pipe(select(fromRoot.getAuthState));
  }

  getNextUrl(): string {
    let nextUrl: string;

    this.auth$.pipe(take(1)).subscribe((auth) => nextUrl = auth.nextUrl);

    return nextUrl;
  }
}
