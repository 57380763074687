import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DRIVERS, Locker } from 'angular-safeguard';

import { SelectItem } from '../../shared/models/select-item';
import { WindowRef } from '../../shared/utils/window-ref';

@Component({
  selector: 'll-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  languagesList: SelectItem[] = [
    { value: 'en_US', text: 'amedia.language.englishLocalized' },
    { value: 'ja_JP', text: 'amedia.language.japaneseLocalized' },
  ];

  constructor(
    private winRef: WindowRef,
    private locker: Locker,
    private router: Router,
    private translate: TranslateService,
  ) { }

  onLangSelect(lang: string): void {
    // On navigation, the PromptForDeactivateGuard canDeactivate guard runs.
    // Components where changes might occur (forms) have promptForDeactivate() boolean functions.
    // PromptForDeactivateGuard pops up a modal to confirm page changes.
    this.router.navigate([!!this.router && this.router.url]).then((nav) => {
      if (nav) {
        this.locker.set(DRIVERS.LOCAL, 'lang', lang);
        this.translate.use(lang);

        // Do not reload on auth pages.
        if (!(this.router.url.indexOf('auth') >= 0)) {
          this.winRef.nativeWindow.location.reload();
        }
      }
    });
  }
}
