import * as channel from '../actions/channel';
import { Channel } from '../../models/channel';
import { PaginatedResponse } from '../../models/paginated-response';
import { Media } from '../../models/media';
import * as _ from 'lodash';
import { ContentFilters } from '../../models/content-filters';
import { AdConfig, AdConfigMedia, AdConfigType } from '../../models/ad-config';

export enum ChannelSection {
  Ads = 'ads',
  CustomProps = 'customProps',
  MediaList = 'mediaList',
  PlayerOptions = 'playerOptions',
  Preview = 'preview',
  Properties = 'properties',
  RssCategories = 'rssCategories',
}

export interface State {
  adConfig: AdConfig;
  allMediaInChannelSelected: boolean;
  allMediaForPickerSelected: boolean;
  tmpChannelAdMedia: AdConfigMedia[];
  busySections: {[channelID: number]: ChannelSection[]};
  failedSections: {[channelID: number]: ChannelSection[]};
  currChannel: Channel;
  currChannelLoading: boolean;
  currChannelMedia: PaginatedResponse | null;
  filteredMediaForPicker: PaginatedResponse;
  lastSelectedMediaInChannel: any | null;
  lastSelectedMediaForPicker: any | null;
  loadingAdMedia: boolean;
  queryText: string;
  removingMediaFailed: boolean;
  saveChannelMediaListFailed: boolean;
  saveFailed: boolean;
  filteringText: boolean;
  mediaInChannelFilters: ContentFilters;
  mediaNotInChannelFilters: ContentFilters;
}

export const initialState: State = {
  adConfig: null,
  allMediaInChannelSelected: false,
  allMediaForPickerSelected: false,
  tmpChannelAdMedia: null,
  busySections: {},
  failedSections: {},
  currChannel: null,
  currChannelLoading: false,
  currChannelMedia: null,
  filteredMediaForPicker: null,
  lastSelectedMediaInChannel: null,
  lastSelectedMediaForPicker: null,
  loadingAdMedia: false,
  queryText: null,
  removingMediaFailed: false,
  saveChannelMediaListFailed: false,
  saveFailed: false,
  filteringText: false,
  mediaInChannelFilters: {
    page: 1,
    pageSize: 10,
    searchText: '',
    queryParams: {},
    sortBy: null,
    sortDir: null,
  },
  mediaNotInChannelFilters: {
    page: 1,
    pageSize: 25,
    searchText: '',
    queryParams: {},
    sortBy: null,
    sortDir: null,
  },
};

const markSectionBusy = (
  state: State,
  channelID: string,
  section: ChannelSection,
): {[channelID: number]: ChannelSection[]} => {
  const newSections = _.clone(state.busySections);

  if (newSections[channelID] === undefined) {
    newSections[channelID] = [];
  }

  newSections[channelID] = [
    ...newSections[channelID],
    section,
  ];

  return newSections;
};

const markSectionNotBusy = (
  state: State,
  channelID: string,
  section: ChannelSection,
): {[channelID: number]: ChannelSection[]} => {
  const newSections = _.clone(state.busySections);

  newSections[channelID] = _.filter(newSections[channelID], (v, k) => v !== section);

  return newSections;
};

const markSectionFailed = (
  state: State,
  mediaID: string,
  section: ChannelSection,
): {[mediaID: number]: ChannelSection[]} => {
  const newSections = _.clone(state.failedSections);

  if (newSections[mediaID] === undefined) {
    newSections[mediaID] = [];
  }

  newSections[mediaID] = [
    ...newSections[mediaID],
    section,
  ];

  return newSections;
};

const markSectionNotFailed = (
  state: State,
  mediaID: string,
  section: ChannelSection,
): {[mediaID: number]: ChannelSection[]} => {
  const newSections = _.clone(state.failedSections);

  newSections[mediaID] = _.filter(newSections[mediaID], (v, k) => v !== section);

  return newSections;
};

export function reducer(
  state = initialState,
  action: channel.Actions,
): State {
  let newValues: Partial<State>;

  switch (action.type) {
    case channel.SELECT_ALL:
      let checked: boolean;
      // for select all on channel-details
      if (action.mediaList === 'currChannelMedia') {
        state.allMediaInChannelSelected = !state.allMediaInChannelSelected;
        checked = state.allMediaInChannelSelected;
      } else {
        // for select all on add channel media modal
        state.allMediaForPickerSelected = !state.allMediaForPickerSelected;
        checked = state.allMediaForPickerSelected;
      }
      state[action.mediaList].results = _.map(
        state[action.mediaList].results,
        (m) => {
          m.selected = checked;
          return m;
        },
      );
      return state;
    case channel.TOGGLE_SELECTION:
      const clickedItem = state[action.mediaList].results.find(
        (item) => item.mediaId === action.payload.item.mediaId,
      );

      // Update correct state depending on which media list we are touching
      const lastSelected = action.mediaList === 'currChannelMedia'
        ? 'lastSelectedMediaInChannel'
        : 'lastSelectedMediaForPicker';

      // allows for shift select
      if (state[lastSelected] && action.payload.shiftPressed) {
        const desiredValue = !clickedItem.selected;
        let startIndex = state[action.mediaList].results.indexOf(state[lastSelected]);
        let endIndex = state[action.mediaList].results.indexOf(clickedItem);
        if (startIndex > endIndex) {
          const tmpIndex = startIndex;
          startIndex = endIndex;
          endIndex = tmpIndex;
        }
        for (let index = startIndex; index <= endIndex; index++) {
          state[action.mediaList].results[index].selected = desiredValue;
        }
      } else {
        clickedItem.selected = !clickedItem.selected;
      }
      state[action.selectionType] = state[action.mediaList].results.every((item) => item.selected);
      state[lastSelected] = clickedItem;
      return state;
    case channel.LOAD_ADS:
      return {
        ...state,
        adConfig: null,
        busySections: markSectionBusy(state, action.channelID, action.section),
      };
    case channel.LOAD_ADS_FAILED:
      return {
        ...state,
        busySections: markSectionNotBusy(state, action.channelID, action.section),
      };
    case channel.LOAD_ADS_SUCCEEDED:
      return {
        ...state,
        adConfig: action.adConfig,
        busySections: markSectionNotBusy(state, action.channelID, action.section),
      };
    case channel.LOAD_CHANNEL:
      return {
        ...state,
        currChannelLoading: true,
      };
    case channel.LOAD_CHANNEL_FAILED:
      return {
        ...state,
        currChannel: null,
        currChannelLoading: false,
      };
    case channel.LOAD_CHANNEL_SUCCEEDED:
      return {
        ...state,
        currChannel: action.channel,
        currChannelLoading: false,
      };
    case channel.LOAD_CHANNEL_MEDIA:
      newValues = {};

      if (action.clearContent) {
        newValues.currChannelMedia = null;
        newValues.mediaInChannelFilters = _.cloneDeep(action.filters);
      } else {
        // Merge in non-nil filters
        newValues.mediaInChannelFilters = {
          ...state.mediaInChannelFilters,
          ..._.omitBy(action.filters, _.isNil),
        };
      }
      return {
        ...state,
        busySections: markSectionBusy(state, action.channelID, ChannelSection.MediaList),
        ...newValues,
      };
    case channel.LOAD_CHANNEL_MEDIA_SUCCEEDED:
      return {
        ...state,
        busySections: markSectionNotBusy(state, action.channelID, ChannelSection.MediaList),
        currChannelMedia: action.payload,
        allMediaInChannelSelected: false,
        lastSelectedMediaInChannel: null,
      };
    case channel.LOAD_CHANNEL_MEDIA_FAILED:
      return {
        ...state,
        busySections: markSectionNotBusy(state, action.channelID, ChannelSection.MediaList),
      };
    case channel.LOAD_MEDIA_FOR_PICKER:
      newValues = {};

      if (action.clearList) {
        newValues.filteredMediaForPicker = null;
        newValues.mediaNotInChannelFilters = _.cloneDeep(action.filters);
      } else {
        // Merge in non-nil filters
        newValues.mediaNotInChannelFilters = {
          ...state.mediaNotInChannelFilters,
          ..._.omitBy(action.filters, _.isNil),
        };
      }
      return {
        ...state,
        busySections: markSectionBusy(state, action.channelID, ChannelSection.MediaList),
        filteringText: action.filters.searchText !== undefined,
        ...newValues,
        allMediaForPickerSelected: false,
      };
    case channel.LOAD_MEDIA_FOR_PICKER_SUCCEEDED:
      return {
        ...state,
        busySections: markSectionNotBusy(state, action.channelID, ChannelSection.MediaList),
        filteringText: false,
        filteredMediaForPicker: action.mediaList,
      };
    case channel.LOAD_MEDIA_FOR_PICKER_FAILED:
      return {
        ...state,
        busySections: markSectionNotBusy(state, action.channelID, ChannelSection.MediaList),
        filteringText: false,
        filteredMediaForPicker: null,
      };
    case channel.UPDATE_CHANNEL_SECTION:
      return {
        ...state,
        busySections: markSectionBusy(state, action.channelID, action.section),
        failedSections: markSectionNotFailed(state, action.channelID, action.section),
      };
    case channel.UPDATE_CHANNEL_SECTION_FAILED:
      return {
        ...state,
        busySections: markSectionNotBusy(state, action.channelID, action.section),
        failedSections: markSectionFailed(state, action.channelID, action.section),
        saveFailed: true,
      };
    case channel.UPDATE_CHANNEL_SECTION_SUCCEEDED:
      if (action.section === ChannelSection.Ads) {
        if (_.get(action, 'newValues.adConfigurationType') === AdConfigType.ChannelMerge) {
          action.newValues.details.weights = _.map(
            state.tmpChannelAdMedia,
            (m, index) => ({
              ...m,
              weight: action.newValues.details.weights[m.id] || '1',
            }),
          );
        }

        return {
          ...state,
          adConfig: action.newValues ? { ...action.newValues } : null,
          busySections: markSectionNotBusy(state, action.channelID, action.section),
        };
      } else if (action.section === ChannelSection.Preview) {
        return {
          ...state,
          currChannel: {
            ...state.currChannel,
            thumbnail_url: action.newValues.thumbnail_url,
          },
          busySections: markSectionNotBusy(state, action.channelID, action.section),
        };
      } else if (action.section === ChannelSection.CustomProps) {
        return {
          ...state,
          currChannel: _.assign(
            {},
            _.cloneDeep(state.currChannel),
            _.cloneDeep({ custom_properties: action.newValues.custom_properties }),
          ),
          busySections: markSectionNotBusy(state, action.channelID, action.section),
        };
      } else {
        return {
          ...state,
          currChannel: _.assign(
            {},
            _.cloneDeep(state.currChannel),
            _.cloneDeep(action.newValues),
          ),
          busySections: markSectionNotBusy(state, action.channelID, action.section),
        };
      }
    case channel.GET_CHANNEL_AD_MEDIA:
      return {
        ...state,
        tmpChannelAdMedia: null,
        loadingAdMedia: true,
      };
    case channel.GET_CHANNEL_AD_MEDIA_FAILED:
      return {
        ...state,
        loadingAdMedia: false,
      };
    case channel.GET_CHANNEL_AD_MEDIA_SUCCEEDED:
      // The initial ad configuration gets parsed to a weight lookup object.  If we haven't already,
      // convert to an array of 'media' with the proper weights.
      const needWeights: boolean = !!state.adConfig && !_.isArray(state.adConfig.details.weights);
      const mediaWithWeights = _.map(
        action.mediaList.results,
        (m) => ({
          id: m.media_id,
          title: m.title,
          weight: _.get(state.adConfig, `details.weights.${m.media_id}`) || '1',
        }),
      );

      return {
        ...state,
        tmpChannelAdMedia: mediaWithWeights,
        adConfig: !needWeights
          ? state.adConfig
          : {
            ...state.adConfig,
            details: {
              ...state.adConfig.details,
              weights: mediaWithWeights,
            },
          },
        loadingAdMedia: false,
      };
    case channel.RESET_TMP_CHANNEL_AD_MEDIA:
      return {
        ...state,
        tmpChannelAdMedia: action.media,
      };
    case channel.BULK_ADD_MEDIA:
    case channel.BULK_REMOVE_MEDIA:
    case channel.REORDER_SINGLE_MEDIA:
    case channel.UPDATE_CHANNEL_MEDIA_LIST:
      return {
        ...state,
        busySections: markSectionBusy(state, action.channelID, ChannelSection.MediaList),
        saveChannelMediaListFailed: false,
      };
    case channel.UPDATE_CHANNEL_MEDIA_LIST_SUCCEEDED:
      return {
        ...state,
        busySections: markSectionNotBusy(state, action.channelID, ChannelSection.MediaList),
        allMediaForPickerSelected: false,
      };
    case channel.UPDATE_CHANNEL_MEDIA_LIST_FAILED:
      return {
        ...state,
        busySections: markSectionNotBusy(state, action.channelID, ChannelSection.MediaList),
        saveChannelMediaListFailed: true,
      };
    case channel.CLONE_CHANNEL:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export const getChannel = (state: State) => state;
