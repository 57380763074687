import * as developerTools from '../../actions/settings/developer-tools';
import * as _ from 'lodash';
import { SortDirection, SortParams } from 'control-ui-common';

import { CallbackListener } from '../../../models/callback-listeners';

export enum DevToolsSection {
  Properties = 'properties',
  ApiInfo = 'apiInfo',
}

export interface State {
  callbackListeners: CallbackListener[];
  callbackListenersLoading: boolean;
  currCallbackListener: CallbackListener;
  currCallbackSaving: boolean;
  deletingCallback: boolean;
  filteredCallbackListeners: CallbackListener[];
  queryText: string;
  savingFailed: boolean;
  sortParams: {
    sortBy: string,
    sortDir: SortDirection,
  };
}

export const initialState: State = {
  callbackListeners: [],
  callbackListenersLoading: false,
  currCallbackListener: null,
  currCallbackSaving: false,
  deletingCallback: false,
  filteredCallbackListeners: [],
  queryText: '',
  savingFailed: false,
  sortParams: {
    sortBy: 'name',
    sortDir: SortDirection.ASC,
  },
};

const filterCallbackListeners = (callbackArr: CallbackListener[], q: string): CallbackListener[] => {
  return (
    q.length > 0 ?
    _.filter(callbackArr, (cb) => {
      return cb.name.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) >= 0;
    }) :
    [ ...callbackArr ]
  );
};

const sortCallbackListeners = (propArray: CallbackListener[], p: SortParams): CallbackListener[] => {
  return _.orderBy(
    _.cloneDeep(propArray),
    [(cp) => _.toString(cp[p.sortBy]).toLowerCase()],
    [p.sortDir],
  );
};

export function reducer(
  state = initialState,
  action: developerTools.Actions,
): State {
  switch (action.type) {
    case developerTools.ADD_CALLBACK_LISTENER:
    case developerTools.UPDATE_CALLBACK_LISTENER:
      return {
        ...state,
        currCallbackSaving: true,
        savingFailed: false,
      };
    case developerTools.ADD_CALLBACK_LISTENER_FAILED:
      return {
        ...state,
        currCallbackSaving: false,
        savingFailed: true,
      };
    case developerTools.ADD_CALLBACK_LISTENER_SUCCEEDED:
      return {
        ...state,
        currCallbackListener: null,
        callbackListeners: sortCallbackListeners(
          filterCallbackListeners(
            [...state.callbackListeners, action.listener],
            state.queryText,
          ),
          state.sortParams,
        ),
        filteredCallbackListeners: sortCallbackListeners(
          filterCallbackListeners(
            [...state.callbackListeners, action.listener],
            state.queryText,
          ),
          state.sortParams,
        ),
        currCallbackSaving: false,
        savingFailed: false,
      };
    case developerTools.DELETE_CALLBACK_LISTENER:
      return {
        ...state,
        deletingCallback: true,
      };
    case developerTools.DELETE_CALLBACK_LISTENER_FAILED:
      return {
        ...state,
        deletingCallback: false,
      };
    case developerTools.DELETE_CALLBACK_LISTENER_SUCCEEDED:
      const listener = !!state.currCallbackListener && action.id === state.currCallbackListener.id ?
      null : state.currCallbackListener;
      return {
        ...state,
        callbackListeners: _.filter(state.callbackListeners, (p) => p.id !== action.id),
        currCallbackListener: listener,
        deletingCallback: false,
        filteredCallbackListeners: _.filter(state.callbackListeners, (p) => p.id !== action.id),
      };
    case developerTools.LOAD_CALLBACK_LISTENERS:
      return {
        ...state,
        callbackListenersLoading: true,
        currCallbackListener: null,
        savingFailed: false,
      };
    case developerTools.LOAD_CALLBACK_LISTENERS_FAILED:
      return {
        ...state,
        callbackListeners: [],
        callbackListenersLoading: false,
      };
    case developerTools.LOAD_CALLBACK_LISTENERS_SUCCEEDED:
      return {
        ...state,
        callbackListeners: sortCallbackListeners(
          action.listeners,
          state.sortParams,
        ),
        callbackListenersLoading: false,
        filteredCallbackListeners: sortCallbackListeners(
          action.listeners,
          state.sortParams,
        ),
        queryText: '',
      };
    case developerTools.FILTER_CALLBACK_LISTENERS:
      return {
        ...state,
        filteredCallbackListeners: sortCallbackListeners(
          filterCallbackListeners(
            state.callbackListeners,
            action.filter,
          ),
          state.sortParams,
        ),
        queryText: action.filter,
      };
    case developerTools.SET_CURR_CALLBACK_LISTENER:
      return {
        ...state,
        currCallbackListener: _.cloneDeep(action.listener),
      };
    case developerTools.UNSET_CURR_CALLBACK_LISTENER:
      return {
        ...state,
        currCallbackListener: null,
      };
    case developerTools.UPDATE_CALLBACK_LISTENER_FAILED:
      return {
        ...state,
        currCallbackSaving: false,
        savingFailed: true,
      };
    case developerTools.UPDATE_CALLBACK_LISTENER_SUCCEEDED:
      const i = _.findIndex(state.callbackListeners, { id: action.listener.id });
      state.callbackListeners[i] = _.cloneDeep(action.listener);
      return {
        ...state,
        callbackListeners: sortCallbackListeners(state.callbackListeners, state.sortParams),
        currCallbackListener: null,
        currCallbackSaving: false,
        filteredCallbackListeners: sortCallbackListeners(
          filterCallbackListeners(
            state.callbackListeners,
            state.queryText,
          ),
          state.sortParams,
        ),
        savingFailed: false,
      };
    default:
      return state;
  }
}

export const getDeveloperTools = (state: State) => state;
