import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/*
  * Transforms the state of a media/channel to a more elegant/readable string.
*/

@Pipe({
  name: 'llStateName',
})
export class StateNamePipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
  ) {}

  transform(text: string): string {
    switch (text) {
    case 'Publishable':
    case 'NotPublished':
      return this.translate.instant('amedia.content.unpublished');
    case 'Error':
      return this.translate.instant('amedia.content.error');
    case 'Published':
        return this.translate.instant('common.published');
    default:
      return text;
    }
  }
}
