import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LLCommonModule } from 'control-ui-common';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';

import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { BaseAuthComponent } from './base-auth.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AnswerQuestionComponent } from './answer-question/answer-question.component';
import { PasswordSentComponent } from './password-sent/password-sent.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeQuestionComponent } from './change-question/change-question.component';

@NgModule({
  imports: [
    SharedModule,
    NgbModule,
    LLCommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    ToastrModule,
    ToastContainerModule,
  ],
  declarations: [
    BaseAuthComponent,
    LoginComponent,
    LogoutComponent,
    ForgotPasswordComponent,
    AnswerQuestionComponent,
    PasswordSentComponent,
    ChangePasswordComponent,
    ChangeQuestionComponent,
  ],
})
export class AuthModule { }
