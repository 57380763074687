import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Duration, duration, utc } from 'moment';

@Directive({ selector: '[llDuration]' })
export class DurationDirective {
  previousDuration: Duration;
  duration: Duration;
  millisEnabled: boolean = false;
  @Input('llDurationSeconds') set llDurationSeconds(d: number) {
    this.duration = duration(d, 'seconds');
    this.previousDuration = this.duration;
    this.millisEnabled = false;
    this.updateFormattedValue();
  }
  @Input('llDurationMillis') set llDurationMillis(d: number) {
    this.duration = duration(d, 'milliseconds');
    this.previousDuration = this.duration;
    this.millisEnabled = true;
    this.updateFormattedValue();
  }
  @Output() attemptChange: EventEmitter<number> = new EventEmitter();
  constructor(private el: ElementRef) { }

  updateFormattedValue(): void {
    const formatStr = this.millisEnabled ? 'HH:mm:ss.SSS' : 'HH:mm:ss';
    this.el.nativeElement.value = utc(this.duration.as('milliseconds')).format(formatStr);
  }

  propagateChange(): void {
    const newDuration = duration(this.el.nativeElement.value);
    if (this.previousDuration.as('milliseconds') !== newDuration.as('milliseconds')) {
      this.attemptChange.emit(
        duration(this.el.nativeElement.value).as(
          this.millisEnabled ? 'milliseconds' : 'seconds',
        ),
      );
    }
    this.updateFormattedValue();
  }

  @HostListener('keydown', ['$event'])
  onKeyPress(e: KeyboardEvent): void {
    if (e.keyCode === 13) {
      this.propagateChange();
    }
  }

  @HostListener('change', ['$event'])
  onChange(e: any): void {
    this.propagateChange();
  }
}
