import * as _ from 'lodash';

import * as media from '../actions/media';
import { Media } from '../../models/media';
import { Channel } from '../../models/channel';
import { Encoding } from '../../models/media-encoding';
import { Cuepoint } from '../../models/cuepoints';

export enum MediaSection {
  Preview = 'preview',
  Captions = 'closedCaptions',
  Cuepoints = 'cuepoints',
  Properties = 'properties',
  Source = 'source',
  Encodings = 'encodings',
  Snippets = 'snippets',
  ClosedCaptions = 'closedCaptions',
  CustomProperties = 'customProperties',
  ViewRestrictions = 'viewRestrictions',
}

export interface State {
  currMedia: Media | null;
  currMediaLoading: boolean;
  defaultPublishState: boolean;
  defaultPublishStateFailed: boolean;
  defaultPublishStateLoading: boolean;
  busySections: {[mediaID: number]: MediaSection[]};
  failedSections: {[mediaID: number]: MediaSection[]};
  listChannelsForMedia: Channel[];
  listChannelsForMediaLoading: boolean;
  imagesLoading: {[actionID: number]: boolean};
  images: {[actionID: number]: string};
  suggestedTags: string[];
  suggestedTagsLoading: boolean; // media properties -> suggested tags
  mediaEncodings: Encoding[] | null;
  currMediaCuepoints: Cuepoint[] | null;
  ccVersions: {[mediaID: string]: number};
  ccDeleting: {[mediaID: string]: boolean};
}

export const initialState: State = {
  currMedia: null,
  currMediaLoading: false,
  defaultPublishState: false,
  defaultPublishStateFailed: false,
  defaultPublishStateLoading: false,
  busySections: {},
  failedSections: {},
  listChannelsForMedia: null,
  listChannelsForMediaLoading: false,
  imagesLoading: {},
  images: {},
  suggestedTags: [],
  suggestedTagsLoading: false,
  mediaEncodings: null,
  currMediaCuepoints: [],
  ccVersions: {},
  ccDeleting: {},
};

const markSectionBusy = (
  state: State,
  mediaID: string,
  section: MediaSection,
): {[mediaID: number]: MediaSection[]} => {
  const newSections = _.clone(state.busySections);

  if (newSections[mediaID] === undefined) {
    newSections[mediaID] = [];
  }

  newSections[mediaID] = [
    ...newSections[mediaID],
    section,
  ];

  return newSections;
};

const markSectionNotBusy = (
  state: State,
  mediaID: string,
  section: MediaSection,
): {[mediaID: number]: MediaSection[]} => {
  const newSections = _.clone(state.busySections);

  newSections[mediaID] = _.filter(newSections[mediaID], (v, k) => v !== section);

  return newSections;
};

const markSectionFailed = (
  state: State,
  mediaID: string,
  section: MediaSection,
): {[mediaID: number]: MediaSection[]} => {
  const newSections = _.clone(state.failedSections);

  if (newSections[mediaID] === undefined) {
    newSections[mediaID] = [];
  }

  newSections[mediaID] = [
    ...newSections[mediaID],
    section,
  ];

  return newSections;
};

const markSectionNotFailed = (
  state: State,
  mediaID: string,
  section: MediaSection,
): {[mediaID: number]: MediaSection[]} => {
  const newSections = _.clone(state.failedSections);

  newSections[mediaID] = _.filter(newSections[mediaID], (v, k) => v !== section);

  return newSections;
};

export function reducer(
  state = initialState,
  action: media.Actions,
): State {
  let currMedia: Media;
  switch (action.type) {
  case media.LOAD_MEDIA:
    return {
      ...state,
      currMediaLoading: true,
      currMedia: null,
    };
  case media.LOAD_MEDIA_SUCCEEDED:
    return {
      ...state,
      currMediaLoading: false,
      currMedia: action.media,
      ccVersions: {
        ...state.ccVersions,
        [action.media.mediaId]: action.media.closedCaptioningFileVersion,
      },
    };
  case media.LOAD_MEDIA_FAILED:
    return {
      ...state,
      currMediaLoading: false,
    };
  case media.EDIT_MEDIA_SECTION:
    return {
      ...state,
      busySections: markSectionBusy(state, action.mediaID, action.section),
      failedSections: markSectionNotFailed(state, action.mediaID, action.section),
    };
  case media.EDIT_MEDIA_SECTION_SUCCEEDED:
    if (action.section === MediaSection.CustomProperties) {
      // TODO: this construction is because other areas in vpws have been updated to reference
      // custom properties via id, but channel properties continue to use name
      let customProps = {};
      action.newValues.customProperties.forEach((propItem) => {
        customProps[propItem.propertyName] = propItem.value;
      });
      return {
        ...state,
        currMedia: _.assign(
          {},
          _.cloneDeep(state.currMedia),
          _.cloneDeep({ customProperties: customProps }),
        ),
        busySections: markSectionNotBusy(state, action.mediaID, action.section),
      };
    }
    return {
      ...state,
      currMedia: _.assign(
        {},
        _.cloneDeep(state.currMedia),
        _.cloneDeep(action.newValues),
      ),
      busySections: markSectionNotBusy(state, action.mediaID, action.section),
    };
  case media.EDIT_MEDIA_SECTION_FAILED:
  return {
    ...state,
    busySections: markSectionNotBusy(state, action.mediaID, action.section),
    failedSections: markSectionFailed(state, action.mediaID, action.section),
  };
  case media.LIST_CHANNELS_FOR_MEDIA:
    return {
      ...state,
      listChannelsForMedia: null,
      listChannelsForMediaLoading: true,
    };
  case media.LIST_CHANNELS_FOR_MEDIA_FAILED:
    return {
      ...state,
      listChannelsForMediaLoading: false,
    };
  case media.LIST_CHANNELS_FOR_MEDIA_SUCCEEDED:
    return {
      ...state,
      listChannelsForMedia: action.channelList,
      listChannelsForMediaLoading: false,
    };
  case media.LOAD_SUGGESTED_TAGS:
    return {
      ...state,
      suggestedTagsLoading: true,
      suggestedTags: null,
    };
  case media.LOAD_SUGGESTED_TAGS_FAILED:
    return {
      ...state,
      suggestedTagsLoading: false,
    };
  case media.LOAD_SUGGESTED_TAGS_SUCCEEDED:
    return {
      ...state,
      suggestedTagsLoading: false,
      suggestedTags: action.suggestedTags,
    };
  case media.LOAD_MEDIA_ENCODINGS:
    return {
      ...state,
      mediaEncodings: null,
      busySections: markSectionBusy(state, action.mediaID, MediaSection.Encodings),
    };
  case media.LOAD_MEDIA_ENCODINGS_SUCCEEDED:
    return {
      ...state,
      mediaEncodings: action.mediaEncodings,
      busySections: markSectionNotBusy(state, action.mediaID, MediaSection.Encodings),
    };
  case media.LOAD_MEDIA_ENCODINGS_FAILED:
    return {
      ...state,
      busySections: markSectionNotBusy(state, action.mediaID, MediaSection.Encodings),
    };
  case media.LOAD_CUEPOINTS:
    return {
      ...state,
      currMediaCuepoints: null,
      busySections: markSectionBusy(state, action.mediaID, MediaSection.Cuepoints),
    };
  case media.LOAD_CUEPOINTS_SUCCEEDED:
    return {
      ...state,
      currMediaCuepoints: action.cuepoints,
      busySections: markSectionNotBusy(state, action.mediaID, MediaSection.Cuepoints),
    };
  case media.LOAD_CUEPOINTS_FAILED:
    return {
      ...state,
      currMediaCuepoints: [],
      busySections: markSectionNotBusy(state, action.mediaID, MediaSection.Cuepoints),
    };
  case media.REPLACE_CUEPOINTS:
    return {
      ...state,
      busySections: markSectionBusy(state, action.mediaID, MediaSection.Cuepoints),
    };
  case media.REPLACE_CUEPOINTS_SUCCEEDED:
    return {
      ...state,
      currMediaCuepoints: _.cloneDeep(action.cuepoints),
      busySections: markSectionNotBusy(state, action.mediaID, MediaSection.Cuepoints),
    };
  case media.REPLACE_CUEPOINTS_FAILED:
    return {
      ...state,
      busySections: markSectionNotBusy(state, action.mediaID, MediaSection.Cuepoints),
    };
  case media.CREATE_CLIP:
    return {
      ...state,
      busySections: markSectionBusy(state, action.mediaID, MediaSection.Snippets),
    };
  case media.CREATE_CLIP_SUCCEEDED:
  case media.CREATE_CLIP_FAILED:
    return {
      ...state,
      busySections: markSectionNotBusy(state, action.mediaID, MediaSection.Snippets),
    };
  case media.SAVE_PREVIEW:
    return {
      ...state,
      busySections: markSectionBusy(state, action.contentId, MediaSection.Preview),
    };
  case media.SAVE_PREVIEW_SUCCEEDED:
    currMedia = _.cloneDeep(state.currMedia);
    if (!!action.previewImageURI) {
      currMedia.previewImageUrl = action.previewImageURI;
    }
    if (!!action.thumbImageURI) {
      currMedia.thumbnailImageUrl = action.thumbImageURI;
    }

    return {
      ...state,
      busySections: markSectionNotBusy(state, action.contentId, MediaSection.Preview),
      currMedia,
    };
  case media.SAVE_PREVIEW_FAILED:
  return {
    ...state,
    busySections: markSectionNotBusy(state, action.contentId, MediaSection.Preview),
  };
  case media.UPLOAD_IMAGE:
    return {
      ...state,
      imagesLoading: {
        ...state.imagesLoading,
        [action.id]: true,
      },
    };
  case media.UPLOAD_IMAGE_SUCCEEDED:
    return {
      ...state,
      images: {
        ...state.images,
        [action.id]: action.imageURL,
      },
      imagesLoading: {
        ...state.imagesLoading,
        [action.id]: false,
      },
    };
  case media.UPLOAD_IMAGE_FAILED:
    return {
      ...state,
      imagesLoading: {
        ...state.imagesLoading,
        [action.id]: false,
      },
    };
  case media.GET_PUBLISH_STATE:
  case media.UPDATE_DEFAULT_PUBLISH_STATE:
    return {
      ...state,
      defaultPublishStateFailed: false,
      defaultPublishStateLoading: true,
    };
  case media.GET_PUBLISH_STATE_FAILED:
    return {
      ...state,
      defaultPublishStateLoading: false,
    };
  case media.GET_PUBLISH_STATE_SUCCEEDED:
    return {
      ...state,
      defaultPublishState: action.publishState === 'Published' ? true : false,
      defaultPublishStateLoading: false,
    };
  case media.UPDATE_DEFAULT_PUBLISH_STATE_FAILED:
    return {
      ...state,
      defaultPublishStateFailed: true,
      defaultPublishStateLoading: false,
    };
  case media.UPDATE_DEFAULT_PUBLISH_STATE_SUCCEEDED:
    return {
      ...state,
      defaultPublishState: !state.defaultPublishState,
      defaultPublishStateLoading: false,
    };
  case media.CREATE_CLOSED_CAPTION:
    return {
      ...state,
      busySections: markSectionBusy(state, action.mediaID, MediaSection.ClosedCaptions),
    };
  case media.CREATE_CLOSED_CAPTION_SUCCEEDED:
  case media.CREATE_CLOSED_CAPTION_FAILED:
    return {
      ...state,
      busySections: markSectionNotBusy(state, action.mediaID, MediaSection.ClosedCaptions),
    };
  case media.UPDATE_CC_VERSIONS:
    currMedia = state.currMedia;
    if (!!currMedia && action.media.mediaId === currMedia.mediaId) {
      currMedia = action.media;
    }
    return {
      ...state,
      currMedia,
      ccVersions: {
        ...state.ccVersions,
        [action.media.mediaId]: action.media.closedCaptioningFileVersion,
      },
    };
  case media.DELETE_CLOSED_CAPTION:
    return {
      ...state,
      ccDeleting: {
        ...state.ccDeleting,
        [action.mediaID]: true,
      },
    };
  case media.DELETE_CLOSED_CAPTION_SUCCEEDED:
    let newVersion: number = (Math.abs(state.currMedia.closedCaptioningFileVersion) * -1);
    return {
      ...state,
      ccDeleting: {
        ...state.ccDeleting,
        [action.mediaID]: false,
      },
      ccVersions: {
        ...state.ccVersions,
        [action.mediaID]: newVersion,
      },
      currMedia: {
        ...state.currMedia,
        closedCaptionsDetails: null,
        closedCaptioningFileName: null,
        closedCaptioningFileVersion: newVersion,
      },
    };
  case media.DELETE_CLOSED_CAPTION_FAILED:
    return {
      ...state,
      ccDeleting: {
        ...state.ccDeleting,
        [action.mediaID]: false,
      },
    };
  default:
    return state;
  }
}
export const getCcVersions = (state: State) => state.ccVersions;
