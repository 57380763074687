import { EnvConfigurationService, Configuration } from './shared/services/environment-configuration.service';
import { IdleTimeoutModalComponent } from './shared/modals/idle-timeout-modal.component';
import { IdleTimeoutService } from './shared/services/idle-timeout.service';
import { Component, ViewContainerRef, Pipe } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, distinctUntilChanged, take } from 'rxjs/operators';
import * as _ from 'lodash';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var gtag: (...args: any[]) => void;

@Component({
  selector: 'll-root',
  template: `<router-outlet></router-outlet>`,
})
export class RootComponent {
  env: Configuration;

  constructor(
    public idleTimeoutService: IdleTimeoutService,
    public modalService: NgbModal,
    public router: Router,
    public viewContainerRef: ViewContainerRef, // Needed for color picker
    private envConfigService: EnvConfigurationService,
  ) {
      this.envConfigService.load().subscribe((res) => this.env = res);
      // listen to open idle timeout modal
      // this is the root component, so no unsubscribe needed
      idleTimeoutService.timeoutApproaching$.subscribe(() => {
        // close any open modals
        this.modalService.dismissAll();
        // open the idle modal
        this.modalService.open(IdleTimeoutModalComponent, {
          backdrop: 'static',
          keyboard: false,
        });
      });

      if (this.env.GOOGLE_ANALYTICS_ID) {
        // Add google analytics script
        let script: HTMLScriptElement = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${this.env.GOOGLE_ANALYTICS_ID}`;
        document.head.appendChild(script);

        script = document.createElement('script');
        script.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${this.env.GOOGLE_ANALYTICS_ID}', { 'send_page_view': false });
        `;
        document.head.appendChild(script);

        const urlsToIgnore = ['/auth/logout', '/auth/unauthorized'];

        // Manually trigger page views when router changes
        this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd),
          distinctUntilChanged(
            (e1: NavigationEnd, e2: NavigationEnd) => e1.urlAfterRedirects === e2.urlAfterRedirects,
          ),
        ).subscribe((event: NavigationEnd) => {
          try {
            if (
              typeof gtag === 'function' &&
              !_.includes(urlsToIgnore, event.urlAfterRedirects)
            ) {
              gtag('config', `${this.env.GOOGLE_ANALYTICS_ID}`, { page_path: event.urlAfterRedirects });
            }
          } catch (e) {
            console.error(e);
          }
        });
      }
  }
}
