import { CustomProperty } from './../../../models/custom-property';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { Scheduler } from 'rxjs/Scheduler';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { empty } from 'rxjs/observable/empty';
import * as _ from 'lodash';

import * as CustomPropertyActions from '../../actions/settings/custom-properties';
import * as fromRoot from '../../reducers';
import { MediaDataService } from '../../../services/media-data.service';
import { addRestrictionsField } from '../../../utils/custom-prop-utils';
import { parseShimErrors } from '../../../utils/parse-errors';
import { Errors } from '../../../models/errors';

export const LOAD_DEBOUNCE = new InjectionToken<number>('Load Debounce');
export const LOAD_SCHEDULER = new InjectionToken<Scheduler>('Load Scheduler');

@Injectable()
export class CustomPropertyEffects {
  // Listen for the 'LOAD_CUSTOM_PROPERTIES' action
  @Effect()
  loadCustomProperties$: Observable<Action> = this.actions$
    .pipe(
      ofType(CustomPropertyActions.LOAD_CUSTOM_PROPERTIES),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: CustomPropertyActions.LoadCustomProps) => {
        return this.mds.getCustomProperties(action.contentType).pipe(
          map((payload) => new CustomPropertyActions.LoadCustomPropsSucceeded(action.contentType, payload)),
          catchError((error) => of(new CustomPropertyActions.LoadCustomPropsFailed(action.contentType))),
        );
      }),
    );

  // Listen for the 'CREATE_CUSTOM_PROP' action
  @Effect()
  createCustomProperties$: Observable<Action> = this.actions$
    .pipe(
      ofType(CustomPropertyActions.CREATE_CUSTOM_PROP),
      // .debounceTime(this.debounce, this.scheduler || async)
        mergeMap((action: CustomPropertyActions.CreateCustomProp) => {
          const createData = this.constructCustomPropPayload(action.property);
          return this.mds.createCustomProperty(action.contentType, createData).pipe(
            map((property) => {
              action.property.restrictions = addRestrictionsField(
                action.property.restrictionsMin,
                action.property.restrictionsMax,
              );
              const verbPast: string = 'amedia.created';
              const itemType: string = 'amedia.settings.customProperty';

              this.toastr.success(
                this.translate.instant(
                  'amedia.successMessage',
                  {
                    verbPast: this.translate.instant(verbPast),
                    itemType: this.translate.instant(itemType),
                    item: action.property.new_property_name,
                  },
                ),
                this.translate.instant('amedia.success'),
              );
              return new CustomPropertyActions.CreateCustomPropSucceeded(
                action.contentType,
                property,
              );
            }),
            catchError((error) => {
              this.toastr.error(
                this.translate.instant(
                  'amedia.errors.failedTo',
                  {
                    verb: this.translate.instant('amedia.create'),
                    item: action.property.new_property_name,
                  },
                ),
                this.translate.instant('common.error.label'),
              );
              return of(new CustomPropertyActions.SaveCustomPropFailed(
                action.contentType,
                action.property,
                error,
              ));
            }),
          );
        }),
    );

  // Listen for the 'UPDATE_CUSTOM_PROP' action
  @Effect()
  updateCustomProperties$: Observable<Action> = this.actions$
    .pipe(
      ofType(CustomPropertyActions.UPDATE_CUSTOM_PROP),
      // .debounceTime(this.debounce, this.scheduler || async)
        mergeMap((action: CustomPropertyActions.UpdateCustomProp) => {
          const updateData = this.constructCustomPropPayload(action.property);
          return this.mds.updateCustomProperty(action.contentType, action.id, updateData).pipe(
            map((custProp: CustomProperty) => {
              const verbPast: string = 'amedia.updated';
              const itemType: string = 'amedia.settings.customProperty';

              custProp.restrictions = addRestrictionsField(
                action.property.restrictionsMin,
                action.property.restrictionsMax,
              );

              this.toastr.success(
                this.translate.instant(
                  'amedia.successMessage',
                  {
                    verbPast: this.translate.instant(verbPast),
                    itemType: this.translate.instant(itemType),
                    item: custProp.type_name,
                  },
                ),
                this.translate.instant('amedia.success'),
              );
              return new CustomPropertyActions.UpdateCustomPropSucceeded(
                action.contentType,
                custProp,
              );
            }),
            catchError((error) => {
              this.toastr.error(
                this.translate.instant(
                  'amedia.errors.failedTo',
                  {
                    verb: _.lowerCase(this.translate.instant('amedia.update')),
                    item: action.property.type_name,
                  },
                ),
                this.translate.instant('common.error.label'),
              );
              return of(new CustomPropertyActions.SaveCustomPropFailed(
                action.contentType,
                action.property,
                error,
              ));
            }),
          );
        }),
    );

  // Listen for the 'SAVE_CUSTOM_PROP_FAILED' action
  @Effect()
  saveCustomPropertiesFailed$: Observable<Action> = this.actions$
    .pipe(
      ofType(CustomPropertyActions.SAVE_CUSTOM_PROP_FAILED),
      switchMap((action: CustomPropertyActions.SaveCustomPropFailed) => {
        let toastrMessage = this.translate.instant(
          'amedia.toastr.failedToSaveError',
          {
            typeToSave: this.translate.instant('amedia.settings.customProperty'),
            name: action.property.new_property_name,
          },
        );
        const parsedErrors: Errors = parseShimErrors(action.error.error || {});

        if (!_.isEmpty(parsedErrors.general)) {
          toastrMessage = parsedErrors.general[0];
        }

        this.toastr.error(
          toastrMessage,
          this.translate.instant('common.error.label'),
        );
        return empty();
      }),
    );

  // Listen for the 'DELETE_CUSTOM_PROP' action
  @Effect()
  deleteCustomProperties$: Observable<Action> = this.actions$
    .pipe(
      ofType(CustomPropertyActions.DELETE_CUSTOM_PROP),
      // .debounceTime(this.debounce, this.scheduler || async)
        mergeMap((action: CustomPropertyActions.DeleteCustomProp) => {
          return this.mds.deleteCustomProperty(action.contentType, action.id).pipe(
            map(() => new CustomPropertyActions.DeleteCustomPropSucceeded(
              action.contentType,
              action.id,
            )),
            catchError((error) => {
              this.toastr.error(
                this.translate.instant(
                  'amedia.errors.failedTo',
                  {
                    verb: this.translate.instant('common.delete'),
                    item: action.id,
                  },
                ),
                this.translate.instant('common.error.label'),
              );
              return of(new CustomPropertyActions.DeleteCustomPropFailed(
                action.contentType,
                error,
              ));
            }),
          );
        }),
    );

  constructor(
    private actions$: Actions,
    private mds: MediaDataService,
    private store: Store<fromRoot.State>,
    private toastr: ToastrService,
    private translate: TranslateService,
    @Optional() @Inject(LOAD_DEBOUNCE) private debounce: number = 300,
    /**
     * You inject an optional Scheduler that will be undefined
     * in normal application usage, but its injected here so that you can mock out
     * during testing using the RxJS TestScheduler for simulating passages of time.
     */
    @Optional() @Inject(LOAD_SCHEDULER) private scheduler: Scheduler,
  ) { }

  private constructCustomPropPayload(formData: CustomProperty): any {
    let tmp = {
      ...formData,
      'type': _.lowerCase(formData.custom_type),
      'restrictions[LessThanOrEqual]' : _.toString(formData.restrictionsMax),
      'restrictions[GreaterThanOrEqual]' : _.toString(formData.restrictionsMin),
    };
    delete tmp.restrictionsMax;
    delete tmp.restrictionsMin;
    delete tmp.custom_type; // create/update payload does not match the fetch payload.
    return tmp;
  }
}
