import { FfmpegVersionInfo } from './../../models/ffmpeg-version-info';

import * as auth from '../actions/auth';
import { User } from '../../models/user';
import { Organization, OrgNode } from '../../models/organization';
import { Errors } from '../../models/errors';
import { FeatureSetAuthorization } from '../../models/feature-sets';

export interface Keys {
  organizationId: string;
  apiKey: string;
  apiSecretKey: string;
}

export interface State {
  keys: Keys;
  keysLoading: boolean;
  loginAttempted: boolean;
  firstUserLoadComplete: boolean;
  userLoading: boolean;
  userLoadingFailed: boolean;
  userSavingErrors: Errors;
  nextUrl: string;
  user: User | null;
  organization: Organization;
  organizationLoading: boolean;
  currOrgId: string;
  orgs: OrgNode[];
  orgsLoading: boolean;
  pwdResetEmail: string;
  pwdResetLoading: boolean;
  pwdResetQuestion: string;
  resendUserEmailLoading: {[userId: string]: boolean};
  featureSetLoading: boolean;
  featureSet: FeatureSetAuthorization;
  wrongEmailOrAnswer: boolean;
  availableFfmpegVersions: FfmpegVersionInfo[];
  ffmpegVersionsLoading: boolean;
  // This doesn't talk to ams, but it doesn't belong anywhere else
  // and is an authorization mechanism.
  playerNonce: string;
  timezone: string;
}

export const initialState: State = {
  keys: null,
  keysLoading: true,
  loginAttempted: false,
  firstUserLoadComplete: false,
  userLoading: false,
  userLoadingFailed: false,
  userSavingErrors: null,
  nextUrl: null,
  user: null,
  organization: null,
  organizationLoading: true,
  currOrgId: null,
  orgs: null,
  orgsLoading: true,
  pwdResetEmail: '',
  pwdResetLoading: false,
  pwdResetQuestion: '',
  resendUserEmailLoading: {},
  featureSetLoading: true,
  featureSet: null,
  wrongEmailOrAnswer: false,
  availableFfmpegVersions: null,
  ffmpegVersionsLoading: true,
  playerNonce: null,
  timezone: 'America/Los_Angeles',
};

export function reducer(
  state = initialState,
  action: auth.Actions,
): State {
  switch (action.type) {
  case auth.LOGIN:
    return {
      ...state,
      loginAttempted: true,
      userLoading: true,
      userLoadingFailed: false,
      nextUrl: state.nextUrl || action.nextUrl,
    };
  case auth.GET_USER:
    return {
      ...state,
      userLoading: true,
      userLoadingFailed: false,
      nextUrl: state.nextUrl || action.nextUrl,
    };
  case auth.USER_LOADED:
    return {
      ...state,
      loginAttempted: false,
      userLoading: false,
      user: action.user,
    };
  case auth.FIRST_LOAD:
    return {
      ...state,
      user: {
        ...state.user,
        passwordUpdateNeeded: false,
      },
      firstUserLoadComplete: true,
    };
  case auth.CLEAR_NEXT_URL:
    return {
      ...state,
      nextUrl: null,
    };
  case auth.NOT_LOGGED_IN:
    return {
      ...state,
      userLoading: false,
      userLoadingFailed: true,
      nextUrl: action.nextUrl,
      firstUserLoadComplete: false,
    };
  case auth.LOGOUT:
    return {
      ...state,
      user: null,
      userLoadingFailed: true,
      firstUserLoadComplete: false,
      playerNonce: null,
    };
  case auth.GRAB_KEYS:
    return {
      ...state,
      keysLoading: true,
    };
  case auth.GRAB_KEYS_FAILED:
    return {
      ...state,
      keysLoading: false,
    };
  case auth.GRAB_KEYS_SUCCEEDED:
    return {
      ...state,
      keys: action.keys,
      keysLoading: false,
    };
  case auth.SAVE_USER:
    return {
      ...state,
      userLoading: true,
      userSavingErrors: null,
    };
  case auth.SAVE_USER_SUCCEEDED:
    return {
      ...state,
      userLoading: false,
      user: {
        ...action.user,
        newSha1DigestedPassword: null,
      },
    };
  case auth.SAVE_USER_FAILED:
    return {
      ...state,
      userLoading: false,
      userSavingErrors: action.errors,
    };
  case auth.LOAD_ORG:
    return {
      ...state,
      organizationLoading: true,
      organization: null,
    };
  case auth.LOAD_ORG_SUCCEEDED:
    return {
      ...state,
      organizationLoading: false,
      organization: action.org,
      currOrgId: action.org.id,
    };
  case auth.LOAD_ORG_FAILED:
    return {
      ...state,
      organizationLoading: false,
      organization: null,
    };
  case auth.LOAD_ORG_LIST:
    return {
      ...state,
      orgsLoading: true,
      orgs: null,
    };
  case auth.LOAD_ORG_LIST_SUCCEEDED:
    return {
      ...state,
      orgsLoading: false,
      orgs: action.orgs,
    };
  case auth.LOAD_ORG_LIST_FAILED:
    return {
      ...state,
      orgsLoading: false,
    };
  case auth.SAVE_ORG:
    return {
      ...state,
      organizationLoading: true,
    };
  case auth.SAVE_ORG_SUCCEEDED:
    return {
      ...state,
      organizationLoading: false,
      organization: action.org,
    };
  case auth.SAVE_ORG_FAILED:
    return {
      ...state,
      organizationLoading: false,
    };
  case auth.START_PASSWORD_RESET:
    return {
      ...state,
      pwdResetEmail: action.email,
      pwdResetLoading: true,
      pwdResetQuestion: '',
      wrongEmailOrAnswer: false,
    };
  case auth.USER_NOT_FOUND:
    return {
      ...state,
      pwdResetLoading: false,
      wrongEmailOrAnswer: true,
    };
  case auth.SECURITY_QUESTION_REQUIRED:
    return {
      ...state,
      pwdResetQuestion: action.question,
      pwdResetLoading: false,
      wrongEmailOrAnswer: false,
    };
  case auth.ANSWER_SECURITY_QUESTION:
    return {
      ...state,
      pwdResetLoading: true,
      wrongEmailOrAnswer: false,
    };
  case auth.SECURITY_QUESTION_ANSWERED_WRONGLY:
    return {
      ...state,
      pwdResetLoading: false,
      wrongEmailOrAnswer: true,
    };
  case auth.PASSWORD_RESET_SENT:
    return {
      ...state,
      pwdResetLoading: false,
      wrongEmailOrAnswer: false,
    };
  case auth.RESEND_NEW_USER_EMAIL:
    return {
      ...state,
      resendUserEmailLoading: {
        ...state.resendUserEmailLoading,
        [action.userId]: true,
      },
    };
  case auth.RESEND_NEW_USER_EMAIL_SUCCEEDED:
  case auth.RESEND_NEW_USER_EMAIL_FAILED:
    return {
      ...state,
      resendUserEmailLoading: {
        ...state.resendUserEmailLoading,
        [action.userId]: false,
      },
    };
  case auth.LOAD_FEATURE_SETS:
    return {
      ...state,
      featureSetLoading: true,
    };
  case auth.LOAD_FEATURE_SETS_SUCCEEDED:
    return {
      ...state,
      featureSetLoading: false,
      featureSet: action.features,
    };
  case auth.LOAD_FEATURE_SETS_FAILED:
    return {
      ...state,
      featureSetLoading: false,
    };
  case auth.LOAD_FFMPEG_VERSIONS:
    return {
      ...state,
      ffmpegVersionsLoading: true,
    };
  case auth.LOAD_FFMPEG_VERSIONS_SUCCEEDED:
    return {
      ...state,
      availableFfmpegVersions: action.versions,
      ffmpegVersionsLoading: false,
    };
  case auth.GET_PLAYER_NONCE_SUCCEEDED:
    return {
      ...state,
      playerNonce: action.nonce,
    };
  case auth.GET_PUBLISHER_TIMEZONE_SUCCEEDED:
    return {
      ...state,
      timezone: action.timezone,
    };
  default:
    return state;
  }
}

export const getUser = (state: State) => state.user;
export const getCurrOrgId = (state: State) => state.currOrgId;
export const getPlayerNonce = (state: State) => state.playerNonce;
export const getTimezone = (state: State) => state.timezone;
