import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as HmacSHA256 from 'crypto-js/hmac-sha256';
import * as Base64 from 'crypto-js/enc-base64';
import * as _ from 'lodash';

import * as fromRoot from '../../shared/redux/reducers';
import * as fromAuth from '../../shared/redux/reducers/auth';
import * as Auth from '../../shared/redux/actions/auth';

@Injectable()
export class VpwsSigningService {
  auth$: Observable<fromAuth.State>;

  constructor(private store: Store<fromRoot.State>) {
    this.auth$ = store.pipe(select(fromRoot.getAuthState));
  }

  signRequest(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      url: this.signUrl(request.method, request.url),
    });
  }

  signUrl(method: string, url: string): string {
    // Grab keys
    let keys: fromAuth.Keys;
    this.auth$.pipe(take(1)).subscribe((auth) => keys = auth.keys);

    // Use anchor to parse URL
    const a: HTMLAnchorElement = document.createElement('a');
    a.setAttribute('href', url);
    const queryParams = a.search
      ? _.chain(a.search)
        .replace('?', '')
        .split('&')
        .map(_.partial(_.split, _, '=', 2))
        .fromPairs()
        .value()
      : {} ;

    // Assemble params
    const params: any = {
      access_key: keys.apiKey,
      expires: new Date().getTime() + 5000,
      ...queryParams,
    };

    // IE11 is non-compliant with the spec for pathname
    let path = a.pathname;
    if (path && !path.startsWith('/')) {
      path = '/' + path;
    }

    // Compute base URL
    const baseUrl: string =
      `${method.toLowerCase()}|${a.hostname}|${path}|` +
      _.join(
        _.map(
          _.sortBy(_.keys(params)),
          (key) => `${key}=${params[key]}`,
        ),
        '&',
      );

    // Compute signature
    const signature: string = Base64.stringify(HmacSHA256(baseUrl, keys.apiSecretKey));

    // Encode the final params
    const finalParams = _.join(
      _.map(
        _.sortBy(_.keys(params)),
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
      ),
      '&',
    );

    // Return signed URL
    return `${a.protocol}//${a.hostname}${path}?${finalParams}&signature=${encodeURIComponent(signature)}`;
  }
}
