import { EnvConfigurationService, Configuration } from './environment-configuration.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { delay, map, take } from 'rxjs/operators';

import { EncodingProfile } from '../models/encoding-profile';
import * as fromRoot from '../redux/reducers';
import { Store, select } from '@ngrx/store';

@Injectable()
export class TranscodeMgmtService {
  env: Configuration;

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,
  ) {
    this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);
  }

  _delayResponse$(v: any): Observable<any> {
    return of(v).pipe(delay(1000));
  }

  getUniversalEncodingProfiles(): Observable<any> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/tms/encoding_profiles/universal`,
    ).pipe(
      map((res) => res['EncodingProfiles']),
    );
  }

  getCustomEncodingProfiles(): Observable<any> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/tms/encoding_profiles`,
    ).pipe(
      map((res) => res['EncodingProfiles']),
    );
  }

  createEncodingProfile(profile: EncodingProfile): Observable<any> {
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/tms/encoding_profiles`,
      profile,
    );
  }

  updateEncodingProfile(profile: EncodingProfile): Observable<any> {
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/tms/encoding_profiles/${profile.id}`,
      profile,
    );
  }

  deleteEncodingProfile(id: string): Observable<any> {
    return this.http.delete(
      `${this.env.SHIM_BASE_URL}/tms/encoding_profiles/${id}`,
    );
  }

  getActiveEncodingProfile(): Observable<any> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/tms/encoding_profiles/default`,
    );
  }

  setActiveEncodingProfile(id: string): Observable<any> {
    return this.http.put(
      `${this.env.SHIM_BASE_URL}/tms/encoding_profiles/active/${id}`,
      '',
    );
  }
}
