import { Observable } from 'rxjs/Observable';
import { Scheduler } from 'rxjs/Scheduler';
import { async } from 'rxjs/scheduler/async';
import { of } from 'rxjs/observable/of';
import { catchError, delay, debounceTime, map, mergeMap } from 'rxjs/operators';
import { Injectable, InjectionToken, Optional, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import * as ThemeActions from '../actions/theme';
import * as fromRoot from '../reducers';
import * as fromTheme from '../reducers/theme';
import { MediaDataService } from '../../services/media-data.service';
import { Theme } from '../../models/theme';

export const LOAD_DEBOUNCE = new InjectionToken<number>('Load Debounce');
export const LOAD_SCHEDULER = new InjectionToken<Scheduler>('Load Scheduler');

@Injectable()
export class ThemeEffects {
  theme$: Observable<fromTheme.State>;

  // Listen for the 'LOAD_THEMES' action
  @Effect()
  loadThemes$: Observable<Action> = this.actions$
    .pipe(
      ofType(ThemeActions.LOAD_THEMES),
      delay(500),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ThemeActions.LoadThemes) => {
        return this.mds.getThemesList().pipe(
          map((themes) => new ThemeActions.LoadThemesSucceeded(themes)),
          catchError((error) => of(new ThemeActions.LoadThemesFailed())),
        );
      }),
  );

  // Listen for the 'SAVE_THEME' action
  @Effect()
  saveTheme$: Observable<Action> = this.actions$
    .pipe(
      ofType(ThemeActions.SAVE_THEME),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ThemeActions.SaveTheme) => {
        const serviceMethod: string = !!action.theme.id ? 'saveTheme' : 'createTheme';
        const { skin, ...newTheme} = action.theme;

        return this.mds[serviceMethod]({
          ...newTheme,
          value: JSON.stringify(_.omitBy(skin, _.isNil)),
        }).pipe(
          map((theme: Theme) => new ThemeActions.SaveThemeSucceeded(theme)),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.themes.failedToSave'),
              this.translate.instant('amedia.content.error'),
            );
            return of(new ThemeActions.SaveThemeFailed());
          }),
        );
      }),
  );

  // Listen for the 'DELETE_THEME' action
  @Effect()
  deleteTheme$: Observable<Action> = this.actions$
    .pipe(
      ofType(ThemeActions.DELETE_THEME),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ThemeActions.DeleteTheme) => {
        return this.mds.deleteTheme(action.id).pipe(
          map((themes) => new ThemeActions.DeleteThemeSucceeded()),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.themes.failedToDelete'),
              this.translate.instant('amedia.content.error'),
            );
            return of(new ThemeActions.DeleteThemeFailed());
          }),
        );
      }),
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private mds: MediaDataService,
    private translate: TranslateService,
    private toastr: ToastrService,
    @Optional() @Inject(LOAD_DEBOUNCE) private debounce: number = 300,
    /**
     * You inject an optional Scheduler that will be undefined
     * in normal application usage, but its injected here so that you can mock out
     * during testing using the RxJS TestScheduler for simulating passages of time.
     */
    @Optional() @Inject(LOAD_SCHEDULER) private scheduler: Scheduler,
  ) {
    this.theme$ = this.store.pipe(select(fromRoot.getThemeState));
  }
}
