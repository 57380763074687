import { EnvConfigurationService, Configuration } from './environment-configuration.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { delay, map, take } from 'rxjs/operators';
import * as _ from 'lodash';

import * as fromRoot from '../redux/reducers';
import { Restriction } from '../models/restriction';
import { Store, select } from '@ngrx/store';

@Injectable()
export class ContentActionManagementService {
  env: Configuration;

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,
  ) {
    this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);
  }

  /**************** Restrictions ****************/
  getRestrictionList(): Observable<any> {
    return this.http.get(
      `${this.env.SHIM_BASE_URL}/cams/restrictions/all?include_deleted_rules=0`,
    );
  }

  createRestriction(restriction: Restriction): Observable<any> {
    return this.http.post(
      `${this.env.SHIM_BASE_URL}/cams/restrictions`,
      restriction,
    );
  }

  updateRestriction(restriction: Restriction): Observable<any> {
    return this.http.put(
      `${this.env.SHIM_BASE_URL}/cams/restrictions`,
      restriction,
    );
  }

  deleteRestriction(id: string): Observable<any> {
    return this.http.delete(
      `${this.env.SHIM_BASE_URL}/cams/restrictions/${id}`,
    );
  }
}
