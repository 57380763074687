// tslint:disable-next-line:variable-name
export const en_US = {
  'amedia.addContent': 'Add {{ contentType }}',
  'amedia.addContentTo': 'Add {{ contentType }} to',
  'amedia.addChannelsToGroup': 'Add selected channels to group',
  'amedia.addGroup': 'Add Group',
  'amedia.addMediaToChannel': 'Add selected media to channel',
  'amedia.addTagTo': 'Tag to add to media:',
  'amedia.addTagToMedia': 'Add tag to selected media',
  'amedia.addToChannel': 'Add to channel',
  'amedia.addToGroup': 'Add to group',
  'amedia.appendTag': 'Add to existing tags',
  'amedia.overwriteTags': 'Replace existing tags',
  'amedia.bulkTagFailed': 'Failed to add Tags to selected media',
  'amedia.bulkTagSuccess': 'Tags added to selected media items',
  'amedia.cancel': 'Cancel',
  'amedia.changePwd.description': 'Please set a new password before proceeding.',
  'amedia.changePwd.setNewPassword': 'Set a new password',
  'amedia.changePwd.title': 'Password Change Required',
  'amedia.changeQuestion.description': 'Please create a security question and answer for ' +
    'your account before proceeding.',
  'amedia.changeQuestion.setNewPassword': 'Set a security question',
  'amedia.changeQuestion.skip': 'Skip',
  'amedia.changeQuestion.title': 'New Security Question',
  'amedia.changePage': 'change page',
  'amedia.changePage.helpSuggestions': 'To keep the changes you have made, cancel and save before leaving this page.',
  'amedia.changePage.loseChanges': 'Unsaved changes will be lost.',
  'amedia.channelDetails.addMediaTo': 'Add media to',
  'amedia.channelDetails.noRssCategories': 'Default category "TV & Film" will be used.',
  'amedia.channelDetails.settings.sharedPlayerOptions': 'Shared Player Options',
  'amedia.channelDetails.settings.enableEmailing': 'Enable emailing',
  'amedia.channelDetails.settings.enableSocialSiteSharing': 'Enable social site sharing',
  'amedia.channelDetails.settings.enableEmbedding': 'Enable embedding',
  'amedia.channelDetails.settings.enableRss': 'Enable RSS',
  'amedia.channelDetails.settings.enableRssItunesFeed': 'Enable iTunes RSS feed',
  'amedia.channelDetails.settings.enableRokuRssFeed': 'Enable Roku RSS feed',
  'amedia.channelDetails.settings.playbackPlayerOptions': 'Playback',
  'amedia.channelDetails.settings.autoplayOnLoad': 'Auto-play media on player load',
  'amedia.channelDetails.settings.autoplayNext': 'Auto-play next clip in playlist',
  'amedia.channelDetails.settings.hd': '5 bitrates (Up to HD)',
  'amedia.channelDetails.settings.high': '4 bitrates (Up to High)',
  'amedia.channelDetails.settings.medium': '3 bitrates (Up to Medium)',
  'amedia.channelDetails.settings.low': '2 bitrates (Up to Low)',
  'amedia.channelDetails.settings.lowest': '1 bitrates (Up to Lowest)',
  'amedia.channelDetails.settings.limitBandwidth': 'Limit bandwidth to',
  'amedia.channelDetails.channelMedia': 'Channel Media List',
  'amedia.channelDetails.channelProps': 'Channel Properties',
  'amedia.channelDetails.channelSettings': 'Channel Settings',
  'amedia.channelDetails.channelPlayerOptions': 'Player Options',
  'amedia.channelDetails.channelAds': 'Channel Advertising',
  'amedia.channelDetails.channelProps.iTunesRssUrl': 'iTunes RSS URL',
  'amedia.channelDetails.channelProps.rssUrl': 'RSS URL',
  'amedia.channelDetails.channelProps.rokuRssUrl': 'Roku RSS URL',
  'amedia.channelDetails.channelRssCategories': 'RSS Categories',
  'amedia.channelDetails.channelSubcategories': 'Subcategories',
  'amedia.channelDetails.updateChannelMediaListSuccess': 'Channel media list updated',
  'amedia.channelDetails.searchMedia': 'Search media',
  'amedia.channelDetails.unsupportedBandwidth': 'Current bandwidth is no longer supported',
  'amedia.channelDetails.weight': 'Weight',
  'amedia.channels': 'Channels',
  'amedia.cloneTooltip': 'Clone {{itemToClone}}',
  'amedia.confirm': 'Confirm',
  'amedia.confirmDialog.body':
    'The selected {{ itemType }} {{ title }} will be {{ verbPast }}.',
  'amedia.support.support': 'Support',
  'amedia.support.australia': 'Australia',
  'amedia.support.billingSupport': 'Billing Support',
  'amedia.support.contact': 'Contact',
  'amedia.support.contactSupport': 'Contact 24/7 support',
  'amedia.support.customerService': 'Customer Service',
  'amedia.support.call': 'Call',
  'amedia.support.callTollFree': 'Call Toll Free',
  'amedia.support.emailUs': 'Email us',
  'amedia.support.india': 'India',
  'amedia.support.international': 'International',
  'amedia.support.japan': 'Japan',
  'amedia.support.p1':
    'As a Limelight Networks customer, you have access to our expert support staff, available 24 hours a day, ' +
    '7 days a week. An easy-to-use trouble ticket system provides automated ticket creation and escalation, ' +
    'as well as logs and reports, to help resolve your issues quickly.',
  'amedia.support.p2':
    'When you email or call us with your questions and concerns, please make sure you include your ' +
    'correct contact info to ensure you receive updates about issues or network maintenance.',
  'amedia.support.singapore': 'Singapore',
  'amedia.support.southKorea': 'South Korea',
  'amedia.content': 'Content',
  'amedia.content.actionLabel': 'Actions',
  'amedia.content.addRSSCategory': 'Add RSS Category',
  'amedia.content.adOrder': 'Ad Order',
  'amedia.content.adProvider': 'Ad Provider',
  'amedia.content.ad': 'Ad',
  'amedia.content.ads': 'Ads',
  'amedia.content.advanced': 'Advanced',
  'amedia.content.all': 'all',
  'amedia.content.audio': 'Audio',
  'amedia.content.bulkModal.body': 'This will {{ verb }} the following {{ contentTypeLabel }}:',
  'amedia.content.bulkModal.cannotChangeContent':
    'Highlighted {{contentTypeLabel}} below will not {{verb}} due to an error or processing status.',
  'amedia.content.removeAllContent': 'This will remove all {{amount}} {{ contentTypeLabel }}',
  'amedia.content.bulkModal.header': '{{verb}} {{contentTypeLabel}}',
  'amedia.content.captionWarning': 'The selected file has been processed, but no captions were found.',
  'amedia.content.captionDeleting': 'Closed caption file is being deleted',
  'amedia.content.captionFileTypes': 'Closed caption files must be one of the following types: ',
  'amedia.content.captionLanguages': '{{languageCount}} languages',
  'amedia.content.captionProcessing': 'Closed caption file is processing',
  'amedia.content.channel': 'channel',
  'amedia.content.channelId': 'Channel ID',
  'amedia.content.channels': 'channels',
  'amedia.content.channelDescription': 'Channel Description',
  'amedia.content.channelName': 'Channel Name',
  'amedia.content.clearForm': 'Clear form',
  'amedia.content.clear': 'Clear',
  'amedia.content.clone': 'Clone',
  'amedia.content.cloned': 'Cloned',
  'amedia.content.closedCaption': 'closed caption',
  'amedia.content.closedCaptionFile': 'closed caption file',
  'amedia.content.closedCaptionFileAttached': 'Closed caption file attached.',
  'amedia.content.closedCaptions': 'Closed Captions',
  'amedia.content.closedCaptionDeleteError': 'There was an error deleting the closed caption file.',
  'amedia.content.codecs': 'CODECS',
  'amedia.content.container': 'Container',
  'amedia.content.contentOverlay': 'Content Overlay',
  'amedia.content.copy': 'copy',
  'amedia.content.copyToClipboard': 'copy to clipboard',
  'amedia.content.idCopiedToClipboard': 'ID copied to clipboard',
  'amedia.content.createdDate': 'Date Created',
  'amedia.content.createSnippet': 'Create Snippet',
  'amedia.content.curePointProperties': 'Cue Point Properties',
  'amedia.content.cuePoints': 'Cue Points',
  'amedia.content.cuePointsNone': 'There are no cuepoints yet',
  'amedia.content.currently': 'Currently:',
  'amedia.content.customEvent': 'Custom Event',
  'amedia.content.customProperty': 'Custom Property',
  'amedia.content.customProperties': 'Custom Properties',
  'amedia.content.data': 'Data',
  'amedia.content.dateUpdated': 'Date Updated',
  'amedia.content.dateUploaded': 'Date Uploaded',
  'amedia.content.deleteModal.body':
    '{{ title }} will be permanently removed from the library',
  'amedia.content.deleteModal.channels': ' and the following channel(s):',
  'amedia.content.deleteFromLibrary': 'Remove from libary',
  'amedia.content.deleting': 'Deleting',
  'amedia.content.description': 'Description',
  'amedia.content.dragDropFile': 'Drag and drop file here',
  'amedia.content.done': 'Done',
  'amedia.content.duration': 'duration',
  'amedia.content.edit': 'edit',
  'amedia.content.embed': 'embed',
  'amedia.content.embed.copy': 'Copy',
  'amedia.content.embed.copyWithoutFormatting': 'Copy Without Formatting',
  'amedia.content.embed.htmlPlayer': 'Limelight HTML Player',
  'amedia.content.embed.htmlLink': 'HTML Link',
  'amedia.content.enables_ads': 'Enables ads for media item',
  'amedia.content.enableDeepLink': 'Enable deep link',
  'amedia.content.encodingInformation': 'Encoding Information',
  'amedia.content.end': 'End',
  'amedia.content.endscreenImage': 'Endscreen Image:',
  'amedia.content.endscreenImageNone': 'No End Screen Image Uploaded',
  'amedia.content.error': 'Error',
  'amedia.content.errored': 'Errored',
  'amedia.content.fileUrls': "File URL's",
  'amedia.content.filterMax': 'Select up to 2 options',
  'amedia.content.format': 'Format',
  'amedia.content.group': 'group',
  'amedia.content.groupId': 'Channelgroup ID',
  'amedia.content.groups': 'groups',
  'amedia.content.groupName': 'Group Name',
  'amedia.content.contentName': '{{contentType}} Name',
  'amedia.content.hasInvalidUrl': 'List contains invalid url',
  'amedia.content.image': 'Image',
  'amedia.content.initializing': 'Initializing',
  'amedia.content.itunesLanguage': 'iTunes RSS Language',
  'amedia.content.label': 'Label',
  'amedia.content.link': 'Link',
  'amedia.content.maxValueError': 'Must be less than or equal to {{value}}',
  'amedia.content.media': 'media',
  'amedia.content.mediaNotApartOfChannel': 'No channels contain this media',
  'amedia.content.mediaProperties': 'Media Properties',
  'amedia.content.minValueError': 'Must be greater than or equal to {{value}}',
  'amedia.content.noEntries': 'There are no entries yet',
  'amedia.content.none': 'None',
  'amedia.content.numItemsSelected': '{{value}} item(s) selected',
  'amedia.content.originalName': 'original name',
  'amedia.content.overlayImageNone': 'No Overlay Image Uploaded',
  'amedia.content.position': 'Position',
  'amedia.content.postroll': 'Post-Roll',
  'amedia.content.preroll': 'Pre-Roll',
  'amedia.content.previewImage': 'Preview Image',
  'amedia.content.previewImageNone': 'No Preview Image Uploaded',
  'amedia.content.primaryUse': 'Primary use',
  'amedia.content.processing': 'Processing',
  'amedia.content.profileName': 'Profile Name',
  'amedia.content.publish': 'Publish',
  'amedia.content.publishable': 'Publishable',
  'amedia.content.published': 'Published',
  'amedia.content.publishedAndScheduled': 'Published, Scheduled',
  'amedia.content.publishedState': 'Published State',
  'amedia.content.publisherName': 'Publisher Name',
  'amedia.content.reference_id': 'Reference ID',
  'amedia.content.removeAll': 'Remove all',
  'amedia.content.removeEndscreenImage': 'Remove Endscreen Image',
  'amedia.content.reorder': 'reorder',
  'amedia.content.doneReordering': 'done reordering',
  'amedia.content.replace': 'Replace',
  'amedia.content.replaceSource': 'Replace Source',
  'amedia.content.replaceSourceFailed': 'Replace Source Failed',
  'amedia.content.replaceSourceFailedFor': 'The attempt to replace source failed for {{name}}',
  'amedia.content.replaceSource.description': 'The source file will be replaced and new encodings produced. ' +
    'The media will be temporarily unavailable during transcoding.',
  'amedia.content.replaceSource.warningTextP1': 'Previous raw playback URLs will be irrevocably deleted after ' +
    'source file replacement, and new ones will be available through the Content API and this interface.',
  'amedia.content.replaceSource.warningTextP2': 'Engagement analytics and cue points may be compromised ' +
    'if the duration of the new file is different than the original.',
  'amedia.content.resolution': 'Resolution',
  'amedia.content.revertToDefault': 'revert to default image',
  'amedia.content.saveToLoad': 'Save to load the file',
  'amedia.content.schedule': 'Schedule',
  'amedia.content.schedule.datesEqualErrorMsg': 'Start and end dates and times cannot be equal.',
  'amedia.content.schedule.endBeforeStartErrorMsg': 'End date and time must be later than the start.',
  'amedia.content.schedule.endDate': 'End Date',
  'amedia.content.schedule.startDate': 'Start Date',
  'amedia.content.selectEncoding': 'Select an encoding',
  'amedia.content.snippets': 'Snippets',
  'amedia.content.sortBy': 'Sort By',
  'amedia.content.start': 'Start',
  'amedia.content.status': 'Status',
  'amedia.content.tag': 'Tag',
  'amedia.content.thumbnailPreview': 'Thumbnail Preview',
  'amedia.content.time': 'Time',
  'amedia.content.title': 'Title',
  'amedia.content.total': 'Total',
  'amedia.content.totalBitRate': 'Total bit rate (kbps)',
  'amedia.content.type': 'Type',
  'amedia.content.unpublish': 'Unpublish',
  'amedia.content.unpublished': 'Unpublished',
  'amedia.content.upload': 'Upload',
  'amedia.content.uploadEndscreenImage': 'upload endscreen image',
  'amedia.content.uploadImage': 'upload image',
  'amedia.content.uploading': 'Uploading',
  'amedia.content.useCurrentFrame': 'use current frame',
  'amedia.content.video': 'Video',
  'amedia.content.videoAudio': 'Video/audio (kbps)',
  'amedia.content.videoAudioBitrates': 'Video/audio bitrates (kbps)',
  'amedia.content.viewCustomProperties': 'View Custom Properties',
  'amedia.content.viewingXofY': 'Viewing {{viewing}} of {{total}}',
  'amedia.content.viewRestrictionRules': 'View Restriction Rules',
  'amedia.content.warning': 'WARNING',
  'amedia.create': 'create',
  'amedia.created': 'created',
  'amedia.cuepoints.adUrl': 'Ad URL',
  'amedia.cuepoints.end': 'End (HH:MM:SS:MS)',
  'amedia.cuepoints.keyValuePairs': 'Key Value Pairs',
  'amedia.cuepoints.midRoll': 'Mid-roll',
  'amedia.cuepoints.programId': 'Program ID',
  'amedia.cuepoints.publisherKey': 'Publisher Key',
  'amedia.cuepoints.start': 'Start (HH:MM:SS:MS)',
  'amedia.cuepoints.timefallsOutside': 'Time falls outside of video timeline',
  'amedia.cuepoints.trackingPixelUrls': 'Tracking Pixel URLs',
  'amedia.cuepoints.uniqueName': 'Name must be unique',
  'amedia.cuepoints.selectChannel': 'Select a Channel',
  'amedia.customProperties.copyIdToClipboard': 'Copy ID to Clipboard',
  'amedia.deleted': 'deleted',
  'amedia.deleteTooltip': 'Delete {{itemToDelete}}',
  'amedia.description': 'Description',
  'amedia.dialogs.link': 'Link',
  'amedia.dialogs.linkUser': 'Link User',
  'amedia.dialogs.linkUser.description': 'There already exists a user named {{name}} in another organization ' +
    'with the email address {{email}}. Would you like to link them to this organization using the {{role}} role?',
  'amedia.dropFilesHere': 'Drop files here',
  'amedia.editTooltip': 'Edit {{itemToEdit}}',
  'amedia.errors.acceptableChars': 'Only letters, numbers, and {{extraChars}} accepted.',
  'amedia.errors.loadingErrorMedia': 'Cannot load details for media {{mediaId}}. Media is in an Error state.',
  'amedia.errors.failedTo': 'Failed to {{verb}} {{item}}',
  'amedia.errors.failedToSaveOrg': 'Failed to save organization',
  'amedia.errors.failedToSaveProfile': 'Failed to save profile',
  'amedia.errors.failedToSaveUser': 'Failed to save user',
  'amedia.errors.failedToLinkUser': 'Failed to link user',
  'amedia.errors.maxLength': 'Exceeded maximum length of {{maximum}}',
  'amedia.errors.maxCharLength': 'Reached max character length of {{maximum}}',
  'amedia.errors.restrictedCharacters': 'The following characters are not allowed: # $ & + , / : ; = ? @',
  'amedia.footer.goToTop': 'Go to top ',
  'amedia.footer.suggestions': 'Suggestions?',
  'amedia.forgotPwd.answer.label': 'Answer',
  'amedia.forgotPwd.answer.placeholder': 'Enter Answer',
  'amedia.forgotPwd.checkYourEmail': 'Check Your Email',
  'amedia.forgotPwd.checkYourEmail.description': 'Your new password has been sent to your email address.',
  'amedia.forgotPwd.confirmId': 'Confirm your identity.',
  'amedia.forgotPwd.contactMlAdmin': 'Contact your Media Library account admin.',
  'amedia.forgotPwd.description': 'Please provide your email address. We will ' +
    'send you a new password.',
  'amedia.forgotPwd.mlEmail': 'Media Library Email',
  'amedia.forgotPwd.pleaseAnswer': 'Please answer the following security question to retrieve your email.',
  'amedia.forgotPwd.title': 'Forgot Your Password?',
  'amedia.forgotPwd.unableToProvide': 'Unable to provide your email address?',
  'amedia.forgotPwd.notAbleToEmail': 'Not able to send an email.',
  'amedia.forgotPwd.wrongAnswer': 'The answer you provided did not match our records. Please try again.',
  'amedia.goBackToLogin': 'Go back to login',
  'amedia.groups': 'Groups',
  'amedia.idleModal.logout': 'Log Out',
  'amedia.idleModal.sessionAboutToExpire': 'Your Session Is About To Expire',
  'amedia.idleModal.stayLoggedIn': 'Stay Logged In',
  'amedia.idleModal.youWillBeLoggedOut': 'You will be logged out in {{formattedTime}}!',
  'amedia.language.englishLocalized': 'English',
  'amedia.language.japaneseLocalized': '日本語',
  'amedia.groupDetails.addChannelTo': 'Add channels to',
  'amedia.groupDetails.groupChannels': 'Group Channels List',
  'amedia.groupDetails.groupProps': 'Group Properties',
  'amedia.groupDetails.searchChannels': 'Search channels',
  'amedia.noThumbnailImage': 'No Thumbnail Preview Uploaded',
  'amedia.login': 'Login',
  'amedia.login.email': 'Email',
  'amedia.login.forgotPassword': 'Forgot password?',
  'amedia.login.learnMore': 'To learn more about Limelight Networks, visit',
  'amedia.login.loginFailed': 'Failed to login using the credentials provided',
  'amedia.login.serviceUnavailable': 'Service unavailable.',
  'amedia.login.signin': 'Sign In',
  'amedia.login.title': 'Welcome to the Limelight Video Platform',
  'amedia.login.noLVPaccount': 'Don’t have an account? ',
  'amedia.login.getaFreeTrial': 'GET A FREE TRIAL',
  'amedia.media': 'Media',
  'amedia.mediaLibraryContent': 'Media Library Content.',
  'amedia.modalHeader': '{{verb}} {{itemType}}',
  'amedia.noDataFound': 'No {{dataType}} found',
  'amedia.notification': 'Notification',
  'amedia.notSelected': 'Not Selected',
  'amedia.orgDisabled.notEnabled': '{{feature}} is not enabled for your account',
  'amedia.orgSelector.contentFor': 'CONTENT FOR',
  'amedia.orgSelector.reportsFor': 'REPORTS FOR',
  'amedia.orgSelector.settingsFor': 'SETTINGS FOR',
  'amedia.orgSelector.themesFor': 'THEMES FOR',
  'amedia.player.failedToLoad': 'Failed to load player',
  'amedia.remove': 'Remove',
  'amedia.removed': 'removed',
  'amedia.reports': 'Reports',
  'amedia.reports.averageTimePlayed': 'Average Time Played',
  'amedia.reports.channelTitle': 'Channel Title',
  'amedia.reports.channel.channelTitle': 'Channel Report',
  'amedia.reports.charts.noData': 'Check an item below to display data',
  // tslint:disable-next-line
  'amedia.reports.engagement.25%': '25%',
  'amedia.reports.engagement.50%': '50%',
  'amedia.reports.engagement.75%': '75%',
  'amedia.reports.engagement.100%': '100%',
  'amedia.reports.eventHistory.eventType': 'Event Type',
  'amedia.reports.eventHistory.affectedItem': 'Affected Item',
  'amedia.reports.eventHistory.details': 'Details',
  'amedia.reports.eventHistory.filterByContentId': 'Filter By Media/Channel/Group ID',
  'amedia.reports.eventHistory.invalidId': 'Enter a valid Media, Channel, or Group ID',
  'amedia.reports.eventHistory.filtersPlaceholder': 'Filter Event Types',
  'amedia.reports.eventHistory.selectAll': 'Select All',
  'amedia.reports.eventHistory.time': 'Time',
  'amedia.reports.eventHistory.tableTitle': 'Event History (Up to 30 Days)',
  'amedia.reports.exportCsv': 'Export CSV',
  'amedia.reports.filterText': 'Filter',
  'amedia.reports.geography.city': 'City',
  'amedia.reports.geography.country': 'Country',
  'amedia.reports.geography.region': 'Region',
  'amedia.reports.geo.countries': 'Countries',
  'amedia.reports.geo.world': 'World',
  'amedia.reports.geo.plays': 'Plays',
  'amedia.reports.mediaTitle': 'Media Title',
  'amedia.reports.overview.mostPlayedMedia': 'Most Played Media',
  'amedia.reports.overview.mostPlayedChannels': 'Most Played Channels',
  'amedia.reports.overview.topTrafficSources': 'Top Traffic Sources',
  'amedia.reports.media.videoSelect': 'Video',
  'amedia.reports.media.audioSelect': 'Audio',
  'amedia.reports.media.allSelect': 'All Media',
  'amedia.reports.media.totalPlays': 'Total Plays',
  'amedia.reports.media.totalReplays': 'Total Replays',
  'amedia.reports.media.avgTimePlayed': 'Average Time Played',
  'amedia.reports.media.distinctReferrers': 'Distinct Referrers',
  'amedia.reports.media.avgDistinctViewers': 'Average Distinct Viewers',
  'amedia.reports.media.graphXAxis': 'Date',
  'amedia.reports.media.mediaTitle': 'Media Report',
  'amedia.reports.overview.overviewTitle': 'Overview Report',
  'amedia.reports.search': 'Search',
  'amedia.reports.tabs.media' : 'Media',
  'amedia.reports.tabs.channel' : 'Channels',
  'amedia.reports.tabs.engagement': 'Engagement',
  'amedia.reports.tabs.eventHistory': 'Event History',
  'amedia.reports.tabs.geography' : 'Geography',
  'amedia.reports.tabs.overview' : 'Overview',
  'amedia.reports.tabs.viewer' : 'Viewer Data',
  'amedia.reports.tabs.usage' : 'Usage',
  'amedia.reports.tabs.usage.bandwidth' : 'Bandwidth',
  'amedia.reports.tabs.usage.storage' : 'Storage',
  'amedia.reports.organizationTimezone': 'Organization\'s Timezone: ',
  'amedia.reports.timezoneInfo': 'Please contact your account manager to change your organization\'s timezone',
  'amedia.reports.totalNumPlays': 'Total Number of Plays',
  'amedia.reports.totalNumReplays': 'Total Number of Replays',
  'amedia.reports.totalTimePlayed': 'Total Time Played',
  'amedia.reports.units.mb': 'MB',
  'amedia.reports.units.gb': 'GB',
  'amedia.reports.units.tb': 'TB',
  'amedia.reports.units.pb': 'PB',
  'amedia.reports.units.eb': 'EB',
  'amedia.reports.trafficSource': 'Traffic Source',
  'amedia.reports.units.plays': 'plays',
  'amedia.reports.units.replays': 'replays',
  'amedia.reports.units.hourMinuteSecondShorthand': 'H:m:s',
  'amedia.reports.usage.bandwidthUsed' : 'Bandwidth Used',
  'amedia.reports.usage.bandwidthUsedMb' : 'Bandwidth Used (MB)',
  'amedia.reports.usage.dataUsageChart' : 'Data Usage Chart',
  'amedia.reports.usage.date' : 'Date',
  'amedia.reports.usage.peakStorage' : 'Peak Storage',
  'amedia.reports.usage.peakStorageMb' : 'Peak Storage (MB)',
  'amedia.reports.usage.storageMb' : 'Storage (MB)',
  'amedia.reports.whatstrending': 'What\'s Trending',
  'amedia.reports.viewerData.avgPlaybackStart': 'Avg Playback Start',
  'amedia.reports.viewerData.browser': 'Browser',
  'amedia.reports.viewerData.browsers': 'Browsers',
  'amedia.reports.viewerData.noData': 'No Data',
  'amedia.reports.viewerData.operatingSystem': 'Operating System',
  'amedia.reports.viewerData.operatingSystems': 'Operating Systems',
  'amedia.reports.viewerData.percentage': '{}%',
  'amedia.reports.charts.pointFormat':
    '{series.name}: <b>{point.y}</b><br>{series.name} %: <b>{point.percentage:.1f}</b>',
  'amedia.reports.viewerData.pieLabel': 'Plays',
  'amedia.reports.viewerData.platform': 'Platform',
  'amedia.reports.viewerData.playsByBrowser': 'Plays by browser',
  'amedia.reports.viewerData.playsByOperatingSystem': 'Plays by operating system',
  'amedia.reports.viewerData.playsByPlatform': 'Plays by platform',
  'amedia.reports.viewerData.playsByTrafficSource': 'Plays by traffic source',
  'amedia.reports.viewerData.percentageOfTotalSubtitleString': '{} {}% of total plays',
  'amedia.reports.viewerData.topPlatformPlays': 'Top Platform Plays',
  'amedia.reports.viewerData.trafficSource': 'Traffic Source',
  'amedia.select': 'Select',
  'amedia.set': 'Set',
  'amedia.settings': 'Settings',
  'amedia.settings.allowAllExcept': 'Allow All Except',
  'amedia.settings.allowOnly': 'Allow Only',
  'amedia.settings.activeEncodingProfile': 'Active encoding profile',
  'amedia.settings.addCustomProps': 'Add custom properties for media, channels, or groups',
  'amedia.settings.addEncoding': 'Add encoding',
  'amedia.settings.addEncodingType': 'Add encoding type',
  'amedia.settings.apiDocs': 'API Documentation',
  'amedia.settings.apiInfo': 'API Info',
  'amedia.settings.apiKeyRotation': 'API Key Rotation',
  'amedia.settings.apiKeyRotationBtn': 'Rotate API Key',
  // tslint:disable-next-line
  'amedia.settings.apiKeyRotation.confirmationInstructions': 'To continue rotating the API key, type "ROTATE" in the box below:',
  // tslint:disable-next-line
  'amedia.settings.apiKeyRotationDescription': 'You may rotate your API key if you have concerns that your current API key has been compromised. You will be logged out, and your new API key will be available when you next login.',
  // tslint:disable-next-line
  'amedia.settings.apiKeyRotationWarning': 'Rotating your API key will cause you to be logged out and will break any scripts which are using your current API key.',
  'amedia.settings.audioBitRate': 'Audio Bit Rate (kbps)',
  'amedia.settings.baseline': 'Baseline',
  'amedia.settings.callback': 'Callback Listener',
  'amedia.settings.callbackDescription': 'Callback Description',
  'amedia.settings.callbackEnableDescription': 'Enables Callback Listener',
  'amedia.settings.callbackEnableListener': 'Enable Listener',
  'amedia.settings.callbackList': 'Callback Listener List',
  'amedia.settings.callbackName': 'Callback Listener Name',
  'amedia.settings.callbackScriptUrl': 'Script URL',
  'amedia.settings.categoryType': 'Category Type',
  'amedia.settings.clearForm': 'Clear form',
  'amedia.settings.cloneCallbackListener': 'Clone callback listener',
  'amedia.settings.cloneEncodingProfile': 'Clone encoding profile',
  'amedia.settings.contentRestriction': 'content restriction',
  'amedia.settings.contentType': 'Content Type',
  'amedia.settings.createRestriction': 'Create viewing restriction rules',
  'amedia.settings.customAudioEncoding': 'custom audio encoding',
  'amedia.settings.customProperty': 'custom property',
  'amedia.settings.customVideoEncoding': 'custom video encoding',
  'amedia.settings.defaultPublishState': 'Default Publish State',
  'amedia.settings.deleteCallbackListener': 'Delete callback listener',
  'amedia.settings.deleteEncodingProfile': 'Delete encoding profile',
  'amedia.settings.domains': 'Domains',
  'amedia.settings.domainRestriction': 'Domain Restriction',
  'amedia.settings.domainNames': 'Domain names',
  'amedia.settings.encoding.invalidUrl': 'Must be a valid url',
  'amedia.settings.editCallbackListener': 'Edit callback listener',
  'amedia.settings.editEncodingProfile': 'Edit encoding profile',
  'amedia.settings.encodingProfile': 'Encoding Profile',
  'amedia.settings.encodingProfileList': 'Encoding Profile List',
  'amedia.settings.encodingProfiles.filterText': 'Filter encoding profiles',
  'amedia.settings.encodingType': 'Encoding Type *',
  'amedia.settings.eventName': 'Event Name',
  'amedia.settings.filterCallbackListeners': 'Filter callback listeners',
  'amedia.settings.encoding.createError': 'Failed to create encoding profile',
  'amedia.settings.encoding.deleteError': 'Failed to delete encoding profile',
  'amedia.settings.encoding.updateError': 'Failed to update encoding profile',
  'amedia.channelDetails.mediaWillBeRemovedFromChannel': 'The selected {{itemType}} {{title}} will be ' +
    'removed from the current channel but not the library.',
  'amedia.channelDetails.bulkMediaWillBeRemovedFromChannel': 'This will remove ' +
    'the following {{ contentTypeLabel }} from the current channel but not the library:',
  'amedia.settings.frameRate': 'Frame Rate',
  'amedia.settings.geoLocation': 'Geo Location',
  'amedia.settings.greaterThan': 'Enter a value greater than or equal to the minimum',
  'amedia.settings.high': 'High',
  'amedia.settings.id': 'Profile ID',
  'amedia.settings.integer': 'Integer',
  'amedia.settings.integerRequired': 'Must be an integer',
  'amedia.settings.lessThan': 'Enter a value less than or equal to the maximum',
  'amedia.settings.list': 'List',
  'amedia.settings.listRestrictions': 'List of restrictions',
  'amedia.settings.listValues': 'List Values',
  'amedia.settings.loadingCallbackListeners': 'Loading Callback Listeners',
  'amedia.settings.loadingEncodingProfiles': 'Loading Encoding Profiles',
  'amedia.settings.locationRestriction': 'Location Restriction',
  'amedia.settings.locations': 'Locations',
  'amedia.settings.main': 'Main',
  'amedia.settings.maximum': 'Maximum',
  'amedia.settings.masterDescription': 'Master Description',
  'amedia.settings.masterKey': 'Master Access Key',
  'amedia.settings.masterKeyDescriptions':
    'Master API keys work with all API requests, including those that can modify the information in your account.',
  'amedia.settings.masterKeyWarning':
    'These keys should not be used in situations where they can be exposed to others, '
    + 'such as client-side code or device apps.',
  'amedia.settings.masterSecret': 'Master Secret',
  'amedia.settings.minimum': 'Minimum',
  'amedia.settings.noDomains': 'There are no domain restrictions',
  'amedia.settings.noRestriction': 'No Restriction',
  'amedia.settings.notify': 'Notify *',
  'amedia.settings.profile.acctLockout': 'Account Lockout',
  'amedia.settings.profile.address': 'Address',
  'amedia.settings.profile.address2': 'Address 2',
  'amedia.settings.profile.city': 'City',
  'amedia.settings.profile.daysUntilPwdExp': 'Days Until Password Expiration',
  'amedia.settings.profile.failedLoginsBeforeLockout': 'Failed Logins Before Lockout',
  'amedia.settings.profile.failedLoginWindowMins': 'Failed Logins Window Minutes',
  'amedia.settings.profile.ffmpegVersionCurrent': '{{version}} (latest)',
  'amedia.settings.profile.ffmpegVersionDefault': '{{version}} (default)',
  'amedia.settings.profile.ffmpegVersionLabel': 'Version of FFMPEG to Use for Encodings',
  'amedia.settings.profile.lockoutPeriodMins': 'Account Lockout Period Minutes',
  'amedia.settings.profile.minLengthOnly': 'Minimum Length Only',
  'amedia.settings.profile.minMax': 'Enter number between {{min}} and {{max}}',
  'amedia.settings.profile.mostRestrictive': 'Most Restrictive',
  'amedia.settings.profile.myProfile': 'My Profile',
  'amedia.settings.profile.orgName': 'Organization Name',
  'amedia.settings.profile.orgProfile': 'Organization Profile',
  'amedia.settings.profile.phone': 'Phone',
  'amedia.settings.profile.province': 'Province',
  'amedia.settings.profile.pwdAging': 'Password Aging',
  'amedia.settings.profile.pwdPolicy': 'Password Policy',
  'amedia.settings.profile.noPolicy': 'No Policy',
  'amedia.settings.profile.pwdReuse': 'Password Reuse',
  'amedia.settings.profile.pwdRule': 'Password Rules:',
  'amedia.settings.profile.pwdRule.min8': 'Must be at least 8 characters long',
  'amedia.settings.profile.pwdRule.oneLower': 'Must contain at least one lowercase character',
  'amedia.settings.profile.pwdRule.oneNumber': 'Must contain at least one number',
  'amedia.settings.profile.pwdRule.oneSymbol': 'Must contain at least one symbol (e.g. &, !, #)',
  'amedia.settings.profile.pwdRule.oneUpper': 'Must contain at least one uppercase character',
  'amedia.settings.profile.securityAnswer': 'Security Answer',
  'amedia.settings.profile.securityQuestion': 'Security Question',
  'amedia.settings.profile.state': 'State',
  'amedia.settings.profile.uniqPwdUntilReuse': 'Unique Passwords Until Reuse',
  'amedia.settings.profile.zipCode': 'Zip / Postal Code',
  'amedia.settings.profile.website': 'Website',
  'amedia.settings.profileType': 'Profile Type',
  'amedia.settings.orgId': 'Organization ID',
  'amedia.settings.playerAPIKeyDescription':
    'Player API keys work with the subset of API requests that can be used to power playback experiences. '
    + 'They can not be used to upload, update, nor delete the information in your account.',
  'amedia.settings.playerAPIKeysWarning':
    'These keys should be used in all situations where read-only actions are all that is required.',
  'amedia.settings.playerDescription': 'Player Description',
  'amedia.settings.playerKey': 'Player Access Key',
  'amedia.settings.playerSecret': 'Player Secret',
  'amedia.settings.propertyName': 'Property Name',
  'amedia.settings.propertyType': 'Property Type',
  'amedia.settings.removeBitRateClamp': 'Remove Bit Rate Clamp',
  'amedia.settings.requiredFields': 'Required fields are denoted with an asterik *.',
  'amedia.settings.restrictions': 'restrictions',
  'amedia.settings.restrictionName': 'Restriction Rule Name',
  'amedia.settings.rotate': 'Rotate',
  'amedia.settings.rotateAPIKey': 'Rotate API Key',
  // tslint:disable-next-line
  'amedia.settings.rotateAPIKeyWarning': 'Proceeding with API Key rotation will log you out from the UI and you will need to update any existing scripts using the old API keys. The new keys will be available in the UI on subsequent logins.',
  'amedia.settings.strictEnforcement': 'Strict Enforcement',
  'amedia.settings.setActiveEncodingProfile': 'set to active encoding profile',
  'amedia.settings.setMediaToPublish': 'Set media to publish on upload',
  'amedia.settings.tabs.profile': 'Profile',
  'amedia.settings.tabs.users': 'Users',
  'amedia.settings.tabs.contentRestrictions': 'Content Restrictions',
  'amedia.settings.tabs.devTools': 'Developer Tools',
  'amedia.settings.tabs.customProps': 'Custom Properties',
  'amedia.settings.tabs.encoding': 'Encoding',
  'amedia.settings.text': 'Text',
  'amedia.settings.user': 'user',
  'amedia.settings.users.administrator': 'Administrator',
  'amedia.settings.users.emailSent.description': 'This user should be receiving an email shortly!',
  'amedia.settings.users.failedToSendEmail': 'Failed to send email',
  'amedia.settings.users.filterText': 'Filter by Email or Name',
  'amedia.settings.users.manageUsers': 'Manage Users',
  'amedia.settings.users.noUsers': 'No users to display',
  'amedia.settings.users.producer': 'Producer',
  'amedia.settings.users.resendEmail': 'Resend Email',
  'amedia.settings.users.emailSent': 'Email Sent',
  'amedia.settings.twoPass': 'Two Pass',
  'amedia.settings.urlErrors': 'Must be a valid url',
  'amedia.settings.users.userInfo': 'User Info',
  'amedia.settings.videoBitRate': 'Video Bit Rate (kbps)',
  'amedia.settings.viewEncodingProfile': 'View encoding profile',
  'amedia.success': 'Success',
  'amedia.successMessage': 'Successfully {{verbPast}} {{itemType}}: {{item}}',
  'amedia.support': 'Support',
  'amedia.themes': 'Themes',
  'amedia.themes.activeBackground': 'Active Background',
  'amedia.themes.background': 'Background',
  'amedia.themes.basic': 'Basic',
  'amedia.themes.bigPlay': 'Big Play',
  'amedia.themes.bottom': 'Bottom',
  'amedia.themes.cloneTheme': 'Clone Theme',
  'amedia.themes.color': 'Color',
  'amedia.themes.controls': 'Controls',
  'amedia.themes.exit': 'Exit',
  'amedia.themes.failedToDelete': 'Failed to delete theme',
  'amedia.themes.failedToSave': 'Failed to save theme',
  'amedia.themes.fontFamily': 'Font Family',
  'amedia.themes.fontSize': 'Font Size',
  'amedia.themes.height': 'Height',
  'amedia.themes.invalidHeightWidth': 'Height and width must be a number or a percentage',
  'amedia.themes.left': 'Left',
  'amedia.themes.moreOptions': 'More options on advanced tab',
  'amedia.themes.placement': 'Placement',
  'amedia.themes.theme': 'Theme',
  'amedia.themes.playerSize': 'Player Size',
  'amedia.themes.playlist': 'Playlist',
  'amedia.themes.playlistItems': 'Playlist Items',
  'amedia.themes.ratio': 'Ratio',
  'amedia.themes.right': 'Right',
  'amedia.themes.themeName': 'Theme Name',
  'amedia.themes.top': 'Top',
  'amedia.themes.width': 'Width',
  'amedia.title': 'Title',
  'amedia.toastr.addMediaToChannelFailed': 'Failed to add media to channel',
  'amedia.toastr.channelCloned': 'Channel cloned',
  'amedia.toastr.channelCloneFailed': 'Failed to clone channel',
  'amedia.toastr.channelCreated': 'New channel has been created',
  'amedia.toastr.channelCreatedFailed': 'Failed to create new channel',
  'amedia.toastr.channelMediaAlreadyExists': 'Media is already in channel',
  'amedia.toastr.channelMediaAlreadyRemoved': 'Media is no longer in this channel',
  'amedia.toastr.channelRemoveMediaFailed': 'Failed to remove media from channel',
  'amedia.toastr.channelUpdateMediaListFailed': 'Failed to update channel media list',
  'amedia.toastr.failedToLoadChannel': 'Failed to load channel',
  'amedia.toastr.failedToPerformAction': 'Failed to perform action on {{contentType}} {{id}}',
  'amedia.toastr.bulkDeletionFailed': 'Bulk deletion failed for {{contentType}}',
  'amedia.toastr.channelNotFound': 'Channel not found',
  'amedia.toastr.highQualityThumbnailKickoffFailed': 'Failed to start job for high-quality thumbnail extraction.',
  'amedia.toastr.mediaNotFound': 'Media not found',
  'amedia.toastr.failedToLoadGroup': 'Failed to load group',
  'amedia.toastr.groupNotFound': 'Group not found',
  'amedia.toastr.getChannelAdConfigurationDetailsFailed': 'Failed to load channel ad configuration',
  'amedia.toastr.getMediaListFailed': 'Failed to load media list',
  'amedia.channelDetails.oneAdUrlRequired': 'Must provide at least one ad URL',
  'amedia.toastr.clipProcessed': 'Your clip \'{{title}}\' is being processed.',
  'amedia.toastr.clipCreated': 'Clip Created',
  'amedia.toastr.cloningChannelStarted': 'Cloning channel started',
  'amedia.toastr.cloningGroupStarted': 'Cloning group started',
  'amedia.toastr.failedToCreateClip': 'Failed to create clip.',
  'amedia.toastr.failedToSaveCuepoints': 'Failed to save cuepoints',
  'amedia.toastr.failedToSaveError': 'Failed to save {{typeToSave}} {{name}}',
  'amedia.toastr.failedToSaveMediaError': 'Failed to save {{typeToSave}} {{name}} on {{mediaID}}',
  'amedia.toastr.groupCloned': 'Group cloned',
  'amedia.toastr.groupClonedFailed': 'Failed to clone group',
  'amedia.groupDetails.channelWillBeRemovedFromGroup': 'The selected {{itemType}} {{title}} will be ' +
    'removed from the current group but not the library.',
  'amedia.groupDetails.bulkChannelWillBeRemovedFromGroup': 'This will remove ' +
    'the following {{ contentTypeLabel }} from the current group but not the library:',
  'amedia.toastr.groupDetailsAddChannelError': 'Failed to add channel',
  'amedia.toastr.groupDetailsCreateGroupError': 'Failed to create group',
  'amedia.toastr.groupDetailsGroupCreated': 'Group has been created',
  'amedia.toastr.groupDetailsLoadingError': 'Failed to load group',
  'amedia.toastr.groupDetailsChannelLoadingError': 'Failed to load group channels',
  'amedia.toastr.groupDetails.ChannelUpdatedSuccess': 'Group channel list updated',
  'amedia.toastr.groupDetailsChannelRemoved': 'Channel is no longer in this group',
  'amedia.toastr.groupDetailsRemoveChannelError': 'Failed to remove channel from group',
  'amedia.toastr.groupDetailsUpdatingError': 'Failed to update group',
  'amedia.toastr.mediaDetailsUpdateError': 'There was an error updating {{mediaID}}',
  'amedia.toastr.loadChannelAdMediaFailed': 'Failed to load ad channel media list',
  'amedia.toastr.loadChannelMediaFailed': 'Failed to load channel media',
  'amedia.toastr.orgDisabled': 'The desired organization has been disabled',
  // tslint:disable-next-line
  'amedia.toastr.orgSuspended': 'This account has been suspended. Please see your Limelight representative for more information.',
  'amedia.toastr.previewProccessed': 'The new preview image is being processed and will update shortly',
  'amedia.toastr.previewSaved': 'Preview Image Saved',
  'amedia.toastr.reportTableExportFailed': 'Report CSV Export Failed',
  'amedia.toastr.restrictionSavingError': 'Restriction saving failed for: {{name}}',
  'amedia.toastr.sourceReplaced': 'Source Replaced',
  'amedia.toastr.sourceReplaced.description': 'The media is being processed and will be available shortly',
  'amedia.toastr.switchOrgError': 'There was an error changing organizations.',
  'amedia.toastr.updateChannelPropertiesFailed': 'Failed to update channel properties',
  'amedia.toastr.uploadFailed': 'Upload Failed',
  'amedia.toastr.uploadFailedFor': 'The upload failed for {{name}}',
  'amedia.unlink': 'Unlink',
  'amedia.unlinked': 'unlinked',
  'amedia.user.imposterLogin': 'Super User Email Address',
  'amedia.user.superPassword': 'Super User Password',
  'amedia.unsupportedProvider': 'Current provider is no longer supported',
  'amedia.update': 'Update',
  'amedia.updated': 'updated',
  'amedia.videoPlatform': 'Video Platform',
};
