import { Action } from '@ngrx/store';

import { Channel } from '../../models/channel';
import { ContentFilters } from '../../models/content-filters';
import { PaginatedResponse } from '../../models/paginated-response';
import { ChannelSection } from '../reducers/channel';
import { Media } from '../../models/media';
import { AdConfig, AdConfigMedia } from '../../models/ad-config';

export const CREATE_CHANNEL = '[Channel] Create Channel';
export const CREATE_CHANNEL_FAILED = '[Channel] Create Channel Failed';
export const CREATE_CHANNEL_SUCCEEDED = '[Channel] Create Channel Succeeded';
export const GET_CHANNEL_AD_MEDIA = '[Channel] Get Channel Ad Media';
export const GET_CHANNEL_AD_MEDIA_FAILED = '[Channel] Get Channel Ad Media Failed';
export const GET_CHANNEL_AD_MEDIA_SUCCEEDED = '[Channel] Get Channel Ad Media Succeeded';
export const RESET_TMP_CHANNEL_AD_MEDIA = '[Channel] Reset Tmp Channel Ad Media';
export const LOAD_ADS = '[Channel] Load Ads';
export const LOAD_ADS_FAILED = '[Channel] Load Ads Failed';
export const LOAD_ADS_SUCCEEDED = '[Channel] Load Ads Succeeded';
export const LOAD_CHANNEL = '[Channel] Load Channel';
export const LOAD_CHANNEL_FAILED = '[Channel] Load Channel Failed';
export const LOAD_CHANNEL_SUCCEEDED = '[Channel] Load Channel Succeeded';
export const LOAD_CHANNEL_MEDIA = '[Content] Load Channel Media';
export const LOAD_CHANNEL_MEDIA_SUCCEEDED = '[Content] Load Channel Media Succeeded';
export const LOAD_CHANNEL_MEDIA_FAILED = '[Content] Load Channel Media Failed';
export const LOAD_MEDIA_FOR_PICKER = '[Channel] Load Media For Channel Media Picker';
export const LOAD_MEDIA_FOR_PICKER_FAILED = '[Channel] Load Media For Channel Media Picker Failed';
export const LOAD_MEDIA_FOR_PICKER_SUCCEEDED = '[Channel] Load Media For Channel Media Picker Succeeded';
export const SELECT_ALL = '[Channel] Select All';
export const TOGGLE_SELECTION = '[Channel] Toggle Selection';
export const UPDATE_CHANNEL_MEDIA_LIST = '[Channel] Update Channel Media List';
export const UPDATE_CHANNEL_MEDIA_LIST_FAILED = '[Channel] Update Channel Media List Failed';
export const UPDATE_CHANNEL_MEDIA_LIST_SUCCEEDED = '[Channel] Update Channel Media List Succeeded';
export const REORDER_SINGLE_MEDIA = '[Channel] Reorder Single Media';
export const BULK_ADD_MEDIA = '[Channel] Bulk Add Media';
export const BULK_REMOVE_MEDIA = '[Channel] Bulk Remove Media';
export const UPDATE_CHANNEL_SECTION = '[Content] Update Channel Section';
export const UPDATE_CHANNEL_SECTION_FAILED = '[Content] Update Channel Section Failed';
export const UPDATE_CHANNEL_SECTION_SUCCEEDED = '[Content] Update Channel Section Succeeded';
export const CLONE_CHANNEL = '[Channel] Clone Channel';
export const CLONE_CHANNEL_FAILED = '[Channel] Clone Channel Failed';

export class CreateChannel implements Action {
  readonly type = CREATE_CHANNEL;

  constructor(public title: string) {}
}

export class CreateChannelFailed implements Action {
  readonly type = CREATE_CHANNEL_FAILED;
}

export class CreateChannelSucceeded implements Action {
  readonly type = CREATE_CHANNEL_SUCCEEDED;
}

export class LoadAds implements Action {
  readonly type = LOAD_ADS;

  constructor(
    public channelID: string,
    public section: ChannelSection,
  ) {}
}

export class LoadAdsFailed implements Action {
  readonly type = LOAD_ADS_FAILED;

  constructor(
    public channelID: string,
    public section: ChannelSection,
  ) {}
}

export class LoadAdsSucceeded implements Action {
  readonly type = LOAD_ADS_SUCCEEDED;

  constructor(
    public adConfig: AdConfig,
    public channelID: string,
    public section: ChannelSection,
  ) {}
}

export class LoadChannel implements Action {
  readonly type = LOAD_CHANNEL;

  constructor(public channelID: string) {}
}

export class LoadChannelFailed implements Action {
  readonly type = LOAD_CHANNEL_FAILED;
}

export class LoadChannelSucceeded implements Action {
  readonly type = LOAD_CHANNEL_SUCCEEDED;

  constructor(public channel: Channel) {}
}

export class LoadMediaForPicker implements Action {
  readonly type = LOAD_MEDIA_FOR_PICKER;

  constructor(
    public channelID: string,
    public clearList: boolean,
    public filters: ContentFilters,
  ) {}
}

export class LoadMediaForPickerFailed implements Action {
  readonly type = LOAD_MEDIA_FOR_PICKER_FAILED;

  constructor(public channelID: string) {}
}

export class LoadMediaForPickerSucceeded implements Action {
  readonly type = LOAD_MEDIA_FOR_PICKER_SUCCEEDED;

  constructor(
    public mediaList: PaginatedResponse,
    public channelID: string,
  ) {}
}

// For bulk add, delete, and reordering of channel media
export class UpdateChannelMediaList implements Action {
  readonly type = UPDATE_CHANNEL_MEDIA_LIST;

  constructor(
    public mediaList: any,
    public channelID: string,
    public fromClone?: boolean,
  ) {}
}

export class UpdateChannelMediaListFailed implements Action {
  readonly type = UPDATE_CHANNEL_MEDIA_LIST_FAILED;

  constructor(public channelID: string) {}
}

export class UpdateChannelMediaListSucceeded implements Action {
  readonly type = UPDATE_CHANNEL_MEDIA_LIST_SUCCEEDED;

  constructor(public channelID: string) {}
}

export class ReorderSingleMedia implements Action {
  readonly type = REORDER_SINGLE_MEDIA;

  constructor(
    public mediaID: string,
    public newOrder: number,
    public channelID: string,
  ) {}
}

export class BulkAddMedia implements Action {
  readonly type = BULK_ADD_MEDIA;

  constructor(
    public mediaIDs: string[],
    public channelID: string,
  ) {}
}

export class BulkRemoveMedia implements Action {
  readonly type = BULK_REMOVE_MEDIA;

  constructor(
    public mediaIDs: string[],
    public channelID: string,
  ) {}
}

export class UpdateChannelSection implements Action {
  readonly type = UPDATE_CHANNEL_SECTION;

  constructor(
    public section: ChannelSection,
    public channelID: string,
    public newValues: any,
    public file: File,
    public fromClone?: boolean,
  ) {}
}

export class UpdateChannelSectionFailed implements Action {
  readonly type = UPDATE_CHANNEL_SECTION_FAILED;

  constructor(
    public section: ChannelSection,
    public channelID: string,
  ) {}
}

export class UpdateChannelSectionSucceeded implements Action {
  readonly type = UPDATE_CHANNEL_SECTION_SUCCEEDED;

  constructor(
    public section: ChannelSection,
    public channelID: string,
    public newValues: any,
  ) {}
}

export class LoadChannelMedia implements Action {
  readonly type = LOAD_CHANNEL_MEDIA;

  constructor(
    public channelID: string,
    public clearContent: boolean,
    public filters: ContentFilters,
  ) {}
}

export class LoadChannelMediaSucceeded implements Action {
  readonly type = LOAD_CHANNEL_MEDIA_SUCCEEDED;

  constructor(
    public payload: PaginatedResponse,
    public channelID: string,
  ) {}
}

export class LoadChannelMediaFailed implements Action {
  readonly type = LOAD_CHANNEL_MEDIA_FAILED;

  constructor(public channelID: string) {}
}

export class SelectAll implements Action {
  readonly type = SELECT_ALL;

  constructor(public mediaList: string) {}
}

export class ToggleSelection implements Action {
  readonly type = TOGGLE_SELECTION;

  constructor(
    public payload: any,
    public selectionType: string,
    public mediaList: string,
  ) {}
}

export class GetChannelAdMedia implements Action {
  readonly type = GET_CHANNEL_AD_MEDIA;

  constructor(public channelID: string) {}
}

export class GetChannelAdMediaFailed implements Action {
  readonly type = GET_CHANNEL_AD_MEDIA_FAILED;
}

export class GetChannelAdMediaSucceeded implements Action {
  readonly type = GET_CHANNEL_AD_MEDIA_SUCCEEDED;

  constructor(public mediaList: PaginatedResponse) {}
}

export class ResetTmpChannelAdMedia implements Action {
  readonly type = RESET_TMP_CHANNEL_AD_MEDIA;

  constructor(public media: AdConfigMedia[]) {}
}

export class CloneChannel implements Action {
  readonly type = CLONE_CHANNEL;

  constructor(
    public newChannel: Channel,
  ) {}
}

export class CloneChannelFailed implements Action {
  readonly type = CLONE_CHANNEL_FAILED;
}

export type Actions = (
  CreateChannel | CreateChannelFailed | CreateChannelSucceeded |
  LoadAds | LoadAdsFailed | LoadAdsSucceeded |
  LoadChannel | LoadChannelFailed | LoadChannelSucceeded |
  LoadChannelMedia | LoadChannelMediaSucceeded | LoadChannelMediaFailed |
  LoadMediaForPicker | LoadMediaForPickerFailed |LoadMediaForPickerSucceeded |
  SelectAll | ToggleSelection | UpdateChannelMediaList |
  UpdateChannelMediaListFailed | UpdateChannelMediaListSucceeded |
  ReorderSingleMedia | BulkAddMedia | BulkRemoveMedia |
  UpdateChannelSection | UpdateChannelSectionFailed | UpdateChannelSectionSucceeded | ResetTmpChannelAdMedia |
  GetChannelAdMedia | GetChannelAdMediaFailed | GetChannelAdMediaSucceeded |
  CloneChannel | CloneChannelFailed
);
