import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Scheduler } from 'rxjs/Scheduler';
import { async } from 'rxjs/scheduler/async';
import { of } from 'rxjs/observable/of';
import { empty } from 'rxjs/observable/empty';
import { from } from 'rxjs/observable/from';
import {catchError,
  concat,
  debounceTime,
  delay,
  finalize,
  first,
  map,
  mergeMap,
  take,
  observeOn,
} from 'rxjs/operators';
import { Injectable, InjectionToken, Optional, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import * as ChannelActions from '../actions/channel';
import * as ContentActions from '../actions/content';
import * as fromRoot from '../reducers';
import * as fromContent from '../reducers/content';
import * as fromChannel from '../reducers/channel';
import { MediaDataService } from '../../services/media-data.service';
import { PaginatedResponse } from '../../models/paginated-response';
import { ContentActionType } from '../../models/content-action-type';
// tslint:disable-next-line
import { ChannelSection } from '../reducers/channel';
import { ContentFilters } from '../../models/content-filters';
import { MediaSearchService } from '../../services/media-search.service';
import { SortDirection } from 'control-ui-common';
import { asap } from 'rxjs/scheduler/asap';
import { Media } from '../../models/media';
import { mssToAmedia } from '../../utils/content-utils';
import { Base64UriToFileConverter } from '../../../shared/utils/base-64-uri-to-file';

export const LOAD_DEBOUNCE = new InjectionToken<number>('Load Debounce');
export const LOAD_SCHEDULER = new InjectionToken<Scheduler>('Load Scheduler');

@Injectable()
export class ChannelEffects {
  content$: Observable<fromContent.State>;
  channelState$: Observable<fromChannel.State>;

  // Listen for the 'CREATE_CHANNEL' action
  @Effect()
  addChannel$: Observable<Action> = this.actions$
    .pipe(
      ofType(ChannelActions.CREATE_CHANNEL),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ChannelActions.CreateChannel) => {
        return this.mds.createChannel({ title: action.title }).pipe(
          mergeMap((channel) => {
            this.toastr.success(
              this.translate.instant('amedia.toastr.channelCreated'),
              this.translate.instant('amedia.success'),
            );

            let obs$: Observable<Action> = of(new ChannelActions.CreateChannelSucceeded());

            this.content$.pipe(take(1)).subscribe((contentState) => {
              // Reload current page
              if (contentState.contentType === 'channels') {
                const { searchText, ...filters } = contentState.filters;

                obs$ = obs$.pipe(concat(
                  this.mds.getChannelsList({ searchText: undefined, ...filters }).pipe(
                    map((payload) => {
                      return new ContentActions.ContentListLoaded(payload as PaginatedResponse, 'channels', true);
                    }),
                    catchError((error) => of(new ContentActions.ContentListFailedToLoad('channels'))),
                  ),
                ));
              }
            });

            return obs$;
          }),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.toastr.channelCreatedFailed'),
              this.translate.instant('common.error.label'),
            );
            return of(new ChannelActions.CreateChannelFailed());
          }),
        );
      }),
  );

  // Listen for the 'LOAD_ADS' action
  @Effect()
  loadChannelAds$: Observable<Action> = this.actions$
    .pipe(
      ofType(ChannelActions.LOAD_ADS),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ChannelActions.LoadAds) => {
        return this.mds.getChannelAdConfigurationDetails(action.channelID).pipe(
          map((ad) => new ChannelActions.LoadAdsSucceeded(ad, action.channelID, action.section)),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.toastr.getChannelAdConfigurationDetailsFailed'),
              this.translate.instant('common.error.label'),
            );
            return of(new ChannelActions.LoadAdsFailed(action.channelID, action.section));
          }),
        );
      }),
    );

  // Listen for the 'LOAD_CHANNEL' action
  @Effect()
  loadChannel$: Observable<Action> = this.actions$
    .pipe(
      ofType(ChannelActions.LOAD_CHANNEL),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ChannelActions.LoadChannel) => {
        return this.mds.getAllChannelProperties(action.channelID).pipe(
          map((channel) => new ChannelActions.LoadChannelSucceeded(channel)),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant(
                error['status'] === 404
                  ? 'amedia.toastr.channelNotFound'
                  : 'amedia.toastr.failedToLoadChannel',
                ),
              this.translate.instant('common.error.label'),
            );
            this.router.navigate(['/app/content/channels']);
            return of(new ChannelActions.LoadChannelFailed());
          }),
        );
      }),
    );

  // Listen for the 'LOAD_MEDIA_FOR_PICKER' action
  @Effect()
  loadMediaForPicker$: Observable<Action> = this.actions$
    .pipe(
      ofType(ChannelActions.LOAD_MEDIA_FOR_PICKER),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ChannelActions.LoadMediaForPicker) => {

        let channelState: fromChannel.State;
        this.channelState$.pipe(take(1)).subscribe((s) => channelState = s);

        return this.mss.getMediaList(channelState.mediaNotInChannelFilters).pipe(
          map((mediaList) => {
            this.content$.pipe(take(1)).subscribe((chState) => {
              if (!!chState.allChannels) {
                const channel = _.find(chState.allChannels, (c) => c.channel_id === action.channelID);
                if (!!channel) {
                  // Mark stale data for display purposes
                  _.forEach(
                    mediaList.results,
                    (media: Media) => {
                      media['alreadyInChannel'] = _.find(
                        channel['media_list'],
                        (m) => m.mediaId === media.mediaId,
                      );
                    },
                  );
                }
              }
            });
            return new ChannelActions.LoadMediaForPickerSucceeded(mediaList, action.channelID);
          }),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.toastr.getMediaListFailed'),
              this.translate.instant('common.error.label'),
            );
            return of(new ChannelActions.LoadMediaForPickerFailed(action.channelID));
          }),
        );
      }),
    );

  // Listen for the 'UPDATE_CHANNEL_SECTION' action
  @Effect()
  updateChannelSection$: Observable<Action> = this.actions$
    .pipe(
      ofType(ChannelActions.UPDATE_CHANNEL_SECTION),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ChannelActions.UpdateChannelSection) => {
        let obs$;
        const CS = ChannelSection;
        switch (action.section) {
          case CS.Properties:
          case CS.PlayerOptions:
          case CS.RssCategories:
            obs$ = this.mds.updateChannel(action.channelID, action.newValues);
            break;
          case CS.Preview:
            obs$ = this.mds.savePreview(
              'channels',
              action.channelID,
              action.file,
            );
            break;
          case CS.Ads:
            obs$ = this.mds.updateChannelAdConfigurationDetails(action.channelID, action.newValues);
            break;
          case CS.CustomProps:
            obs$ = this.mds.assignCustomProperty('channels', action.channelID, action.newValues['custom_properties']);
            break;
          default:
            return empty();
        }

        return obs$.pipe(
          map((res) => {
            let newValues = action.newValues;
            if (action.section === ChannelSection.Ads) {
              newValues = res;
            }
            if (action.section === ChannelSection.Preview && !action.fromClone) {
              this.toastr.success(
                this.translate.instant('amedia.toastr.previewProccessed'),
                this.translate.instant('amedia.toastr.previewSaved'),
              );
            }
            return new ChannelActions.UpdateChannelSectionSucceeded(
              action.section,
              action.channelID,
              newValues,
            );
          }),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.toastr.updateChannelPropertiesFailed'),
              this.translate.instant('common.error.label'),
            );
            return of(new ChannelActions.UpdateChannelSectionFailed(action.section, action.channelID));
          }),
        );
      }),
    );

  // Listen for the 'LOAD_CHANNEL_MEDIA' action
  @Effect()
  loadChannelMedia$: Observable<Action> = this.actions$
    .pipe(
      ofType(ChannelActions.LOAD_CHANNEL_MEDIA),
      observeOn(asap), // force concat to be truly synchronous
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ChannelActions.LoadChannelMedia) => {
        // First, get fresh channels list
        return of(new ContentActions.GetChangeset('channels')).pipe(
          concat(
            this.content$.pipe(
              first((content) => !content.channelsChangesetLoading),
              mergeMap((content) => {
                let filters: ContentFilters = action.filters;
                if (!filters) {
                  this.channelState$.pipe(take(1)).subscribe((channelState) => {
                    filters = {
                      pageSize: 10,
                      page: 1,
                    };
                    if (!!channelState.currChannelMedia) {
                      filters = {
                        pageSize: channelState.currChannelMedia.size,
                        page: channelState.currChannelMedia.page,
                      };
                    }
                  });
                }

                let channelMediaIDs: string[] = [];
                const channel = _.find(content.allChannels, (c) => c.channel_id === action.channelID);
                if (!!channel) {
                  channelMediaIDs = _.map(channel['media_list'], (ch) => ch.mediaId);
                }

                // Don't bother querying if there are no media
                if (channelMediaIDs.length === 0) {
                  return of(new ChannelActions.LoadChannelMediaSucceeded(
                    {
                      page: 1,
                      size: filters.pageSize,
                      total: 0,
                      results: [],
                    },
                    action.channelID,
                  ));
                }

                // This fakes the pagination by querying for the proper slice of IDs
                let start: number = (filters.page - 1) * filters.pageSize;
                if (start >= channelMediaIDs.length) {
                  // We deleted enough for the current page to not exist anymore
                  filters.page = 1;
                  start = (filters.page - 1) * filters.pageSize;
                }
                const pageOfMediaIds =  _.slice(
                  channelMediaIDs, start,
                  Math.min(start + filters.pageSize, channelMediaIDs.length),
                );
                const mssFilters: ContentFilters = {
                  ...filters,
                  queryParams: {
                    ...filters.queryParams,
                    // Fake pagination by only asking for one page worth of IDs
                    id: pageOfMediaIds,
                  },
                  page: 1,
                };

                return this.mss.getMediaList(mssFilters).pipe(
                  map((payload) => {
                    payload.page = filters.page;
                    payload.total = channelMediaIDs.length;

                    // Sort based on play order, there could be duplicates
                    // N^2 yayayayayayay
                    const results = payload.results;
                    payload.results = [];
                    for (const id of pageOfMediaIds) {
                      const media = _.find(results, (r) => r.mediaId === id);
                      if (!!media) {
                        payload.results.push(media);
                      }
                    }

                    // Assign play order
                    payload.results = _.map(
                      payload.results,
                      (m, idx) => ({
                        ...m,
                        playOrder: start + idx + 1,
                      }),
                    );

                    return new ChannelActions.LoadChannelMediaSucceeded(
                      payload as PaginatedResponse,
                      action.channelID,
                    );
                  }),
                  catchError((error) => {
                    this.toastr.error(
                      this.translate.instant('amedia.toastr.loadChannelMediaFailed'),
                      this.translate.instant('common.error.label'),
                    );
                    return of(new ChannelActions.LoadChannelMediaFailed(action.channelID));
                  }),
                );
              },
            ),
          ),
        ));
      }),
    );

  // Listen for the 'GET_CHANNEL_AD_MEDIA' action
  @Effect()
  getChannelAdMedia$: Observable<Action> = this.actions$
    .pipe(
      ofType(ChannelActions.GET_CHANNEL_AD_MEDIA),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ChannelActions.GetChannelAdMedia) => {
        return this.mds.getChannelMedia(action.channelID, {}).pipe(
          map((mediaList) => new ChannelActions.GetChannelAdMediaSucceeded(mediaList),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.toastr.loadChannelAdMediaFailed'),
              this.translate.instant('common.error.label'),
            );
            return of(new ChannelActions.GetChannelAdMediaFailed());
          }),
        ));
      }),
    );

  // Listen for the 'REORDER_SINGLE_MEDIA' action
  @Effect()
  reorderSingleMedia$: Observable<Action> = this.actions$
    .pipe(
      ofType(ChannelActions.REORDER_SINGLE_MEDIA),
      observeOn(asap), // force concat to be truly synchronous
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ChannelActions.ReorderSingleMedia) => {
        // First, get fresh channels list
        return of(new ContentActions.GetChangeset('channels')).pipe(
          concat(
            this.content$.pipe(
              first((content) => !content.channelsChangesetLoading),
              map((content) => {
                const channel = _.find(content.allChannels, (c) => c.channel_id === action.channelID);
                let mediaList = _.cloneDeep(channel['media_list']);
                const idx = _.findIndex(mediaList, (m) => m['mediaId'] === action.mediaID);
                if (idx === -1) {
                  this.toastr.error(
                    this.translate.instant('amedia.toastr.channelMediaAlreadyRemoved'),
                    this.translate.instant('common.error.label'),
                  );

                  return new ChannelActions.LoadChannelMedia(action.channelID, false, null);
                } else {
                  if (action.newOrder < 1) {
                    // check to see if user entered a negative number
                    action.newOrder = 0;
                  }

                  mediaList.splice(action.newOrder, 0, mediaList.splice(idx, 1)[0]);
                  return new ChannelActions.UpdateChannelMediaList(
                    _.uniq(mediaList),
                    action.channelID,
                  );
                }
              }),
            ),
          ),
        );
      }),
    );

  // Listen for the 'BULK_ADD_MEDIA' action
  @Effect()
  bulkAddMedia$: Observable<Action> = this.actions$
    .pipe(
      ofType(ChannelActions.BULK_ADD_MEDIA),
      observeOn(asap), // force concat to be truly synchronous
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ChannelActions.BulkAddMedia) => {
        // First, get fresh channels list
        return of(new ContentActions.GetChangeset('channels')).pipe(
          concat(
            this.content$.pipe(
              observeOn(asap),
              first((content) => !content.channelsChangesetLoading),
              mergeMap((content) => {
                let channel = _.find(content.allChannels, (c) => c.channel_id === action.channelID);

                return of(new ChannelActions.UpdateChannelMediaList(
                  _.uniq([
                    ...channel['media_list'],
                    ..._.map(action.mediaIDs, (id) => ({ mediaId: id })),
                  ]),
                  action.channelID,
                )).pipe(
                  concat(
                    this.channelState$.pipe(
                      first((chState) => {
                        const sections = chState.busySections[action.channelID] || [];
                        return sections.indexOf(ChannelSection.MediaList) < 0;
                      }),
                      map((chState) => {
                        // We got here because LoadChannelMedia / GetChangeset finished. So, grab
                        // latest changset data.
                        let allChannels;
                        this.content$.pipe(take(1)).subscribe((cState) => allChannels = cState.allChannels);
                        channel = _.find(allChannels, (c) => c.channel_id === action.channelID);

                        // Mark stale data for display purposes
                        const mediaNotInChannel = _.cloneDeep(chState.filteredMediaForPicker);
                        _.forEach(
                          mediaNotInChannel.results,
                          (media: Media) => {
                            media['selected'] = false;
                            media['alreadyInChannel'] = _.find(
                              channel['media_list'],
                              (m) => m.mediaId === media.mediaId,
                            );
                          },
                        );
                        return new ChannelActions.LoadMediaForPickerSucceeded(mediaNotInChannel, action.channelID);
                      }),
                    ),
                  ),
                );
              }),
            ),
          ),
        );
      }),
    );

  // Listen for the 'BULK_REMOVE_MEDIA' action
  @Effect()
  bulkRemoveMedia$: Observable<Action> = this.actions$
    .pipe(
      ofType(ChannelActions.BULK_REMOVE_MEDIA),
      observeOn(asap), // force concat to be truly synchronous
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ChannelActions.BulkRemoveMedia) => {
        // First, get fresh channels list
        return of(new ContentActions.GetChangeset('channels')).pipe(
          concat(
            this.content$.pipe(
              first((content) => !content.channelsChangesetLoading),
              map((content) => {
                const channel = _.find(content.allChannels, (c) => c.channel_id === action.channelID);
                const mediaList = _.filter(
                  channel['media_list'],
                  (m) => !_.includes(action.mediaIDs, m['mediaId']),
                );

                return new ChannelActions.UpdateChannelMediaList(
                  _.uniq(mediaList),
                  action.channelID,
                );
              }),
            ),
          ),
        );
      }),
    );

  // Listen for the 'UPDATE_CHANNEL_MEDIA_LIST' action
  @Effect()
  updateChannelMediaList$: Observable<Action> = this.actions$
    .pipe(
      ofType(ChannelActions.UPDATE_CHANNEL_MEDIA_LIST),
      // .debounceTime(this.debounce, this.scheduler || async)
      observeOn(asap), // force concat to be truly synchronous
      mergeMap((action: ChannelActions.UpdateChannelMediaList) => {
        // Update list
        return this.mds.setChannelMediaList(action.channelID, action.mediaList).pipe(
          delay(500), // Mitigate timing issues
          mergeMap(() => {
            // Fetch changeset and get fresh list
            return of(new ChannelActions.LoadChannelMedia(action.channelID, false, null)).pipe(
              concat(
                this.channelState$.pipe(
                  first((chState) => {
                    const sections = chState.busySections[action.channelID] || [];
                    return sections.indexOf(ChannelSection.MediaList) < 0;
                  }),
                  map((chState) => {
                    if (!action.fromClone) {
                      this.toastr.success(
                        this.translate.instant('amedia.channelDetails.updateChannelMediaListSuccess'),
                        this.translate.instant('amedia.success'),
                      );
                    }
                    return new ChannelActions.UpdateChannelMediaListSucceeded(action.channelID);
                  }),
                ),
              ),
            );
          }),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant('amedia.toastr.channelUpdateMediaListFailed'),
              this.translate.instant('common.error.label'),
            );
            return of(new ChannelActions.UpdateChannelMediaListFailed(action.channelID));
          }),
        );
      }),
  );

  // Listen for the 'CLONE_CHANNEL' action
  @Effect()
  cloneChannel$: Observable<Action> = this.actions$
    .pipe(
      ofType(ChannelActions.CLONE_CHANNEL),
      mergeMap((action: ChannelActions.CloneChannel) => {
        this.toastr.info(
          this.translate.instant('amedia.toastr.cloningChannelStarted'),
          this.translate.instant('amedia.notification'),
        );
        const channelProps = _.omit(
          action.newChannel,
          ['channel_id', 'create_date', 'media_list', 'publish_date', 'thumbnail', 'update_date', 'custom_properties'],
        );

        // backend requires these to be strings instead of booleans
        return this.mds.createChannel({
          ..._.mapValues(_.omitBy(channelProps, _.isNil), (v) => `${v}`),
        }).pipe(
          mergeMap((res) => {
            return this.mds.getChannelAdConfigurationDetails(action.newChannel.channel_id).pipe(
              observeOn(asap),
              mergeMap((ads: any) => {
                return new Observable((observer) => {
                  // Dispatches action
                  observer.next(new ChannelActions.UpdateChannelMediaList(
                    _.map(action.newChannel['media_list'], (m) => ({ mediaId: m.mediaId })),
                    res.channel_id,
                    true,
                  ));

                  // Wait for list to update
                  this.channelState$.pipe(
                    first((channelState) => {
                      const sections = channelState.busySections[res.channel_id] || [];
                      return sections.indexOf(ChannelSection.MediaList) < 0;
                    }),
                  ).subscribe(() => {
                    if (!ads) {
                      ads = { adConfigurationType: null };
                    } else {
                      ads.details['adConfigurationType'] = ads.adConfigurationType;
                      ads = ads.details;
                    }

                    // Dispathes action
                    observer.next(new ChannelActions.UpdateChannelSection(
                      ChannelSection.Ads,
                      res.channel_id,
                      ads,
                      null,
                    ));

                    // Wait for ads to update
                    this.channelState$.pipe(
                      first((chState) => {
                        const sections = chState.busySections[res.channel_id] || [];
                        return sections.indexOf(ChannelSection.Ads) < 0;
                      }),
                    ).subscribe((cState) => {

                      if (!_.isEmpty(action.newChannel.custom_properties)) {
                        observer.next(new ChannelActions.UpdateChannelSection(
                          ChannelSection.CustomProps,
                          res.channel_id,
                          { custom_properties: action.newChannel.custom_properties},
                          null,
                        ));
                      }

                      const reloadPageAndFinish = () => {
                        let redoFilters;
                        let contentType;
                        this.content$.pipe(take(1)).subscribe((contentState) => {
                          contentType = contentState.contentType;
                          if (contentType === 'channels') {
                            const { searchText, ...filters } = contentState.filters;
                            redoFilters = filters;
                          }
                        });

                        if (contentType === 'channels') {
                          // Reload current page
                          this.mds.getChannelsList({ searchText: undefined, ...redoFilters }).pipe(
                            catchError((error) => of(new ContentActions.ContentListFailedToLoad('channels'))),
                            finalize(() => observer.complete()),
                          ).subscribe((payload) => {
                            observer.next(
                              new ContentActions.ContentListLoaded(payload as PaginatedResponse, 'channels', true));
                            this.toastr.success(
                              this.translate.instant('amedia.toastr.channelCloned'),
                              this.translate.instant('amedia.success'),
                            );
                          });
                        }
                      };

                      if (!!action.newChannel.thumbnail_url) {
                        // Load image in to canvas
                        const image = new Image();
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        image.onload = () => {
                          canvas.width = image.width;
                          canvas.height = image.height;
                          ctx.drawImage(image, 0, 0, image.width, image.height);

                          observer.next(new ChannelActions.UpdateChannelSection(
                            ChannelSection.Preview,
                            res.channel_id,
                            { thumbnail_url: canvas.toDataURL('image/png') },
                            this.base64UriToFile.base64UriToFile(canvas.toDataURL('image/png')),
                            true,
                          ));

                          this.channelState$.pipe(
                            first((chState) => {
                              const sections = chState.busySections[res.channel_id] || [];
                              return sections.indexOf(ChannelSection.Preview) < 0;
                            }),
                          ).subscribe(() => reloadPageAndFinish());
                        };
                        image.crossOrigin = 'Anonymous';
                        image.src = action.newChannel.thumbnail_url;
                      } else {
                        // We're done
                        reloadPageAndFinish();
                      }
                    });
                  });
                }) as Observable<Action>;
              }),
            );
          }),
          // Catch error for clone failing
          catchError((error) => {
            console.error(error);
            this.toastr.error(
              this.translate.instant('amedia.toastr.channelCloneFailed'),
              this.translate.instant('common.error.label'),
            );
            return of(new ChannelActions.CloneChannelFailed());
          }),
        );
      }),
    );

  constructor(
    private actions$: Actions,
    private base64UriToFile: Base64UriToFileConverter,
    private mds: MediaDataService,
    private mss: MediaSearchService,
    private router: Router,
    private store: Store<fromRoot.State>,
    private toastr: ToastrService,
    private translate: TranslateService,
    @Optional() @Inject(LOAD_DEBOUNCE) private debounce: number = 300,
    /**
     * You inject an optional Scheduler that will be undefined
     * in normal application usage, but its injected here so that you can mock out
     * during testing using the RxJS TestScheduler for simulating passages of time.
     */
    @Optional() @Inject(LOAD_SCHEDULER) private scheduler: Scheduler,
  ) {
    this.content$ = this.store.pipe(select(fromRoot.getContentState));
    this.channelState$ = this.store.pipe(select(fromRoot.getChannelState));
  }
}
