export enum ReportsTabs {
    OVERVIEW = 'OVERVIEW',
    USAGE = 'USAGE',
    MEDIA = 'MEDIA',
    CHANNEL = 'CHANNEL',
    GEOGRAPHY = 'GEOGRAPHY',
    VIEWER = 'VIEWER',
    ENGAGEMENT = 'ENGAGEMENT',
    EVENT_HISTORY = 'EVENT_HISTORY',
}
