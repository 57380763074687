import { EnvConfigurationService, Configuration } from './../../services/environment-configuration.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Scheduler } from 'rxjs/Scheduler';
import { async } from 'rxjs/scheduler/async';
import { empty } from 'rxjs/observable/empty';
import { of } from 'rxjs/observable/of';
import { timer } from 'rxjs/observable/timer';
import { forkJoin } from 'rxjs/observable/forkJoin';
import {
  catchError,
  concat,
  first,
  map,
  mergeMap,
  take,
  takeUntil,
  switchMap,
} from 'rxjs/operators';
import { Injectable, InjectionToken, Optional, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import * as MediaActions from '../actions/media';
import * as fromRoot from '../reducers';
import * as fromMedia from '../reducers/media';
import { MediaDataService } from '../../services/media-data.service';
import { MediaSearchService } from '../../services/media-search.service';
import { CuepointManagementService } from '../../services/cuepoint-mgmt.service';
import { Cuepoint } from '../../models/cuepoints';
import { Errors } from '../../models/errors';
import { parseShimErrors } from '../../utils/parse-errors';
import { uuidToDuuid } from '../../utils/duuid-generator';
import { Base64UriToFileConverter } from '../../../shared/utils/base-64-uri-to-file';

export const LOAD_DEBOUNCE = new InjectionToken<number>('Load Debounce');
export const LOAD_SCHEDULER = new InjectionToken<Scheduler>('Load Scheduler');

@Injectable()
export class MediaEffects {
  ccVersions$: Observable<{[mediaID: string]: number}>;
  media$: Observable<fromMedia.State>;
  orgId$: Observable<string>;
  env: Configuration;

  // Listen for the 'LOAD_MEDIA' action
  @Effect()
  loadMedia$: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.LOAD_MEDIA),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: MediaActions.LoadMedia) => {
        return this.mss.getMedia(action.mediaID).pipe(
          mergeMap((media) => {
            if (media.closedCaptioningFileVersion > 0) {
              return this.orgId$.pipe(
                first((id) => !!id),
                map((orgId) => {
                  media.closedCaptioningUrl = `${this.env.CLOSED_CAPTIONS_BASE_URL}/` +
                    `${uuidToDuuid(orgId)}/${uuidToDuuid(action.mediaID)}/cc/` +
                    `${media.closedCaptioningFileVersion}.xml`;
                  return new MediaActions.LoadMediaSucceeded(media);
                }),
              );
            } else {
              return of(new MediaActions.LoadMediaSucceeded(media));
            }
          }),
          catchError((error) => {
            const errors: Errors = parseShimErrors(error.error || {});
            this.toastr.error(
              _.isEmpty(errors.general)
              ? this.translate.instant('amedia.toastr.mediaNotFound')
              : errors.general[0],
              this.translate.instant('common.error.label'),
            );
            this.router.navigate(['/app/content/media']);
            return of(new MediaActions.LoadMediaFailed(action.mediaID));
          }),
        );
      }),
    );

  // Listen for the 'EDIT_MEDIA_SECTION' action
  @Effect()
  editMediaSection$: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.EDIT_MEDIA_SECTION),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: MediaActions.EditMediaSection) => {
        let obs$;

        if (action.section === fromMedia.MediaSection.CustomProperties) {
          obs$ = this.mds.assignCustomProperty('media', action.mediaID, action.newValues['customProperties']);
        } else {
          // We must rename fields from the MSS-variant to MDS
          const rename: {[key: string]: string} = {
            contentAccessRuleId: 'restrictionrule_id',
            publishDate: 'sched_start_date',
            unpublishDate: 'sched_end_date',
            refId: 'ref_id',
          };

          // rename fields if needed and remove incompatible placeholder
          let vpwsValues = _.pickBy(
            _.mapKeys(action.newValues, (v, k) => rename[k] || k),
            (v, k) => k !== 'adsDisabled',
          );

          // Convert millis to seconds
          if (!!vpwsValues['sched_start_date']) {
            vpwsValues['sched_start_date'] = vpwsValues['sched_start_date'] / 1000;
          }
          if (!!vpwsValues['sched_end_date']) {
            vpwsValues['sched_end_date'] = vpwsValues['sched_end_date'] / 1000;
          }

          let desiredState: string = vpwsValues['mediaState'];
          // the state can sometimes be 'Publishable' or 'Unpublished'
          // the UI generally shows 'Unpublished', so normalize that for comparison
          if (desiredState === 'Publishable') {
            desiredState = 'Unpublished';
          }
          // service will 400 if mediaState is sent during properties update
          vpwsValues = _.omit(vpwsValues, ['mediaState']);
          let updateObs = this.mds.updateMedia(action.mediaID, vpwsValues);
          obs$ = updateObs;
          if (action.section === fromMedia.MediaSection.Properties) {
            // we need to check if we changed the published state
            let currState;
            this.media$.pipe(take(1)).subscribe((state: fromMedia.State) => {
              currState = state.currMedia.mediaState;
            });
            if (!!desiredState && desiredState !== currState &&
                (desiredState === 'Published' || desiredState === 'Unpublished')) {
              let publishObs;
              if (desiredState === 'Published') {
                publishObs = this.mds.publishUnpublishMedia(action.mediaID, 'publish');
              } else {
                publishObs = this.mds.publishUnpublishMedia(action.mediaID, 'unpublish');
              }
              // introduce delay to avoid an optimistic locking exception from MDS
              let delayedPublishObs = timer(5000).pipe(switchMap(() => {
                return publishObs;
              }));
              obs$ = forkJoin(updateObs, delayedPublishObs);
            }
          } else {
            obs$ = updateObs;
          }
        }

        return obs$.pipe(
          map((payload) => new MediaActions.EditMediaSectionSucceeded(
            action.section, action.mediaID, action.newValues,
          )),
          catchError((error) => {
            const errors: Errors = parseShimErrors(error.error || {});

            this.toastr.error(
              _.isEmpty(errors.general)
                ? this.translate.instant(
                  'amedia.toastr.mediaDetailsUpdateError',
                  { mediaID: action.mediaID },
                )
                : errors.general[0],

              this.translate.instant('amedia.content.error'),
            );

            return of(new MediaActions.EditMediaSectionFailed(action.section, action.mediaID));
          }),
        );
      }),
    );

  // Listen for the 'SAVE_PREVIEW' action
  @Effect()
  savePreview$: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.SAVE_PREVIEW),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: MediaActions.SavePreview) => {
        let obs$: Observable<Action>;

        if (action.imageURI.startsWith('http')) {
          // When reverting to the default image, we use the remote URL
          obs$ = this.mds.savePreviewUrl(
            action.contentType, action.contentId, action.imageURI, action.thumbImageURI,
          );
        } else {
          if (!action.image) {
            // If no file is specified, fake it using the base64 URI
            action.image = this.base64UriToFile.base64UriToFile(action.imageURI);
          }
          // Override the filename, but keep the extension.
          // back end doesn't support spaces, but this isn't a user-facing filename
          // so arbitrary changing the name shouldn't matter.
          // Note: this is strictly as a workaround for the services, which should support spaces.
          // Today, the service tries to put the filename directly into a url, which fails.
          const parts = action.image.name.split('.');
          const ext = parts[parts.length - 1];
          const f = action.image;
          action.image = new File([f], 'thumbnail.' + ext, { type: f.type });
          obs$ = this.mds.savePreview(
            action.contentType, action.contentId, action.image,
          );
        }

        return obs$.pipe(
          map((res) => {
            this.toastr.success(
              this.translate.instant('amedia.toastr.previewProccessed'),
              this.translate.instant('amedia.toastr.previewSaved'),
            );

            if (action.contentType === 'media' && action.frameTimeInMillis) {
              // This kicks of high-quality thumbnail processing. The thumbnail will be temporarily
              // replaced by the lower-quality snapshot from the player.
              this.mds.startMediaPreviewProcessing(action.contentId, action.frameTimeInMillis)
              .pipe(
                catchError(() => {
                  this.toastr.error(
                    this.translate.instant('amedia.toastr.highQualityThumbnailKickoffFailed'),
                  );
                  return empty();
                }),
              )
              .subscribe();
            }
            return new MediaActions.SavePreviewSucceeded(
              action.contentId, action.imageURI, action.thumbImageURI,
            );
          }),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant(
                'amedia.toastr.uploadFailedFor',
                { name: action.image.name },
              ),
              this.translate.instant('amedia.toastr.uploadFailed'),
            );

            return of(new MediaActions.SavePreviewFailed(action.contentId, action.image));
          }),
        );
      }),
    );

  // Listen for the 'UPLOAD_IMAGE' action
  @Effect()
  uploadImage$: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.UPLOAD_IMAGE),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: MediaActions.UploadImage) => {
        // If no file is specified, fake it
        if (!action.image) {
          action.image = this.base64UriToFile.base64UriToFile(action.base64URI);
        }

        return this.mds.uploadImage(
          action.image,
          action.imageType,
          action.width,
          action.height,
          action.orgId,
        ).pipe(
          mergeMap((imageURL) => {
            const successAction = new MediaActions.UploadImageSucceeded(action.id, imageURL);

            // Load the image as part of the effect so the browser will cache the image
            const img = new Image();
            img.onload = () => this.store.dispatch(successAction);
            img.onerror = () => this.store.dispatch(successAction);
            img.src = imageURL;

            return empty() as Observable<Action>;
          }),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant(
                'amedia.toastr.uploadFailedFor',
                { name: action.image.name },
              ),
              this.translate.instant('amedia.toastr.uploadFailed'),
            );

            return of(new MediaActions.UploadImageFailed(action.id));
          }),
        );
      }),
    );

  // Listen for the 'LOAD_SUGGESTED_TAGS' action
  @Effect()
  loadSuggestedTags$: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.LOAD_SUGGESTED_TAGS),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: MediaActions.LoadSuggestedTags) => {
        return this.mds.getSuggestedTags(action.mediaID).pipe(
          map((payload) => new MediaActions.LoadSuggestedTagsSucceeded(payload)),
          catchError((error) => of(new MediaActions.LoadSuggestedTagsFailed())),
        );
      }),
    );

  // Listen for the 'CREATE_CLIP' action
  @Effect()
  createClip$: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.CREATE_CLIP),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: MediaActions.CreateClip) => {
        return this.mds.createClip(
          action.mediaID,
          action.title,
          action.startTime,
          action.endTime,
          action.endscreenImageUri,
        ).pipe(
          map((payload) => {

            this.toastr.success(
              this.translate.instant(
                'amedia.toastr.clipProcessed',
                { title: action.title },
              ),
              this.translate.instant('amedia.toastr.clipCreated'),
              { enableHtml: true },
            );

            return new MediaActions.CreateClipSucceeded(action.mediaID);
          }),
          catchError((error) => {
            const errors: Errors = parseShimErrors(error.error || {});

            this.toastr.error(
              _.isEmpty(errors.general)
                ? this.translate.instant('amedia.toastr.failedToCreateClip')
                : errors.general[0],
              this.translate.instant('amedia.content.error'),
            );

            return of(new MediaActions.CreateClipFailed(action.mediaID));
          }),
        );
      }),
    );

  // Listen for the 'LOAD_CUEPOINTS' action
  @Effect()
  loadCuepoints$: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.LOAD_CUEPOINTS),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: MediaActions.LoadCuepoints) => {
        return this.cpms.getCuepoints(action.mediaID).pipe(
          map((payload) => new MediaActions.LoadCuepointsSucceeded(action.mediaID, payload)),
          catchError((error) => of(new MediaActions.LoadCuepointsFailed(action.mediaID))),
        );
      }),
    );

  // Listen for the 'REPLACE_CUEPOINTS' action
  @Effect()
  replaceCuepoints$: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.REPLACE_CUEPOINTS),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: MediaActions.ReplaceCuepoints) => {
        // Convert to camelcase and details becomes JSON string
        const cuepoints: Cuepoint[] = _.map(
          action.cuepoints,
          (cuepoint) => _.reduce(
            cuepoint,
            (result, v, k) => {
              if (k === 'details') {
                v = JSON.stringify(v);
              }

              result[_.camelCase(k)] = v;

              return result;
            },
            {},
          ) as Cuepoint,
        );

        return this.cpms.replaceCuepoints(action.mediaID, cuepoints).pipe(
          map((payload) => new MediaActions.ReplaceCuepointsSucceeded(action.mediaID, action.cuepoints)),
          catchError((error) => {
            const errors: Errors = parseShimErrors(error.error || {});

            this.toastr.error(
              _.isEmpty(errors.general)
                ? this.translate.instant('amedia.toastr.failedToSaveCuepoints')
                : errors.general[0],
              this.translate.instant('common.error.label'),
            );

            return of(new MediaActions.ReplaceCuepointsFailed(action.mediaID));
          }),
        );
      }),
    );

    // Listen for the 'LOAD_MEDIA_ENCODINGS' action
    @Effect()
    loadMediaEncodings$: Observable<Action> = this.actions$
      .pipe(
        ofType(MediaActions.LOAD_MEDIA_ENCODINGS),
        // .debounceTime(this.debounce, this.scheduler || async)
        mergeMap((action: MediaActions.LoadMediaEncodings) => {
          return this.mds.getMediaEncodings(action.mediaID).pipe(
            map((payload) => new MediaActions.LoadMediaEncodingsSucceeded(action.mediaID, payload)),
            catchError((error) => of(new MediaActions.LoadMediaEncodingsFailed(action.mediaID))),
          );
        }),
      );

    // Listen for the 'GET_PUBLISH_STATE' acton
    @Effect()
    getPublishState$: Observable<Action> = this.actions$
      .pipe(
        ofType(MediaActions.GET_PUBLISH_STATE),
        // .debounceTime(this.debounce, this.schedular || async)
        mergeMap((action: MediaActions.GetPublishState) => {
          return this.mds.getPubishState().pipe(
            map((payload) => new MediaActions.GetPublishStateSucceeded(payload)),
            catchError((error) => of(new MediaActions.GetPublishStateFailed())),
          );
        }),
      );

    // Listen for the 'UPDATE_DEFAULT_PUBLISH_STATE' acton
    @Effect()
    updateDefaultPublishState$: Observable<Action> = this.actions$
      .pipe(
        ofType(MediaActions.UPDATE_DEFAULT_PUBLISH_STATE),
        // .debounceTime(this.debounce, this.schedular || async)
        mergeMap((action: MediaActions.UpdateDefaultPublishState) => {
          return this.mds.updateDefaultPublishState(action.state).pipe(
            map((payload) => new MediaActions.UpdateDefaultPublishStateSucceeded()),
            catchError((error) => of(new MediaActions.UpdateDefaultPublishStateFailed())),
          );
        }),
      );

  @Effect()
  saveClosedCaption$: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.CREATE_CLOSED_CAPTION),
      // .debounceTime(this.debounce, this.schedular || async)
      mergeMap((action: MediaActions.CreateClosedCaption) => {
        return this.mds.saveClosedCaptions(action.mediaID, action.captionFile).pipe(
          // Start polling looking for an updated CC file version
          mergeMap(() => timer(2000, 5000).pipe(
            takeUntil(
              this.ccVersions$.pipe(
                first((versions) => versions[action.mediaID] >= Math.abs(action.ccVersion) + 1),
              ),
            ),
            // give up after 3 attempts
            // takeWhile((itr) => itr < 3),
            mergeMap(() => this.mss.getMedia(action.mediaID).pipe(
              mergeMap((media) => {
                if (media.closedCaptioningFileVersion > 0) {
                  return this.orgId$.pipe(
                    first((id) => !!id),
                    map((orgId) => {
                      media.closedCaptioningUrl = `${this.env.CLOSED_CAPTIONS_BASE_URL}/` +
                        `${uuidToDuuid(orgId)}/${uuidToDuuid(action.mediaID)}/cc/` +
                        `${media.closedCaptioningFileVersion}.xml`;
                      return new MediaActions.UpdateCCVersions(media);
                    }),
                  );
                } else {
                  return of(new MediaActions.UpdateCCVersions(media));
                }
              }),
            )),
            concat(
              this.ccVersions$.pipe(
                take(1),
                map((versions) => {
                  // ALWAYS mark the closed captions as no longer busy. We succeeded if
                  // the CC version was bumped and positive

                  if (versions[action.mediaID] >= Math.abs(action.ccVersion) + 1) {
                    return new MediaActions.CreateClosedCaptionSucceeded(
                      action.mediaID,
                      action.captionFile,
                    );
                  } else {
                    return new MediaActions.CreateClosedCaptionFailed(
                      null,
                      action.captionFile.name,
                      action.mediaID,
                    );
                  }
                }),
              ),
            ),
          )),
          catchError((error) => {
            const errors: Errors = parseShimErrors(error.error || {});
            return of(new MediaActions.CreateClosedCaptionFailed(
              errors,
              action.captionFile.name,
              action.mediaID,
            ));
          }),
        );
      }),
    );

  @Effect()
  createClosedCaptionFailed$: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.CREATE_CLOSED_CAPTION_FAILED),
      mergeMap((action: MediaActions.CreateClosedCaptionFailed) => {
        this.toastr.error(
          _.isEmpty(action.errors.general)
            ? this.translate.instant(
                'amedia.toastr.failedToSaveMediaError',
                {
                  typeToSave: this.translate.instant('amedia.content.closedCaption'),
                  name: action.fileName,
                  mediaID: action.mediaID,
                },
              )
            : action.errors.general[0],
          this.translate.instant('common.error.label'),
        );
        return empty();
      }),
    );

  @Effect()
  deleteClosedCaption$: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.DELETE_CLOSED_CAPTION),
      mergeMap((action: MediaActions.DeleteClosedCaption) => {
        return this.mds.deleteClosedCaption(action.mediaID).pipe(
          // Start polling looking for an updated CC file version
          mergeMap(() => timer(1000, 5000).pipe(
            takeUntil(
              this.ccVersions$.pipe(
                first((versions) => versions[action.mediaID] < 0),
              ),
            ),
            // give up after 3 attempts
            // takeWhile((itr) => itr < 3),
            mergeMap(() => this.mss.getMedia(action.mediaID).pipe(
              map((media) => new MediaActions.UpdateCCVersions(media)),
            )),
            concat(
              this.ccVersions$.pipe(
                take(1),
                map((versions) => versions[action.mediaID] < 0
                  ? new MediaActions.DeleteClosedCaptionSucceeded(action.mediaID)
                  : new MediaActions.DeleteClosedCaptionFailed(action.mediaID),
                ),
              ),
            ),
          )),
          catchError((error) => {
            const errors: Errors = parseShimErrors(error.error || {});

            this.toastr.error(
              _.isEmpty(errors.general)
                ? this.translate.instant('amedia.content.closedCaptionDeleteError')
                : errors.general[0],

              this.translate.instant('amedia.content.error'),
            );

            return of(new MediaActions.DeleteClosedCaptionFailed(action.mediaID));
          }),
        );
      }),
    );

  // Listen for the 'LIST_CHANNELS_FOR_MEDIA' action
  @Effect()
  listChannelsForMedia$: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.LIST_CHANNELS_FOR_MEDIA),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: MediaActions.ListChannelsForMedia) => {
        return this.mds.listChannelsForMedia(action.mediaID).pipe(
          map((channelList) => new MediaActions.ListChannelsForMediaSucceeded(channelList)),
          catchError((error) => of(new MediaActions.ListChannelsForMediaFailed())),
        );
      }),
    );

  // Listen for the 'BULK_TAG_MEDIA' action
  @Effect()
  bulkTagMedia: Observable<Action> = this.actions$
    .pipe(
      ofType(MediaActions.BULK_TAG_MEDIA),
      mergeMap((action: MediaActions.BulkTagMedia) => {
        let vpwsValues = {
          mediaIds: action.mediaList.map((e) => {
            return e.mediaId;
          }),
          tags: action.tag,
          append: action.append,
        };
        return this.mds.addMediaTagBulk(vpwsValues).pipe(
          mergeMap(() => {
            this.toastr.success(this.translate.instant('amedia.bulkTagSuccess'));
            return empty();
          }),
          catchError((error) => {
            const errors: Errors = parseShimErrors(error.error || {});

            this.toastr.error(
              _.isEmpty(errors.general)
                ? this.translate.instant('amedia.bulkTagFailed')
                : errors.general[0],

              this.translate.instant('amedia.content.error'),
            );

            return empty();
          }),
        );
      }),
    );

  constructor(
    private actions$: Actions,
    private base64UriToFile: Base64UriToFileConverter,
    private store: Store<fromRoot.State>,
    private mds: MediaDataService,
    private mss: MediaSearchService,
    private cpms: CuepointManagementService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private envConfigService: EnvConfigurationService,
    @Optional() @Inject(LOAD_DEBOUNCE) private debounce: number = 300,
    /**
     * You inject an optional Scheduler that will be undefined
     * in normal application usage, but its injected here so that you can mock out
     * during testing using the RxJS TestScheduler for simulating passages of time.
     */
    @Optional() @Inject(LOAD_SCHEDULER) private scheduler: Scheduler,
  ) {
    this.media$ = store.pipe(select(fromRoot.getMediaState));
    this.orgId$ = store.pipe(select(fromRoot.getCurrOrgId));
    this.ccVersions$ = store.pipe(select(fromRoot.getCcVersions));
    this.envConfigService.load().subscribe((res) => {
      this.env = res;
    });
  }
}
