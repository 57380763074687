import * as _ from 'lodash';
import { SelectOption } from 'control-ui-common';

import * as group from '../actions/group';
import { Group } from '../../models/group';
import { PaginatedResponse } from '../../models/paginated-response';
import { Channel } from '../../models/channel';

export enum GroupSection {
  CustomProps = 'customProps',
  Properties = 'properties',
  Preview = 'preview',
  ChannelList = 'channelList',
}

export interface State {
  allChannelsInGroupSelected: boolean;
  allChannelsNotInGroupSelected: boolean;
  busySections: {[groupID: number]: GroupSection[]};
  failedSections: {[groupID: number]: GroupSection[]};
  paginatedChannelList: PaginatedResponse | null;
  currGroup: Group | null;
  currGroupLoading: boolean;
  filteredChannelsNotInGroup: Channel[];
  filteredChannelList: Channel[];
  lastSelectedChannelInGroup: any | null;
  lastSelectedChannelNotInGroup: any | null;
  queryText: string;
  removingChannelFailed: boolean;
  saveSectionFailed: boolean;
  saveGroupChannelListFailed: boolean;
}

export const initialState: State = {
  allChannelsInGroupSelected: false,
  allChannelsNotInGroupSelected: false,
  busySections: {},
  failedSections: {},
  paginatedChannelList: null,
  currGroup: null,
  currGroupLoading: false,
  filteredChannelsNotInGroup: null,
  filteredChannelList: null,
  lastSelectedChannelInGroup: null,
  lastSelectedChannelNotInGroup: null,
  queryText: null,
  removingChannelFailed: false,
  saveSectionFailed: false,
  saveGroupChannelListFailed: false,
};

const markSectionBusy = (
  state: State,
  groupID: string,
  section: GroupSection,
): {[groupID: number]: GroupSection[]} => {
  const newSections = _.clone(state.busySections);

  if (newSections[groupID] === undefined) {
    newSections[groupID] = [];
  }

  newSections[groupID] = [
    ...newSections[groupID],
    section,
  ];

  return newSections;
};

const markSectionNotBusy = (
  state: State,
  groupID: string,
  section: GroupSection,
): {[groupID: number]: GroupSection[]} => {
  const newSections = _.clone(state.busySections);

  newSections[groupID] = _.filter(newSections[groupID], (v, k) => v !== section);

  return newSections;
};

const markSectionFailed = (
  state: State,
  mediaID: string,
  section: GroupSection,
): {[mediaID: number]: GroupSection[]} => {
  const newSections = _.clone(state.failedSections);

  if (newSections[mediaID] === undefined) {
    newSections[mediaID] = [];
  }

  newSections[mediaID] = [
    ...newSections[mediaID],
    section,
  ];

  return newSections;
};

const markSectionNotFailed = (
  state: State,
  mediaID: string,
  section: GroupSection,
): {[mediaID: number]: GroupSection[]} => {
  const newSections = _.clone(state.failedSections);

  newSections[mediaID] = _.filter(newSections[mediaID], (v, k) => v !== section);

  return newSections;
};

const getChannelList = (channelIds: string[], allChannels: Channel[]): Channel[] => {
  return _.filter(allChannels, (c) => {
    return !_.find(channelIds, (id) => id === c.channel_id);
  });
};

const filterChannels = (channelsArray: Channel[], q: string): Channel[] => {
  const lowerCaseQuery = !!q && q.toLocaleLowerCase();
  return (
    !!q && q.length > 0 ?
    _.filter(channelsArray, (c) => {
      return (
        c.title.toLocaleLowerCase().indexOf(lowerCaseQuery) >= 0
      );
    }) :
    [ ...channelsArray ]
  );
};

export function reducer(
  state = initialState,
  action: group.Actions,
): State {
  switch (action.type) {
    case group.LOAD_CHANNELS_NOT_IN_GROUP:
      return {
        ...state,
        allChannelsNotInGroupSelected: false,
        filteredChannelsNotInGroup: _.map(getChannelList(action.channelIds, action.allChannels), (c) => {
          c.selected = false;
          return c;
        }),
        filteredChannelList: filterChannels(getChannelList(action.channelIds, action.allChannels), state.queryText),
      };
    case group.LOAD_GROUP_CHANNELS:
      return {
        ...state,
        busySections: markSectionBusy(state, action.groupID, GroupSection.ChannelList),
      };
    case group.LOAD_GROUP_CHANNELS_FAILED:
      return {
        ...state,
        busySections: markSectionNotBusy(state, action.groupID, GroupSection.ChannelList),
      };
    case group.LOAD_GROUP_CHANNELS_SUCCEEDED:
      return {
        ...state,
        allChannelsInGroupSelected: false,
        busySections: markSectionNotBusy(state, action.groupID, GroupSection.ChannelList),
        lastSelectedChannelInGroup: null,
        paginatedChannelList: action.channelList,
      };
    case group.LOAD_GROUP:
      return {
        ...state,
        currGroup: null,
        currGroupLoading: true,
      };
    case group.LOAD_GROUP_FAILED:
      return {
        ...state,
        currGroupLoading: false,
      };
    case group.LOAD_GROUP_SUCCEEDED:
      return {
        ...state,
        currGroup: action.group,
        currGroupLoading: false,
      };
    case group.SELECT_ALL:
      // sets select key on channels to true. This checks the boxes on the ui.
      // selected channels from group-details page
      if (action.channelList === 'paginatedChannelList') {
        state.allChannelsInGroupSelected = !state.allChannelsInGroupSelected;
        state[action.channelList].results = _.map(
          state[action.channelList].results,
          (c) => {
            c.selected = state.allChannelsInGroupSelected;
            return c;
          },
        );
      } else if (action.channelList === 'filteredChannelList') {
        // selected channels from the add group channel modal
        state.allChannelsNotInGroupSelected = !state.allChannelsNotInGroupSelected;
        state[action.channelList] = _.map(
          state[action.channelList],
          (s) => {
            s.selected = state.allChannelsNotInGroupSelected;
            return s;
          },
        );
      }
      return state;
    case group.TOGGLE_SELECTION:
      // Enables shift select feature.
      // Dynamically turns on and off the all checked box if all boxes are checked
      const channelList = action.channelList === 'paginatedChannelList'
        ? state[action.channelList].results
        : state[action.channelList];
      const clickedItem = channelList.find(
        (item) => item['channel_id'] === action.payload.item['channel_id'],
      );

      // Update correct state depending on which channel list we are touching
      const lastSelected = action.channelList === 'paginatedChannelList'
        ? 'lastSelectedChannelInGroup'
        : 'lastSelectedChannelNotInGroup';

      if (state[lastSelected] && action.payload.shiftPressed) {
        const desiredValue = !clickedItem.selected;
        let startIndex = channelList.indexOf(state[lastSelected]);
        let endIndex = channelList.indexOf(clickedItem);
        if (startIndex > endIndex) {
          const tmpIndex = startIndex;
          startIndex = endIndex;
          endIndex = tmpIndex;
        }
        for (let index = startIndex; index <= endIndex; index++) {
          channelList[index].selected = desiredValue;
        }
      } else {
        clickedItem.selected = !clickedItem.selected;
      }
      state[action.selectionType] = channelList.every((item) => item.selected);
      state[lastSelected] = clickedItem;
      return state;
    case group.UPDATE_GROUP_SECTION:
      return {
        ...state,
        busySections: markSectionBusy(state, action.groupID, action.section),
        failedSections: markSectionNotFailed(state, action.groupID, action.section),
        saveSectionFailed: false,
      };
    case group.UPDATE_GROUP_SECTION_FAILED:
      return {
        ...state,
        busySections: markSectionNotBusy(state, action.groupID, action.section),
        failedSections: markSectionFailed(state, action.groupID, action.section),
        saveSectionFailed: true,
      };
    case group.UPDATE_GROUP_SECTION_SUCCEEDED:
      if (action.section === GroupSection.CustomProps) {
        return {
          ...state,
          busySections: markSectionNotBusy(state, action.groupID, action.section),
          currGroup: _.assign(
            {},
            _.cloneDeep(state.currGroup),
            _.cloneDeep({ custom_properties: action.newValues.custom_properties }),
          ),
        };
      }
      return {
        ...state,
        busySections: markSectionNotBusy(state, action.groupID, action.section),
        currGroup: _.assign(
          {},
          _.cloneDeep(state.currGroup),
          _.cloneDeep(action.newValues),
        ),
      };
    case group.BULK_ADD_CHANNELS:
    case group.BULK_REMOVE_CHANNELS:
    case group.REORDER_SINGLE_CHANNEL:
    case group.UPDATE_GROUP_CHANNEL_LIST:
      return {
        ...state,
        busySections: markSectionBusy(state, action.groupID, GroupSection.ChannelList),
        saveGroupChannelListFailed: false,
      };
    case group.UPDATE_GROUP_CHANNEL_LIST_FAILED:
      return {
        ...state,
        busySections: markSectionNotBusy(state, action.groupID, GroupSection.ChannelList),
        saveGroupChannelListFailed: true,
      };
    case group.FILTER_CHANNEL_LIST:
      return {
        ...state,
        filteredChannelList: filterChannels(state.filteredChannelsNotInGroup, action.queryText),
        queryText: action.queryText,
      };
    default:
      return state;
  }
}

export const getGroup = (state: State) => state;
