import { Observable } from 'rxjs/Observable';
import { interval } from 'rxjs/observable/interval';
import { map, take } from 'rxjs/operators';

const interpolate = (y, from , to): number => {
  return (from * (1 - y) + to * y);
};

const flip = (fn) => {
  return (x) => 1 - fn(1 - x);
};

const pow3 = (x: number): number => {
  return x * x * x;
};

const easeInOut$ = (from: number, to: number, duration: number = 500): Observable<number> => {
  const ivrl = 10;
  const totalTicks = Math.round(duration / ivrl);
  const fn = pow3;
  const fnFlipped = flip(fn);

  return interval(ivrl).pipe(
    take(totalTicks + 1),
    map((tick) => {
      const x = tick / totalTicks;
      const y = (x < 0.5) ?
        (fn(2 * x) * 0.5) :
        (0.5 + fnFlipped(2 * (x - 0.5)) * 0.5);
      return interpolate(y, from, to);
    }),
  );
};

export { easeInOut$ };
