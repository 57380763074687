import { Action } from '@ngrx/store';

import { CallbackListener } from '../../../models/callback-listeners';

export const ADD_CALLBACK_LISTENER = '[Setting] Add Callback Listener';
export const ADD_CALLBACK_LISTENER_FAILED = '[Setting] Add Callback Listener Failed';
export const ADD_CALLBACK_LISTENER_SUCCEEDED = '[Setting] Add Callback Listener Succeeded';
export const DELETE_CALLBACK_LISTENER = '[Setting] Delete Callback Listener';
export const DELETE_CALLBACK_LISTENER_FAILED = '[Setting] Delete Callback Listener Failed';
export const DELETE_CALLBACK_LISTENER_SUCCEEDED = '[Setting] Delete Callback Listener Succeeded';
export const FILTER_CALLBACK_LISTENERS = '[Setting] Filter Callback Listeners';
export const LOAD_CALLBACK_LISTENERS = '[Setting] Load Callback Listeners';
export const LOAD_CALLBACK_LISTENERS_FAILED = '[Setting] Load Callback Listeners Failed';
export const LOAD_CALLBACK_LISTENERS_SUCCEEDED = '[Setting] Load Callback Listeners Succeeded';
export const SET_CURR_CALLBACK_LISTENER = '[Setting] Set Curr Callback Listener';
export const SET_CURR_CALLBACK_LISTENER_FAILED = '[Setting] Set Curr Callback Listener Failed';
export const SET_CURR_CALLBACK_LISTENER_SUCCEEDED = '[Setting] Set Curr Callback Listener Succeeded';
export const UNSET_CURR_CALLBACK_LISTENER = '[Setting] Unset Curr Callback Listener';
export const UPDATE_CALLBACK_LISTENER = '[Setting] Update Callback Listener';
export const UPDATE_CALLBACK_LISTENER_FAILED = '[Setting] Update Callback Listener Failed';
export const UPDATE_CALLBACK_LISTENER_SUCCEEDED = '[Setting] Update Callback Listener Succeeded';
export const ROTATE_API_KEYS = '[Settings] Rotate Api Keys';

export class AddCallbackListener implements Action {
  readonly type = ADD_CALLBACK_LISTENER;

  constructor(public listener: CallbackListener) { }
}

export class AddCallbackListenerFailed implements Action {
  readonly type = ADD_CALLBACK_LISTENER_FAILED;
}

export class AddCallbackListenerSucceeded implements Action {
  readonly type = ADD_CALLBACK_LISTENER_SUCCEEDED;

  constructor(public listener: CallbackListener) { }
}

export class DeleteCallbackListener implements Action {
  readonly type = DELETE_CALLBACK_LISTENER;

  constructor(public id: string) { }
}

export class DeleteCallbackListenerFailed implements Action {
  readonly type = DELETE_CALLBACK_LISTENER_FAILED;
}

export class DeleteCallbackListenerSucceeded implements Action {
  readonly type = DELETE_CALLBACK_LISTENER_SUCCEEDED;

  constructor(public id: string) { }
}

export class FilterCallbackListeners implements Action {
  readonly type = FILTER_CALLBACK_LISTENERS;

  constructor(public filter: string) { }
}

export class LoadCallbackListeners implements Action {
  readonly type = LOAD_CALLBACK_LISTENERS;
}

export class LoadCallbackListenersFailed implements Action {
  readonly type = LOAD_CALLBACK_LISTENERS_FAILED;
}

export class LoadCallbackListenersSucceeded implements Action {
  readonly type = LOAD_CALLBACK_LISTENERS_SUCCEEDED;

  constructor(public listeners: CallbackListener[]) {}
}

export class SetCurrCallbackListener implements Action {
  readonly type = SET_CURR_CALLBACK_LISTENER;

  constructor(public listener: CallbackListener) {}
}

export class UnsetCurrCallbackListener implements Action {
  readonly type = UNSET_CURR_CALLBACK_LISTENER;
}

export class UpdateCallbackListener implements Action {
  readonly type = UPDATE_CALLBACK_LISTENER;

  constructor(public listener: CallbackListener) {}
}

export class UpdateCallbackListenerFailed implements Action {
  readonly type = UPDATE_CALLBACK_LISTENER_FAILED;
}

export class UpdateCallbackListenerSucceeded implements Action {
  readonly type = UPDATE_CALLBACK_LISTENER_SUCCEEDED;

  constructor(public listener: CallbackListener) {}
}

export class RotateApiKeys implements Action {
  readonly type = ROTATE_API_KEYS;
}

export type Actions = (
  AddCallbackListener | AddCallbackListenerFailed | AddCallbackListenerSucceeded |
  DeleteCallbackListener | DeleteCallbackListenerFailed | DeleteCallbackListenerSucceeded |
  LoadCallbackListeners | LoadCallbackListenersFailed | LoadCallbackListenersSucceeded |
  SetCurrCallbackListener | UnsetCurrCallbackListener | UpdateCallbackListener |
  UpdateCallbackListenerFailed | UpdateCallbackListenerSucceeded | FilterCallbackListeners |
  RotateApiKeys
);
