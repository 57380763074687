import { EnvConfigurationService, Configuration } from './../services/environment-configuration.service';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { IdleTimeoutService } from '../services/idle-timeout.service';
import * as fromRoot from '../redux/reducers';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

/**
 * This interceptor exists to reset the idle timeout on all http requests
 * which will hit the shim layer, since this call will result in the session
 * timeout being refreshed.
 */
@Injectable()
export class IdleTimeoutInterceptor implements HttpInterceptor {

  env: Configuration;

  constructor(
    private idleTimeoutService: IdleTimeoutService,
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,
  ) {
    this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // only requests to the shim will reset the session timeout
    // we also do not want to start the timer when we are logging out
    if (request.url.startsWith(this.env.SHIM_BASE_URL) && !request.url.endsWith('/logout')) {
      this.idleTimeoutService.reset();
    }
    return next.handle(request);
  }
}
