import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'll-header-modal',
  templateUrl: './header-modal.component.html',
})
export class HeaderModalComponent {
  @Input() headerMessage: string;
  @Input() showDismiss: boolean = true;
  @Input() verb: string;

  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslateService,
  ) { }
}
