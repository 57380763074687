import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as SHA1 from 'crypto-js/sha1';
import * as Base64 from 'crypto-js/enc-base64';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

import * as fromRoot from '../../shared/redux/reducers';
import * as fromAuth from '../../shared/redux/reducers/auth';
import * as AuthActions from '../../shared/redux/actions/auth';
import { BaseAuthComponent } from '../base-auth.component';

export type ShowFieldErrorFnType = (control: FormControl) => boolean;

@Component({
  templateUrl: './change-question.component.html',
})
export class ChangeQuestionComponent implements OnInit {
  form: FormGroup;
  auth$: Observable<fromAuth.State>;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  errorLabels: any = {
    maxlength: this.translate.instant('amedia.errors.maxLength', { maximum: 255 }),
  };

  constructor(
    public base: BaseAuthComponent,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.auth$ = store.pipe(select(fromRoot.getAuthState));
    this.base.authTitle = 'amedia.changeQuestion.title';
    this.form = fb.group({
      securityQuestion: ['', [Validators.required, Validators.maxLength(255)]],
      securityAnswer: ['', [Validators.required, Validators.maxLength(255)]],
    });
  }

  ngOnInit(): void {
    this.toastr.overlayContainer = this.toastContainer;
  }

  showErrors(): ShowFieldErrorFnType {
    return (control) => control.dirty && control.invalid;
  }

  onSubmit() {
    this.form.get('securityQuestion').markAsDirty();
    this.form.get('securityAnswer').markAsDirty();

    if (this.form.valid) {
      this.auth$.pipe(take(1)).subscribe((authState) => {
        this.store.dispatch(new AuthActions.SaveUser({
          ...authState.user,
          securityQuestion: this.form.value.securityQuestion,
          securityAnswer: Base64.stringify(SHA1(this.form.value.securityAnswer)),
        }));
      });
    }

    this.auth$.pipe(take(1)).subscribe((authState: fromAuth.State) => {
      if (!!authState.userSavingErrors) {
        this.base.onShake();
      }
    });
  }
}
