import { de_DE } from './de_DE';
import { en_UK } from './en_UK';
import { en_US } from './en_US';
import { en_XO } from './en_XO';
import { es_ES } from './es_ES';
import { fr_CA } from './fr_CA';
import { fr_FR } from './fr_FR';
import { ja_JP } from './ja_JP';

export interface Locale {
  [key: string]: string;
}

export const locales = {
  de_DE,
  en_UK,
  en_US,
  en_XO,
  es_ES,
  fr_CA,
  fr_FR,
  ja_JP,
};
