
export enum AdConfigType {
  Dart = 'Dart',
  VAST = 'VAST',
  ChannelMerge = 'ChannelMerge',
}

export interface AdConfigMedia {
  id: string;
  title: string;
  weight: number;
}

export interface AdConfigDetails {
  adPosition: string;
  adChannelId: string;
  includeEnclosingAd: boolean;
  randomizeAdOrder: boolean;
  weights: AdConfigMedia[] | {[mediaId: string]: string};
}

export interface AdConfig {
  adConfigurationType: AdConfigType;
  details: AdConfigDetails;
}
