import { MediaChartDataType } from './../reducers/reports';
import { OverviewReportTables } from './../../models/overview-report-tables';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { ReportsTabs } from '../../../shared/models/reports-tabs';
import * as _ from 'lodash';

import * as fromReports from '../reducers/reports';
import * as fromRoot from '../reducers';
import * as ReportsActions from '../actions/reports';
import { catchError, filter, map, mergeMap, skip, take, withLatestFrom, switchMap } from 'rxjs/operators';
import { AnalyticsService } from '../../services/analytics.service';
import { PaginatedResponse } from '../../models/paginated-response';
import { extendMoment } from 'moment-range';
import * as Moment from 'moment';
import { AnalyticsFilters } from '../../analytics-filters';
import { defer } from 'rxjs/observable/defer';
import { ViewerDataReports } from '../../../shared/models/viewer-data-reports';
import { ChannelsOrMediaSummaryData } from '../../models/channels-aggregation';
import { NameAndPlays } from './../../models/viewer-data-summary-data';
import { GeoReportsGranularity } from '../../models/geo-reports-granularity';
import { GeoSummaryData } from '../../models/geo-summary-data';
import { empty, combineLatest } from 'rxjs';
import { EventHistoryRequestParams } from '../../models/event-history-request-params';

const moment = extendMoment(Moment);

@Injectable()
export class ReportsEffects {
  reports$: Observable<fromReports.State>;

  // listen for SET_REPORTS_TAB
  @Effect()
  setReportsTab$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.SetReportsTab>(ReportsActions.SET_REPORTS_TAB),
    withLatestFrom(defer(() => this.reports$)),
    filter(([action, state]) => action.loadActiveReport === true),
    mergeMap(([action, state]) => {
      return of(new ReportsActions.LoadActiveReport());
    }),
  );

  @Effect()
  loadActiveReport$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadActiveReport>(ReportsActions.LOAD_ACTIVE_REPORT),
    withLatestFrom(defer(() => this.reports$)),
    mergeMap(([action, state]) => {
      let resultingActions = [];
      let filters: AnalyticsFilters;
      switch (state.selectedReportsTab) {
        case ReportsTabs.OVERVIEW:
          resultingActions = [
            new ReportsActions.LoadOverviewTableData(state.overviewFilters),
          ];
          break;
        case ReportsTabs.MEDIA:
          filters = action.resetPagination ? {
              ...state.mediaTableFilters,
              page: fromReports.initialState.mediaTableFilters.page,
            } : state.mediaTableFilters;
          resultingActions = [
            new ReportsActions.LoadMediaSummary(),
            new ReportsActions.LoadMediaTableData(filters),
          ];
          break;
        case ReportsTabs.CHANNEL:
          filters = action.resetPagination ? {
            ...state.channelTableFilters,
            page: fromReports.initialState.channelTableFilters.page,
          } : state.channelTableFilters;
          resultingActions = [
            new ReportsActions.LoadChannelSummary(),
            new ReportsActions.LoadChannelTableData(filters),
          ];
          break;
        case ReportsTabs.ENGAGEMENT:
          filters = action.resetPagination ? {
            ...state.engagementTableFilters,
            page: fromReports.initialState.engagementTableFilters.page,
          } : state.engagementTableFilters;
          resultingActions = [
            new ReportsActions.LoadEngagementTable(filters),
          ];
          break;
        case ReportsTabs.GEOGRAPHY:
          filters = action.resetPagination ? {
            ...state.geoTableFilters,
            page: fromReports.initialState.geoTableFilters.page,
          } : state.geoTableFilters;
          resultingActions = [
            new ReportsActions.LoadGeoTableData(filters),
            new ReportsActions.LoadGeoSummary(),
          ];
          break;
        case ReportsTabs.VIEWER:
          filters = action.resetPagination ? {
            ...state.viewerDataTableFilters,
            page: fromReports.initialState.viewerDataTableFilters.page,
          } : state.viewerDataTableFilters;
          resultingActions = [
            new ReportsActions.LoadViewerDataTable(filters),
            new ReportsActions.LoadViewerDataSummary(),
          ];
          break;
        case ReportsTabs.USAGE:
          resultingActions = [
            new ReportsActions.LoadDataUsageSummaryData(),
            new ReportsActions.LoadDataUsageChartData(),
          ];
          break;
        case ReportsTabs.EVENT_HISTORY:
          let eventFilters: EventHistoryRequestParams = action.resetPagination ? {
            ...state.eventHistoryFilters,
            pageId: fromReports.initialState.eventHistoryFilters.pageId,
          } : state.eventHistoryFilters;
          resultingActions = [
            new ReportsActions.LoadEventHistoryEventTypes(),
            new ReportsActions.LoadEventHistory(eventFilters),
          ];
          break;
      }
      return from(resultingActions);
    }),
  );

  // Listen for LOAD_CHANNEL_SUMMARY
  @Effect()
  loadChannelSummary$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadChannelSummary>(ReportsActions.LOAD_CHANNEL_SUMMARY),
    switchMap((action) => {
      return this.waitForDateRangeThen((state: fromReports.State) => {
        let dateRange = state.dateRange.range;

        let currentReqObs = this.analyticsService.getChannelOverview(dateRange);
        let rangeDiff = dateRange.duration('ms');
        let previousStart = moment(dateRange.start.valueOf() - rangeDiff);
        let prevReqObs = this.analyticsService.getChannelOverview(moment.range(previousStart, dateRange.start));

        return forkJoin(currentReqObs, prevReqObs).pipe(map(([current, prev]) => {
          let result: ChannelsOrMediaSummaryData = {
            plays: {
              value: current.plays,
              trend: this.calculatePercentageChange(prev.plays, current.plays),
            },
            replays: {
              value: current.replays,
              trend: this.calculatePercentageChange(prev.replays, current.replays),
            },
            totalTimePlayed: {
              value: current.totalTimePlayed,
              trend: this.calculatePercentageChange(prev.totalTimePlayed, current.totalTimePlayed),
            },
            dateRange,
          };
          return new ReportsActions.LoadChannelSummarySucceeded(result);
        }));
      });
    }),
  );

  // Listen for LOAD_CHANNEL_TABLE_DATA
  @Effect()
  loadChannelTableData$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadChannelTableData>(ReportsActions.LOAD_CHANNEL_TABLE_DATA),
    switchMap((action) => {
      return this.waitForDateRangeThen((state: fromReports.State) => {
      let dateRange = state.dateRange.range;
      action.filters.startDate = dateRange.start.clone();
      action.filters.endDate = dateRange.end.clone();
      return this.analyticsService.getChannelPerformance(action.filters).pipe(
        map((res: PaginatedResponse) => {
          return new ReportsActions.LoadChannelTableDataSucceeded(res);
        }),
        catchError(() => of(new ReportsActions.LoadChannelTableDataFailed())),
      );
      });
    }),
  );

    // Listen for LOAD_CHANNEL_TABLE_DATA_SUCCEEDED
    @Effect()
    loadChannelTableDataSucceeded$: Observable<Action> = this.actions$
    .pipe(
      ofType<ReportsActions.LoadChannelTableDataSucceeded>(ReportsActions.LOAD_CHANNEL_TABLE_DATA_SUCCEEDED),
      switchMap((action) => {
        if (action.data.results.length === 0) {
          return of(new ReportsActions.SelectChannelDataRows([]));
        }
        return empty();
      }),
    );

  // Listens for SELECT_CHANNEL_TABLE_ROW
  @Effect()
  selectChannelTableRows$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.SelectChannelDataRows>(ReportsActions.SELECT_CHANNEL_TABLE_ROW),
    withLatestFrom(defer(() => this.reports$)),
    filter(([action, state]) => !!state.dateRange),
    mergeMap(([action, state]) => {
      return of(new ReportsActions.LoadChannelChartData(state.mediaChartFilters));
    }),
  );

  // Listens for DESELECT_CHANNEL_TABLE_ROW
  @Effect()
  deselectChannelTableRows$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.DeselectChannelDataRows>(ReportsActions.DESELECT_CHANNEL_TABLE_ROW),
    withLatestFrom(defer(() => this.reports$)),
    filter(([action, state]) => !!state.dateRange),
    mergeMap(([action, state]) => {
      return of(new ReportsActions.LoadChannelChartData(state.mediaChartFilters));
    }),
  );

  // Listen for LOAD_CHANNEL_CHART_DATA
  @Effect()
  loadChannelChartData$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadChannelChartData>(ReportsActions.LOAD_CHANNEL_CHART_DATA),
    withLatestFrom(defer(() => this.reports$)),
    filter(([action, state]) => !!state.dateRange),
    switchMap(([action, state]) => {
      let dateRange = state.dateRange.range;
      action.filters.startDate = dateRange.start.clone();
      action.filters.endDate = dateRange.end.clone();

      if (dateRange.duration('days') <= 1) {
        action.filters.interval = 'hourly';
      } else {
        action.filters.interval = 'daily';
      }
      if (state.channelTableSelectedIds && state.channelTableSelectedIds.length > 0) {
        return this.analyticsService.getChannelIntervals(action.filters, state.channelTableSelectedIds).pipe(
          map((res: any) => {
            return new ReportsActions.LoadChannelChartDataSucceeded(res);
          }),
          catchError(() => of(new ReportsActions.LoadChannelChartDataFailed())),
        );
      } else {
        return of(new ReportsActions.LoadChannelChartDataSucceeded({}));
      }
    }),
  );

  // Listen for LOAD_ENGAGEMENT_TABLE
  @Effect()
  loadEngagementTable$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadEngagementTable>(ReportsActions.LOAD_ENGAGEMENT_TABLE),
    switchMap((action) => {
      return this.waitForDateRangeThen((state: fromReports.State) => {
        let dateRange = state.dateRange.range;
        action.filters.startDate = dateRange.start.clone();
        action.filters.endDate = dateRange.end.clone();

        return this.analyticsService.getMediaEngagement(action.filters).pipe(
          map((res: PaginatedResponse) => {
            return new ReportsActions.LoadEngagementTableSucceeded(res);
          }),
          catchError(() => of(new ReportsActions.LoadEngagementTableFailed())),
        );
      });
    }),
  );

  // Listen for LOAD_EVENT_HISTORY
  @Effect()
  loadEventHistory$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadEventHistory>(ReportsActions.LOAD_EVENT_HISTORY),
    // this requires the list of event types to be loaded, thus the additional rxjs magic.
    switchMap((action) => {
      return this.reports$.pipe(
        filter((state) => state.eventHistoryEventTypes.length > 0),
        take(1),
        mergeMap((state) => {
          return this.waitForDateRangeThen(() => {
            let dateRange = state.dateRange.range;
            action.filters.begin = dateRange.start.clone();
            action.filters.until = dateRange.end.clone();
            if(_.isEmpty(action.filters.eventTypes)) {
              action.filters.eventTypes = _.cloneDeep(state.eventHistoryEventTypes);
            }
            return this.analyticsService.getEventHistory(action.filters).pipe(
              map((res: PaginatedResponse) => {
                return new ReportsActions.LoadEventHistorySucceeded(res);
              }),
              catchError(() => of(new ReportsActions.LoadEventHistoryFailed())),
            );
          });
        }),
      );
    }),
  );

  // Listen for LOAD_EVENT_HISTORY_EVENT_TYPES
  @Effect()
  loadEventHistoryEventTypes$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadEventHistoryEventTypes>(ReportsActions.LOAD_EVENT_HISTORY_EVENT_TYPES),
    switchMap(() => {
      return this.analyticsService.getEventHistoryEventTypes().pipe(
        map((res: string[]) => {
          return new ReportsActions.LoadEventHistoryEventTypesSucceeded(res);
        }),
      );
    }),
  );

  // Listen for LOAD_GEO_TABLE_DATA
  @Effect()
  loadGeoTableData$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadGeoTableData>(ReportsActions.LOAD_GEO_TABLE_DATA),
    switchMap((action) => {
      return this.waitForDateRangeThen((state: fromReports.State) => {
        let dateRange = state.dateRange.range;
        action.filters.startDate = dateRange.start.clone();
        action.filters.endDate = dateRange.end.clone();

        return this.analyticsService.getGeoPerformance(state.geoReportsGranularity, action.filters).pipe(
          map((res: PaginatedResponse) => {
            return new ReportsActions.LoadGeoTableDataSuceeded(res);
          }),
          catchError(() => of(new ReportsActions.LoadGeoTableDataFailed())),
        );
      });
  }));

  // Listen for LOAD_GEO_SUMMARY
  @Effect()
  loadGeoSummary$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadGeoSummary>(ReportsActions.LOAD_GEO_SUMMARY),
    switchMap((action) => {
      return this.waitForDateRangeThen((state: fromReports.State) => {
        let dateRange = state.dateRange.range;
        let currFilters: AnalyticsFilters = {
          pageSize: 300,
          startDate: dateRange.start.clone(),
          endDate: dateRange.end.clone(),
        };

        let currentReqObs = this.analyticsService.getGeoPerformance(GeoReportsGranularity.COUNTRY, currFilters);
        let rangeDiff = dateRange.duration('ms');
        let previousStart = moment(dateRange.start.valueOf() - rangeDiff);
        let prevRange = moment.range(previousStart, dateRange.start);
        let prevFilters: AnalyticsFilters = {
          startDate: prevRange.start.clone(),
          endDate: prevRange.end.clone(),
        };
        let prevReqObs = this.analyticsService.getGeoPerformance(GeoReportsGranularity.COUNTRY, prevFilters);

        return forkJoin(currentReqObs, prevReqObs).pipe(map(([current, prev]) => {
          let currTotalPlays = _.reduce(current.results, (sum, res) => {
            return sum + res.num_media_country_plays;
          }, 0);
          let prevTotalPlays = _.reduce(prev.results, (sum, res) => {
            return sum + res.num_media_country_plays;
          }, 0);
          let result: GeoSummaryData = {
            numCountries: current.size,
            totalPlays: {
              value: currTotalPlays.toString(),
              trend: this.calculatePercentageChange(prevTotalPlays, currTotalPlays),
            },
            dateRange,
          };
          return new ReportsActions.LoadGeoSummarySucceeded(result);
        }));
      });
    }),
  );

  // Listen for LOAD_MEDIA_TABLE_DATA
  @Effect()
  loadMediaTableData$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadMediaTableData>(ReportsActions.LOAD_MEDIA_TABLE_DATA),
    switchMap((action) => {
      return this.waitForDateRangeThen((state: fromReports.State) => {
        let dateRange = state.dateRange.range;
        action.filters.startDate = dateRange.start.clone();
        action.filters.endDate = dateRange.end.clone();

        return this.analyticsService.getMediaPerformance(action.filters).pipe(
          map((res: PaginatedResponse) => {
            return new ReportsActions.LoadMediaTableDataSucceeded(res);
          }),
          catchError(() => of(new ReportsActions.LoadMediaTableDataFailed())),
        );
      });
    }),
  );

  // Listen for LOAD_MEDIA_TABLE_DATA_SUCCEEDED
  // This is needed because, if the dataset is empty, we need to
  // clear out the row selection, which will in turn trigger a chart update.
  // If the data is not empty, the table initializing will select the rows and
  // trigger a chart update.
  @Effect()
  loadMediaTableDataSucceeded$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadMediaTableDataSucceeded>(ReportsActions.LOAD_MEDIA_TABLE_DATA_SUCCEEDED),
    switchMap((action) => {
      if (action.data.results.length === 0) {
        return of(new ReportsActions.SelectMediaDataRows([]));
      }
      return empty();
    }),
  );

  // Listens for SELECT_MEDIA_TABLE_ROW
  @Effect()
  selectMediaTableRows$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.SelectMediaDataRows>(ReportsActions.SELECT_MEDIA_TABLE_ROW),
    withLatestFrom(defer(() => this.reports$)),
    filter(([action, state]) => !!state.dateRange),
    mergeMap(([action, state]) => {
      return of(new ReportsActions.LoadMediaChartData(state.mediaChartFilters));
    }),
  );

  // Listens for DESELECT_MEDIA_TABLE_ROW
  @Effect()
  deselectMediaTableRows$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.DeselectMediaDataRows>(ReportsActions.DESELECT_MEDIA_TABLE_ROW),
    withLatestFrom(defer(() => this.reports$)),
    mergeMap(([action, state]) => {
      return of(new ReportsActions.LoadMediaChartData(state.mediaChartFilters));
    }),
  );

  // Listen for LOAD_MEDIA_CHART_DATA
  @Effect()
  loadMediaChartData$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadMediaChartData>(ReportsActions.LOAD_MEDIA_CHART_DATA),
    withLatestFrom(defer(() => this.reports$)),
    filter(([action, state]) => !!state.dateRange),
    switchMap(([action, state]) => {
      let dateRange = state.dateRange.range;
      action.filters.startDate = dateRange.start.clone();
      action.filters.endDate = dateRange.end.clone();

      if (dateRange.duration('days') <= 1) {
        action.filters.interval = 'hourly';
      } else {
        action.filters.interval = 'daily';
      }

      let obsList: Array<Observable<any>> = [];
      // short-circuit if there are no selected media ids
      // vpws/vars will respond with an error in this case
      if (state.mediaTableSelectedIds && state.mediaTableSelectedIds.length > 0) {
        if (state.mediaChartDataType.includes(MediaChartDataType.Video)) {
          obsList.push(this.analyticsService.getMediaIntervals(action.filters, state.mediaTableSelectedIds, 'Video'));
        }
        if (state.mediaChartDataType.includes(MediaChartDataType.Audio)) {
          obsList.push(this.analyticsService.getMediaIntervals(action.filters, state.mediaTableSelectedIds, 'Audio'));
        }
        return forkJoin(...obsList).pipe(map(([...data]) => {
          let returnData: any = {};
          for (let mediaData in data) {
            if (data.hasOwnProperty(mediaData)) {
              returnData = _.assign(returnData, data[mediaData]);
            }
          }
          return new ReportsActions.LoadMediaChartDataSucceeded(returnData);
        }), catchError(() => of(new ReportsActions.LoadMediaChartDataFailed())));
      } else {
        return of(new ReportsActions.LoadMediaChartDataSucceeded({}));
      }
    }),
  );

  // Listen for LOAD_MEDIA_SUMMARY
  @Effect()
  loadMediaSummary$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadMediaSummary>(ReportsActions.LOAD_MEDIA_SUMMARY),
    switchMap((action) => {
      return this.waitForDateRangeThen((state: fromReports.State) => {
        let dateRange = state.dateRange.range;
        let currentReqObs = this.analyticsService.getMediaOverview(dateRange);
        let rangeDiff = dateRange.duration('ms');
        let previousStart = moment(dateRange.start.valueOf() - rangeDiff);
        let prevReqObs = this.analyticsService.getMediaOverview(moment.range(previousStart, dateRange.start));

        return forkJoin(currentReqObs, prevReqObs).pipe(map(([current, prev]) => {
          let result: ChannelsOrMediaSummaryData = {
            plays: {
              value: current.plays,
              trend: this.calculatePercentageChange(prev.plays, current.plays),
            },
            replays: {
              value: current.replays,
              trend: this.calculatePercentageChange(prev.replays, current.replays),
            },
            totalTimePlayed: {
              value: current.totalTimePlayed,
              trend: this.calculatePercentageChange(prev.totalTimePlayed, current.totalTimePlayed),
            },
            dateRange,
          };
          return new ReportsActions.LoadMediaSummarySucceeded(result);
        }));
      });
    }),
  );

  // Listen for SET_CHART_MEDIA_TYPE
  @Effect()
  setChartMediaType: Observable<Action> = this.actions$
    .pipe(
      ofType(ReportsActions.SET_CHART_MEDIA_TYPE),
      withLatestFrom(defer(() => this.reports$)),
      mergeMap(([action, state]) => {
        return of(new ReportsActions.LoadMediaChartData(state.mediaChartFilters));
      }),
  );

  // Listen for LOAD_OVERVIEW_CHART_DATA
  @Effect()
  loadOverviewChartData$: Observable<Action> = this.actions$
    .pipe(
      ofType(ReportsActions.LOAD_OVERVIEW_CHART_DATA),
      switchMap((action: ReportsActions.LoadOverviewChartData) => {
        return this.reports$.pipe(filter((d) => !!d.dateRange && !!d.overviewTableSelectVal && !!d.overviewTableData),
        take(1), switchMap((state: fromReports.State) => {
          let dateRange = state.dateRange.range;
          action.filters.startDate = dateRange.start.clone();
          action.filters.endDate = dateRange.end.clone();

          if (dateRange.duration('days') <= 1) {
            action.filters.interval = 'hourly';
          } else {
            action.filters.interval = 'daily';
          }

          let method;
          let idField;
          switch (state.overviewTableSelectVal) {
            case OverviewReportTables.MEDIA:
              method = 'getMediaIntervals';
              idField = 'media_id';
              break;
            case OverviewReportTables.CHANNEL:
              method = 'getChannelIntervals';
              idField = 'chan_id';
              break;
            case OverviewReportTables.DOMAIN:
              method = 'getDomainIntervals';
              idField = 'domain';
              break;
          }

          let ids: string[] = [];
          for (let data in state.overviewTableData.results) {
            if (state.overviewTableData.results.hasOwnProperty(data)) {
              ids.push(state.overviewTableData.results[data][idField]);
            }
          }

          // if there are no ids, there is no data to be fetched
          if (ids.length === 0) {
            return of(new ReportsActions.LoadOverviewChartDataSucceeded({}));
          }

          let obsList: Array<Observable<any>> = [];
          if (state.overviewTableSelectVal === OverviewReportTables.MEDIA) {
            obsList.push(this.analyticsService.getMediaIntervals(action.filters, ids, 'Video'));
            obsList.push(this.analyticsService.getMediaIntervals(action.filters, ids, 'Audio'));
          } else {
            obsList.push(this.analyticsService[method](action.filters, ids));
          }

          return forkJoin(...obsList).pipe(map(([...data]) => {
            let returnData: any = {};
            for (let reportData in data) {
              if (data.hasOwnProperty(reportData)) {
                returnData = _.assign(returnData, data[reportData]);
              }
            }
            return new ReportsActions.LoadOverviewChartDataSucceeded(returnData);
          }), catchError((err) => of(new ReportsActions.LoadOverviewChartDataFailed())));
        }));
      }),
    );

   // Listen for LOAD_OVERVIEW_TABLE_DATA
   @Effect()
   loadOverviewTableData$: Observable<Action> = this.actions$
    .pipe(
      ofType(ReportsActions.LOAD_OVERVIEW_TABLE_DATA),
      switchMap((action: ReportsActions.LoadOverviewTableData) => {
        return this.reports$.pipe(filter((d) => !!d.dateRange && !!d.overviewTableSelectVal),
        take(1), switchMap((state: fromReports.State) => {
          let dateRange = state.dateRange.range;
          action.filters.startDate = dateRange.start.clone();
          action.filters.endDate = dateRange.end.clone();

          let method;
          switch (state.overviewTableSelectVal) {
            case OverviewReportTables.MEDIA:
              method = 'getMediaPerformance';
              break;
            case OverviewReportTables.CHANNEL:
              method = 'getChannelPerformance';
              break;
            case OverviewReportTables.DOMAIN:
              method = 'getDomainPerformance';
              break;
          }
          return this.analyticsService[method](action.filters)
          .pipe(
            map((res: PaginatedResponse) => {
              return new ReportsActions.LoadOverviewTableDataSucceeded(res);
            }),
            catchError((err) => of(new ReportsActions.LoadOverviewTableDataFailed())),
          );
        }));
      }),
    );

  // Listen for LOAD_OVERVIEW_TABLE_DATA_SUCCEEDED
  @Effect()
  loadOverviewTableDataSucceeded$: Observable<Action> = this.actions$
  .pipe(
    ofType(ReportsActions.LOAD_OVERVIEW_TABLE_DATA_SUCCEEDED),
    withLatestFrom(defer(() => this.reports$)),
    mergeMap(([action, state]) => {
      return of(new ReportsActions.LoadOverviewChartData(state.overviewFilters));
    }),
  );

    // Listen for SET_DATE_RANGE
    @Effect()
    setDateRange$: Observable<Action> = this.actions$
      .pipe(
        ofType(ReportsActions.SET_DATE_RANGE),
        // Skip first emit, because we do not want to trigger a data fetch from here initially.
        // Data fetch for the table is fired from the select dropdown initially.
        skip(1),
        filter((action: ReportsActions.SetDateRange) => action.triggerDataReload === true),
        mergeMap((action: ReportsActions.SetDateRange) => {
          return of(new ReportsActions.LoadActiveReport(true));
        }),
      );

    // Listen for LOAD_DATA_USAGE_SUMMARY_DATA
    @Effect()
    loadDataUsageSummaryData$: Observable<Action> = this.actions$
    .pipe(
      ofType(ReportsActions.LOAD_DATA_USAGE_SUMMARY_DATA),
      switchMap((action: ReportsActions.LoadDataUsageSummaryData) => {
        return this.waitForDateRangeThen((state: fromReports.State) => {
          // there is a special case in vpws with date handling here, becuase
          // the EQ endpoints this talks to are inclusive, so we need to
          // subtract one day here
          const updatedRange = state.dateRange.range.clone();
          updatedRange.end = updatedRange.end.subtract(1, 'day');
          return this.analyticsService.getDataUsage(updatedRange, 'all')
          .pipe(
            map((res: any[]) => {
              return new ReportsActions.LoadDataUsageSummaryDataSucceeded(res);
            }),
            catchError((err) => of(new ReportsActions.LoadDataUsageSummaryDataFailed())),
          );
        });
      }),
    );

    // Listen for LOAD_DATA_USAGE_CHART_DATA
    @Effect()
    loadDataUsageChartData$: Observable<Action> = this.actions$
    .pipe(
      ofType(ReportsActions.LOAD_DATA_USAGE_CHART_DATA),
      switchMap((action: ReportsActions.LoadDataUsageChartData) => {
        return this.waitForDateRangeThen((state: fromReports.State) => {
          let dateRange = state.dateRange.range;
          let interval: string = (dateRange.diff('days') > 100) ? 'monthly' : 'daily';

          const updatedRange = state.dateRange.range.clone();
          updatedRange.end = updatedRange.end.subtract(1, 'day');
          return this.analyticsService.getDataUsage(updatedRange, interval)
          .pipe(
            map((res: any[]) => {
              return new ReportsActions.LoadDataUsageChartDataSucceeded(res, interval);
            }),
            catchError((err) => of(new ReportsActions.LoadDataUsageChartDataFailed())),
          );
        });
      }),
    );

  // Listen for LOAD_VIEWER_DATA_TABLE
  @Effect()
  loadViewerDataTable$: Observable<Action> = this.actions$
  .pipe(
    ofType(ReportsActions.LOAD_VIEWER_DATA_TABLE),
    switchMap((action: ReportsActions.LoadViewerDataTable) => {
      return this.reports$.pipe(filter((d) => !!d.dateRange && !!d.viewerDataTableSelectVal),
      take(1), mergeMap((state: fromReports.State) => {
        let dateRange = state.dateRange.range;
        action.filters.startDate = dateRange.start.clone();
        action.filters.endDate = dateRange.end.clone();

        let method;
        switch (state.viewerDataTableSelectVal) {
          case ViewerDataReports.OPERATING_SYSTEM:
            method = 'getOperatingSystemMediaPerformance';
            break;
          case ViewerDataReports.BROWSER:
            method = 'getBrowserMediaPerformance';
            break;
          case ViewerDataReports.PLATFORM:
            method = 'getPlatformMediaPerformance';
            break;
          case ViewerDataReports.TRAFFIC_SOURCES:
            method = 'getDomainPerformance';
            break;
        }
        return this.analyticsService[method](action.filters)
        .pipe(
          map((res: PaginatedResponse) => {
            return new ReportsActions.LoadViewerDataTableSucceeded(res);
          }),
          catchError((err) => of(new ReportsActions.LoadViewerDataTableFailed())),
        );
      }));
    }),
  );

  // Listen for SET_VIEWER_DATA_TABLE_SELECT_VAL
  @Effect()
  setViewerDataTableSelectVal$: Observable<Action> = this.actions$
    .pipe(
      ofType(ReportsActions.SET_VIEWER_DATA_TABLE_SELECT_VAL),
      mergeMap((action: ReportsActions.SetViewerDataTableSelectVal) => {
        let filters: AnalyticsFilters;
        this.reports$.pipe(take(1)).subscribe((state: fromReports.State) => {
          filters = state.viewerDataTableFilters;
        });
        return of(new ReportsActions.LoadViewerDataTable(filters));
      }),
    );

  // Listen for LOAD_VIEWER_DATA_SUMMARY
  @Effect()
  loadViewerDataSummary$: Observable<Action> = this.actions$
  .pipe(
    ofType<ReportsActions.LoadViewerDataSummary>(ReportsActions.LOAD_VIEWER_DATA_SUMMARY),
    withLatestFrom(defer(() => this.reports$)),
    filter(([action, state]) => !!state.dateRange),
    switchMap(([action, state]) => {
      let dateRange = state.dateRange.range;
      let browsers = this.analyticsService.getBrowserPerformance(dateRange);
      let platforms = this.analyticsService.getPlatformPerformance(dateRange);
      let oses = this.analyticsService.getOperatingSystemPerformance(dateRange);

      return forkJoin(browsers, platforms, oses).pipe(
        map(([browsersRes, platformsRes, osRes]) => {
          let browserList = this.buildNameAndPlaysList(browsersRes.results,
            'media_browser', 'num_media_browser_plays');
          let platformList = this.buildNameAndPlaysList(platformsRes.results,
            'media_platform', 'num_media_platform_plays');
          let osList = this.buildNameAndPlaysList(osRes.results, 'media_os', 'num_media_os_plays');
          return new ReportsActions.LoadViewerDataSummarySucceeded({
            browsers: browserList,
            platforms: platformList,
            operatingSystems: osList,
          });
        }),
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private analyticsService: AnalyticsService,
    private store: Store<fromRoot.State>,
  ) {
    /**
     * This gives us a way to get other information needed for effects
     * from the report state.
     */
    this.reports$ = store.pipe(select(fromRoot.getReportsState));
  }

  private buildNameAndPlaysList(list: any[], nameKey: string, playsKey: string): NameAndPlays[] {
    let results: NameAndPlays[] = [];
    for (let item of list) {
      results.push({
        name: item[nameKey],
        plays: item[playsKey],
      });
    }
    return results;
  }

  private calculatePercentageChange(previous: number, current: number): number {
    // division by 0
    if (previous === 0) {
      return undefined;
    }
    return Math.trunc(((current - previous) / previous) * 100);
  }

  private waitForDateRangeThen(callback: (state: fromReports.State) => Observable<Action>): any {
    return this.reports$.pipe(filter((d) => !!d.dateRange), take(1), mergeMap((state: fromReports.State) => {
      return callback(state);
    }));
  }
}
