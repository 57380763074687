import { Component } from '@angular/core';
import { Location } from '@angular/common';

import { NavItem } from '../shared/navigation/nav-item';
import { SlideInOutAnimation } from '../shared/animations/slide-in-out.animation';

@Component({
  styleUrls: ['./page-not-found.component.scss'],
  templateUrl: './page-not-found.component.html',
  animations: [
    SlideInOutAnimation,
  ],
})
export class PageNotFoundComponent {
  menuState = 'in';
  navItems: NavItem[] = [
    { title: 'amedia.content', url: '/app/content', faClass: 'fab fa-youtube' },
    { title: 'amedia.themes', url: '/app/themes', faClass: 'fas fa-cubes' },
    { title: 'amedia.reports', url: '/app/reports', faClass: 'far fa-chart-bar' },
    { title: 'amedia.settings', url: '/app/settings', faClass: 'fas fa-cog' },
  ];

  constructor(private location: Location) {}

  onNavToggle(): void {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  goBack(): void {
    this.location.back();
  }
}
