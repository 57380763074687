import { Action, Store } from '@ngrx/store';
import { SortParams } from 'control-ui-common';

import { Restriction } from '../../../models/restriction';

export const LOAD_RESTRICTIONS = '[Restriction] Load Restrictions';
export const LOAD_RESTRICTIONS_SUCCEEDED = '[Restriction] Load Restrictions Succeeded';
export const LOAD_RESTRICTIONS_FAILED = '[Restriction] Load Restrictions Failed';
export const SELECT_CURR_RESTRICTION = '[Restriction] Select Current Restriction';
export const CHANGE_RESTRICTION_SORT = '[Restriction] Change Sorting Parameters';
export const CREATE_RESTRICTION = '[Restriction] Create New Restriction';
export const CREATE_RESTRICTION_SUCCEEDED = '[Restriction] Create New Restriction Succeeded';
export const CREATE_RESTRICTION_FAILED = '[Restriction] Create New Restriction Failed';
export const UPDATE_RESTRICTION = '[Restriction] Update Restriction';
export const UPDATE_RESTRICTION_SUCCEEDED = '[Restriction] Update Restriction Succeeded';
export const UPDATE_RESTRICTION_FAILED = '[Restriction] Update Restriction Failed';
export const DELETE_RESTRICTION = '[Restriction] Delete Restriction';
export const DELETE_RESTRICTION_SUCCEEDED = '[Restriction] Delete Restriction Succeeded';
export const DELETE_RESTRICTION_FAILED = '[Restriction] Delete Restriction Failed';

export class LoadRestrictions implements Action {
  readonly type = LOAD_RESTRICTIONS;
}

export class LoadRestrictionsSucceeded implements Action {
  readonly type = LOAD_RESTRICTIONS_SUCCEEDED;

  constructor(public restrictions: Restriction[]) {}
}

export class LoadRestrictionsFailed implements Action {
  readonly type = LOAD_RESTRICTIONS_FAILED;
}

export class SelectCurrRestriction implements Action {
  readonly type = SELECT_CURR_RESTRICTION;

  constructor(public restriction: Restriction) {}
}

export class ChangeRestrictionSort implements Action {
  readonly type = CHANGE_RESTRICTION_SORT;

  constructor(public sortParams: SortParams) {}
}

export class CreateRestriction implements Action {
  readonly type = CREATE_RESTRICTION;

  constructor(public restriction: Restriction) {}
}

export class CreateRestrictionSucceeded implements Action {
  readonly type = CREATE_RESTRICTION_SUCCEEDED;

  constructor(public restriction: Restriction) {}
}

export class CreateRestrictionFailed implements Action {
  readonly type = CREATE_RESTRICTION_FAILED;

  constructor(
    public restriction: Restriction,
    public error: any,
  ) {}
}

export class UpdateRestriction implements Action {
  readonly type = UPDATE_RESTRICTION;

  constructor(public restriction: Restriction) {}
}

export class UpdateRestrictionSucceeded implements Action {
  readonly type = UPDATE_RESTRICTION_SUCCEEDED;

  constructor(public restriction: Restriction) {}
}

export class UpdateRestrictionFailed implements Action {
  readonly type = UPDATE_RESTRICTION_FAILED;

  constructor(
    public restriction: Restriction,
    public error: any,
  ) {}
}

export class DeleteRestriction implements Action {
  readonly type = DELETE_RESTRICTION;

  constructor(public id: string) {}
}

export class DeleteRestrictionSucceeded implements Action {
  readonly type = DELETE_RESTRICTION_SUCCEEDED;

  constructor(public id: string) {}
}

export class DeleteRestrictionFailed implements Action {
  readonly type = DELETE_RESTRICTION_FAILED;

  constructor(public error: any) {}
}

export type Actions = (
  LoadRestrictions | LoadRestrictionsSucceeded | LoadRestrictionsFailed |
  SelectCurrRestriction | ChangeRestrictionSort |
  CreateRestriction | CreateRestrictionSucceeded | CreateRestrictionFailed |
  UpdateRestriction | UpdateRestrictionSucceeded | UpdateRestrictionFailed |
  DeleteRestriction | DeleteRestrictionSucceeded | DeleteRestrictionFailed
);
