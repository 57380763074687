import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { Scheduler } from 'rxjs/Scheduler';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { ContentActionManagementService } from '../../../services/content-action-management.service';
import * as ContentRestrictionActions from '../../actions/settings/content-restrictions';
import * as fromRoot from '../../reducers';
import * as fromContentRestriction from '../../reducers/settings/content-restrictions';

export const LOAD_DEBOUNCE = new InjectionToken<number>('Load Debounce');
export const LOAD_SCHEDULER = new InjectionToken<Scheduler>('Load Scheduler');

@Injectable()
export class ContentRestrictionEffects {
  restriction$: Observable<fromContentRestriction.State>;

  // Listen for the 'LOAD_RESTRICTIONS' action
  @Effect()
  loadContentRestrictions$: Observable<Action> = this.actions$
    .pipe(
      ofType(ContentRestrictionActions.LOAD_RESTRICTIONS),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: ContentRestrictionActions.LoadRestrictions) => {
        return this.cams.getRestrictionList().pipe(
          map((payload) => new ContentRestrictionActions.LoadRestrictionsSucceeded(payload)),
          catchError((error) => of(new ContentRestrictionActions.LoadRestrictionsFailed())),
        );
      }),
    );

  // Listen for the CREATE_RESTRICTION' action
  @Effect()
  createContentRestrictions$: Observable<Action> = this.actions$
    .pipe(
      ofType(ContentRestrictionActions.CREATE_RESTRICTION),
      // .debounceTime(this.debounce, this.scheduler || async)
        mergeMap((action: ContentRestrictionActions.CreateRestriction) => {
          return this.cams.createRestriction(action.restriction).pipe(
            map((restriction) => {
              const verbPast: string = 'amedia.created';
              const itemType: string = 'amedia.settings.contentRestriction';
              this.toastrService.success(
                this.translate.instant(
                  'amedia.successMessage',
                  {
                    verbPast: this.translate.instant(verbPast),
                    itemType: this.translate.instant(itemType),
                    item: restriction.name,
                  },
                ),
                this.translate.instant('amedia.success'),
              );
              return new ContentRestrictionActions.CreateRestrictionSucceeded(restriction);
            }),
            catchError((error) => {
              this.toastrService.error(
                this.translate.instant(
                  'amedia.toastr.restrictionSavingError',
                  { name: action.restriction.name },
                ),
                this.translate.instant('common.error.label'),
              );

              return of(new ContentRestrictionActions.CreateRestrictionFailed(action.restriction, error));
            }),
          );
        }),
    );

  // Listen for the 'UPDATE_RESTRICTION' action
  @Effect()
  updateContentRestrictions$: Observable<Action> = this.actions$
    .pipe(
      ofType(ContentRestrictionActions.UPDATE_RESTRICTION),
      // .debounceTime(this.debounce, this.scheduler || async)
        mergeMap((action: ContentRestrictionActions.UpdateRestriction) => {

          let restrictionState: fromContentRestriction.State;
          this.restriction$.pipe(take(1)).subscribe((r) => restrictionState = r);
          action.restriction.id = restrictionState.currContentRestriction.id;

          return this.cams.updateRestriction(action.restriction).pipe(
            map((restriction) => {
              const verbPast: string = 'amedia.updated';
              const itemType: string = 'amedia.settings.contentRestriction';
              this.toastrService.success(
                this.translate.instant(
                  'amedia.successMessage',
                  {
                    verbPast: this.translate.instant(verbPast),
                    itemType: this.translate.instant(itemType),
                    item: restriction.name,
                  },
                ),
                this.translate.instant('amedia.success'),
              );
              return new ContentRestrictionActions.UpdateRestrictionSucceeded(restriction);
            }),
            catchError((error) => {
              this.toastrService.error(
                this.translate.instant(
                  'amedia.toastr.restrictionSavingError',
                  { name: action.restriction.name },
                ),
                this.translate.instant('common.error.label'),
              );

              return of(new ContentRestrictionActions.UpdateRestrictionFailed(action.restriction, error));
            }),
          );
        }),
    );

  // Listen for the 'DELETE_RESTRICTION' action
  @Effect()
  deleteContentRestrictions$: Observable<Action> = this.actions$
    .pipe(
      ofType(ContentRestrictionActions.DELETE_RESTRICTION),
      // .debounceTime(this.debounce, this.scheduler || async)
        mergeMap((action: ContentRestrictionActions.DeleteRestriction) => {
          return this.cams.deleteRestriction(action.id).pipe(
            map(() => new ContentRestrictionActions.DeleteRestrictionSucceeded(action.id)),
            catchError((error) => {
              this.toastrService.error(
                this.translate.instant(
                  'amedia.errors.failedTo',
                  {
                    verb: this.translate.instant('common.delete'),
                    item: this.translate.instant('amedia.settings.contentRestriction'),
                  },
                ),
                this.translate.instant('common.error.label'),
              );
              return of(new ContentRestrictionActions.DeleteRestrictionFailed(error));
            }),
          );
        }),
    );

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private cams: ContentActionManagementService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    @Optional() @Inject(LOAD_DEBOUNCE) private debounce: number = 300,
    /**
     * You inject an optional Scheduler that will be undefined
     * in normal application usage, but its injected here so that you can mock out
     * during testing using the RxJS TestScheduler for simulating passages of time.
     */
    @Optional() @Inject(LOAD_SCHEDULER) private scheduler: Scheduler,
  ) {
    this.restriction$ = this.store.pipe(select(fromRoot.getContentRestrictionState));
  }
}
