import { Observable } from 'rxjs/Observable';
import { Scheduler } from 'rxjs/Scheduler';
import { empty } from 'rxjs/observable/empty';
import { of } from 'rxjs/observable/of';
import { concat, mergeMap, take } from 'rxjs/operators';
import { from } from 'rxjs/observable/from';
import { Injectable, InjectionToken, Optional, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as Bulk from '../actions/bulk';
import * as fromBulk from '../reducers/bulk';
import * as fromRoot from '../reducers';

export const LOAD_DEBOUNCE = new InjectionToken<number>('Load Debounce');
export const LOAD_SCHEDULER = new InjectionToken<Scheduler>('Load Scheduler');

@Injectable()
export class BulkEffects {
  bulk$: Observable<fromBulk.State>;

  // Listen for the 'START_BULK_ACTION' action
  @Effect()
  startBulkAction$: Observable<Action> = this.actions$
    .pipe(
      ofType(Bulk.START_BULK_ACTION),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((bulkAction: Bulk.StartBulkAction) => {
        bulkAction.actions.forEach((a) => a.bulkAction = bulkAction );
        return from(bulkAction.actions);
      }),
    );

  // Listen for the 'BULK_SUB_ACTION_COMPLETE' action
  @Effect()
  bulkSubActionComplete$: Observable<Action> = this.actions$
    .pipe(
      ofType(Bulk.BULK_SUB_ACTION_COMPLETE),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Bulk.BulkSubActionComplete) => {
        let bulkState: fromBulk.State;
        this.bulk$.pipe(take(1)).subscribe((b) => bulkState = b);

        // If all actions are complete, dispatch BulkActionComplete
        const bulkAction: Bulk.StartBulkAction = bulkState.bulkActions[action.bulkActionId];

        if (
          !!bulkAction &&
          bulkAction.actions.every((a) => a.done)
        ) {
          let obs$: Observable<Action> = of(new Bulk.BulkActionComplete(action.bulkActionId));
          if (
            !!bulkAction.doneAction &&
            !bulkAction.actions.find((a) => a.failed)
          ) {
            obs$ = of(bulkAction.doneAction).pipe(concat(obs$));
          }
          return obs$;
        }

        return empty();
      }),
    );

  // Listen for the 'BULK_CANCEL_SUB_ACTION' action
  @Effect()
  bulkCancelSubAction$: Observable<Action> = this.actions$
    .pipe(
      ofType(Bulk.BULK_CANCEL_SUB_ACTION),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Bulk.BulkCancelSubAction) => {
        // Run cancel callbacks
        action.subAction.cancel();

        return action.subAction.finish(empty());
      }),
    );

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    @Optional() @Inject(LOAD_DEBOUNCE) private debounce: number = 300,
    /**
     * You inject an optional Scheduler that will be undefined
     * in normal application usage, but its injected here so that you can mock out
     * during testing using the RxJS TestScheduler for simulating passages of time.
     */
    @Optional() @Inject(LOAD_SCHEDULER) private scheduler: Scheduler,
  ) {
    this.bulk$ = this.store.pipe(select(fromRoot.getBulkState));
  }
}
