import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../shared/redux/reducers';
import * as Auth from '../../shared/redux/actions/auth';

@Component({
  template: ``,
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new Auth.Logout());
    this.router.navigate([ '/auth/login' ]);
  }
}
