import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { of } from 'rxjs/observable/of';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ScriptsService {
  private loaded: {[src: string]: BehaviorSubject<boolean>} = {};

  public load(src: string): Observable<boolean> {
    if (!this.loaded[src]) {
      this.loaded[src] = new BehaviorSubject(false);

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.onload = () => this.loaded[src].next(true);
      script.onerror = () => this.loaded[src].next(true);
      document.getElementsByTagName('head')[0].appendChild(script);
    }

    return this.loaded[src].pipe(filter((loaded) => loaded));
  }
}
