import { Subject } from 'rxjs/Subject';
import * as Highcharts from 'highcharts';
import { Observable } from 'rxjs';
import { OnInit, Input, SimpleChanges, Component, OnChanges, OnDestroy } from '@angular/core';
import * as fromReports from '../../redux/reducers/reports';
import * as fromRoot from '../../redux/reducers';
import { Store, MemoizedSelector, select } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import 'moment-timezone';

export interface SeriesDetails {
  name: string;
  data?: [[number, number]];
}

@Component({
  selector: 'll-reports-chart',
  templateUrl: './reports-chart.component.html',
  styleUrls: ['./reports-chart.component.scss'],
})
export class ReportsChartComponent implements OnInit, OnChanges, OnDestroy {
  reportState$: Observable<fromReports.State>;

  @Input() reportType: string;
  @Input() chartType: string;
  @Input() title: string;
  @Input() xAxisType: string;
  @Input() xAxisTitle: string;
  @Input() yAxisType: string;
  @Input() yAxisTitle: string;
  @Input() series: SeriesDetails[] = [];
  @Input() selectedField: string;
  @Input() dataSelector: MemoizedSelector<object, any>;
  @Input() loading: boolean;

  Highcharts = Highcharts;
  chartOptions = {
    chart: {
      type: this.chartType,
    },
    title: {
      text: this.title,
    },
    xAxis: {
      endOnTick: true,
      dateTimeLabelFormats: {
        millisecond: '%e. %b %H:%M:%S.%L',
      },
      title: {
        text: this.xAxisTitle,
      },
      type: this.xAxisType,
    },
    yAxis: {
      endOnTick: true,
      title: {
        text: this.yAxisTitle,
      },
      type: this.yAxisType,
    },
    series: this.series,
    credits: {
      enabled: false,
    },
  };
  updateFlag: boolean = false;

  dataFields = {
    media: {
      title: 'media_title',
    },
    channel: {
      title: 'channel_title',
    },
    domain: {
      title: 'referrer_url_host',
    },
  };

  private chart: Highcharts.Chart;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
     public store: Store<fromRoot.State>,
  ) {
    this.reportState$ = store.pipe(select(fromRoot.getReportsState));

  }

  ngOnInit() {
    this.createListener();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let prop in changes) {
      if (this.hasOwnProperty(prop)) {
        this[prop] = changes[prop].currentValue;
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  onChartInstanceLoaded(chart: Highcharts.Chart): void {
    this.chart = chart;
  }

  setIntervalChartData(data: any) {
    this.series = [];
    for (let item in data) {
      if (data.hasOwnProperty(item)) {
        let seriesDetails: SeriesDetails = {
          name: '',
        };
        seriesDetails.name = data[item][0][this.dataFields[this.reportType].title];
        for (let dataElements in data[item]) {
          if (data[item].hasOwnProperty(dataElements)) {
            let intervalData: any = data[item][dataElements];
            let startDate = moment.utc(intervalData.start_date);
            let value: number;
            value = intervalData[this.selectedField];
            if (!seriesDetails.data) {
              seriesDetails.data = [[startDate.valueOf(), value]];
            } else {
              seriesDetails.data.push([startDate.valueOf(), value]);
            }
          }
        }
        this.series.push(seriesDetails);
      }
    }

    this.refreshChart();
  }

  private refreshChart() {
    this.chartOptions.chart.type = this.chartType;
    this.chartOptions.title.text = this.title;
    this.chartOptions.xAxis.type = this.xAxisType;
    this.chartOptions.xAxis.title.text = this.xAxisTitle;
    this.chartOptions.yAxis.type = this.yAxisType;
    this.chartOptions.yAxis.title.text = this.yAxisTitle;

    for (let x: number = this.chartOptions.series.length; x > -1; x--) {
      this.chartOptions.series.pop();
    }
    for (let serie in this.series) {
      if (this.series.hasOwnProperty(serie)) {
        this.chartOptions.series.push(this.series[serie]);
      }
    }
    this.updateFlag = true;
    if (this.chart) {
      setTimeout(() => {
        this.chart.reflow();
      });
    }
  }

  private createListener() {
    this.store.pipe(select(this.dataSelector), takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.setIntervalChartData(data);
    });
  }
}
