import { Component, Input } from '@angular/core';

import { SlideInOutAnimation } from '../animations/slide-in-out.animation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'll-top-nav',
  styleUrls: ['./top-nav.component.scss'],
  templateUrl: './top-nav.component.html',
  animations: [
    SlideInOutAnimation,
  ],
})
export class TopNavComponent {
  @Input() menuState;
  control = '';
}
