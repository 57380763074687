import { EnvConfigurationService, Configuration } from './environment-configuration.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as fromRoot from '../redux/reducers';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

/**
 * The purpose of this service is to allow for the presentation of a
 * "You're session is about to expire" popup to the user so that they
 * are not abruptly logged out of the app when they click on something
 * and the call response with a 401. This is intended to be used with
 * an http interceptor which will reset the countdown on each call.
 */

@Injectable()
export class IdleTimeoutService {
  // a margin of safety for the countdown to account for possible delays in initialization
  readonly SAFETY_MARGIN = 3000;
  get timeoutApproaching$(): Observable<void> {
    return this._timeoutApproaching$.asObservable();
  }

  env: Configuration;
  private _timeoutApproaching$ = new Subject<void>();
  private timeout: any;
  private isStopped: boolean = false;

  constructor(
    private store: Store<fromRoot.State>,
    private envConfigService: EnvConfigurationService,
    ) {
      this.envConfigService.load().pipe(take(1)).subscribe((res) => this.env = res);
  }

  reset(): void {
    // no reset if idle explicitly stopped
    if (this.isStopped) {
      return;
    }
    clearTimeout(this.timeout);
    this.startTimer();
  }

  // allow resets to happen normally but do not start the timer
  allow(): void {
    this.isStopped = false;
  }

  // block idle timeout until start() is called and stop current timer
  // usage scenario: login page should not trigger idle timeout
  block(): void {
    clearTimeout(this.timeout);
    this.isStopped = true;
  }

  private startTimer(): void {
    this.timeout = setTimeout(() => {
      this._timeoutApproaching$.next();
    }, this.env.IDLE_TIMEOUT_VALUE - this.env.IDLE_TIMEOUT_ALERT_DELTA - this.SAFETY_MARGIN);
  }
}
