import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Scheduler } from 'rxjs/Scheduler';
import { async } from 'rxjs/scheduler/async';
import { empty } from 'rxjs/observable/empty';
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { interval } from 'rxjs/observable/interval';
import { timer } from 'rxjs/observable/timer';
import { zip } from 'rxjs/observable/zip';
import { catchError, concat, exhaustMap, first, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { Injectable, InjectionToken, Optional, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DRIVERS, Locker } from 'angular-safeguard';
import { SortDirection } from 'control-ui-common';

import * as Content from '../actions/content';
import * as ChannelActions from '../actions/channel';
import * as GroupActions from '../actions/group';
import * as fromRoot from '../reducers';
import * as fromContent from '../reducers/content';
import { MediaSearchService } from '../../services/media-search.service';
import { MediaDataService } from '../../services/media-data.service';
import { Media } from '../../models/media';
import { Channel } from '../../models/channel';
import { Group } from '../../models/group';
import { PaginatedResponse } from '../../models/paginated-response';
import { ContentActionType } from '../../models/content-action-type';
import { CapitalizePipe } from '../../utils/capitalize.pipe';
import { contentTypeToId, mssToAmedia } from '../../utils/content-utils';
import { Changeset } from '../../models/changeset';
import { MediaTotals } from '../../models/facets';
import { ChannelSection } from '../reducers/channel';
import { GroupSection } from '../reducers/group';
import { Errors } from '../../models/errors';
import { parseShimErrors } from '../../utils/parse-errors';

export const LOAD_DEBOUNCE = new InjectionToken<number>('Load Debounce');
export const LOAD_SCHEDULER = new InjectionToken<Scheduler>('Load Scheduler');

@Injectable()
export class ContentEffects {
  mediaChangesetPollingActive$: Observable<boolean>;
  channelsChangesetPollingActive$: Observable<boolean>;
  channelgroupsChangesetPollingActive$: Observable<boolean>;
  facetPollingActive$: Observable<boolean>;
  newlyCreatedMedia$: Observable<any[]>;
  content$: Observable<fromContent.State>;

  // Listen for the 'LOAD_CONTENT_LIST' action
  @Effect()
  loadContentList$: Observable<Action> = this.actions$
    .pipe(
      ofType(Content.LOAD_CONTENT_LIST),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Content.LoadContentList) => {
        // Compile dynamic service method
        const method = 'get' + new CapitalizePipe().transform(action.payload.type) + 'List';

        // Grab current filters from redux store
        let contentState: fromContent.State;
        this.content$.pipe(take(1)).subscribe((s) => contentState = s);

        const service: any = action.payload.type === 'media' ? this.mss : this.mds;

        // Restart all polling based on the current filters
        let obs$: Observable<Action> = of(new Content.StopChangesetPolling(contentState.contentType)).pipe(
          concat(of(new Content.StartChangesetPolling(contentState.contentType))),
        );

        if (contentState.contentType === 'media') {
          obs$ = obs$.pipe(
            concat(of(new Content.StopFacetPolling())),
            concat(of(new Content.StartFacetPolling())),
          );
        }

        this.locker.set(
          DRIVERS.LOCAL,
          `filters.${action.payload.type}`,
          JSON.stringify(contentState.filters),
        );

        // Hit API and fire off success / failure
        return obs$.pipe(concat(
          action.finish(service[method](contentState.filters).pipe(
            map((payload) => new Content.ContentListLoaded(payload as PaginatedResponse, action.payload.type)),
            catchError((error) => of(new Content.ContentListFailedToLoad('media')))),
          ),
        ));
      }),
    );

  // Listen for the 'START_CHANGESET_POLLING' action
  @Effect()
  startChangesetPolling$: Observable<Action> = this.actions$
    .pipe(
      ofType(Content.START_CHANGESET_POLLING),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Content.StartChangesetPolling) => {
        return timer(0, 15000).pipe(
          takeUntil(
            this[`${action.contentType}ChangesetPollingActive$`].pipe(
              first((active) => !active),
            ),
          ),
          map((v) => new Content.GetChangeset(action.contentType)),
        );
      }),
    );

  // Listen for the 'GET_CHANGESET' action
  @Effect()
  getChangeset$: Observable<Action> = this.actions$
    .pipe(
      ofType(Content.GET_CHANGESET),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Content.GetChangeset) => {
        let after: moment.Moment;
        this.content$.pipe(take(1)).subscribe((content) => {
          after = content[`last${_.upperFirst(action.contentType)}Changeset`];
        });

        return this.mds.getChangeset(action.contentType, after).pipe(
          map((c: Changeset) => {
            return new Content.GetChangesetSucceeded(
              action.contentType,
              c.changeset,
              after, // data changed since last time
              moment(c.timestamp), // server timestamp
            );
          }),
          catchError((error) => of(new Content.GetChangesetFailed(action.contentType))),
        );
      }),
    );

  // Listen for the 'START_FACET_POLLING' action
  @Effect()
  startFacetPolling$: Observable<Action> = this.actions$
    .pipe(
      ofType(Content.START_FACET_POLLING),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Content.StartFacetPolling) => {
        return timer(0, 15000).pipe(
          takeUntil(
            this.facetPollingActive$.pipe(
              first((active) => !active),
            ),
          ),
          map(() => new Content.UpdateFacets()),
        );
      }),
    );

  // Listen for the 'UPDATE_FACETS' action
  @Effect()
  updateFacets$: Observable<Action> = this.actions$
    .pipe(
      ofType(Content.UPDATE_FACETS),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Content.UpdateFacets) => {
        let contentState: fromContent.State;
        this.content$.pipe(take(1)).subscribe((s) => contentState = s);

        return zip(
          this.mss.getStateFacets(contentState.filters),
          this.mss.getTypeFacets(contentState.filters),
        ).pipe(
          map((facets: Array<Partial<MediaTotals>>) => new Content.UpdateFacetsSucceeded(
            _.assign({}, ...facets),
          )),
          catchError((error) => {
            this.toastr.error(
              this.translate.instant(
                'amedia.errors.failedTo',
                {
                  verb: _.lowerCase(this.translate.instant('amedia.update')),
                  item: _.lowerCase(this.translate.instant('amedia.content')),
                },
              ),
              this.translate.instant('common.error.label'),
            );
            return of(new Content.UpdateFacetsFailed());
          }),
        );
      }),
    );

  // Listen for the 'CREATE_MEDIA' action
  @Effect()
  createMedia$: Observable<Action> = this.actions$
    .pipe(
      ofType(Content.CREATE_MEDIA),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Content.CreateMedia) => {

        /*
         * The angular HttpClient explicitly executes requests when the
         * request observable is subscribed to.  This means that subscribing
         * more than once will perform the request multiple times.
         *
         * Fortunately, the HttpClient also handles calling abort() on the
         * underlying XMLHttpRequest if you unsubscribe from the request observable.
         *
         * We create a wrapper observable to continue chaining redux actions,
         * but we hold on to the subscription to be used when cancelling uploads.
         */
        return new Observable((observer) => {
          // For consistency with ML4, strip the file extension
          let name = action.file.name;
          let nameParts = name.split('.');
          if (nameParts.length > 1) {
            name = nameParts.slice(0, -1).join('.');
          }
          // Hold reference to upload subscription for cancelling
          const sub = this.mds.createMedia(name, action.file).pipe(
            mergeMap((event: HttpEvent<any>) => {
              switch (event.type) {
              case HttpEventType.UploadProgress:
                return action.progressUpdate(
                  Math.round(100 * event.loaded / event.total),
                );
              case HttpEventType.Response:
                return action.finish(
                  of(new Content.CreateMediaSucceeded({ ...event.body, file: action.file })),
                );
              default:
                return empty();
              }
            }),
            catchError((error) => {
              this.toastr.error(
                this.translate.instant(
                  'amedia.toastr.uploadFailedFor',
                  { name: action.file.name },
                ),
                this.translate.instant('amedia.toastr.uploadFailed'),
              );

              return action.finishWithError(
                of(new Content.CreateMediaFailed(action.file)),
                error,
              );
            }),
          ).subscribe((o) => observer.next(o));

          // Add teardown callback for subscription.
          sub.add(() => observer.complete());

          // Unsubscribing from the http request will cancel the upload
          action.registerCancelCallback(() => {
            sub.unsubscribe();
          });
        });
      }, null, 5), // Limits to 5 concurrent observables
    );

  // Listen for the 'REPLACE_SOURCE' action
  @Effect()
  replaceSource$: Observable<Action> = this.actions$
    .pipe(
      ofType(Content.REPLACE_SOURCE),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Content.ReplaceSource) => {

        return new Observable((observer) => {
          // Hold reference to upload subscription for cancelling
          const sub = this.mds.replaceSource(action.media.mediaId, action.file).pipe(
            mergeMap((event: HttpEvent<any>) => {
              switch (event.type) {
              case HttpEventType.UploadProgress:
                return action.progressUpdate(
                  Math.round(100 * event.loaded / event.total),
                );
              case HttpEventType.Response:
                this.toastr.success(
                  this.translate.instant('amedia.toastr.sourceReplaced.description'),
                  this.translate.instant('amedia.toastr.sourceReplaced'),
                );

                return action.finish(
                  of(new Content.ReplaceSourceSucceeded({ ...event.body, file: action.file })),
                );
              default:
                return empty();
              }
            }),
            catchError((error) => {
              this.toastr.error(
                _.isEmpty(error.error)
                  ? this.translate.instant(
                    'amedia.content.replaceSourceFailedFor',
                    { name: action.media.title },
                  )
                  : error.error,
                this.translate.instant('amedia.content.replaceSourceFailed'),
              );

              return action.finishWithError(
                of(new Content.ReplaceSourceFailed(action.file)),
                error,
              );
            }),
          ).subscribe((o) => observer.next(o));

          // Add teardown callback for subscription.
          sub.add(() => observer.complete());

          // Unsubscribing from the http request will cancel the upload
          action.registerCancelCallback(() => {
            sub.unsubscribe();
          });
        });
      }, null, 5), // Limits to 5 concurrent observables
    );

  // Listen for the 'CREATE_MEDIA_SUCCEEDED' action
  @Effect()
  createMediaSucceeded$: Observable<Action> = this.actions$
    .pipe(
      ofType(Content.CREATE_MEDIA_SUCCEEDED),
      // Ignore new creates because polling has already started
      exhaustMap((action: Content.CreateMediaSucceeded) => {
        return interval(5000).pipe(
          // Poll until there are no more newly created media
          takeUntil(
            this.newlyCreatedMedia$.pipe(
              first((newMedia) => newMedia.length === 0),
            ),
          ),
          mergeMap(() => this.newlyCreatedMedia$.pipe(take(1))),
          mergeMap((newMedia) => {
            // Query to see if the new media IDs are being returned yet
            return this.mss.getMediaList(
              {
                page: 1,
                pageSize: newMedia.length,
                sortBy: 'title',
                sortDir: SortDirection.ASC,
                queryParams: { id: _.map(newMedia, (m) => m.media_id) },
              },
            ).pipe(
              mergeMap((res: PaginatedResponse) => {
                // Mark those returned as created
                if (res.results.length > 0) {
                  let obs$: Observable<Action> = of(
                    new Content.MarkMediaCreated(_.map(res.results, (m) => m.mediaId)),
                  );

                  this.content$.pipe(take(1)).subscribe((contentState) => {
                    // Reload current page if new media exists
                    if (contentState.contentType === 'media') {
                      const { searchText, ...filters } = contentState.filters;

                      obs$ = this.mss.getMediaList({ searchText: undefined, ...filters }).pipe(
                        map((payload) => new Content.ContentListLoaded(payload as PaginatedResponse, 'media', true)),
                        catchError((error) => of(new Content.ContentListFailedToLoad('media'))),
                        concat(obs$),
                      );
                    }
                  });

                  return obs$;
                }
                return empty();
              }),
            );
          }),
        );
      }),
    );

  // Listen for the 'CHUNKED_CONTENT_DELETE' action
  @Effect()
  chunkedConentDelete$: Observable<Action> = this.actions$
    .pipe(
      ofType(Content.CHUNKED_CONTENT_DELETE),
      mergeMap((action: Content.ChunkedContentDelete) => {
        const idKey: string = contentTypeToId(action.payload.contentType);
        let method: string;
        let ids: string[];
        const contentType = action.payload.contentType;
        switch (contentType) {
          case 'media':
            method = 'mediaBulkDelete';
            ids = _.map(action.payload.contentList, (c) => {
              return c[idKey];
            });
            break;
          default:
            throw Error(`Chunked content delete not implemented for ${contentType}`);
        }
        return this.mds[method](ids).pipe(
          mergeMap(() => action.finish(of(new Content.ChunkedContentDeleteSucceeded()))),
          catchError((errorPayload) => {
            this.toastr.error(
              // message
              this.translate.instant(
                'amedia.toastr.bulkDeletionFailed',
                { contentType },
              ),
              // title
              this.translate.instant('amedia.content.error'),
            );

            return action.finishWithError(
              of(
                new Content.ChunkedContentDeleteFailed(action.payload, errorPayload)),
                errorPayload,
              );
          }),
        );
      }),
    );

  // Listen for the 'PERFORM_ACTION' action
  @Effect()
  performContentAction$: Observable<Action> = this.actions$
    .pipe(
      ofType(Content.PERFORM_ACTION),
      // .debounceTime(this.debounce, this.scheduler || async)
      mergeMap((action: Content.PerformAction) => {
        let method: string;
        let params: any;
        let type = action.payload.contentType;
        if (type !== 'media') {
          type = type.slice(0, -1);
        }
        type = new CapitalizePipe().transform(type);
        switch (action.payload.actionType) {
        case ContentActionType.Create:
          method = 'create' + type;
          params = action.payload.params;
          break;
        case ContentActionType.Delete:
          // Compile dynamic service method
          method = 'delete' + type;
          params = action.payload.params;
          break;
        case ContentActionType.ReEncode:
          method = 'update' + type;
          params = { state: 'Processing' };
          break;
        case ContentActionType.AddTo:
          // method = 'addTo' + type;
          // params = action.payload.params;
          break;
        case ContentActionType.Publish:
          method = 'publishUnpublish' + type;
          params = 'publish';
          break;
        case ContentActionType.Unpublish:
          method = 'publishUnpublish' + type;
          params = 'unpublish';
          break;
        case ContentActionType.Edit:
          method = 'update' + type;
          params = action.payload.params;
          break;
        }

        let responseAction: Observable<Action> = empty();

        if (!!method && method.includes('create')) {
          responseAction = this.mds[method](params).pipe(
            map((payload) => new Content.PerformActionSucceeded(action.payload.content, action.payload.contentType)),
            catchError((error) => of(new Content.PerformActionFailed(action.payload, error))),
          );
        } else if (!!method) {
          const id: string = contentTypeToId(action.payload.contentType);
          const state: string = action.payload.contentType === 'media' ? 'mediaState' : 'state';

          responseAction = this.mds[method](action.payload.content[id], params).pipe(
            mergeMap((newContent: any) => {
              switch (action.payload.actionType) {
              case ContentActionType.Publish:
                newContent = {
                  [id]: action.payload.content[id],
                  [state]: 'Published',
                };
                break;
              case ContentActionType.Unpublish:
                newContent = {
                  [id]: action.payload.content[id],
                  [state]: action.payload.contentType === 'media' ? 'Publishable' : 'NotPublished',
                };
                break;
              }
              return action.finish(
                of(new Content.PerformActionSucceeded(newContent, action.payload.contentType)),
              );
            }),
            catchError((error) => {
              const errors: Errors = parseShimErrors(error.error || {});

              let t: string;
              if (action.payload.contentType === 'media') {
                t = this.translate.instant('amedia.content.media');
              } else if (action.payload.contentType === 'channels') {
                t = this.translate.instant('amedia.content.channel');
              } else {
                t = this.translate.instant('amedia.content.group');
              }

              this.toastr.error(
                _.isEmpty(errors.general)
                  ? this.translate.instant(
                    'amedia.toastr.failedToPerformAction',
                    { id: action.payload.content[id].bold(), contentType: t },
                  )
                  : errors.general[0],
                this.translate.instant('amedia.content.error'),
                { enableHtml: true },
              );

              return action.finishWithError(
                of(new Content.PerformActionFailed(action.payload, error)),
                error,
              );
            }),
          );
        }

        return responseAction;
      }),
    );

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private locker: Locker,
    private toastr: ToastrService,
    private translate: TranslateService,
    private mss: MediaSearchService,
    private mds: MediaDataService,
    @Optional() @Inject(LOAD_DEBOUNCE) private debounce: number = 300,
    /**
     * You inject an optional Scheduler that will be undefined
     * in normal application usage, but its injected here so that you can mock out
     * during testing using the RxJS TestScheduler for simulating passages of time.
     */
    @Optional() @Inject(LOAD_SCHEDULER) private scheduler: Scheduler,
  ) {
    this.content$ = this.store.pipe(select(fromRoot.getContentState));
    this.mediaChangesetPollingActive$ = this.store.pipe(select(fromRoot.getMediaChangesetPollingActive));
    this.channelsChangesetPollingActive$ = this.store.pipe(select(fromRoot.getChannelsChangesetPollingActive));
    this.channelgroupsChangesetPollingActive$ = this.store.pipe(select(fromRoot.getGroupsChangesetPollingActive));
    this.facetPollingActive$ = this.store.pipe(select(fromRoot.getFacetPollingActive));
    this.newlyCreatedMedia$ = this.store.pipe(select(fromRoot.getNewlyCreatedMedia));
  }
}
