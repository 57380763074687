import * as _ from 'lodash';

import * as bulk from '../actions/bulk';
import * as content from '../actions/content';

export interface State {
  bulkActions: any;
}

export const initialState: State = {
  bulkActions: {
    // 1: new bulk.StartBulkAction([
    //   new content.CreateMedia(
    //     new File([new ArrayBuffer(10000000)],
    //     'Really long filename that will probably wrap.zip', { type: 'application/zip' })
    //   ),
    //   new content.CreateMedia(new File([''], 'Mock.zip', { type: 'application/zip' })),
    //   new content.CreateMedia(new File([''], 'Mock.zip', { type: 'application/zip' })),
    //   new content.CreateMedia(new File([''], 'Mock.zip', { type: 'application/zip' })),
    //   new content.CreateMedia(new File([''], 'Mock.zip', { type: 'application/zip' })),
    //   new content.CreateMedia(new File([''], 'Mock.zip', { type: 'application/zip' })),
    //   new content.CreateMedia(new File([''], 'Mock.zip', { type: 'application/zip' })),
    //   new content.CreateMedia(new File([''], 'Mock.zip', { type: 'application/zip' })),
    //   new content.CreateMedia(new File([''], 'Mock.zip', { type: 'application/zip' }))
    // ])
  },
};

export function reducer(
  state = initialState,
  action: bulk.Actions,
): State {
  switch (action.type) {
  case bulk.START_BULK_ACTION:
    return {
      bulkActions: {
        ...state.bulkActions,
        [action.id]: action,
      },
    };
  case bulk.BULK_SUB_ACTION_UPDATE:
    action.subAction.progress = action.progress;
    return state;
  case bulk.BULK_SUB_ACTION_COMPLETE:
    action.subAction.done = true;
    return {
      bulkActions: { ...state.bulkActions },
    };
  case bulk.BULK_SUB_ACTION_FAILED:
    action.subAction.progress = Math.max(50, action.subAction.progress);
    action.subAction.failed = true;
    return {
      bulkActions: { ...state.bulkActions },
    };
  case bulk.BULK_ACTION_COMPLETE:
    return {
      bulkActions: _.pickBy(state.bulkActions, (v, k) => Number(k) !== action.bulkActionId),
    };
  default:
    return state;
  }
}

// export const getUserLoading = (state: State) => state.userLoading;
// export const getUserLoadingFaild = (state: State) => state.userLoadingFailed;
export const getBulkActions = (state: State) => state.bulkActions;
